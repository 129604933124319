import React, { SVGProps } from "react";

export const THEETH_47 = (props: SVGProps<SVGSVGElement>) => {

    return (
		<>
           <svg width="2.92rem" height="5rem" viewBox="0 0 38 66" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g filter="url(#filter0_i_402_4961)">
<path d="M31.9382 62.4649C44.7641 67.2149 29.1269 55.1009 32.6563 16.626C26.1648 11.0843 10.6589 0.335012 3.43893 10.215C2.7313 19.951 7.4813 64.601 20.7813 65.076C26.9706 65.297 14.1313 43.701 17.4563 40.376C23.6321 40.8509 25.1244 59.9415 31.9382 62.4649Z" fill="url(#paint0_radial_402_4961)"/>
</g>
<g filter="url(#filter1_i_402_4961)">
<path d="M32.3499 16.2643C20 5.81963 34.2504 60.8788 36.1501 63.2575C37.2684 64.6578 38.0499 62.3101 33.775 46.1601C32.6546 41.9275 32.6371 36.7038 32.3499 30.9601C31.8749 21.4601 34.9152 18.4338 32.3499 16.2643Z" fill="url(#paint1_linear_402_4961)"/>
</g>
<path d="M33.3742 7.6C31.0942 -0.76 21.6575 2.21672 17.2242 4.75008C15.7993 2.37515 9.62412 0 7.24912 0C4.87412 0 -1.30088 1.9 1.07412 13.3C2.97412 22.42 12.6325 21.2167 17.2242 19.475C28.1492 25.175 36.2242 18.05 33.3742 7.6Z" fill="url(#paint2_linear_402_4961)"/>
<path d="M33.3742 7.6C31.0942 -0.76 21.6575 2.21672 17.2242 4.75008C15.7993 2.37515 9.62412 0 7.24912 0C4.87412 0 -1.30088 1.9 1.07412 13.3C2.97412 22.42 12.6325 21.2167 17.2242 19.475C28.1492 25.175 36.2242 18.05 33.3742 7.6Z" fill="url(#paint3_radial_402_4961)" fill-opacity="0.3"/>
<g filter="url(#filter2_f_402_4961)">
<path d="M16.565 9.48726L12.7593 5.04718C12.4123 4.64245 12.8927 4.06116 13.3556 4.32564L14.2996 4.86509L17.1496 6.76509L21.6412 5.1798C22.1685 4.99373 22.528 5.71282 22.0628 6.02295L16.8147 9.5217C16.7347 9.575 16.6276 9.56022 16.565 9.48726Z" fill="#BBA8A8"/>
<path d="M16.565 9.48726L12.7593 5.04718C12.4123 4.64245 12.8927 4.06116 13.3556 4.32564L14.2996 4.86509L17.1496 6.76509L21.6412 5.1798C22.1685 4.99373 22.528 5.71282 22.0628 6.02295L16.8147 9.5217C16.7347 9.575 16.6276 9.56022 16.565 9.48726Z" stroke="#BBA8A8" stroke-width="0.95"/>
</g>
<g filter="url(#filter3_f_402_4961)">
<path d="M19.4332 12.465C18.4832 17.215 22.9847 15.79 25.6081 15.79C28.2315 15.79 27.5083 13.6137 27.5083 11.515C27.5083 9.41631 20.1566 7.23999 17.5332 7.23999C14.9098 7.23999 16.5832 11.04 19.4332 12.465Z" fill="#FDFDFD"/>
</g>
<defs>
<filter id="filter0_i_402_4961" x="-0.428906" y="2.3936" width="37.5114" height="62.684" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-5.7" dy="-5.7"/>
<feGaussianBlur stdDeviation="1.9"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_402_4961"/>
</filter>
<filter id="filter1_i_402_4961" x="27.3647" y="14.9478" width="11.5665" height="50.5533" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="1.9" dy="1.9"/>
<feGaussianBlur stdDeviation="1.9"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_402_4961"/>
</filter>
<filter id="filter2_f_402_4961" x="9.31162" y="0.930029" width="16.2942" height="11.9485" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="1.425" result="effect1_foregroundBlur_402_4961"/>
</filter>
<filter id="filter3_f_402_4961" x="11.4741" y="2.48999" width="20.8755" height="18.2505" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2.375" result="effect1_foregroundBlur_402_4961"/>
</filter>
<radialGradient id="paint0_radial_402_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(15.2497 32.4151) rotate(84.6678) scale(35.7798 20.4842)">
<stop stop-color="#D7BA8E"/>
<stop offset="0.4967" stop-color="#A47F4A"/>
<stop offset="1" stop-color="#886024"/>
</radialGradient>
<linearGradient id="paint1_linear_402_4961" x1="27.1077" y1="20.9001" x2="39.4577" y2="63.6501" gradientUnits="userSpaceOnUse">
<stop stop-color="#725935"/>
<stop offset="1" stop-color="#5B482A"/>
</linearGradient>
<linearGradient id="paint2_linear_402_4961" x1="17.2456" y1="0" x2="17.2456" y2="21.4948" gradientUnits="userSpaceOnUse">
<stop offset="0.64" stop-color="white"/>
<stop offset="1" stop-color="#B48B8B"/>
</linearGradient>
<radialGradient id="paint3_radial_402_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(6.10059 8.94116) rotate(32.9052) scale(9.61802 14.9501)">
<stop stop-color="white" stop-opacity="0"/>
<stop offset="1" stop-color="#9B9B9B"/>
</radialGradient>
</defs>
</svg>


        </>
	);
};