import { authHost } from '../axios';
import { IStageService } from '../interface/stage-service.interface';

export class StagaeServiceService {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor() {}
	static async findAll(query: any): Promise<IStageService[] | null> {
		const { data } = await authHost.get(`stage_service?${query}`);
		return data;
	}

	static async findOne(uuid: string) {
		const { data } = await authHost.get(`stage_service/${uuid}`);
		return data;
	}

	static async create(data: IStageService) {
		const stage_service = await authHost.post('stage_service',data)
		return stage_service;
	}

	static async update(uuid: string, data: IStageService) {
		const stage_service = await authHost.patch(`stage_service/${uuid}`, data)		
		return stage_service;
	}
}
