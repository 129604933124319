import { authHost } from '../axios';
import { IConsultationList } from '../interface/consultation-list.interface';

export class ConsultationListService {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor() {}
	static async findAll(query: any): Promise<IConsultationList[] | null> {
		const { data } = await authHost.get(`consultation_list?${query}`);

		return data;
	}

	static async findOne(uuid: string) {
		const { data } = await authHost.get(`consultation_list/${uuid}`);
		return data;
	}

	static async create(data: IConsultationList) {
		console.log(data, 'datadata');

		const consultation_list = await authHost.post('consultation_list', JSON.parse(JSON.stringify(data)) );
		console.log(444984984984984, consultation_list);

		return consultation_list;
	}

	static async update(uuid: string, data: IConsultationList) {
		const consultation_list = await authHost.patch(`consultation_list/${uuid}`, data);
		return consultation_list;
	}
}
