import React, { FC } from 'react';
import PropTypes from 'prop-types';

interface ILogoProps {
	width?: number;
	height?: number;
}
const Logo: FC<ILogoProps> = ({ width, height }) => {
	return (
		<svg
			width={height !== 854 && !!height ? height * (2155 / 854) : width}
			height={width !== 2155 && !!width ? width * (854 / 2155) : height}
			viewBox='0 0 117 143'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g id='Frame' clipPath='url(#clip0_33_1152)'>
				<g id='Group'>
					<g id='Layer-1'>
						<g id='Group_2'>
							<path
								id='Vector'
								d='M42.0834 9.74642C42.0948 15.1584 49.4178 19.5754 58.4282 19.5784C67.4386 19.5814 74.7655 15.0752 74.8393 9.76531C74.8278 4.35328 67.4037 0.0227366 58.3933 0.0197029C49.3829 0.0166679 42.1571 4.43654 42.0834 9.74642ZM47.5205 9.8268C47.5519 6.12716 52.3975 3.21463 58.4949 3.25595C64.5072 3.19514 69.4176 6.1738 69.4021 9.68493C69.3708 13.3846 64.5252 16.2971 58.4277 16.2558C52.4154 16.3166 47.5051 13.3379 47.5205 9.8268Z'
								fill='url(#paint0_linear_33_1152)'
							/>
						</g>
						<g id='Group_3'>
							<path
								id='Vector_2'
								d='M7.90072 22.4288C7.65047 23.1672 7.70382 23.6465 7.64001 24.4008C7.91372 37.8128 11.7722 49.9139 14.5015 63.1539C15.7064 69.9978 15.2279 75.6547 15.2129 82.4903C15.1013 83.8102 15.0055 84.9416 14.6222 86.1436C14.505 86.4185 14.3879 86.6935 14.2708 86.9684C13.6531 88.7203 10.3351 92.4277 13.5101 93.7409C17.1431 95.1878 19.3744 91.0087 20.0559 88.5025C20.189 88.039 20.2368 87.4733 20.3699 87.0098C21.9666 78.124 19.0658 58.0327 17.4023 47.7316C17.2637 47.1501 17.2103 46.6708 16.9705 46.1757C16.7946 44.9264 16.155 42.4984 16.2666 41.1784C16.1281 40.597 16.5328 40.2514 16.5328 40.2514C17.1934 40.2123 17.966 42.1768 18.2058 42.6719C22.5697 51.0175 24.1052 59.5036 26.658 68.1708C28.3475 73.7262 29.5469 79.5251 32.845 84.9319C33.7986 85.8672 34.3101 86.4802 35.6311 86.4021C37.9696 86.505 38.4217 82.2702 38.9382 80.6047C39.2363 79.3005 39.4491 77.8942 39.5767 76.3857C39.6241 72.4965 39.5598 69.9272 39.6231 65.8495C39.132 59.4458 36.8344 47.7617 38.0223 48.1471C38.7521 48.3987 39.1305 49.4753 39.4396 50.2611C41.2357 56.7751 42.7282 63.5484 44.9984 70.0076C46.4322 75.2566 48.1535 80.435 49.2143 85.6524C50.0354 90.3747 50.1371 93.6118 50.2976 98.3732L50.3679 105.311C50.3415 106.733 51.0344 109.64 53.2024 109.539C55.7592 109.281 55.8759 105.682 56.2378 103.624C57.4067 84.2566 49.3927 68.0056 44.2551 51.0484C43.7382 49.3904 43.2212 47.7324 42.619 45.9722C40.5567 40.3852 38.292 34.9709 35.2496 29.8707C34.5677 29.0534 34.1255 28.7311 33.2785 28.7544C32.5328 28.6912 32.1973 29.3276 31.963 29.8775C30.9406 31.9749 30.6908 36.0369 30.9042 37.9541C31.7626 43.3443 36.9561 77.3984 34.5643 76.8161C31.2562 75.9665 20.4356 28.5222 12.1779 20.7017L11.8209 20.4815C11.6505 20.2772 11.1923 20.1435 11.1923 20.1435C9.41317 20.0879 8.58765 20.9676 7.90072 22.4288Z'
								fill='url(#paint1_linear_33_1152)'
							/>
						</g>
						<g id='Group_4'>
							<path
								id='Vector_3'
								d='M105.244 20.4132C105.244 20.4132 105.042 20.5859 104.84 20.7587C96.3961 28.4917 84.5882 75.9947 81.3244 76.8575C78.9232 77.5085 84.9081 43.3706 85.7432 37.9361C85.9878 36.1534 85.8647 32.0616 84.8368 29.7915C84.6823 29.3988 84.2722 28.6997 83.612 28.7387C82.7654 28.762 82.3608 29.1074 81.6367 29.9004C78.3949 34.9414 76.0529 40.4382 73.8814 46.1392C73.28 47.7019 72.7637 49.3667 72.2475 51.0315C66.6807 68.025 58.3503 84.405 59.0614 103.733C59.3599 105.752 59.4296 109.364 62.0387 109.585C64.1898 109.672 64.9029 106.79 64.9293 105.369C65.0354 103.005 65.0404 100.727 65.0454 98.4496C65.5451 93.6512 65.5446 90.3291 66.5023 85.6643C67.6942 80.4499 69.4452 75.2827 71.0098 70.0998C73.4315 63.6606 75.0385 56.8677 76.917 50.1927C77.2523 49.5567 77.6355 48.3552 78.4981 48.1434C79.6481 47.8611 77.0675 59.5072 76.5254 65.9157C76.478 69.8032 76.4412 72.4578 76.2927 76.4317C76.4527 77.869 76.6126 79.3062 76.8737 80.6572C77.3904 82.3145 77.6999 86.422 80.0374 86.5249C81.3578 86.4468 81.8795 85.8266 82.8911 84.963C86.25 79.6472 87.6069 73.5925 89.3738 68.2368C92.088 59.4495 93.9236 51.0624 98.3843 42.7075C98.6185 42.1579 99.4382 40.234 100.098 40.1949C100.098 40.1949 100.439 40.6034 100.391 41.1689C100.28 42.4883 99.8009 44.8207 99.4018 46.2106C99.2688 46.6739 99.2209 47.2394 99.0879 47.7027C97.178 58.0768 93.8799 78.2047 95.2975 86.962C95.436 87.5432 95.4894 88.0223 95.5427 88.5014C96.2672 91.0306 98.355 95.1937 101.954 93.6949C105.25 92.4551 101.911 88.6606 101.394 87.0033C101.325 86.7127 101.256 86.4221 101.101 86.0294C100.723 84.9533 100.632 83.8066 100.557 82.4715C100.674 75.5524 100.305 69.9212 101.625 63.1989C104.722 49.8879 108.841 37.8025 109.228 24.3571C109.291 23.6032 109.339 23.0377 109.014 22.4407C108.482 20.9719 107.715 20.0528 105.835 20.0836C105.633 20.2563 105.447 20.2405 105.244 20.4132Z'
								fill='url(#paint2_linear_33_1152)'
							/>
						</g>
						<g id='Group_5'>
							<path
								id='Vector_4'
								d='M40.4723 25.8926C40.1631 25.8664 39.8274 26.153 39.9556 26.4788L39.9209 30.5706C39.8944 30.8833 40.1904 31.0658 40.4996 31.092L53.8177 31.0379C54.1269 31.064 54.308 30.7644 54.3344 30.4516L54.2852 26.4315C54.3117 26.1188 54.0996 25.8646 53.7904 25.8384L40.4723 25.8926Z'
								fill='url(#paint3_linear_33_1152)'
							/>
						</g>
						<g id='Group_6'>
							<path
								id='Vector_5'
								d='M42.6925 32.6571C42.3847 32.631 42.1341 32.845 42.1077 33.1563L42.1567 37.1587C42.1304 37.4701 42.3415 37.7231 42.6493 37.7492L53.7405 37.6686C54.0484 37.6947 54.299 37.4807 54.3254 37.1693L54.2764 33.1669C54.3027 32.8556 54.0916 32.6025 53.7837 32.5765L42.6925 32.6571Z'
								fill='url(#paint4_linear_33_1152)'
							/>
						</g>
						<g id='Group_7'>
							<path
								id='Vector_6'
								d='M44.6921 39.122C44.387 39.0962 44.2084 39.3919 44.1823 39.7004L44.1957 42.2656C44.1696 42.5742 44.3789 42.825 44.684 42.8508L53.7499 42.841C54.1378 42.7962 54.3165 42.5005 54.3426 42.1919L54.3291 39.6267C54.3552 39.3181 54.0632 39.138 53.7581 39.1122L44.6921 39.122Z'
								fill='url(#paint5_linear_33_1152)'
							/>
						</g>
						<g id='Group_8'>
							<path
								id='Vector_7'
								d='M46.4464 44.2739C46.1434 44.2483 45.8144 44.5291 45.94 44.8484L45.8711 47.4664C45.8452 47.7729 46.1353 47.9518 46.4383 47.9774L53.763 47.9799C54.066 48.0055 54.3949 47.7247 54.2694 47.4054L54.3382 44.7874C54.3641 44.4809 54.0741 44.302 53.771 44.2764L46.4464 44.2739Z'
								fill='url(#paint6_linear_33_1152)'
							/>
						</g>
						<g id='Group_9'>
							<path
								id='Vector_8'
								d='M47.5678 49.369C47.2666 49.3435 47.0902 49.6354 47.0644 49.9401L46.996 52.5424C47.0519 52.7773 47.3274 53.1074 47.5598 53.0504L53.7736 53.116C54.0748 53.1415 54.333 52.7798 54.277 52.5449L54.3455 49.9425C54.3712 49.6379 54.0958 49.3077 53.7817 49.4345L47.5678 49.369Z'
								fill='url(#paint7_linear_33_1152)'
							/>
						</g>
						<g id='Group_10'>
							<path
								id='Vector_9'
								d='M49.0828 54.4002C48.8538 54.4565 48.5314 54.7317 48.5866 54.9633L48.5572 57.0784C48.5318 57.3787 48.816 57.554 49.113 57.5792L53.8221 57.5995C54.0512 57.5433 54.293 57.3369 54.3184 57.0365L54.2672 54.9902C54.2926 54.6899 54.0889 54.4457 53.792 54.4206L49.0828 54.4002Z'
								fill='url(#paint8_linear_33_1152)'
							/>
						</g>
						<g id='Group_11'>
							<path
								id='Vector_10'
								d='M51.2064 58.994C50.9831 59.0488 50.6688 59.3171 50.644 59.6099L50.694 61.6047C50.6692 61.8975 50.9339 62.2148 51.1573 62.16L53.7752 62.2341C54.0771 62.1122 54.3128 61.9109 54.3376 61.6181L54.3662 59.5563C54.3124 59.3305 54.0477 59.0132 53.8244 59.068L51.2064 58.994Z'
								fill='url(#paint9_linear_33_1152)'
							/>
						</g>
						<g id='Group_12'>
							<path
								id='Vector_11'
								d='M57.69 30.8387C57.3796 30.8125 57.1136 31.1851 57.1713 31.4271L57.1543 36.2472C57.1277 36.5611 57.3406 36.8162 57.6509 36.8425L72.8948 36.7888C73.2051 36.8151 73.3869 36.5143 73.4134 36.2004L73.4305 31.3803C73.3728 31.1383 73.1599 30.8832 72.8495 30.8569L57.69 30.8387Z'
								fill='url(#paint10_linear_33_1152)'
							/>
						</g>
						<g id='Group_13'>
							<path
								id='Vector_12'
								d='M57.6981 38.4784C57.3887 38.4522 57.1367 38.6673 57.1103 38.9802L57.0755 43.0751C57.1198 43.4728 57.416 43.6555 57.6547 43.5969L71.363 43.6537C71.7565 43.6082 72.0084 43.3931 71.9509 43.1518L71.9856 39.057C71.9414 38.6592 71.7291 38.4049 71.4904 38.4635L57.6981 38.4784Z'
								fill='url(#paint11_linear_33_1152)'
							/>
						</g>
						<g id='Group_14'>
							<path
								id='Vector_13'
								d='M57.6238 45.3229C57.3158 45.2969 57.1354 45.5954 57.109 45.9069L57.114 49.5155C57.158 49.9115 57.3693 50.1647 57.6069 50.1063L69.2367 50.1492C69.5447 50.1753 69.8087 49.8054 69.7514 49.5653L69.8301 45.8852C69.786 45.4893 69.5748 45.2361 69.3372 45.2944L57.6238 45.3229Z'
								fill='url(#paint12_linear_33_1152)'
							/>
						</g>
						<g id='Group_15'>
							<path
								id='Vector_14'
								d='M57.6582 51.7721C57.352 51.7461 57.1026 51.959 57.1464 52.3527L57.0944 55.7018C57.1514 55.9406 57.3614 56.1923 57.6676 56.2182L67.31 56.1763C67.6162 56.2022 67.8656 55.9894 67.8218 55.5957L67.7906 52.3176C67.8168 52.0078 67.6068 51.7561 67.3006 51.7302L57.6582 51.7721Z'
								fill='url(#paint13_linear_33_1152)'
							/>
						</g>
						<g id='Group_16'>
							<path
								id='Vector_15'
								d='M57.6929 57.8845C57.3899 57.8589 57.1432 58.0695 57.1173 58.3759L57.0918 61.3829C57.0659 61.6893 57.4251 61.9512 57.6589 61.8938L64.6793 61.8707C64.9823 61.8963 65.2289 61.6857 65.2549 61.3793L65.1982 58.4425C65.2241 58.1361 64.947 57.804 64.7133 57.8614L57.6929 57.8845Z'
								fill='url(#paint14_linear_33_1152)'
							/>
						</g>
						<g id='Group_17'>
							<path
								id='Vector_16'
								d='M57.7036 63.6065C57.404 63.5811 57.16 63.7894 57.1344 64.0925L57.1348 66.7635C57.1091 67.0665 57.3146 67.3129 57.6956 67.2688L62.9782 67.258C63.2779 67.2833 63.5218 67.075 63.5475 66.7719L63.5471 64.101C63.5727 63.7979 63.3672 63.5516 62.9862 63.5957L57.7036 63.6065Z'
								fill='url(#paint15_linear_33_1152)'
							/>
						</g>
						<g id='Group_18'>
							<path
								id='Vector_17'
								d='M57.6542 68.9104C57.3593 68.8855 57.1065 69.2397 57.1613 69.4696L57.1195 71.7196C57.0943 72.018 57.2966 72.2605 57.6716 72.2171L61.4514 72.3116C61.8265 72.2682 61.9992 71.9823 62.0244 71.684L61.9862 69.5024C62.0114 69.204 61.8091 68.9615 61.5141 68.9366L57.6542 68.9104Z'
								fill='url(#paint16_linear_33_1152)'
							/>
						</g>
						<g id='Group_19'>
							<path
								id='Vector_18'
								d='M57.6545 73.9414C57.3672 73.9171 57.1333 74.1168 57.1087 74.4074L57.0926 76.3083C57.1337 76.6775 57.3307 76.9137 57.696 76.8715L59.941 76.8419C60.2283 76.8663 60.4622 76.6666 60.4867 76.376L60.5029 74.4751C60.4618 74.1058 60.1868 73.9362 59.8995 73.9119L57.6545 73.9414Z'
								fill='url(#paint17_linear_33_1152)'
							/>
						</g>
						<g id='Group_20'>
							<path
								id='Vector_19'
								d='M57.6998 78.5232C57.3492 78.5638 57.1247 78.7555 57.1011 79.0343L57.121 80.4403C57.1605 80.7947 57.3496 81.0214 57.6253 81.0447L58.74 80.9986C59.0157 81.0219 59.315 80.7664 59.3386 80.4875L59.3187 79.0815C59.2793 78.7271 59.0902 78.5005 58.7396 78.541L57.6998 78.5232Z'
								fill='url(#paint18_linear_33_1152)'
							/>
						</g>
						<g id='Group_21'>
							<path
								id='Vector_20'
								d='M7.44219 128.51L7.41879 124.091L5.29928 127.688H4.26308L2.15511 124.21V128.51H0V120.173H1.91979L4.81644 124.984L7.64224 120.173H9.5617L9.58543 128.51H7.44219Z'
								fill='black'
							/>
						</g>
						<g id='Group_22'>
							<path
								id='Vector_21'
								d='M16.5208 126.425C16.8583 126.227 17.1253 125.947 17.3217 125.586C17.5175 125.224 17.616 124.81 17.616 124.341C17.616 123.873 17.5175 123.458 17.3217 123.097C17.1253 122.736 16.8583 122.456 16.5208 122.257C16.1833 122.059 15.8063 121.959 15.3904 121.959C14.9741 121.959 14.5974 122.059 14.2599 122.257C13.9224 122.456 13.6555 122.736 13.459 123.097C13.2629 123.458 13.1647 123.873 13.1647 124.341C13.1647 124.81 13.2629 125.224 13.459 125.586C13.6555 125.947 13.9224 126.227 14.2599 126.425C14.5974 126.624 14.9741 126.723 15.3904 126.723C15.8063 126.723 16.1833 126.624 16.5208 126.425ZM13.0411 128.117C12.3464 127.743 11.8006 127.227 11.4045 126.568C11.008 125.909 10.8096 125.167 10.8096 124.341C10.8096 123.516 11.008 122.773 11.4045 122.114C11.8006 121.455 12.3464 120.939 13.0411 120.566C13.7359 120.193 14.519 120.006 15.3904 120.006C16.2618 120.006 17.0448 120.193 17.7396 120.566C18.4343 120.939 18.9798 121.455 19.3763 122.114C19.7728 122.773 19.9708 123.516 19.9708 124.341C19.9708 125.167 19.7728 125.909 19.3763 126.568C18.9798 127.227 18.4343 127.743 17.7396 128.117C17.0448 128.49 16.2618 128.676 15.3904 128.676C14.519 128.676 13.7359 128.49 13.0411 128.117Z'
								fill='black'
							/>
						</g>
						<g id='Group_23'>
							<path
								id='Vector_22'
								d='M24.9996 126.628C25.7062 126.628 26.2731 126.425 26.7012 126.021C27.129 125.615 27.3429 125.056 27.3429 124.341C27.3429 123.627 27.129 123.067 26.7012 122.662C26.2731 122.257 25.7062 122.054 24.9996 122.054H23.5277V126.628H24.9996ZM21.1963 120.173H25.0939C25.9966 120.173 26.7971 120.343 27.4962 120.685C28.1945 121.026 28.7364 121.511 29.121 122.138C29.5056 122.765 29.6981 123.5 29.6981 124.341C29.6981 125.183 29.5056 125.917 29.121 126.544C28.7364 127.172 28.1945 127.656 27.4962 127.997C26.7971 128.339 25.9966 128.51 25.0939 128.51H21.1963V120.173Z'
								fill='black'
							/>
						</g>
						<g id='Group_24'>
							<path
								id='Vector_23'
								d='M37.5398 126.687V128.51H30.9219V120.173H37.3865V121.995H33.2299V123.4H36.8922V125.163H33.2299V126.687H37.5398Z'
								fill='black'
							/>
						</g>
						<g id='Group_25'>
							<path
								id='Vector_24'
								d='M43.545 122.352C43.3017 122.138 42.9405 122.031 42.4616 122.031H41.1664V124.472H42.4616C42.9405 124.472 43.3017 124.367 43.545 124.157C43.7882 123.946 43.9101 123.647 43.9101 123.257C43.9101 122.869 43.7882 122.567 43.545 122.352ZM42.4382 126.294H41.1664V128.51H38.835V120.173H42.603C43.3489 120.173 43.9965 120.298 44.5459 120.548C45.0953 120.798 45.5195 121.155 45.8177 121.62C46.116 122.084 46.265 122.63 46.265 123.257C46.265 123.861 46.1259 124.387 45.8471 124.835C45.5682 125.284 45.1701 125.636 44.652 125.889L46.4301 128.51H43.9335L42.4382 126.294Z'
								fill='black'
							/>
						</g>
						<g id='Group_26'>
							<path
								id='Vector_25'
								d='M55.3911 120.173V128.51H53.4717L49.8331 124.067V128.51H47.5488V120.173H49.4683L53.1065 124.615V120.173H55.3911Z'
								fill='black'
							/>
						</g>
						<g id='Group_27'>
							<path
								id='Vector_26'
								d='M62.7982 120.173H60.4668V128.51H62.7982V120.173Z'
								fill='black'
							/>
						</g>
						<g id='Group_28'>
							<path
								id='Vector_27'
								d='M71.9008 128.51L71.8774 124.091L69.7579 127.688H68.7217L66.6138 124.21V128.51H64.459V120.173H66.3781L69.2751 124.984L72.1009 120.173H74.0207L74.0441 128.51H71.9008Z'
								fill='black'
							/>
						</g>
						<g id='Group_29'>
							<path
								id='Vector_28'
								d='M80.4004 124.157C80.6436 123.946 80.7653 123.647 80.7653 123.257C80.7653 122.869 80.6436 122.566 80.4004 122.352C80.1572 122.138 79.796 122.031 79.3171 122.031H78.0219V124.472H79.3171C79.796 124.472 80.1572 124.367 80.4004 124.157ZM81.4013 120.548C81.9508 120.798 82.3746 121.155 82.6732 121.62C82.9715 122.084 83.1208 122.63 83.1208 123.257C83.1208 123.885 82.9715 124.428 82.6732 124.889C82.3746 125.35 81.9508 125.705 81.4013 125.955C80.8516 126.205 80.2043 126.33 79.4585 126.33H78.0219V128.51H75.6904V120.173H79.4585C80.2043 120.173 80.8516 120.298 81.4013 120.548Z'
								fill='black'
							/>
						</g>
						<g id='Group_30'>
							<path
								id='Vector_29'
								d='M84.2529 120.173H86.5844V126.64H90.5175V128.51H84.2529V120.173Z'
								fill='black'
							/>
						</g>
						<g id='Group_31'>
							<path
								id='Vector_30'
								d='M96.3689 125.151L95.3094 122.483L94.2494 125.151H96.3689ZM97.0522 126.89H93.5665L92.9189 128.509H90.54L94.1789 120.173H96.4751L100.125 128.509H97.6998L97.0522 126.89Z'
								fill='black'
							/>
						</g>
						<g id='Group_32'>
							<path
								id='Vector_31'
								d='M108.626 120.173V128.51H106.706L103.068 124.067V128.51H100.783V120.173H102.703L106.341 124.615V120.173H108.626Z'
								fill='black'
							/>
						</g>
						<g id='Group_33'>
							<path
								id='Vector_32'
								d='M112.03 122.043H109.498V120.173H116.881V122.043H114.361V128.509H112.03V122.043Z'
								fill='black'
							/>
						</g>
						<g id='Group_34'>
							<path
								id='Vector_33'
								d='M37.1749 142.801L37.163 137.275L34.4549 141.848H33.772L31.0635 137.347V142.801H29.6035V134.464H30.8635L34.1369 139.99L37.3516 134.464H38.6115L38.6234 142.801H37.1749Z'
								fill='black'
							/>
						</g>
						<g id='Group_35'>
							<path
								id='Vector_34'
								d='M47.0305 141.503V142.801H40.8486V134.464H46.8657V135.762H42.3792V137.93H46.3592V139.204H42.3792V141.503H47.0305Z'
								fill='black'
							/>
						</g>
						<g id='Group_36'>
							<path
								id='Vector_35'
								d='M52.271 141.491C52.8751 141.491 53.4074 141.374 53.8665 141.14C54.3256 140.905 54.6792 140.572 54.9264 140.139C55.1736 139.706 55.2972 139.204 55.2972 138.633C55.2972 138.061 55.1736 137.559 54.9264 137.126C54.6792 136.693 54.3256 136.36 53.8665 136.126C53.4074 135.892 52.8751 135.775 52.271 135.775H50.2692V141.491H52.271ZM48.7383 134.464H52.3416C53.2205 134.464 54.002 134.637 54.6848 134.983C55.3677 135.328 55.8977 135.816 56.2744 136.447C56.6514 137.079 56.84 137.807 56.84 138.633C56.84 139.458 56.6514 140.187 56.2744 140.818C55.8977 141.449 55.3677 141.938 54.6848 142.283C54.002 142.628 53.2205 142.801 52.3416 142.801H48.7383V134.464Z'
								fill='black'
							/>
						</g>
						<g id='Group_37'>
							<path
								id='Vector_36'
								d='M59.9954 134.464H58.4648V142.801H59.9954V134.464Z'
								fill='black'
							/>
						</g>
						<g id='Group_38'>
							<path
								id='Vector_37'
								d='M63.781 142.366C63.11 141.997 62.5837 141.487 62.2033 140.836C61.8223 140.185 61.6318 139.451 61.6318 138.633C61.6318 137.815 61.8243 137.081 62.2089 136.429C62.5935 135.778 63.1215 135.268 63.7929 134.899C64.4639 134.53 65.216 134.345 66.0478 134.345C66.7228 134.345 67.3391 134.464 67.8964 134.703C68.4541 134.941 68.9247 135.286 69.3097 135.739L68.3203 136.679C67.7241 136.028 66.9898 135.703 66.1187 135.703C65.5535 135.703 65.0469 135.828 64.5994 136.078C64.1521 136.328 63.8024 136.676 63.5516 137.12C63.3002 137.565 63.1746 138.069 63.1746 138.633C63.1746 139.196 63.3002 139.701 63.5516 140.145C63.8024 140.59 64.1521 140.937 64.5994 141.187C65.0469 141.437 65.5535 141.562 66.1187 141.562C66.9898 141.562 67.7241 141.233 68.3203 140.574L69.3097 141.527C68.9247 141.979 68.4518 142.325 67.8908 142.563C67.3296 142.801 66.7113 142.92 66.0363 142.92C65.2038 142.92 64.4524 142.735 63.781 142.366Z'
								fill='black'
							/>
						</g>
						<g id='Group_39'>
							<path
								id='Vector_38'
								d='M72.3121 134.464H70.7812V142.801H72.3121V134.464Z'
								fill='black'
							/>
						</g>
						<g id='Group_40'>
							<path
								id='Vector_39'
								d='M81.8603 134.464V142.801H80.6004L76.0552 137.156V142.801H74.5361V134.464H75.7964L80.3416 140.11V134.464H81.8603Z'
								fill='black'
							/>
						</g>
						<g id='Group_41'>
							<path
								id='Vector_40'
								d='M90.2581 141.503V142.801H84.0762V134.464H90.0933V135.762H85.6071V137.93H89.587V139.204H85.6071V141.503H90.2581Z'
								fill='black'
							/>
						</g>
					</g>
				</g>
			</g>
			<defs>
				<linearGradient
					id='paint0_linear_33_1152'
					x1='-21.006'
					y1='77.599'
					x2='81.127'
					y2='-7.61358'
					gradientUnits='userSpaceOnUse'>
					<stop stopColor='#31ACE2' />
					<stop offset='0.99' stopColor='#6BC4AA' />
					<stop offset='1' stopColor='#6BC4AA' />
				</linearGradient>
				<linearGradient
					id='paint1_linear_33_1152'
					x1='-3.0846'
					y1='94.6195'
					x2='99.0762'
					y2='9.38284'
					gradientUnits='userSpaceOnUse'>
					<stop stopColor='#31ACE2' />
					<stop offset='0.99' stopColor='#6BC4AA' />
					<stop offset='1' stopColor='#6BC4AA' />
				</linearGradient>
				<linearGradient
					id='paint2_linear_33_1152'
					x1='20.4043'
					y1='122.792'
					x2='122.522'
					y2='37.5922'
					gradientUnits='userSpaceOnUse'>
					<stop stopColor='#31ACE2' />
					<stop offset='0.99' stopColor='#6BC4AA' />
					<stop offset='1' stopColor='#6BC4AA' />
				</linearGradient>
				<linearGradient
					id='paint3_linear_33_1152'
					x1='-5.0939'
					y1='73.0626'
					x2='79.6245'
					y2='2.37962'
					gradientUnits='userSpaceOnUse'>
					<stop stopColor='#31ACE2' />
					<stop offset='0.99' stopColor='#6BC4AA' />
					<stop offset='1' stopColor='#6BC4AA' />
				</linearGradient>
				<linearGradient
					id='paint4_linear_33_1152'
					x1='-1.24345'
					y1='77.388'
					x2='83.101'
					y2='7.01714'
					gradientUnits='userSpaceOnUse'>
					<stop stopColor='#31ACE2' />
					<stop offset='0.99' stopColor='#6BC4AA' />
					<stop offset='1' stopColor='#6BC4AA' />
				</linearGradient>
				<linearGradient
					id='paint5_linear_33_1152'
					x1='2.35461'
					y1='80.9927'
					x2='85.9477'
					y2='11.2487'
					gradientUnits='userSpaceOnUse'>
					<stop stopColor='#31ACE2' />
					<stop offset='0.99' stopColor='#6BC4AA' />
					<stop offset='1' stopColor='#6BC4AA' />
				</linearGradient>
				<linearGradient
					id='paint6_linear_33_1152'
					x1='5.44829'
					y1='84.2513'
					x2='88.4731'
					y2='14.9812'
					gradientUnits='userSpaceOnUse'>
					<stop stopColor='#31ACE2' />
					<stop offset='0.99' stopColor='#6BC4AA' />
					<stop offset='1' stopColor='#6BC4AA' />
				</linearGradient>
				<linearGradient
					id='paint7_linear_33_1152'
					x1='8.32423'
					y1='87.3953'
					x2='90.8548'
					y2='18.5378'
					gradientUnits='userSpaceOnUse'>
					<stop stopColor='#31ACE2' />
					<stop offset='0.99' stopColor='#6BC4AA' />
					<stop offset='1' stopColor='#6BC4AA' />
				</linearGradient>
				<linearGradient
					id='paint8_linear_33_1152'
					x1='11.4365'
					y1='90.1626'
					x2='92.8018'
					y2='22.2774'
					gradientUnits='userSpaceOnUse'>
					<stop stopColor='#31ACE2' />
					<stop offset='0.99' stopColor='#6BC4AA' />
					<stop offset='1' stopColor='#6BC4AA' />
				</linearGradient>
				<linearGradient
					id='paint9_linear_33_1152'
					x1='14.9742'
					y1='92.6432'
					x2='94.293'
					y2='26.4653'
					gradientUnits='userSpaceOnUse'>
					<stop stopColor='#31ACE2' />
					<stop offset='0.99' stopColor='#6BC4AA' />
					<stop offset='1' stopColor='#6BC4AA' />
				</linearGradient>
				<linearGradient
					id='paint10_linear_33_1152'
					x1='5.52877'
					y1='84.8359'
					x2='90.5663'
					y2='13.8869'
					gradientUnits='userSpaceOnUse'>
					<stop stopColor='#31ACE2' />
					<stop offset='0.99' stopColor='#6BC4AA' />
					<stop offset='1' stopColor='#6BC4AA' />
				</linearGradient>
				<linearGradient
					id='paint11_linear_33_1152'
					x1='8.80931'
					y1='88.6368'
					x2='93.5915'
					y2='17.9006'
					gradientUnits='userSpaceOnUse'>
					<stop stopColor='#31ACE2' />
					<stop offset='0.99' stopColor='#6BC4AA' />
					<stop offset='1' stopColor='#6BC4AA' />
				</linearGradient>
				<linearGradient
					id='paint12_linear_33_1152'
					x1='11.7279'
					y1='91.8761'
					x2='96.119'
					y2='21.4663'
					gradientUnits='userSpaceOnUse'>
					<stop stopColor='#31ACE2' />
					<stop offset='0.99' stopColor='#6BC4AA' />
					<stop offset='1' stopColor='#6BC4AA' />
				</linearGradient>
				<linearGradient
					id='paint13_linear_33_1152'
					x1='14.5278'
					y1='94.9155'
					x2='98.434'
					y2='24.9105'
					gradientUnits='userSpaceOnUse'>
					<stop stopColor='#31ACE2' />
					<stop offset='0.99' stopColor='#6BC4AA' />
					<stop offset='1' stopColor='#6BC4AA' />
				</linearGradient>
				<linearGradient
					id='paint14_linear_33_1152'
					x1='17.1952'
					y1='97.4341'
					x2='100.206'
					y2='28.1758'
					gradientUnits='userSpaceOnUse'>
					<stop stopColor='#31ACE2' />
					<stop offset='0.99' stopColor='#6BC4AA' />
					<stop offset='1' stopColor='#6BC4AA' />
				</linearGradient>
				<linearGradient
					id='paint15_linear_33_1152'
					x1='19.8408'
					y1='100.009'
					x2='101.943'
					y2='31.5087'
					gradientUnits='userSpaceOnUse'>
					<stop stopColor='#31ACE2' />
					<stop offset='0.99' stopColor='#6BC4AA' />
					<stop offset='1' stopColor='#6BC4AA' />
				</linearGradient>
				<linearGradient
					id='paint16_linear_33_1152'
					x1='22.4485'
					y1='102.288'
					x2='103.265'
					y2='34.8606'
					gradientUnits='userSpaceOnUse'>
					<stop stopColor='#31ACE2' />
					<stop offset='0.99' stopColor='#6BC4AA' />
					<stop offset='1' stopColor='#6BC4AA' />
				</linearGradient>
				<linearGradient
					id='paint17_linear_33_1152'
					x1='25.1538'
					y1='104.114'
					x2='103.868'
					y2='38.4413'
					gradientUnits='userSpaceOnUse'>
					<stop stopColor='#31ACE2' />
					<stop offset='0.99' stopColor='#6BC4AA' />
					<stop offset='1' stopColor='#6BC4AA' />
				</linearGradient>
				<linearGradient
					id='paint18_linear_33_1152'
					x1='28.0642'
					y1='105.506'
					x2='103.607'
					y2='42.4789'
					gradientUnits='userSpaceOnUse'>
					<stop stopColor='#31ACE2' />
					<stop offset='0.99' stopColor='#6BC4AA' />
					<stop offset='1' stopColor='#6BC4AA' />
				</linearGradient>
				<clipPath id='clip0_33_1152'>
					<rect width='117' height='143' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
Logo.defaultProps = {
	width: 2155,
	height: 854,
};

export default Logo;
