import React, { SVGProps } from "react";

export const THEETH_46 = (props: SVGProps<SVGSVGElement>) => {

    return (
		<>
           <svg width="36" height="63" viewBox="0 0 36 63" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g filter="url(#filter0_i_402_4962)">
<path d="M30.9002 60.9086C41.8359 64.9586 27.891 52.8086 30.9003 20.0037C25.3654 15.2787 12.7568 7.93468 6.60081 16.3587C1.74097 26.4836 2.95597 60.0986 15.9153 59.6936C18.8015 59.6034 9.43597 39.8486 15.9153 38.2287C21.1809 38.6336 25.0906 58.757 30.9002 60.9086Z" fill="url(#paint0_linear_402_4962)"/>
</g>
<g filter="url(#filter1_i_402_4962)">
<path d="M27.0096 17.1932L30.9032 19.67C31.0287 19.7498 31.1004 19.8913 31.0901 20.0398C30.3103 31.3224 31.1327 44.1939 34.752 57.4646C35.4261 59.6116 35.1957 60.6254 34.7638 61.267C34.4404 61.7474 33.6718 61.5789 33.4353 61.0504C27.2275 47.1806 26.0196 36.8351 26.6363 17.3952C26.6423 17.2072 26.8508 17.0922 27.0096 17.1932Z" fill="url(#paint1_linear_402_4962)"/>
</g>
<path d="M27.5806 22.3212C31.7684 22.3212 32.5665 12.4455 32.4428 7.42267C32.4414 7.36378 32.433 7.30513 32.4179 7.24821C31.5123 3.845 28.4181 4.45818 26.5384 5.3961C26.194 5.56793 25.7735 5.51195 25.495 5.24631C21.0727 1.028 16.0001 3.01188 13.521 4.88695C13.2099 5.12222 12.7785 5.13593 12.4656 4.9032C8.28293 1.79267 3.25987 -0.058877 0.522703 5.94837C0.472176 6.05926 0.447937 6.18826 0.452858 6.31002C0.887972 17.0796 4.09992 19.0859 5.71055 19.8912C7.00655 20.5392 13.5405 20.1612 16.6455 19.8912C18.5356 20.7012 23.3686 22.3212 27.5806 22.3212Z" fill="url(#paint2_linear_402_4962)"/>
<path d="M27.5806 22.3212C31.7684 22.3212 32.5665 12.4455 32.4428 7.42267C32.4414 7.36378 32.433 7.30513 32.4179 7.24821C31.5123 3.845 28.4181 4.45818 26.5384 5.3961C26.194 5.56793 25.7735 5.51195 25.495 5.24631C21.0727 1.028 16.0001 3.01188 13.521 4.88695C13.2099 5.12222 12.7785 5.13593 12.4656 4.9032C8.28293 1.79267 3.25987 -0.058877 0.522703 5.94837C0.472176 6.05926 0.447937 6.18826 0.452858 6.31002C0.887972 17.0796 4.09992 19.0859 5.71055 19.8912C7.00655 20.5392 13.5405 20.1612 16.6455 19.8912C18.5356 20.7012 23.3686 22.3212 27.5806 22.3212Z" fill="url(#paint3_radial_402_4962)" fill-opacity="0.3"/>
<g filter="url(#filter2_f_402_4962)">
<path d="M25.7443 5.83521L27.3643 11.5052" stroke="black" stroke-width="0.81"/>
</g>
<g filter="url(#filter3_f_402_4962)">
<path d="M12.7843 5.02515L14.4043 10.6951" stroke="black" stroke-width="0.81"/>
</g>
<g filter="url(#filter4_f_402_4962)">
<path d="M16.7344 9.43889C15.9244 13.4889 19.7625 12.2739 21.9992 12.2739C24.236 12.2739 23.6194 10.4183 23.6194 8.62889C23.6194 6.83948 17.3511 4.98389 15.1143 4.98389C12.8776 4.98389 14.3044 8.22389 16.7344 9.43889Z" fill="#FDFDFD"/>
</g>
<defs>
<filter id="filter0_i_402_4962" x="3.97266" y="9.26952" width="35.2346" height="52.3899" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="8.1" dy="-3.24"/>
<feGaussianBlur stdDeviation="2.025"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_402_4962"/>
</filter>
<filter id="filter1_i_402_4962" x="26.4927" y="17.1558" width="10.3041" height="46.0148" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="1.62" dy="1.62"/>
<feGaussianBlur stdDeviation="1.62"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_402_4962"/>
</filter>
<filter id="filter2_f_402_4962" x="20.495" y="0.863877" width="12.1189" height="15.6126" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2.43" result="effect1_foregroundBlur_402_4962"/>
</filter>
<filter id="filter3_f_402_4962" x="7.53502" y="0.0538187" width="12.1189" height="15.6126" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2.43" result="effect1_foregroundBlur_402_4962"/>
</filter>
<filter id="filter4_f_402_4962" x="9.94805" y="0.933887" width="17.7992" height="15.5609" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2.025" result="effect1_foregroundBlur_402_4962"/>
</filter>
<linearGradient id="paint0_linear_402_4962" x1="19.565" y1="12.5095" x2="19.565" y2="61.6595" gradientUnits="userSpaceOnUse">
<stop stop-color="#D29439"/>
<stop offset="1" stop-color="#887250"/>
</linearGradient>
<linearGradient id="paint1_linear_402_4962" x1="26.2617" y1="22.4942" x2="38.0625" y2="61.9973" gradientUnits="userSpaceOnUse">
<stop stop-color="#725935"/>
<stop offset="1" stop-color="#5B482A"/>
</linearGradient>
<linearGradient id="paint2_linear_402_4962" x1="16.4501" y1="2" x2="16.4501" y2="22.3212" gradientUnits="userSpaceOnUse">
<stop offset="0.64" stop-color="white"/>
<stop offset="1" stop-color="#B48B8B"/>
</linearGradient>
<radialGradient id="paint3_radial_402_4962" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(6.80182 9.74288) rotate(116.87) scale(3.02052 4.73233)">
<stop stop-color="white" stop-opacity="0"/>
<stop offset="1" stop-color="#9B9B9B"/>
</radialGradient>
</defs>
</svg>


        </>
	);
};