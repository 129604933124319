export interface IBranchProps {
	address: string;
}

const BRANCHES: { [key: string]: IBranchProps } = {
	FIRST: {
		address: 'Дзорап 74/1',
	},
	SECOND: {
		address: 'Маштотс 23/4',
	},
	THIRD: {
		address: 'Дзорап 40',
	},
};

export function getServiceDataWithServiceaddress(serviceAddress: string) {
	return BRANCHES[
		// @ts-ignore
		Object.keys(BRANCHES).filter((f) => BRANCHES[f].address.toString() === serviceAddress)
	];
}

export default BRANCHES;
