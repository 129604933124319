import React, { FC, useEffect, useState } from 'react';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../../components/bootstrap/Modal';
import Icon from '../../../components/icon/Icon';
import Button from '../../../components/bootstrap/Button';
import Input from '../../../components/bootstrap/forms/Input';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import { useMutation, useQueryClient } from 'react-query';
import useDarkMode from '../../../hooks/useDarkMode';
import { StageService } from '../../../custom/api/service/stage.service';
import Textarea from '../../../components/bootstrap/forms/Textarea';
import { IStage } from '@textnpayme/custom/api/interface/stage.interface';
import { IAct } from '@textnpayme/custom/api/interface/act.interface';
import { ActService } from '../../../custom/api/service/act.service';

interface IActModalProps {
	isOpen: boolean;
	setIsOpen(...args: unknown[]): unknown;
	visit: any;
}

interface CreateActParams {
	inputDto: IAct;
}

const ActModal: FC<IActModalProps> = ({ isOpen, setIsOpen, visit }) => {
	// Function to trigger the file input visit
	const [values, setValues] = useState<IStage>({
		userUuid: '',
		visitUuid: '',
		patientUuid: '',
		description: '',
	});

	const { themeStatus } = useDarkMode();

	const changeValues = (key: string, value: string | number) => {
		setValues((prev) => ({ ...prev, [key]: value }));
	};

	const formatNumber = (value: string) => {
		if (!value) return '';
		return Number(value).toLocaleString('en-US');
	};

	const handleAmountChange = (type: string, value: string) => {
		const formattedValue = value.replace(/,/g, '');
		changeValues(type, Number(formattedValue));
	};

	useEffect(() => {
		setValues({
			userUuid: visit?.user?.uuid || null,
			visitUuid: visit?.uuid || null,
			patientUuid: visit?.patient?.uuid || null,
			description: '',
		});
	}, [visit]);

	const queryClient = useQueryClient();

	const { mutate: createAct } = useMutation(
		({ inputDto }: CreateActParams) => ActService.create(inputDto),
		{
			onSuccess: () => {
				queryClient.invalidateQueries('visits');
				setIsOpen(false);
				setValues({
					userUuid: visit?.user?.uuid || null,
					visitUuid: visit?.uuid || null,
					patientUuid: visit?.patient?.uuid || null,
					description: '',
				});
			},
			onError: (error) => {
				console.error(error); // Using console.error for error logging
			},
		},
	);

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		createAct({ inputDto: values });
	};

	return (
		<>
			<Modal isCentered isOpen={isOpen} setIsOpen={setIsOpen} size='lg'>
				<ModalHeader setIsOpen={setIsOpen} className='p-4'>
					<span className='fs-3 fw-bold'>
						<Icon icon='Person' className='me-2 fs-2' color='primary' />
						Создать Смету
					</span>
				</ModalHeader>
				<form onSubmit={handleSubmit}>
					<ModalBody className='px-10004'>
						<div className='row'>
							<FormGroup className='col-12' id='medicine' label='Сумма'>
								<Input
									type='text'
									step={1}
									min={0}
									onChange={(e: any) =>
										handleAmountChange('amount', e.target.value)
									}
									value={formatNumber(values.amount)}
								/>
							</FormGroup>
						</div>
						<div className='row'>
							<FormGroup className='col-12' id='medicine' label='Описание'>
								<Textarea
									style={{ maxHeight: 300 }}
									onChange={(e: any) =>
										changeValues('description', e.target.value)
									}
									value={values.description}
								/>
							</FormGroup>
						</div>
					</ModalBody>
					<ModalFooter>
						<div className='d-flex justify-content-between w-100'>
							<Button
								className='fw-bold fs-6'
								color='link'
								isLight
								style={{ color: '#0D6EFD' }}
								isOutline={true}
								onClick={() => {
									setIsOpen(false);
								}}>
								Отменить
							</Button>
							<Button type='submit' className='fw-bold fs-6' color='info'>
								Создать
							</Button>
						</div>
					</ModalFooter>
				</form>
			</Modal>
		</>
	);
};

export default ActModal;
