import { authHost } from '../axios';
import { IVisit } from '../interface/visit.interface';

export class VisitService {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor() {}
	static async findAll(query: any): Promise<IVisit[] | null> {
		const { data } = await authHost.get(`visit?${query}`);		
		return data;
	}

	static async findOne(uuid: string) {
		const { data } = await authHost.get(`visit/${uuid}`);
		return data;
	}

	static async create(data: IVisit) {
		const visit = await authHost.post('visit',data)
		return visit;
	}

	static async update(uuid: string | undefined, data: IVisit) {	
		const visit = await authHost.patch(`visit/${uuid}`, data)
		return visit;
	}
	
}
