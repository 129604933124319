import React, { FC, useEffect, useState } from 'react';
import Icon from '../../../../components/icon/Icon';
import Button from '../../../../components/bootstrap/Button';
import Tooltips from '../../../../components/bootstrap/Tooltips';
import { InputWithTitle, SelectWithTitle } from '../../medicalCard/medicalCaseComponents/UI/Inputs';

interface IServices {
	index: number;
	isDisabled: boolean;
	item: any;
	handleDeleteService?: any;
	isChecked?: boolean;
	handleCheckboxChange?: any;
	themeStatus?: any;
}

export const ServicesList: FC<IServices> = ({
	index,
	isDisabled,
	item,
	handleDeleteService,
	isChecked,
	handleCheckboxChange,
	themeStatus,
}) => {
	console.log(item);
	const count = 1;
	const teeth = [];
	// border: 1px solid #e7eef8;
	return (
		<div className='shadow col-12 rounded-1 light-border-services-tab p-2'>
			<div className='form-check m-0 gap-2' key={index}>
				{handleCheckboxChange ? (
					<input
						color={themeStatus}
						className='form-check-input'
						onChange={(e: any) => handleCheckboxChange(e)}
						type='checkbox'
						value={item.uuid}
						name='services'
						checked={isChecked}
						id={`flexCheckDefault-${index}`}
						disabled={isDisabled}
					/>
				) : (
					<Button
						isDisable={isDisabled}
						onClick={() => handleDeleteService(item?.uuid)}
						className='form-check-input'>
						<Icon className='deletIcone' color={'red'} size={'2x'} icon='Delete'></Icon>
					</Button>
				)}

				<label
					aria-disabled={isDisabled}
					className='form-check-label col-12 d-flex gap-3 p-0'>
					<div className='col-2'>
						<span
							className='p-2 d-flex justify-content-center col-12 fw-normal text-white'
							style={{
								borderRadius: '0.5rem',
								backgroundColor: '#2c3178',
								width: '100%',
							}}>
							{item.serviceId}
						</span>
					</div>
					<p className='d-flex m-0' style={{ width: '70%' }}>
						<span className='overflow-hidden w-100'>
							<Tooltips title={item.title} placement='top' flip={['top']}>
								<span className='text-truncate'>{item.title}</span>
							</Tooltips>
						</span>
					</p>
				</label>
			</div>
			<div className='d-flex gap-3 align-items-center'>
				<SelectWithTitle
					title='*Зуб'
					placeholder='43'
					width='4rem'
					options={[{ value: '1', label: '' }]}
				/>
				<InputWithTitle title='*Кол.' placeholder='1' width='4rem' value={count} />
				<InputWithTitle title='*Сумма за 1х' placeholder='60,000' value={item.price} />
				<InputWithTitle
					title='*Общая сумма'
					placeholder='60,000'
					value={item.price * count}
				/>
			</div>
		</div>
	);
};
