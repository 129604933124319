import React, { useCallback, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useFormik } from 'formik';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import AuthContext from '../../../contexts/authContext';
import USERS, { getUserDataWithEmail } from '../../data/customData/receptionistData';
import CreatePass from '../../../components/CreatePass';

const SetPassword = () => {
	const { setUser } = useContext(AuthContext);
	const { darkModeStatus } = useDarkMode();
	const navigate = useNavigate();
	const handleOnClick = useCallback(() => navigate('/'), [navigate]);
	const handleOnReturn = useCallback(() => navigate('/login'), [navigate]);
	const emailCheck = (email: string) => {
		return !!getUserDataWithEmail(email);
	};
	const passwordCheck = (email: string, password: string) => {
		return getUserDataWithEmail(email).password === password;
	};
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			loginEmail: USERS.JOHN.email,
			loginPassword: USERS.JOHN.password,
		},
		validate: (values) => {
			const errors: { loginEmail?: string; loginPassword?: string } = {};

			if (!values.loginEmail) {
				errors.loginEmail = 'Введите эл. почту';
			}

			if (!values.loginPassword) {
				errors.loginPassword = 'Введите пароль';
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: (values) => {
			if (emailCheck(values.loginEmail)) {
				if (passwordCheck(values.loginEmail, values.loginPassword)) {
					if (setUser) {
						setUser(values.loginEmail);
					}

					handleOnClick();
				} else {
					formik.setFieldError('loginPassword', 'email and password do not match.');
				}
			}
		},
	});

	return (
		<PageWrapper isProtected={false} title='Sign Up' className='custom-login-wrapper'>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center h4  mb-4'>
									Придумайте постоянный пароль
								</div>{' '}
								<div className='text-center my-4'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}
										aria-label='Facit'>
										<CreatePass width={300} />
									</Link>
								</div>
								<p className='text-center fs-5 mb-3 fw-semibold'>
									Вы использовали временный пароль для дальнейшего пользования
									придумайте основной пароль для входа
								</p>
								<form className='row g-4'>
									<div className='col-12'>
										<FormGroup
											id='loginPassword'
											isFloating
											label='Пароль'
											className='mb-3'>
											<Input
												type='password'
												autoComplete='current-password'
												value={formik.values.loginPassword}
												isTouched={formik.touched.loginPassword}
												invalidFeedback={formik.errors.loginPassword}
												validFeedback='Looks good!'
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
											/>
										</FormGroup>
										<FormGroup
											id='loginPassword1'
											isFloating
											label='Подтвердите пароль'>
											<Input
												type='password'
												autoComplete='current-password'
												value={formik.values.loginPassword}
												isTouched={formik.touched.loginPassword}
												invalidFeedback={formik.errors.loginPassword}
												validFeedback='Looks good!'
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
											/>
										</FormGroup>
									</div>
									<div className='col-12'>
										<Button
											color='warning'
											className='w-100 py-3 mb-2'
											onClick={formik.handleSubmit}>
											Подтвердить
										</Button>
										<Button
											isLight
											className='w-100 py-3 mb-2'
											onClick={handleOnReturn}>
											Назад
										</Button>
									</div>
								</form>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default SetPassword;
