import React, { SVGProps } from "react";

export const THEETH_48 = (props: SVGProps<SVGSVGElement>) => {

    return (
		<>
           
           <svg width="35" height="58" viewBox="0 0 35 58" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M34.3862 52C32.3862 47 25.1687 48.56 28.1479 16.0831C22.6684 11.4054 9.57979 2.33183 3.48535 10.6716C2.88804 18.8898 3.17726 55.1323 21.621 57.9389C21.621 54.5 12.4039 37.8067 15.2105 35C19.621 36.2029 25.1644 58.4152 34.3862 52Z" fill="url(#paint0_linear_402_4960)"/>
            <g filter="url(#filter0_f_402_4960)">
            <path d="M11.4729 40.8873C11.3194 38.8202 10.5623 34.9379 8.2653 24.0474C8.2653 24.0474 12.6757 30.0617 13.4776 28.8588C14.2795 27.656 15.8833 20.8398 15.8833 20.8398C17.3402 30.0035 18.9653 34.4215 23.9023 40.4864" stroke="#F3F3F3" stroke-width="2.4057"/>
            </g>
            <path d="M27.5434 13.2759C27.6458 14.7153 14.7296 44.516 34.4452 51.8419C34.5458 51.8792 34.6361 51.7557 34.5607 51.6794C25.5452 42.5661 27.1288 24.3403 28.7138 15.2267L27.5434 13.2759C27.5408 13.2402 27.5303 13.2219 27.511 13.2219L27.5434 13.2759Z" fill="#D9D9D9"/>
            <path d="M27.5434 13.2759C27.6458 14.7153 14.7296 44.516 34.4452 51.8419C34.5458 51.8792 34.6361 51.7557 34.5607 51.6794C25.5452 42.5661 27.1288 24.3403 28.7138 15.2267L27.5434 13.2759C27.5408 13.2402 27.5303 13.2219 27.511 13.2219L27.5434 13.2759Z" fill="url(#paint1_linear_402_4960)"/>
            <path d="M0.814642 8.47989C1.21559 2.86659 4.02224 3.26754 13.645 5.67331C16.8527 3.10728 20.8622 4.87138 22.4659 6.07419C24.0697 4.87136 30.8857 3.26754 28.8811 15.697C24.3905 22.7537 15.2488 19.7065 11.2393 17.3008C8.83364 17.4344 3.78167 17.6216 2.81939 17.3008C1.61654 16.8998 0.413691 14.0932 0.814642 8.47989Z" fill="url(#paint2_linear_402_4960)"/>
            <path d="M0.814642 8.47989C1.21559 2.86659 4.02224 3.26754 13.645 5.67331C16.8527 3.10728 20.8622 4.87138 22.4659 6.07419C24.0697 4.87136 30.8857 3.26754 28.8811 15.697C24.3905 22.7537 15.2488 19.7065 11.2393 17.3008C8.83364 17.4344 3.78167 17.6216 2.81939 17.3008C1.61654 16.8998 0.413691 14.0932 0.814642 8.47989Z" fill="url(#paint3_radial_402_4960)" fill-opacity="0.3"/>
            <g filter="url(#filter1_f_402_4960)">
            <path d="M21.0158 11.6842C20.4935 14.296 22.9686 13.5125 24.4111 13.5125C25.8535 13.5125 25.4559 12.3158 25.4559 11.1619C25.4559 10.0079 21.4135 8.81128 19.9711 8.81128C18.5286 8.81128 19.4487 10.9007 21.0158 11.6842Z" fill="#FDFDFD"/>
            </g>
            <g filter="url(#filter2_f_402_4960)">
            <path d="M22.2985 6.00464C23.5274 7.20369 24.0231 7.94343 23.9023 9.61319" stroke="black" stroke-width="0.8019"/>
            </g>
            <g filter="url(#filter3_f_402_4960)">
            <path d="M13.4598 5.60376C14.6888 6.80281 15.1845 7.54255 15.0636 9.21231" stroke="black" stroke-width="0.8019"/>
            </g>
            <defs>
            <filter id="filter0_f_402_4960" x="0.673179" y="14.1493" width="30.5772" height="33.5118" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feGaussianBlur stdDeviation="3.2076" result="effect1_foregroundBlur_402_4960"/>
            </filter>
            <filter id="filter1_f_402_4960" x="15.2417" y="4.80178" width="14.2739" height="12.8303" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feGaussianBlur stdDeviation="2.00475" result="effect1_foregroundBlur_402_4960"/>
            </filter>
            <filter id="filter2_f_402_4960" x="17.2072" y="0.906374" width="11.925" height="13.5471" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feGaussianBlur stdDeviation="2.4057" result="effect1_foregroundBlur_402_4960"/>
            </filter>
            <filter id="filter3_f_402_4960" x="8.36853" y="0.505495" width="11.925" height="13.5471" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feGaussianBlur stdDeviation="2.4057" result="effect1_foregroundBlur_402_4960"/>
            </filter>
            <linearGradient id="paint0_linear_402_4960" x1="20.0484" y1="7.2771" x2="20.0484" y2="52.9854" gradientUnits="userSpaceOnUse">
            <stop stop-color="#D29439"/>
            <stop offset="1" stop-color="#887250"/>
            </linearGradient>
            <linearGradient id="paint1_linear_402_4960" x1="24.6845" y1="17.4895" x2="30.2513" y2="49.7367" gradientUnits="userSpaceOnUse">
            <stop stop-color="#725935"/>
            <stop offset="1" stop-color="#5B482A"/>
            </linearGradient>
            <linearGradient id="paint2_linear_402_4960" x1="14.9914" y1="4" x2="14.9914" y2="20.0674" gradientUnits="userSpaceOnUse">
            <stop offset="0.64" stop-color="white"/>
            <stop offset="1" stop-color="#B48B8B"/>
            </linearGradient>
            <radialGradient id="paint3_radial_402_4960" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(9.06698 10.4152) rotate(135) scale(3.40217 5.07356)">
            <stop stop-color="white" stop-opacity="0"/>
            <stop offset="1" stop-color="#9B9B9B"/>
            </radialGradient>
            </defs>
            </svg>

    </>
	);
};