import React, { SVGProps } from "react";

export const THEETH_45 = (props: SVGProps<SVGSVGElement>) => {

    return (
		<>
           <svg width="2.23rem" height="5.92rem" viewBox="0 0 29 77" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M15.1604 75.8025C3.16051 67.8024 2.51233 28.3332 4.179 9.99986C4.61181 2.99986 24.6642 9.49916 24.1787 18C23.3726 32.1127 17.6787 53.5 18.6611 74.303C17.823 77.2091 16.9024 76.9639 15.1604 75.8025Z" fill="url(#paint0_linear_402_4963)"/>
<g filter="url(#filter0_f_402_4963)">
<path d="M13.0444 21.5244C9.05922 35.7699 8.61975 43.8673 11.5444 58.5244" stroke="#F3F3F3" stroke-width="2.5" stroke-linecap="round"/>
</g>
<path d="M17.3874 75.4657C14.6493 55.6264 16.4309 40.0097 23.9746 15.3039C24.0108 15.1856 24.1418 15.1348 24.2253 15.2261C24.4874 15.5127 24.9045 16.276 24.5446 18.0752C24.0444 20.5752 24.2262 20.2065 24.0446 21.5752C23.554 25.2733 23.0446 31.0752 23.0446 31.0752L20.5446 47.0752L19.0446 61.0752L18.5466 74.0234C18.5452 74.0577 18.5381 74.0915 18.5253 74.1233L17.9594 75.5381C17.8455 75.8229 17.4293 75.7695 17.3874 75.4657Z" fill="url(#paint1_linear_402_4963)"/>
<path d="M24.7937 10.6359C25.1569 19.6372 19.7675 28 12.7923 28C3.67811 28 1.0638 16.6399 0.828949 10.1228C0.805803 9.48054 0.923573 8.84175 1.21467 8.26874C3.70798 3.36069 9.70681 0 12.7923 0C15.9722 0 23.2782 5.09943 24.6719 9.83948C24.7479 10.0981 24.7828 10.3666 24.7937 10.6359Z" fill="url(#paint2_linear_402_4963)"/>
<path d="M24.7937 10.6359C25.1569 19.6372 19.7675 28 12.7923 28C3.67811 28 1.0638 16.6399 0.828949 10.1228C0.805803 9.48054 0.923573 8.84175 1.21467 8.26874C3.70798 3.36069 9.70681 0 12.7923 0C15.9722 0 23.2782 5.09943 24.6719 9.83948C24.7479 10.0981 24.7828 10.3666 24.7937 10.6359Z" fill="url(#paint3_radial_402_4963)" fill-opacity="0.3"/>
<g filter="url(#filter1_f_402_4963)">
<path d="M14.4482 14.5752C13.4482 19.5752 18.1867 18.0752 20.9481 18.0752C23.7095 18.0752 22.9483 15.7843 22.9483 13.5752C22.9483 11.3661 15.2096 9.0752 12.4482 9.0752C9.68679 9.0752 11.4482 13.0752 14.4482 14.5752Z" fill="#FDFDFD"/>
</g>
<defs>
<filter id="filter0_f_402_4963" x="2.40234" y="14.2742" width="17.8926" height="51.5005" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_402_4963"/>
</filter>
<filter id="filter1_f_402_4963" x="6.06982" y="4.0752" width="21.9746" height="19.2109" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2.5" result="effect1_foregroundBlur_402_4963"/>
</filter>
<linearGradient id="paint0_linear_402_4963" x1="13.8456" y1="7.03394" x2="13.8456" y2="76.6154" gradientUnits="userSpaceOnUse">
<stop stop-color="#D29439"/>
<stop offset="1" stop-color="#887250"/>
</linearGradient>
<linearGradient id="paint1_linear_402_4963" x1="15.9664" y1="22.5992" x2="37.8308" y2="71.3388" gradientUnits="userSpaceOnUse">
<stop stop-color="#725935"/>
<stop offset="1" stop-color="#5B482A"/>
</linearGradient>
<linearGradient id="paint2_linear_402_4963" x1="12.811" y1="0" x2="12.811" y2="28" gradientUnits="userSpaceOnUse">
<stop offset="0.64" stop-color="white"/>
<stop offset="1" stop-color="#B48B8B"/>
</linearGradient>
<radialGradient id="paint3_radial_402_4963" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(5.5769 10.6687) rotate(105.414) scale(3.85106 3.83463)">
<stop stop-color="white" stop-opacity="0"/>
<stop offset="1" stop-color="#9B9B9B"/>
</radialGradient>
</defs>
</svg>


        </>
	);
};