import React, { FC } from 'react';
import PropTypes from 'prop-types';

interface IForgotPassProps {
	width?: number;
	height?: number;
}
const ForgotPass: FC<IForgotPassProps> = ({ width, height }) => {
	return (
		<svg
			width={height !== 854 && !!height ? height * (2155 / 854) : width}
			height={width !== 2155 && !!width ? width * (854 / 2155) : height}
			viewBox='0 0 196 196'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M191.916 104.084C191.916 116.742 173.215 108.167 165.906 125.971C164.635 128.943 164.063 132.167 164.233 135.395C164.403 138.622 165.31 141.768 166.886 144.591C173.868 157.249 172.806 156.024 160.107 168.723C151.083 177.747 143.774 158.474 126.216 165.824C121.558 167.663 117.728 171.131 115.436 175.583C111.638 182.525 113.19 191.917 104.084 191.917C86.1988 191.917 86.2805 193.632 82.238 179.667C81.3475 176.571 79.7631 173.719 77.605 171.327C75.447 168.936 72.7722 167.068 69.7838 165.865C52.348 158.597 44.8347 177.707 35.8922 168.764L27.2763 160.107C18.1297 150.961 37.5663 143.774 30.1755 126.216V126.012C22.8663 108.576 4.08301 116.62 4.08301 104.084V91.8341C4.08301 79.0941 22.8663 87.7508 30.1347 69.825C37.403 51.8991 18.3747 44.9166 27.2763 35.8925C35.443 27.7258 36.5863 26.0516 39.2405 25.2758C47.938 22.54 53.7772 36.8316 69.9063 30.135C87.383 22.9891 79.4205 4.08329 91.9155 4.08329C109.351 4.08329 109.678 2.16412 113.721 16.3333C114.605 19.4305 116.178 22.2876 118.323 24.6907C120.467 27.0938 123.128 28.9806 126.105 30.21C129.082 31.4393 132.299 31.9793 135.514 31.7895C138.73 31.5998 141.86 30.6851 144.672 29.1141C157.412 22.05 156.106 23.3158 168.723 35.9333C177.747 44.9575 158.433 52.2666 165.824 69.825C167.02 72.82 168.891 75.4985 171.292 77.6516C173.693 79.8047 176.559 81.3744 179.666 82.2383C193.305 86.1583 191.916 85.75 191.916 104.084Z'
				fill='#7C7D7D'
			/>
			<path
				d='M191.917 104.084C191.917 116.742 173.215 108.167 165.906 125.971C159.781 144.264 173.46 147.98 170.724 156.759C163.939 161.83 156.493 165.952 148.592 169.009C142.957 165.906 136.342 161.618 126.216 165.742C121.545 167.602 117.712 171.101 115.436 175.583H114.333C89.425 175.583 65.537 165.689 47.9242 148.076C30.3114 130.463 20.4166 106.575 20.4166 81.6666C20.3963 81.2994 20.3963 80.9313 20.4166 80.5641C22.921 79.2297 25.1344 77.4095 26.9273 75.21C28.7203 73.0104 30.0569 70.4756 30.859 67.7536C31.6612 65.0316 31.9128 62.177 31.5992 59.3567C31.2856 56.5363 30.413 53.8067 29.0325 51.3275L26.8683 47.2441C29.9257 39.3439 34.0471 31.898 39.1183 25.1125C47.8158 22.3766 53.655 36.6683 69.7841 29.9716C87.3833 22.9891 79.4208 4.08329 91.9158 4.08329C109.352 4.08329 109.678 2.16412 113.721 16.3333C114.605 19.4305 116.178 22.2876 118.323 24.6907C120.468 27.0938 123.128 28.9806 126.105 30.21C129.082 31.4393 132.299 31.9793 135.514 31.7895C138.73 31.5998 141.861 30.6851 144.672 29.1141C157.412 22.05 156.106 23.3158 168.723 35.9333C177.747 44.9575 158.433 52.2666 165.824 69.825C167.02 72.82 168.892 75.4985 171.293 77.6516C173.694 79.8047 176.559 81.3744 179.667 82.2383C193.305 86.1583 191.917 85.75 191.917 104.084Z'
				fill='#919191'
			/>
			<path
				d='M151.083 98C151.083 112.079 145.491 125.581 135.536 135.536C125.581 145.491 112.079 151.083 98 151.083C48.0608 151.083 25.7658 87.7917 64.8025 56.6358C72.6042 50.3831 82.0133 46.4608 91.9463 45.3206C101.879 44.1804 111.932 45.8686 120.948 50.1908C129.964 54.513 137.575 61.2936 142.907 69.7517C148.238 78.2099 151.072 88.0018 151.083 98Z'
				fill='#DAD7E5'
			/>
			<path
				d='M151.083 98C151.106 110.084 146.968 121.807 139.364 131.197C129.157 139.264 116.344 143.302 103.356 142.544C90.369 141.786 78.1121 136.286 68.913 127.087C59.714 117.887 54.2133 105.631 53.4554 92.6432C52.6976 79.6559 56.7354 66.8424 64.8021 56.6358C72.6038 50.3831 82.0129 46.4608 91.9458 45.3206C101.879 44.1804 111.932 45.8686 120.948 50.1908C129.963 54.513 137.575 61.2936 142.906 69.7517C148.238 78.2099 151.072 88.0018 151.083 98Z'
				fill='#EDEBF2'
			/>
			<path
				d='M114 93C111.791 93 110 91.2435 110 89.0769V78.6154C110 72.1258 104.617 66.8462 98 66.8462C91.3832 66.8462 86 72.1258 86 78.6154V89.0769C86 91.2435 84.2091 93 82 93C79.7909 93 78 91.2435 78 89.0769V78.6154C78 67.7995 86.972 59 98 59C109.028 59 118 67.7995 118 78.6154V89.0769C118 91.2435 116.209 93 114 93Z'
				fill='#FFF5F5'
			/>
			<path
				d='M98 59V66.8462C104.617 66.8462 110 72.1258 110 78.6154V89.0769C110 91.2435 111.791 93 114 93C116.209 93 118 91.2435 118 89.0769V78.6154C118 67.7995 109.028 59 98 59Z'
				fill='#E2DFF4'
			/>
			<path
				d='M113.789 130H82.2105C77.1318 130 73 125.844 73 120.735V94.2647C73 89.1561 77.1318 85 82.2105 85H113.789C118.868 85 123 89.1561 123 94.2647V120.735C123 125.844 118.868 130 113.789 130Z'
				fill='#86C4E7'
			/>
			<path
				d='M113.789 85H98V130H113.789C118.868 130 123 125.844 123 120.735V94.2647C123 89.1561 118.868 85 113.789 85Z'
				fill='#00B3CB'
			/>
			<path
				d='M92.563 115.847L87.1261 110.283C85.6246 108.746 85.6246 106.254 87.1261 104.718C88.6275 103.181 91.0616 103.181 92.563 104.718L95.2815 107.5L103.437 99.1526C104.938 97.6158 107.373 97.6158 108.874 99.1526C110.375 100.689 110.375 103.181 108.874 104.717L97.9999 115.847C96.4985 117.384 94.0644 117.384 92.563 115.847Z'
				fill='#0A789B'
			/>
		</svg>
	);
};
ForgotPass.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
ForgotPass.defaultProps = {
	width: 2155,
	height: 854,
};

export default ForgotPass;
