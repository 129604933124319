import React, { SVGProps } from "react";

export const THEETH_38 = (props: SVGProps<SVGSVGElement>) => {

    return (
		<>
           <svg width="2.46rem" height="3.77rem" viewBox="0 0 32 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M0.823983 44.8499C0.0889682 40.8069 9.22477 44.8474 6.4938 15.0763C11.5168 10.7882 23.5149 2.47064 29.1016 10.1156C29.6492 17.6491 28.7973 46.3313 11.8902 48.9041C9.31741 45.5962 18.5061 36.4076 15.9332 33.8348C11.8902 34.9374 8.89898 49.5999 0.823983 44.8499Z" fill="url(#paint0_linear_402_4983)"/>
<g filter="url(#filter0_f_402_4983)">
<path d="M21.7797 37.8143C21.9204 35.9194 22.6144 32.3605 24.72 22.3774C24.72 22.3774 20.677 27.8906 19.9419 26.7879C19.2068 25.6853 17.7367 19.437 17.7367 19.437C16.4011 27.8372 14.9115 31.8871 10.3857 37.4468" stroke="#F3F3F3" stroke-width="2.20528"/>
</g>
<path d="M7.04726 12.5048C6.98468 13.7586 18.2693 37.6699 0.820043 44.547C0.607914 44.6306 0.421598 44.3754 0.569995 44.2023C8.90234 34.4824 6.61536 25.5994 5.97535 14.2913L7.04726 12.5048C7.04895 12.4709 7.05893 12.4536 7.07799 12.4536L7.04726 12.5048Z" fill="#D9D9D9"/>
<path d="M7.04726 12.5048C6.98468 13.7586 18.2693 37.6699 0.820043 44.547C0.607914 44.6306 0.421598 44.3754 0.569995 44.2023C8.90234 34.4824 6.61536 25.5994 5.97535 14.2913L7.04726 12.5048C7.04895 12.4709 7.05893 12.4536 7.07799 12.4536L7.04726 12.5048Z" fill="url(#paint1_linear_402_4983)"/>
<path d="M31.5498 8.10666C31.1822 2.96102 28.6094 3.32857 19.7883 5.5339C16.8479 3.18165 13.1724 4.79879 11.7023 5.90139C10.2321 4.79876 3.98401 3.32857 5.82155 14.7225C9.93806 21.1913 18.3181 18.3979 21.9936 16.1927C24.1988 16.3152 28.8299 16.4867 29.712 16.1927C30.8147 15.8251 31.9173 13.2523 31.5498 8.10666Z" fill="url(#paint2_linear_402_4983)"/>
<path d="M31.5498 8.10666C31.1822 2.96102 28.6094 3.32857 19.7883 5.5339C16.8479 3.18165 13.1724 4.79879 11.7023 5.90139C10.2321 4.79876 3.98401 3.32857 5.82155 14.7225C9.93806 21.1913 18.3181 18.3979 21.9936 16.1927C24.1988 16.3152 28.8299 16.4867 29.712 16.1927C30.8147 15.8251 31.9173 13.2523 31.5498 8.10666Z" fill="url(#paint3_radial_402_4983)" fill-opacity="0.3"/>
<g filter="url(#filter1_f_402_4983)">
<path d="M13.0317 11.0443C13.5106 13.4384 11.2416 12.7202 9.91933 12.7202C8.59705 12.7202 8.96157 11.6232 8.96157 10.5654C8.96157 9.5076 12.6671 8.41064 13.9894 8.41064C15.3117 8.41064 14.4682 10.326 13.0317 11.0443Z" fill="#FDFDFD"/>
</g>
<g filter="url(#filter2_f_402_4983)">
<path d="M11.856 5.83765C10.7294 6.9368 10.275 7.61492 10.3858 9.14556" stroke="black" stroke-width="0.735092"/>
</g>
<g filter="url(#filter3_f_402_4983)">
<path d="M19.958 5.47021C18.8315 6.56937 18.3771 7.24748 18.4879 8.77813" stroke="black" stroke-width="0.735092"/>
</g>
<defs>
<filter id="filter0_f_402_4983" x="3.65003" y="13.3038" width="28.0295" height="30.7197" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2.94037" result="effect1_foregroundBlur_402_4983"/>
</filter>
<filter id="filter1_f_402_4983" x="5.24007" y="4.73519" width="13.0848" height="11.7616" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="1.83773" result="effect1_foregroundBlur_402_4983"/>
</filter>
<filter id="filter2_f_402_4983" x="5.5914" y="1.16391" width="10.9319" height="12.4188" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2.20528" result="effect1_foregroundBlur_402_4983"/>
</filter>
<filter id="filter3_f_402_4983" x="13.6935" y="0.79648" width="10.9319" height="12.4188" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2.20528" result="effect1_foregroundBlur_402_4983"/>
</filter>
<linearGradient id="paint0_linear_402_4983" x1="13.9185" y1="7.00391" x2="13.9185" y2="48.9041" gradientUnits="userSpaceOnUse">
<stop stop-color="#D29439"/>
<stop offset="1" stop-color="#887250"/>
</linearGradient>
<linearGradient id="paint1_linear_402_4983" x1="9.66885" y1="16.3657" x2="4.56591" y2="45.9263" gradientUnits="userSpaceOnUse">
<stop stop-color="#725935"/>
<stop offset="1" stop-color="#5B482A"/>
</linearGradient>
<linearGradient id="paint2_linear_402_4983" x1="18.5541" y1="4" x2="18.5541" y2="18.7288" gradientUnits="userSpaceOnUse">
<stop offset="0.64" stop-color="white"/>
<stop offset="1" stop-color="#B48B8B"/>
</linearGradient>
<radialGradient id="paint3_radial_402_4983" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(23.9849 9.88074) rotate(45) scale(3.11873 4.65087)">
<stop stop-color="white" stop-opacity="0"/>
<stop offset="1" stop-color="#9B9B9B"/>
</radialGradient>
</defs>
</svg>


        </>
	);
};