import React from 'react';
import Tooltips from '../../../../components/bootstrap/Tooltips';

export const StringWithTooltips: React.FC<{
	string: string;
}> = ({ string }) => {
	
	return (
		<div className='flex-grow-1 ms-3 d-flex align-items-start flex-column text-nowrap fs-6 w-100'>
			<span className='text-truncate'>
				<Tooltips title={string} placement='top' flip={['top']}>
					<span className='text-truncate overflow-hidden d-inline-block'>{string}</span>
				</Tooltips>
			</span>
		</div>
	);
};
