import React, { useState, useEffect } from 'react';

const Clock = () => {
	const [currentTime, setCurrentTime] = useState(
		new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
	);

	useEffect(() => {
		const intervalId = setInterval(() => {
			setCurrentTime(
				new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
			);
		}, 1000);

		return () => clearInterval(intervalId);
	}, []);

	return <div className='fs-2'>{currentTime}</div>;
};

export default Clock;
