import { authHost } from '../axios';
import { IMedicalCase } from '../interface/medicalCases.interface';

export class MedicalCasesService {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor() {}
	static async findAll(query: any): Promise<IMedicalCase[] | null> {
		const { data } = await authHost.get(`medical_case?${query}`);
		return data;
	}

	static async findOne(uuid: string, query?:any) {
		const { data } = await authHost.get(`medical_case/${uuid}?${query}`);
		return data;
	}

	static async create(data: IMedicalCase) {
		const medical_case = await authHost.post('medical_case',data)
		return medical_case;
	}

	static async update(uuid: string, data: IMedicalCase) {		
		const medicalCases = await authHost.patch(`medical_case/${uuid}`, data)		
		return medicalCases;
	}
}
