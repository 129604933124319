import React, { SVGProps } from "react";

export const THEETH_36 = (props: SVGProps<SVGSVGElement>) => {

    return (
		<>
           <svg width="33" height="57" viewBox="0 0 33 57" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g filter="url(#filter0_i_402_4982)">
<path d="M4.87412 55.7247C-5.09927 59.4183 7.61851 48.3375 4.87405 18.4194C9.92181 14.1102 21.4209 7.41246 27.0352 15.0951C31.4673 24.3291 30.3592 54.986 18.5404 54.6166C15.9081 54.5343 24.4495 36.518 18.5404 35.0406C13.7381 35.4099 10.1725 53.7625 4.87412 55.7247Z" fill="url(#paint0_linear_402_4982)"/>
</g>
<g filter="url(#filter1_i_402_4982)">
<path d="M8.42212 15.8562L4.87111 18.115C4.75662 18.1878 4.69129 18.3169 4.70064 18.4522C5.41189 28.742 4.66183 40.4808 1.36103 52.5837C0.746263 54.5418 0.956361 55.4663 1.35025 56.0515C1.64515 56.4896 2.34612 56.336 2.56186 55.854C8.22338 43.2047 9.32495 33.7696 8.76249 16.0404C8.75705 15.8689 8.56691 15.7641 8.42212 15.8562Z" fill="url(#paint1_linear_402_4982)"/>
</g>
<path d="M7.90165 20.5329C4.08235 20.5329 3.35451 11.5263 3.46729 6.94547C3.46861 6.89177 3.47621 6.83828 3.49002 6.78637C4.31589 3.68264 7.13783 4.24186 8.85213 5.09724C9.16621 5.25395 9.54969 5.2029 9.80367 4.96063C13.8368 1.11354 18.4631 2.92284 20.724 4.6329C21.0077 4.84746 21.4011 4.85997 21.6865 4.64772C25.5011 1.81091 30.0821 0.122304 32.5784 5.60091C32.6245 5.70204 32.6466 5.8197 32.6421 5.93074C32.2453 15.7526 29.316 17.5823 27.8471 18.3168C26.6652 18.9077 20.7062 18.563 17.8744 18.3168C16.1507 19.0555 11.743 20.5329 7.90165 20.5329Z" fill="url(#paint2_linear_402_4982)"/>
<path d="M7.90165 20.5329C4.08235 20.5329 3.35451 11.5263 3.46729 6.94547C3.46861 6.89177 3.47621 6.83828 3.49002 6.78637C4.31589 3.68264 7.13783 4.24186 8.85213 5.09724C9.16621 5.25395 9.54969 5.2029 9.80367 4.96063C13.8368 1.11354 18.4631 2.92284 20.724 4.6329C21.0077 4.84746 21.4011 4.85997 21.6865 4.64772C25.5011 1.81091 30.0821 0.122304 32.5784 5.60091C32.6245 5.70204 32.6466 5.8197 32.6421 5.93074C32.2453 15.7526 29.316 17.5823 27.8471 18.3168C26.6652 18.9077 20.7062 18.563 17.8744 18.3168C16.1507 19.0555 11.743 20.5329 7.90165 20.5329Z" fill="url(#paint3_radial_402_4982)" fill-opacity="0.3"/>
<g filter="url(#filter2_f_402_4982)">
<path d="M9.57607 5.4978L8.09863 10.6688" stroke="black" stroke-width="0.73872"/>
</g>
<g filter="url(#filter3_f_402_4982)">
<path d="M21.3959 4.75903L19.9185 9.93007" stroke="black" stroke-width="0.73872"/>
</g>
<g filter="url(#filter4_f_402_4982)">
<path d="M12.9718 11.0381C13.7105 14.7317 10.2101 13.6236 8.17018 13.6236C6.13026 13.6236 6.69261 11.9313 6.69261 10.2993C6.69261 8.6674 12.4093 6.9751 14.4492 6.9751C16.4891 6.9751 15.1879 9.92998 12.9718 11.0381Z" fill="#FDFDFD"/>
</g>
<defs>
<filter id="filter0_i_402_4982" x="-1.96318" y="8.62984" width="31.3948" height="47.7796" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2.95488" dy="-2.95488"/>
<feGaussianBlur stdDeviation="1.8468"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_402_4982"/>
</filter>
<filter id="filter1_i_402_4982" x="0.973633" y="15.822" width="9.39736" height="41.9655" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="1.47744" dy="1.47744"/>
<feGaussianBlur stdDeviation="1.47744"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_402_4982"/>
</filter>
<filter id="filter2_f_402_4982" x="3.31133" y="0.963921" width="11.0521" height="14.2387" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2.21616" result="effect1_foregroundBlur_402_4982"/>
</filter>
<filter id="filter3_f_402_4982" x="15.1312" y="0.225151" width="11.0521" height="14.2387" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2.21616" result="effect1_foregroundBlur_402_4982"/>
</filter>
<filter id="filter4_f_402_4982" x="2.92798" y="3.2815" width="16.2329" height="14.1914" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="1.8468" result="effect1_foregroundBlur_402_4982"/>
</filter>
<linearGradient id="paint0_linear_402_4982" x1="15.2118" y1="11.5847" x2="15.2118" y2="56.4095" gradientUnits="userSpaceOnUse">
<stop stop-color="#D29439"/>
<stop offset="1" stop-color="#887250"/>
</linearGradient>
<linearGradient id="paint1_linear_402_4982" x1="9.10416" y1="20.6907" x2="-1.65814" y2="56.7176" gradientUnits="userSpaceOnUse">
<stop stop-color="#725935"/>
<stop offset="1" stop-color="#5B482A"/>
</linearGradient>
<linearGradient id="paint2_linear_402_4982" x1="18.0527" y1="2" x2="18.0527" y2="20.5329" gradientUnits="userSpaceOnUse">
<stop offset="0.64" stop-color="white"/>
<stop offset="1" stop-color="#B48B8B"/>
</linearGradient>
<radialGradient id="paint3_radial_402_4982" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(26.8519 9.0615) rotate(63.1296) scale(2.75471 4.31588)">
<stop stop-color="white" stop-opacity="0"/>
<stop offset="1" stop-color="#9B9B9B"/>
</radialGradient>
</defs>
</svg>


        </>
	);
};