import React, { SVGProps } from "react";

export const THEETH_15 = (props: SVGProps<SVGSVGElement>) => {

    return (
		<>
           <svg width="2.15rem" height="6rem" viewBox="0 0 28 78" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M15.0823 1.52466C3.08238 9.52477 2.43421 48.994 4.10088 67.3273C4.53368 74.3273 24.5861 67.828 24.1006 59.3271C23.2945 45.2144 17.6006 23.8271 18.583 3.02414C17.7448 0.11808 16.8243 0.363297 15.0823 1.52466Z" fill="url(#paint0_linear_402_4980)"/>
<g filter="url(#filter0_f_402_4980)">
<path d="M12.9663 55.8027C8.9811 41.5572 8.54162 33.4598 11.4663 18.8027" stroke="#F3F3F3" stroke-width="2.5" stroke-linecap="round"/>
</g>
<path d="M17.3093 1.86148C14.5712 21.7007 16.3528 37.3175 23.8965 62.0232C23.9326 62.1415 24.0636 62.1923 24.1471 62.101C24.4093 61.8145 24.8264 61.0512 24.4664 59.252C23.9663 56.752 24.148 57.1207 23.9664 55.752C23.4758 52.0539 22.9664 46.252 22.9664 46.252L20.4664 30.252L18.9664 16.252L18.4684 3.30373C18.4671 3.26947 18.4599 3.23568 18.4472 3.20384L17.8813 1.78907C17.7674 1.50426 17.3512 1.55761 17.3093 1.86148Z" fill="url(#paint1_linear_402_4980)"/>
<path d="M24.7156 66.6912C25.0788 57.69 19.6894 49.3271 12.7141 49.3271C3.59998 49.3271 0.98567 60.6872 0.750824 67.2043C0.727678 67.8466 0.845448 68.4854 1.13654 69.0584C3.62986 73.9665 9.62869 77.3271 12.7141 77.3271C15.894 77.3271 23.2 72.2277 24.5937 67.4877C24.6698 67.2291 24.7047 66.9606 24.7156 66.6912Z" fill="url(#paint2_linear_402_4980)"/>
<path d="M24.7156 66.6912C25.0788 57.69 19.6894 49.3271 12.7141 49.3271C3.59998 49.3271 0.98567 60.6872 0.750824 67.2043C0.727678 67.8466 0.845448 68.4854 1.13654 69.0584C3.62986 73.9665 9.62869 77.3271 12.7141 77.3271C15.894 77.3271 23.2 72.2277 24.5937 67.4877C24.6698 67.2291 24.7047 66.9606 24.7156 66.6912Z" fill="url(#paint3_radial_402_4980)" fill-opacity="0.3"/>
<g filter="url(#filter1_f_402_4980)">
<path d="M14.3701 62.752C13.3701 57.752 18.1086 59.252 20.87 59.252C23.6314 59.252 22.8702 61.5428 22.8702 63.752C22.8702 65.9611 15.1315 68.252 12.3701 68.252C9.60867 68.252 11.3701 64.252 14.3701 62.752Z" fill="#FDFDFD"/>
</g>
<defs>
<filter id="filter0_f_402_4980" x="2.32422" y="11.5525" width="17.8926" height="51.5005" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_402_4980"/>
</filter>
<filter id="filter1_f_402_4980" x="5.9917" y="54.041" width="21.9746" height="19.2109" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2.5" result="effect1_foregroundBlur_402_4980"/>
</filter>
<linearGradient id="paint0_linear_402_4980" x1="13.7675" y1="70.2932" x2="13.7675" y2="0.711761" gradientUnits="userSpaceOnUse">
<stop stop-color="#D29439"/>
<stop offset="1" stop-color="#887250"/>
</linearGradient>
<linearGradient id="paint1_linear_402_4980" x1="15.8883" y1="54.7279" x2="37.7526" y2="5.98839" gradientUnits="userSpaceOnUse">
<stop stop-color="#725935"/>
<stop offset="1" stop-color="#5B482A"/>
</linearGradient>
<linearGradient id="paint2_linear_402_4980" x1="12.7329" y1="77.3271" x2="12.7329" y2="49.3271" gradientUnits="userSpaceOnUse">
<stop offset="0.64" stop-color="white"/>
<stop offset="1" stop-color="#B48B8B"/>
</linearGradient>
<radialGradient id="paint3_radial_402_4980" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(5.49877 66.6585) rotate(-105.414) scale(3.85106 3.83463)">
<stop stop-color="white" stop-opacity="0"/>
<stop offset="1" stop-color="#9B9B9B"/>
</radialGradient>
</defs>
</svg>


        </>
	);
};