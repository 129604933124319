import { authHost } from '../axios';
import { IStage } from '../interface/stage.interface';

export class StageService {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor() {}
	static async findAll(query: any): Promise<IStage[] | null> {
		const { data } = await authHost.get(`stage?${query}`);
		return data;
	}

	static async findOne(uuid: string) {
		const { data } = await authHost.get(`stage/${uuid}`);
		return data;
	}

	static async create(data: IStage) {
		const stage = await authHost.post('stage',data)
		return stage;
	}

	static async update(uuid: string, data: IStage) {				
		const stage = await authHost.patch(`stage/${uuid}`, data)		
		return stage;
	}

	static async delete(uuid: string) {				
		const stage = await authHost.delete(`stage/${uuid}`)		
		return stage;
	}
}
