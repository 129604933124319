import React, { SVGProps } from "react";

export const THEETH_16 = (props: SVGProps<SVGSVGElement>) => {

    return (
		<>
           <svg width="33" height="67" viewBox="0 0 33 67" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M11.0023 1.08407C21.8023 8.28379 25.5277 41.3664 24.5023 57.7838C24.2361 64.0522 8.46064 46.3764 8.75935 38.7639C9.25525 26.1261 8.75935 25.2639 8.30859 6.48406C8.82421 3.88171 9.75969 0.255726 11.0023 1.08407Z" fill="url(#paint0_linear_402_4958)"/>
<g filter="url(#filter0_i_402_4958)">
<path d="M3.35903 5.91387C-3.39089 14.0139 5.60908 22.1139 5.1277 51.1411C10.8914 55.5621 24.6591 64.1378 31.0697 56.2557C31.698 48.4885 35.3098 7.71387 19.5598 6.36387C15.6347 6.02743 23.6098 20.3139 17.6974 32.4639C12.3591 26.1639 6.95913 2.76387 3.35903 5.91387Z" fill="url(#paint1_linear_402_4958)"/>
</g>
<path d="M1.57698 61.8271C3.73698 69.7471 15.077 65.3138 16.8207 61.8271C18.1707 64.077 24.0208 66.3271 26.2708 66.3271C28.5208 66.3271 34.3708 64.5271 32.1208 53.7271C30.3208 45.0871 23.1771 42.3638 16.8207 45.0638C5.17705 41.4638 -1.12302 51.9271 1.57698 61.8271Z" fill="url(#paint2_linear_402_4958)"/>
<path d="M1.57698 61.8271C3.73698 69.7471 15.077 65.3138 16.8207 61.8271C18.1707 64.077 24.0208 66.3271 26.2708 66.3271C28.5208 66.3271 34.3708 64.5271 32.1208 53.7271C30.3208 45.0871 23.1771 42.3638 16.8207 45.0638C5.17705 41.4638 -1.12302 51.9271 1.57698 61.8271Z" fill="url(#paint3_radial_402_4958)" fill-opacity="0.3"/>
<defs>
<filter id="filter0_i_402_4958" x="-1.95113" y="2.83378" width="33.8188" height="56.6301" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-5.4" dy="-3.6"/>
<feGaussianBlur stdDeviation="1.395"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_402_4958"/>
</filter>
<linearGradient id="paint0_linear_402_4958" x1="16.487" y1="59.104" x2="16.487" y2="0.963711" gradientUnits="userSpaceOnUse">
<stop stop-color="#5C472A"/>
<stop offset="1" stop-color="#685233"/>
</linearGradient>
<linearGradient id="paint1_linear_402_4958" x1="16.3534" y1="59.4639" x2="16.3534" y2="5.62372" gradientUnits="userSpaceOnUse">
<stop stop-color="#D29439"/>
<stop offset="1" stop-color="#887250"/>
</linearGradient>
<linearGradient id="paint2_linear_402_4958" x1="16.7917" y1="66.3271" x2="16.7917" y2="44.0631" gradientUnits="userSpaceOnUse">
<stop offset="0.64" stop-color="white"/>
<stop offset="1" stop-color="#B48B8B"/>
</linearGradient>
<radialGradient id="paint3_radial_402_4958" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(21.989 54.9636) rotate(41.1859) scale(4.78357 6.33985)">
<stop stop-color="white" stop-opacity="0"/>
<stop offset="1" stop-color="#9B9B9B"/>
</radialGradient>
</defs>
</svg>


        </>
	);
};