import React, { FC, useState } from 'react';
import classNames from 'classnames';
import Button from '../../../../components/bootstrap/Button';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../../components/bootstrap/Dropdown';
import EVENT_STATUS from '../../../../common/data/enumEventStatus';
import useDarkMode from '../../../../hooks/useDarkMode';
import StatusSubmitModal from '../../../pages/visit/StatusSubmitModal';
import { ImagesService } from '../../../api/service/images.service';
import { useMutation, useQueryClient } from 'react-query';

interface ICommonUpcomingEventsProps {
	image: any;
	// setUpdateVisitModal(...args: unknown[]): unknown;
	// setPatientId(...args: unknown[]): unknown;
	// setMedicalCaseUuid(...args: unknown[]): unknown;
	// setServiceUuid(...args: unknown[]): unknown;
	// setClinicUuid(...args: unknown[]): unknown;
	// setStageUuid(...args: unknown[]): unknown;
	// setVisitUpdate(...args: unknown[]): unknown;
	// handleOpenCancel(...args: unknown[]): unknown;
	// setVisitUuid(...args: unknown[]): unknown;
	// changeStatus(...args: unknown[]): unknown;
	// setActModal(...args: unknown[]): unknown;
	// setActVisit(...args: unknown[]): unknown;
	// setCanvasData?(...args: unknown[]): unknown;
	// setEventAdding?(...args: unknown[]): unknown;
}

const DeleteOpen: FC<ICommonUpcomingEventsProps> = ({
	image,
	// setUpdateVisitModal,
	// setPatientId,
	// setMedicalCaseUuid,
	// setServiceUuid,
	// setClinicUuid,
	// setStageUuid,
	// setVisitUpdate,
	// handleOpenCancel,
	// setVisitUuid,
	// setCanvasData,
	// changeStatus,
	// setActModal,
	// setEventAdding,
	// setActVisit,
}) => {
	const [dropdownClose, setDropdownClose] = useState<boolean>(false);
	const [statusModal, setStatusModal] = useState<boolean>(false);
	const { darkModeStatus } = useDarkMode();

	const queryClient = useQueryClient();

	const { mutate: deleteImage } = useMutation((uuid: string) => ImagesService.delete(uuid), {
		onSuccess: () => {
			queryClient.invalidateQueries('images');
		},
		onError: (error) => {
			console.error(error);
		},
	});

	// const handleStatusChange = (status: string) => {
	// 	changeStatus({
	// 		uuid: visit?.uuid,
	// 		patientUuid: visit?.patientUuid,
	// 		status: status,
	// 	});
	// 	setDropdownClose(false);
	// };

	const handleDelete = (e: React.FormEvent<HTMLFormElement>) => {
		// setUpdateVisitModal(true);
		// setPatientId(visit?.patient?.uuid);
		// setMedicalCaseUuid(visit?.medicalCase?.uuid);
		// // Safeguard the array spreading
		// const services_uuids = visit?.services.map((item: any) => item.event.uuid);
		// setServiceUuid((prev: any) => {
		// 	if (!prev) prev = [];
		// 	if (services_uuids) {
		// 		return [...prev, services_uuids];
		// 	}
		// 	return prev;
		// });
		// setClinicUuid(visit?.clinic?.uuid);
		// setStageUuid(visit?.medicalCase?.stage[0]?.uuid);
		// setVisitUpdate(visit);
		// setDropdownClose(false);
		deleteImage(image.uuid);
	};

	const handleOpen = () => {
		// if (setCanvasData && setEventAdding) {
		// 	setCanvasData(visit);
		// 	setEventAdding(true);
		// 	setDropdownClose(false);
		// }
	};

	return (
		<>
			<Dropdown isOpen={dropdownClose} setIsOpen={setDropdownClose}>
				<DropdownToggle hasIcon={false}>
					<Button
						isOutline={!darkModeStatus}
						color='dark'
						isLight={darkModeStatus}
						className={classNames(
							'text-nowrap',

							{
								'border-light': !darkModeStatus,
							},
							'fw-normal',
							'fs-6',
						)}>
						•••
					</Button>
				</DropdownToggle>
				<DropdownMenu className='fw-bold'>
					<>
						<DropdownItem>
							<Button onClick={handleOpen}>Открыть</Button>
						</DropdownItem>
						<DropdownItem>
							<Button onClick={handleDelete}>Удалить</Button>
						</DropdownItem>
					</>
				</DropdownMenu>
			</Dropdown>

			{/* <StatusSubmitModal
				setIsOpen={setStatusModal}
				isOpen={statusModal}
				changeStatus={handleStatusChange}
			/> */}
		</>
	);
};

export default DeleteOpen;
