import { Middleware, combineReducers, configureStore } from "@reduxjs/toolkit";
import formDataSlice from "./formData/formData";
import dentalFormulaSlice from "./dentalFormula/dentalFormula";

// Root reducer
const rootReducer = combineReducers({
  formData: formDataSlice.reducer,
  dentalFormula: dentalFormulaSlice.reducer,
});

// Define any additional middlewares (none in this case)
const additionalMiddlewares: Middleware[] = [];

// Create the store
export function makeStore() {
  return configureStore({
    reducer: rootReducer,
    // middleware: (getDefaultMiddleware) => 
    //   getDefaultMiddleware() // Ensure proper type inference
    //     .concat(additionalMiddlewares), // Add any additional middlewares here
  });
}

// Types for the store and dispatch
export type AppStore = ReturnType<typeof makeStore>;
export type RootState = ReturnType<AppStore["getState"]>;
export type AppDispatch = AppStore["dispatch"];
