import React, { SVGProps } from "react";

export const THEETH_31 = (props: SVGProps<SVGSVGElement>) => {

    return (
		<>
           <svg width="1.77rem" height="5.61rem" viewBox="0 0 23 73" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M9.69287 71.5C17.8957 63.5399 15.4597 26.7488 14.3204 8.50724C14.0246 1.54229 2.8609 2.04174 3.1928 10.5C3.74381 24.5421 6.19258 55.5005 6.79963 69.5086C7.37254 72.4001 8.50208 72.6555 9.69287 71.5Z" fill="url(#paint0_linear_402_4974)"/>
<g filter="url(#filter0_f_402_4974)">
<path d="M10.043 26.5513C11.4157 35.789 11.5054 41.6101 10.543 53.0513" stroke="#F3F3F3" stroke-width="1.5" stroke-linecap="round"/>
</g>
<path d="M8.06104 72C6.06104 68.5 5.76608 55.3825 3.56104 18" stroke="url(#paint1_linear_402_4974)" stroke-width="0.9" stroke-linecap="round"/>
<path d="M2.06104 4.5C2.06104 8 2.06104 27 10.061 27C15.561 27 17.561 16.5 17.561 14V1.5C17.561 0.5 17.061 0 15.061 0H4.56104C3.56104 0 2.06104 1 2.06104 4.5Z" fill="url(#paint2_linear_402_4974)"/>
<path d="M2.06104 4.5C2.06104 8 2.06104 27 10.061 27C15.561 27 17.561 16.5 17.561 14V1.5C17.561 0.5 17.061 0 15.061 0H4.56104C3.56104 0 2.06104 1 2.06104 4.5Z" fill="url(#paint3_radial_402_4974)" fill-opacity="0.3"/>
<g filter="url(#filter1_f_402_4974)">
<path d="M13.6572 17.5C14.6572 22.5 9.91878 21 7.15736 21C4.39594 21 5.15719 18.7091 5.15719 16.5C5.15719 14.2909 12.8958 12 15.6573 12C18.4187 12 16.6572 16 13.6572 17.5Z" fill="#FDFDFD"/>
</g>
<defs>
<filter id="filter0_f_402_4974" x="3.29297" y="19.8013" width="14.6396" height="40" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_402_4974"/>
</filter>
<filter id="filter1_f_402_4974" x="0.0610352" y="7" width="21.9746" height="19.2109" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2.5" result="effect1_foregroundBlur_402_4974"/>
</filter>
<linearGradient id="paint0_linear_402_4974" x1="9.3732" y1="3.68213" x2="9.3732" y2="72.1424" gradientUnits="userSpaceOnUse">
<stop stop-color="#D29439"/>
<stop offset="1" stop-color="#887250"/>
</linearGradient>
<linearGradient id="paint1_linear_402_4974" x1="7.29314" y1="24.64" x2="-18.4623" y2="52.4902" gradientUnits="userSpaceOnUse">
<stop stop-color="#725935"/>
<stop offset="1" stop-color="#5B482A"/>
</linearGradient>
<linearGradient id="paint2_linear_402_4974" x1="9.81104" y1="0" x2="9.81104" y2="27" gradientUnits="userSpaceOnUse">
<stop offset="0.64" stop-color="white"/>
<stop offset="1" stop-color="#B48B8B"/>
</linearGradient>
<radialGradient id="paint3_radial_402_4974" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(7.06103 8) rotate(66.8014) scale(3.80789 2.6424)">
<stop stop-color="white" stop-opacity="0"/>
<stop offset="1" stop-color="#9B9B9B"/>
</radialGradient>
</defs>
</svg>


        </>
	);
};