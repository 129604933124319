/* eslint-disable react/self-closing-comp */
import React, { FC, useEffect, useState } from 'react';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../../../components/bootstrap/Modal';
import Button from '../../../../components/bootstrap/Button';
import SubHeader from '../../../../layout/SubHeader/SubHeader';
import { IPatientUpdate } from '@textnpayme/custom/api/interface/patient.interface';
import { IUserProps } from '../../../../common/data/userDummyData';
import { TColor } from '../../../../type/color-type';
import { IEvents } from '../../../../common/data/events';
import classNames from 'classnames';
import { format } from 'date-fns';
import { notification } from 'antd';
import type { NotificationArgsProps } from 'antd';
import { TimePicker } from 'antd';
import useDarkMode from '../../../../hooks/useDarkMode';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import dayjs, { Dayjs } from 'dayjs';
import Input from '../../../../components/bootstrap/forms/Input';
import { useDebounce } from '../../../../hooks/useDebounce';

type NotificationPlacement = NotificationArgsProps['placement'];

interface ITimePickerModalProps {
	isOpen: boolean;
	setIsOpen(...args: unknown[]): unknown;
	changeValues(...args: unknown[]): unknown;
	chooseTimeDate: Date;
	setChooseTimeDate(...args: unknown[]): unknown;
	visitData: any;
	setVisitData(...args: unknown[]): unknown;
	visits: any;
	prevVisit: any;
}

interface UpdatePatientParams {
	uuid: string;
	inputDto: IPatientUpdate;
}

type NoUndefinedRangeValueType<T> = T | [T, T] | null;

const now = new Date();

interface IEvent extends IEvents {
	user?: IUserProps;
	users?: IUserProps[];
	color?: TColor;
}

const TimePickerModal: FC<ITimePickerModalProps> = ({
	isOpen,
	chooseTimeDate,
	changeValues,
	setChooseTimeDate,
	setVisitData,
	visitData,
	setIsOpen,
	visits,
	prevVisit,
}) => {
	const [api, contextHolder] = notification.useNotification();
	const [start, setStart] = useState<any>();
	const [end, setEnd] = useState<any>();
	const [duration, setDuration] = useState<number | null>(null); // Duration in minutes
	const [startTime, setStartTime] = useState<any>();
	const [endTime, setEndTime] = useState<any>();

	const DebounsedDuration = useDebounce(duration);

	console.log(22222222, visits);

	// function checkTimeOverlap(startTime:string, endTime:string, objectsArray:any) {
	// 	// Convert input times to Date objects for comparison
	// 	console.log('objectsArray',objectsArray);

	// 	if (!Array.isArray(objectsArray)) {
	// 		throw new TypeError('objectsArray must be an array');
	// 	}
	// 	const start = new Date(startTime);
	// 	const end = new Date(endTime);

	// 	return objectsArray.some((obj:any) => {
	// 		const objStart = new Date(obj.startDate);
	// 		const objEnd = new Date(obj.endDate);
	// 		console.log(888888,(objStart <= start || start <= objEnd));

	// 		// if((objStart <= start || start <= objEnd) )  {
	// 		// 	console.log(111111111, 99999);

	// 		// 	return false
	// 		// }

	// 		// if((objStart <= end || end <= objEnd)) {
	// 		// 	console.log(22222222, 99999);
	// 		// 	return false
	// 		// }

	// 		return (objStart >= start && objEnd <= end);
	// 		// Check if the object's time falls within the given time range
	// 	});
	// }

	console.log(visits, 'docker-composedocker-compose');

	function checkDateInRanges(startDate: string, endDate: string, dateRanges: any) {
		// Convert startDate and endDate to Date objects for comparison
		const start = new Date(startDate);
		const end = new Date(endDate);

		return dateRanges.some((range: any, index: number) => {
			const rangeStart = new Date(range.startDate);
			const rangeEnd = new Date(range.endDate);

			if (start >= rangeStart && start <= rangeEnd) {
				return true;
			}

			if ((start <= rangeStart && start <= rangeEnd)&&(end >= rangeStart && end >= rangeEnd)) {
				return true;
			}
			if (end >= rangeStart && end <= rangeEnd) {
				return true;
			}

			return false;
		});
	}

	const openNotificationDateChangeTime = (placement: NotificationPlacement) => {
		api.info({
			message: `Важно !`,
			description: 'Вы не можете выбрать прошедшую дату для визита',
			placement,
			className: 'ant-notification-custom',
			props: {
				style: {
					zIndex: 150000,
				},
			},
		});
	};

	const openNotificationDuration = (placement: NotificationPlacement) => {
		api.info({
			message: `Важно !`,
			description: 'Нужно выбрать время прихода',
			placement,
			className: 'ant-notification-custom',
			props: {
				style: {
					zIndex: 150000,
				},
			},
		});
	};

	const openNotificationDateChangeTimeLate = (placement: NotificationPlacement) => {
		api.info({
			message: `Важно !`,
			description: 'Дата начала должна быть раньше даты окончания',
			placement,
			className: 'ant-notification-custom',
			props: {
				style: {
					zIndex: 150000,
				},
			},
		});
	};

	const openNotificationDateChangeTimeStart = (placement: NotificationPlacement) => {
		api.info({
			message: `Важно !`,
			description: 'выберите время начала визита',
			placement,
			className: 'ant-notification-custom',
			props: {
				style: {
					zIndex: 150000,
				},
			},
		});
	};

	const openNotificationDateChangeTimeEnd = (placement: NotificationPlacement) => {
		api.info({
			message: `Важно !`,
			description: 'выберите время окончания визита',
			placement,
			className: 'ant-notification-custom',
			props: {
				style: {
					zIndex: 150000,
				},
			},
		});
	};

	const openNotificationTimeRangeError = (placement: NotificationPlacement) => {
		api.info({
			message: `Важно !`,
			description: 'выберите другое время, этот диапазон времени занят',
			placement,
			className: 'ant-notification-custom',
			props: {
				style: {
					zIndex: 150000,
				},
			},
		});
	};

	function isDateBeforeNow(date: Date | string): boolean {
		const givenDate = new Date(date);
		const currentDate = new Date();
		return givenDate > currentDate;
	}
	let formattedDate = '';
	if (chooseTimeDate) {
		formattedDate = chooseTimeDate.toISOString();
	}

	const joinTimeAndDate = (time: string, date: string): any => {
		const hour = time.split(':')[0];
		const minute = time.split(':')[1]; // Corrected from 'mimute' to 'minute'
		if (!isNaN(+dayjs(date).hour(+hour).minute(+minute))) {
			const formattedTime = dayjs(date).hour(+hour).minute(+minute).toISOString();
			return `${date.split('T')[0]}T${formattedTime.split('T')[1].split('.')[0]}.000Z`;
		}
	};

	const calculateEndTime = (start: string, duration: number): string => {
		const startTime = dayjs(start, 'HH:mm');
		const endTime = startTime.add(duration, 'minute');
		return endTime.format('HH:mm');
	};
	const calculateStartTime = (end: string, duration: number): string => {
		const endTime = dayjs(end, 'HH:mm');
		const startTime = endTime.subtract(duration, 'minute');
		return startTime.format('HH:mm');
	};

	const calculateDuration = (startTime: string, endTime: string): void => {
		const [startHour, startMinute] = startTime.split(':').map(Number);
		const [endHour, endMinute] = endTime.split(':').map(Number);

		const startTotalMinutes = startHour * 60 + startMinute;
		const endTotalMinutes = endHour * 60 + endMinute;

		const durationInMinutes = endTotalMinutes - startTotalMinutes;

		return setDuration(durationInMinutes);
	};

	const { darkModeStatus } = useDarkMode();

	const handleSelect = () => {
		// console.log(111111111, checkTimeOverlap(start, end, visits?.data));
		if (checkDateInRanges(start, end, visits?.data)) {
			changeValues('startDate', '');

			changeValues('endDate', '');

			setStart('');
			setEnd('');

			changeValues('visitDate', '');

			setStartTime('');
			setEndTime('');
			return openNotificationTimeRangeError('bottomRight');
		}

		if (!isDateBeforeNow(start)) {
			changeValues('startDate', '');

			changeValues('endDate', '');

			setStart('');
			setEnd('');

			changeValues('visitDate', '');

			setStartTime('');
			setEndTime('');
			return openNotificationDateChangeTime('bottomRight');
		}

		if (start > end) {
			changeValues('startDate', '');

			changeValues('endDate', '');

			setStart('');
			setEnd('');

			changeValues('visitDate', '');

			setStartTime('');
			setEndTime('');
			return openNotificationDateChangeTimeLate('bottomRight');
		}

		if (
			!visitData.find((i: any) => i?.id === 'visitDate') &&
			end &&
			start &&
			isDateBeforeNow(start)
		) {
			setVisitData((prev: any) => [
				...prev,
				{
					id: 'visitDate',
					visitData: visitData,
					name: 'asdasd',
					setVisitData: setVisitData,
					prevVisit: !!prevVisit ? prevVisit : null,
					changeValues: changeValues,
					start: new Date(start),
					end: new Date(end),
				},
			]);
			changeValues('startDate', joinTimeAndDate(startTime, formattedDate));
			changeValues('visitDate', joinTimeAndDate(startTime, formattedDate));
			changeValues('endDate', joinTimeAndDate(endTime, formattedDate));
		}

		if (
			visitData.find((i: any) => i?.id === 'visitDate') &&
			end &&
			start &&
			isDateBeforeNow(start)
		) {
			setVisitData([
				...visits?.data,
				{
					id: 'visitDate',
					visitData: visitData,
					setVisitData: setVisitData,
					name: 'asdasd',

					prevVisit: !!prevVisit ? prevVisit : null,
					changeValues: !!prevVisit ? changeValues : null,
					start: new Date(start),
					end: new Date(end),
				},
			]);
			changeValues('startDate', joinTimeAndDate(startTime, formattedDate));
			changeValues('visitDate', joinTimeAndDate(startTime, formattedDate));
			changeValues('endDate', joinTimeAndDate(endTime, formattedDate));
		}

		if (!start) {
			openNotificationDateChangeTimeStart('bottomRight');
		}
		if (!end) {
			openNotificationDateChangeTimeEnd('bottomRight');
		}
		if (start && end) setIsOpen(false);
	};

	useEffect(() => {
		console.log(988989888, startTime);

		if (!!startTime && duration !== null) {
			const calculatedEndTime = calculateEndTime(startTime, duration);
			setEndTime(calculatedEndTime);
			setEnd(joinTimeAndDate(calculatedEndTime, formattedDate));
			changeValues('endDate', joinTimeAndDate(calculatedEndTime, formattedDate));
		}

		if (!duration) {
			setEndTime('');
			setEnd('');
			changeValues('endDate', '');
		}
	}, [duration, endTime, startTime]);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const eventStyleGetter = (
		event: { color?: TColor },
		start: any,
		end: any,
		isSelected: boolean,
	) => {
		const isActiveEvent = start <= now && end >= now;
		const isPastEvent = end < now;
		const color = isActiveEvent ? 'success' : event.color;

		return {
			className: classNames({
				[`bg-l${darkModeStatus ? 'o25' : '10'}-${color} text-${color}`]: color,
				'border border-success': isActiveEvent,
				'opacity-50': isPastEvent,
			}),
		};
	};

	const handleDurationChange = (e: any) => {
		const value = Number(e.target.value);
		if (value > 0) {
			setDuration(value);
		} else {
			setDuration(null);
		}
		if (!startTime) {
			openNotificationDuration('bottomRight');
			setDuration(null);
		}
	};

	return (
		<Modal
			modalLevel={140000}
			isStaticBackdrop
			isScrollable={false}
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			fullScreen={'md'}>
			<ModalHeader setIsOpen={setIsOpen} className='p-4'>
				<span className='fs-3 fw-bold'>Выберите длительность визита</span>
			</ModalHeader>
			<ModalBody className='px-4' style={{ overflow: 'hidden' }}>
				{contextHolder}
				<div className='col-12 d-flex justify-content-center mb-3'>
					<div className='col-12'>
						{!!formattedDate && (
							<span className='text-info fw-bold'>
								Дата визита - {dayjs(formattedDate).format('DD.MM.YYYY')}
							</span>
						)}
					</div>
				</div>
				<div className='col-12 d-flex flex-column justify-content-center'>
					<FormGroup className='col-12 mb-4' id='name' label='Время прихода'>
						<Input
							value={startTime}
							onChange={(e: any) => {
								setStartTime(e.target.value);
								changeValues(
									'startDate',
									joinTimeAndDate(e.target.value, formattedDate),
								);
								setStart(joinTimeAndDate(e.target.value, formattedDate));
								changeValues(
									'visitDate',
									joinTimeAndDate(e.target.value, formattedDate),
								);
							}}
							type={'time'}
						/>
					</FormGroup>

					<FormGroup className='col-12 mb-4' id='duration' label='Длительность (минуты)'>
						<Input
							value={duration || ''}
							onChange={handleDurationChange}
							type={'number'}
							placeholder='Введите длительность в минутах'
						/>
					</FormGroup>

					<FormGroup className='col-12' id='name' label='Время окончания'>
						<Input
							value={endTime}
							onChange={(e: any) => {
								if (startTime) {
									calculateDuration(startTime, e.target.value);
								} else {
									openNotificationDuration('bottomRight');
									setEndTime('');
									setEnd('');
									changeValues('endDate', '');
								}
								setEndTime(e.target.value);
								changeValues(
									'endDate',
									joinTimeAndDate(e.target.value, formattedDate),
								);
								setEnd(joinTimeAndDate(e.target.value, formattedDate));
							}}
							type={'time'}
						/>
					</FormGroup>

					{/* <TimePicker
							// placeholder={['Время прихода','Время окончания']}
							onOpenChange={(open)=> {
							if(open){
								document.body.style.overflowX = "hidden";
							}else{
								document.body.style.overflowX = "auto";
								document.body.style.transition = '2s'
							}
						}}
					
						// value={[startTime, endTime]}
						// onChange={(time, timeString) => {

						// 	if (time !== null) {
						// 		changeValues('startDate', joinTimeAndDate(time[0], formattedDate));

						// 		changeValues('endDate', joinTimeAndDate(time[1], formattedDate));

						// 		setStart(joinTimeAndDate(time[0], formattedDate));
						// 		setEnd(joinTimeAndDate(time[1], formattedDate));

						// 		changeValues('visitDate', joinTimeAndDate(time[0], formattedDate));
						// 	}

						// 	if (time) {
						// 		setStartTime(time[0]);
						// 		setEndTime(time[1]);
						// 	}
						// }}
						className='col-11'
						size={'large'}
						popupStyle={{ zIndex: 150000, width: '100%', overflow: 'hidden' }}
					/> */}
				</div>
			</ModalBody>
			<ModalFooter>
				<div className='d-flex flex-row col-12 gap-3 px-3'>
					<Button
						color='primary'
						className='col-12'
						onClick={() => {
							handleSelect();
						}}>
						Сохранить
					</Button>
				</div>
			</ModalFooter>
		</Modal>
	);
};

export default TimePickerModal;
