import React, { SVGProps } from "react";

export const TC_TR = (props: SVGProps<SVGSVGElement>) => {

    return (
		<>
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="path-1-inside-1_523_10063" fill={props.fill?props.fill:"white"}>
        <path d="M20.5 20.23C20.5 17.6035 19.9827 15.0028 18.9776 12.5763C17.9725 10.1498 16.4993 7.94502 14.6421 6.08784C12.785 4.23067 10.5802 2.75748 8.15367 1.75239C5.72716 0.747296 3.12644 0.22998 0.5 0.22998V20.23H20.5Z"/>
        </mask>
        <g filter="url(#filter0_i_523_10063)">
        <path d="M20.5 20.23C20.5 17.6035 19.9827 15.0028 18.9776 12.5763C17.9725 10.1498 16.4993 7.94502 14.6421 6.08784C12.785 4.23067 10.5802 2.75748 8.15367 1.75239C5.72716 0.747296 3.12644 0.22998 0.5 0.22998V20.23H20.5Z" fill={!!props.fill?props.fill:"url(#paint0_linear_523_10063)"} fill-opacity={!!props.fill?"0.8":"0.3"}/>
        </g>
        <path d="M20.5 20.23C20.5 17.6035 19.9827 15.0028 18.9776 12.5763C17.9725 10.1498 16.4993 7.94502 14.6421 6.08784C12.785 4.23067 10.5802 2.75748 8.15367 1.75239C5.72716 0.747296 3.12644 0.22998 0.5 0.22998V20.23H20.5Z" stroke="black" stroke-opacity="0.2" mask="url(#path-1-inside-1_523_10063)"/>
        <defs>
        <filter id="filter0_i_523_10063" x="0.5" y="0.22998" width="20" height="24" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="2"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_523_10063"/>
        </filter>
        <linearGradient id="paint0_linear_523_10063" x1="0.500002" y1="0.729981" x2="20.5" y2="20.23" gradientUnits="userSpaceOnUse">
        <stop stop-color="#F8F4ED"/>
        <stop offset="1" stop-color="#9B9B9B"/>
        </linearGradient>
        </defs>
    </svg>

        </>
	);
};