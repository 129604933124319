import { authHost } from '../axios';

export class GoogleService {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor() {}
	static async generateGoogleCallendarAccses(query?: any): Promise<any[] | null> {
		const { data } = await authHost.get(`google?${query}`);		
		return data;
	}
}
