import React, { FC, useContext, useEffect, useState } from 'react';
import { ModalBody } from '../../../../../components/bootstrap/Modal';
import FormGroup from '../../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../../components/bootstrap/forms/Input';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../../components/bootstrap/Card';
// import Select from '../../../../../components/bootstrap/forms/Select';
import Textarea from '../../../../../components/bootstrap/forms/Textarea';
import Button from '../../../../../components/bootstrap/Button';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IVisit } from '@textnpayme/custom/api/interface/visit.interface';
import { PatientService } from '../../../../../custom/api/service/patient.service';
import { IPatient } from '@textnpayme/custom/api/interface/patient.interface';
import { IClinic } from '@textnpayme/custom/api/interface/clinic.interface';
import * as qs from 'qs';
import { ClinicService } from '../../../../../custom/api/service/clinic.service';
import { ServiceService } from '../../../../../custom/api/service/visit-service.service';
import { UserService } from '../../../../../custom/api/service/user.service';
import { IUser } from '@textnpayme/custom/api/interface/user.interface';
import { Select } from 'antd';
import { useDebounce } from '../../../../../hooks/useDebounce';
import { VisitService } from '../../../../../custom/api/service/visit.service';
import { relative } from 'path';
import { IService } from '@textnpayme/custom/api/interface/service.interface';
import { title } from 'process';
import CustomSelect from '../../../../../custom/components/Select/select';
import { PhoneNumberUtil } from 'google-libphonenumber';
import ScheduleModal from '../../../../../custom/pages/doctors/schedule/ScheduleModal';
import useDarkMode from '../../../../../hooks/useDarkMode';

import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../../../components/bootstrap/Dropdown';
import PaginationButtons, {
	dataPagination,
	PER_COUNT,
	PER_COUNT_FILTER,
} from '../../../../../components/PaginationButtons';
import Icon from '../../../../../components/icon/Icon';
import EVENT_STATUS from '../../../../../common/data/enumEventStatus';
import { Divider, notification, Space } from 'antd';
import type { NotificationArgsProps } from 'antd';
import ServiceChooseModal from '../../../../../custom/pages/services/ServiceChooseModal';
import { GoogleService } from '../../../../../custom/api/service/google-callendar.service';
import { StageService } from '../../../../../custom/api/service/stage.service';
import { ConsultationListService } from '../../../../../custom/api/service/consulatation-list.service';
import { IConsultationList } from '../../../../../custom/api/interface/consultation-list.interface';
import DentalContextFunctions from '../../../../../contexts/dentalContextFunctions';
import DentalContext from '../../../../../contexts/dentalContext';

interface CreateConsultationParams {
	inputDto: IConsultationList;
	uuid: string;
}

interface CreateServiceParams {
	inputDto: IService;
	uuid: string;
}
type NotificationPlacement = NotificationArgsProps['placement'];


interface CreateVisitProps {
	patientId: string;
	setIsOpen(...args: unknown[]): unknown;
	setIsPatientChoosen(...args: unknown[]): unknown;
	setIsNewPatientChoosen(...args: unknown[]): unknown;
	setChoosenPatientId(...args: unknown[]): unknown;
	setPatientCreated(...args: unknown[]): unknown;
	setPatientCreatedId(...args: unknown[]): unknown;
	setCreateVisit(...args: unknown[]): unknown;
	choosenPatientId: string | null;
	serviceId: null | string | [];
	medicalCaseUuid: null | string;
	stageUuid: null | string;
	isNewPatientChoosen?: boolean;
	clinicUuid: string;
}

const CreateVisit: FC<CreateVisitProps> = ({
	patientId,
	clinicUuid,
	setIsOpen,
	setIsPatientChoosen,
	setIsNewPatientChoosen,
	setChoosenPatientId,
	setPatientCreated,
	isNewPatientChoosen,
	setPatientCreatedId,
	setCreateVisit,
	choosenPatientId,
	serviceId,
	medicalCaseUuid,
	stageUuid,
}) => {
	console.log('CREATE VISIT SERVICE ID:', serviceId);

	const {
		formData,
		setFormData,
		event_default,
		dentalFormula,
		setSelectMany,
		setSelectedItems,

		implant_default,
	} = useContext(DentalContext);
	const {
		localFormData,
		updateDentalFormulaFromBackend,
		getServicesUuids,
		setLocalFormData,
		getInfoForCreateVisit,
	} = useContext(DentalContextFunctions);

	const [search, setSearch] = useState<any>({});
	const DebouncedSearch = useDebounce(search);
	const [visitData, setVisitData] = useState([]);
	const [schedulModalOpen, setSchedulModalOpen] = useState(false);

	const [popperIsOpenService, setPopperIsOpenService] = useState(false);
	const [servicesCurrentPage, setServicesCurrentPage] = useState<number>(1);
	const [servicesPerPage, setServicesPerPage] = useState<number>(PER_COUNT_FILTER['5']);

	const [isGoogleCalendar, setIsGoogleCallendar] = useState<boolean>(false);
	const [isGoogleCalendarLink, setIsGoogleCallendarLink] = useState<any>(null);

	const { themeStatus, darkModeStatus } = useDarkMode();

	const queryClient = useQueryClient();

	const [api, contextHolder] = notification.useNotification();

	const [isUserDoctor, setIsUserDoctor] = useState<boolean>(false);

	const { data: user_role } = useQuery(['user_role'], () =>
		UserService.getProfile(qs.stringify({ refetchOnWindowFocus: true })),
	);

	useEffect(() => {
		setIsUserDoctor(
			user_role?.roles?.some(
				(role: any) => role?.role === 'mainDoctor' || role?.role === 'Doctor',
			),
		);
	}, [user_role]);

	useEffect(() => {
		if (isUserDoctor) {
			changeValues('clinicUuid', user_role?.roles[0]?.clinicUuid);
		}
	}, [isUserDoctor]);

	const openNotification = (
		placement: NotificationPlacement,
		message: string,
		description: string,
	) => {
		api.info({
			message,
			description,
			placement,
		});
	};

	const { isLoading: patientLoading, data: patient } = useQuery<IPatient, Error>(
		['patient', patientId],
		() =>
			PatientService.findOne(
				patientId,
				qs.stringify({
					queryMeta: {
						paginate: true,
					},
					filterMeta: {},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);
	const [isValid, setIsValid] = useState(false);

	const validatePhoneNumber = (phoneNumber: string): void => {
		const phoneUtil = PhoneNumberUtil.getInstance();
		const number = phoneNumber.replace(/\+/g, '');
		try {
			const parsedNumber = phoneUtil.parse(number);
			setIsValid(phoneUtil.isValidNumber(parsedNumber));
		} catch (e) {
			const allRegions: string[] = phoneUtil.getSupportedRegions();
			let isValidForAnyRegion: boolean = false;
			for (const region of allRegions) {
				try {
					const parsedNumber = phoneUtil.parse(number, region);
					if (phoneUtil.isValidNumber(parsedNumber)) {
						isValidForAnyRegion = true;
						break;
					}
				} catch (e) {
					continue;
				}
			}
			setIsValid(isValidForAnyRegion);
		}
	};

	const [values, setValues] = useState<IVisit>({});

	const DebouncedSearchServices = useDebounce(values?.serviceName);

	useEffect(() => {
		setValues(
			medicalCaseUuid !== null && stageUuid !== null
				? {
						services: serviceId,
						userUuid: undefined,
						complaint: undefined,
						visitDate: '',
						clinicUuid: clinicUuid,
						medicalCaseUuid: medicalCaseUuid,
						stageUuid: stageUuid,
						patientUuid: patientId,
						startDate: '',
						endDate: '',
						visitNumber: '',
				  }
				: {
						services: serviceId,
						userUuid: undefined,
						complaint: undefined,
						clinicUuid: clinicUuid,
						visitDate: '',
						patientUuid: patientId,
						startDate: '',
						endDate: '',
						visitNumber: '',
				  },
		);
	}, [patientId, medicalCaseUuid, stageUuid, servicesPerPage, serviceId, clinicUuid]);

	const changeSearchValue = (key: string, value: string | number) => {
		setSearch((prev: any) => ({ ...prev, [key]: value }));
	};

	const [parts, setParts] = useState<any>('');

	useEffect(() => {
		if (typeof search?.user === 'string') {
			if (search?.user.includes('+')) {
				validatePhoneNumber(search?.user);
			}

			if (search?.user) {
				setParts(search?.user.split(' '));
			}
		}
	}, [search?.user]);

	const { isLoading: clinicsLoading, data: clinics } = useQuery<IClinic | null, Error>(
		['clinics', DebouncedSearch],
		() =>
			ClinicService.findAll(
				qs.stringify({
					queryMeta: {
						order: { createdAt: 'DESC' },
					},
					filterMeta: {
						...(search?.clinic && { name: { iLike: `%${search.clinic}%` } }),
					},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	// const { isLoading: serviceLoading, data: services } = useQuery<IVisit | null, Error>(
	// 	['services', DebouncedSearch],
	// 	() =>
	// 		ServiceService.findAll(
	// 			qs.stringify({
	// 				queryMeta: {
	// 					order: { createdAt: 'DESC' },
	// 				},
	// 				filterMeta: {
	// 					...(search?.service && {
	// 						...(search?.service && /\d/.test(search?.service)
	// 							? { serviceId: { iLike: `%${search?.service}%` } }
	// 							: { title: { iLike: `%${search?.service}%` } }),
	// 					}),
	// 					...(stageUuid && { stageUuid: stageUuid }),
	// 				},
	// 			}),
	// 		),
	// 	{
	// 		keepPreviousData: true,
	// 		refetchOnWindowFocus: false,
	// 	},
	// );

	const { data: servicesCount }: { data: IService[] | any; isLoading: boolean } = useQuery(
		['servicesCount', values?.stageUuid, DebouncedSearchServices],
		() =>
			ServiceService.findAll(
				qs.stringify({
					includeMeta: [
						{
							association: 'stages',
							where: {
								uuid: values?.stageUuid,
							},
						},
					],
					filterMeta: {
						...(values.serviceName && {
							...(values.serviceName && /\d/.test(values.serviceName)
								? { serviceId: { iLike: `%${values.serviceName}%` } }
								: { title: { iLike: `%${values.serviceName}%` } }),
						}),
					},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	const { data: services }: { data: IService[] | any; isLoading: boolean } = useQuery(
		[
			'services',
			values.stageUuid,
			DebouncedSearchServices,
			// values.serviceName,
			servicesCurrentPage,
			servicesPerPage,
		],
		() =>
			ServiceService.findAll(
				qs.stringify({
					queryMeta: {
						paginate: true,
						limit: servicesPerPage,
						page: DebouncedSearchServices?.length ? 1 : servicesCurrentPage,
						order: { createdAt: 'DESC' },
					},
					includeMeta: [
						{
							association: 'stages',
							where: {
								uuid: values.stageUuid,
							},
						},
					],

					filterMeta: {
						...(values.serviceName && {
							...(values.serviceName && /\d/.test(values.serviceName)
								? { serviceId: { iLike: `%${values.serviceName}%` } }
								: { title: { iLike: `%${values.serviceName}%` } }),
						}),
					},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	const [externalPopup, setExternalPopup] = useState<string | null>(null);

	const { data: calendarAccess, isLoading } = useQuery(
		['calendar_access', isGoogleCalendar],
		async () => {
			if (isGoogleCalendar) {
				return await GoogleService.generateGoogleCallendarAccses();
			}
			return null;
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	useEffect(() => {
		setIsGoogleCallendarLink(calendarAccess);
	}, [isGoogleCalendar, calendarAccess]);

	// const { isLoading: usersLoading, data: users } = useQuery<IVisit | null, Error>(
	// 	['users', DebouncedSearch, DebouncedSearchData],
	// 	() =>
	// 		UserService.findAll(
	// 			qs.stringify({
	// 				queryMeta: {
	// 					order: { createdAt: 'DESC' },
	// 				},
	// 				includeMeta: [
	// 					{
	// 						association: 'roles',
	// 						where: {
	// 							role: ['mainDoctor', 'doctor'],
	// 							...(values.clinicUuid && { clinicUuid: values.clinicUuid }),
	// 						},
	// 						// include: [
	// 						// 	{
	// 						// 	  association: 'clinics',
	// 						// 	  where: {
	// 						// 		uuid: '4178f72a-45e4-4541-b71e-798578e1575f'
	// 						// 	  }
	// 						// 	}
	// 						//   ]
	// 					},
	// 					{
	// 						...(search?.user &&
	// 							/\d/.test(search?.user) &&
	// 							!isValid && {
	// 								association: 'passport',
	// 								where: {
	// 									or: [
	// 										{
	// 											passportNumber: {
	// 												iLike: `%${search?.user}%`,
	// 											},
	// 										},
	// 										{
	// 											ssCardId: {
	// 												iLike: `%${search?.user}%`,
	// 											},
	// 										},
	// 									],
	// 								},
	// 							}),
	// 					},
	// 				],
	// 				filterMeta: {
	// 					...(search?.user &&
	// 						/\d/.test(search?.user) &&
	// 						isValid && { phone: { iLike: `%${search?.user}%` } }),
	// 					...(search?.user &&
	// 						!/\d/.test(search?.user) &&
	// 						!isValid &&
	// 						((parts.length === 1 && {
	// 							name: { iLike: `%${parts[0]}%` },
	// 						}) ||
	// 							(parts.length === 2 && {
	// 								and: [
	// 									{ name: { iLike: `%${parts[0]}%` } },
	// 									{ surname: { iLike: `%${parts[1]}%` } },
	// 								],
	// 							}) ||
	// 							(parts.length === 3 && {
	// 								and: [
	// 									{ name: { iLike: `%${parts[0]}%` } },
	// 									{ surname: { iLike: `%${parts[1]}%` } },
	// 									{ middleName: { iLike: `%${parts[2]}%` } },
	// 								],
	// 							}))),
	// 				},
	// 			}),
	// 		),
	// 	{
	// 		keepPreviousData: true,
	// 		refetchOnWindowFocus: false,
	// 	},
	// );

	const connectClick = () => {
		const width_ = 500;
		const height_ = 400; // This is sufficient, no need to redeclare it
		const left = window.screenX + (window.outerWidth - width_) / 2;
		const top = window.screenY + (window.outerHeight - height_) / 2.5;
		const title = `GOOGLE CALLENDAR ACCESS`;
		const url = isGoogleCalendarLink;
		if (url) {
			window.open(url, title, `width=${width_},height=${height_},left=${left},top=${top}`);
		}
	};

	const { mutate: addService } = useMutation(
		({ uuid, inputDto }: CreateServiceParams) => StageService.update(uuid, inputDto),
		{
			onSuccess: () => {
				queryClient.invalidateQueries('services');
				queryClient.invalidateQueries('stages');
				setIsOpen(false);
				// updateConslutationList({
				// 	uuid: values.consulatation_list_uuid,
				// 	inputDto: { dentalFormula: values.form_data },
				// });
			},
			onError: (error) => {
				console.error(error); // Using console.error for error logging
			},
		},
	);

	useEffect(() => {
		if (isGoogleCalendar && isGoogleCalendarLink && typeof isGoogleCalendarLink === 'string') {
			connectClick();
		}
	}, [isGoogleCalendar, isGoogleCalendarLink]);

	const queryOptions = {
		onSuccess: (response: any, formData: any) => {
			if (values.stageUuid) {
				updateConslutationList({
					uuid: values.consulatation_list_uuid,
					inputDto: { dentalFormula: values.form_data },
				});				// addService({
				// 	uuid: values.stageUuid,
				// 	inputDto: { services: values.services_stage },
				// });
				queryClient.invalidateQueries('visits');
				setIsOpen(false);
				setValues(
					medicalCaseUuid !== null && stageUuid !== null
						? {
								services: serviceId ? serviceId : undefined,
								userUuid: undefined,
								complaint: undefined,
								visitDate: '',
								clinicUuid: clinicUuid,
								medicalCaseUuid: medicalCaseUuid,
								stageUuid: stageUuid,
								patientUuid: patientId,
						  }
						: {
								services: serviceId ? serviceId : undefined,
								userUuid: undefined,
								complaint: undefined,
								clinicUuid: clinicUuid,
								visitDate: '',
								patientUuid: patientId,
						  },
				);
				setIsPatientChoosen(false);
				setIsNewPatientChoosen(false);
				setChoosenPatientId(null);
				setPatientCreatedId('');
				setPatientCreated(false);
				setCreateVisit(false);
			}
		},
		onError: (error: any) => {
			if (error.response.data.message === 'calendar_auth_failed') {
				setIsGoogleCallendar(true);
			}
			if (error.response.data.message === 'choose_user_date_time')
				openNotification(
					'bottomRight',
					'Важно !',
					'Выберите дату и врача чтобы создать визит',
				);
			if (error.response.data.message === 'Start date must be before end date')
				openNotification(
					'bottomRight',
					'Важно !',
					'Дата начала должна быть раньше даты окончания.',
				);
			if (error.response.data.message === 'end_date_empty')
				openNotification('bottomRight', 'Важно !', 'выберите время окончания визита');

			if (error.response.data.message === 'start_date_empty')
				openNotification('bottomRight', 'Важно !', 'выберите время начала визита');

			if (error.response.data.message === 'can_not_create_visit_whitout_services')
				openNotification('bottomRight', 'Важно !', 'выберите услуги чтобы создать визит');


			if(error.response.data.message === 'time_range_choosen') 
				openNotification('bottomRight', 'Важно !', 'выбранное время уже занято');

		},
	};

	const { mutate: updateConslutationList } = useMutation(
		({ uuid, inputDto }: CreateConsultationParams) =>
			ConsultationListService.update(uuid, inputDto),
		{
			onSuccess: () => {
				queryClient.invalidateQueries('consultation_list');
				queryClient.invalidateQueries('stages');
				setValues({
					services: [],
					serviceName: '',
				});
				setIsOpen(false);
				setSelectMany(false);
				setSelectedItems([]);
			},
			onError: (error) => {
				console.error(error); // Using console.error for error logging
			},
		},
	);

	const { mutate } = useMutation(
		(inputDto: IVisit) => VisitService.create(inputDto),
		queryOptions,
	);

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		console.log(values,'localChoosenServiceslocalChoosenServices');
		
		await mutate({
			...values,
			services: values.services,
		});
	};

	console.log(values, 'valuesvaluesvalues');

	const changeValues = (key: string, value: string | number) => {
		setValues((prev) => ({ ...prev, [key]: value }));
	};

	if (patientLoading || clinicsLoading) {
		return <div>Loading...</div>;
	}

	function formatDateToYYYYMMDD(date: any) {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
		const day = String(date.getDate()).padStart(2, '0');
		return `${day}.${month}.${year}`;
	}

	const handleCheckboxChange = (e: any) => {
		const { name, type, checked, value, defaultValue } = e.target;

		if (type === 'checkbox') {
			setValues((prev: any) => {
				const currentValues = Array.isArray(prev[name]) ? prev[name] : [];
				if (checked) {
					// Add the value if not already present
					if (!currentValues.includes(defaultValue)) {
						return {
							...prev,
							[name]: [...currentValues, defaultValue],
						};
					}
				} else {
					// Remove the value
					return {
						...prev,
						[name]: currentValues.filter((val: string) => val !== defaultValue),
					};
				}
				return prev;
			});
		}

		if (type === 'text' && name === 'serviceName') {
			setValues((prev: any) => ({
				...prev,
				[name]: value,
			}));
		}
	};

	const VisitStatusStyles = (status: any) => {
		switch (status) {
			case EVENT_STATUS.PENDING_SERVICE.name:
				return {
					iconeStyles: {
						color: '#DC3545',
						iconeColor: {
							color: EVENT_STATUS.PENDING_SERVICE.color,
						},
					},
					disabled: false,
				};

			case EVENT_STATUS.COMPLETED_SERVICE.name:
				return {
					iconeStyles: {
						color: '#198754',
						iconeColor: {
							color: EVENT_STATUS.COMPLETED_SERVICE.color,
						},
					},
					disabled: true,
				};
			default:
				return undefined; // Handle unknown status
		}
	};

	const formatPrice = (
		amount: number | string,
		currency: string,
		locale: string = 'en-US',
	): string => {
		try {
			// Convert string to number if amount is of type string
			const parsedAmount = typeof amount === 'string' ? parseFloat(amount) : amount;

			// If parsing fails or results in NaN, return the original amount as a string
			if (isNaN(parsedAmount)) {
				throw new Error('Invalid amount');
			}

			return new Intl.NumberFormat(locale, {
				style: 'currency',
				currency: currency,
			}).format(parsedAmount);
		} catch (error) {
			console.error('Error formatting price:', error);
			return amount.toString();
		}
	};

	const timeFormatter = (time: string) => {
		return new Date(time).toTimeString().slice(0, 5);
	};

	const dateOfVisit = (start: any, end: any) => {
		if (start && !end) {
			return timeFormatter(start);
		}
		if (start && end) {
			return `${timeFormatter(start)} -	${timeFormatter(end)}`;
		}
	};

	return (
		<>
			{contextHolder}
			<ModalBody className='d-flex flex-column gap-4 align-items-center col-12 px-0'>
				<form style={{ width: '100%' }} onSubmit={handleSubmit}>
					<div className='d-flex flex-row justify-content-between align-items-start mb-4 col-12'>
						<div className='d-flex flex-column gap-4 col-5'>
							<FormGroup className='col-12' id='visitNumber' label='Номер визита'>
								<Input
									required
									value={values.visitNumber}
									onChange={(e: any) =>
										changeValues('visitNumber', e.target.value)
									}
								/>
							</FormGroup>
							<FormGroup className='col-12' id='patient' label='Пациент'>
								<Input
									disabled={true}
									value={`${patient?.surname} ${patient?.name} ${patient?.middleName}`}
									type='text'
									className='px-4'
								/>
							</FormGroup>
							<FormGroup
								id='clinic'
								label='Филиал'
								className='m-0 d-flex flex-column'>
								<CustomSelect
									placeholder={'Выберите филиал'}
									disabled={isUserDoctor}
									name={'clinic'}
									value={values.clinicUuid}
									options={clinics?.data.map((item: IClinic, index: number) => {
										return {
											value: item.uuid,
											label: item.name,
										};
									})}
									changeSearchValue={changeSearchValue}
									changeValues={changeValues}
								/>
							</FormGroup>

							<div className='d-flex flex-column gap-3'>
								<FormGroup className='col-12' id='complaint' label='Жалоба'>
									<Textarea
										required
										value={values.complaint}
										onChange={(e: any) =>
											changeValues('complaint', e.target.value)
										}
									/>
								</FormGroup>
							</div>
						</div>
						<div className='d-flex flex-column gap-4 col-5'>
							{' '}
							<FormGroup label='Услуги' className='col-12'>
								<Button
									style={{
										boxShadow: 'inset 0 1px 2px rgba(0, 0, 0, 0.075)',
									}}
									onClick={() => setPopperIsOpenService(true)}
									color={themeStatus}
									className='text-nowrap me-2 col-12'>
									Управление услугами
								</Button>
							</FormGroup>
							<FormGroup id='doctor' label='Врач' className='m-0'>
								<Button
									onClick={() => {
										setSchedulModalOpen(true);
									}}
									isLight
									color='primary'
									className='text-nowrap fw-normal me-2 col-12 d-flex align-items-center justify-content-center'>
									Выбрать врача, дату и время визита
								</Button>
							</FormGroup>
							<Card className={`bg-light-primary rounded-2 mb-0 shadow-none pb-4`}>
								<CardHeader className='bg-transparent pb-3'>
									<CardLabel icon='Article' iconColor='primary'>
										<span className='fs-4 fw-bold'>Детали визита</span>
									</CardLabel>
								</CardHeader>
								<CardBody className='pt-0 d-flex flex-column gap-2 pb-4'>
									<p
										className='m-0 fs-6 d-flex'
										style={{ color: themeStatus, fontWeight: 400 }}>
										<span className='w-100' style={{ maxWidth: '3.5rem' }}>
											Дата
										</span>
										<span className='text-primary fw-bold'>
											{values?.visitDate &&
												formatDateToYYYYMMDD(new Date(values?.visitDate))}
										</span>
									</p>
									<p
										className='m-0 fs-6 d-flex'
										style={{ color: themeStatus, fontWeight: 400 }}>
										<span className='w-100' style={{ maxWidth: '3.5rem' }}>
											Время
										</span>
										<span className='text-primary fw-bold'>
											{dateOfVisit(values?.visitDate, values?.endDate)}
										</span>
									</p>
									<p
										className='m-0 fs-6 d-flex'
										style={{ color: themeStatus, fontWeight: 400 }}>
										<span className='w-100' style={{ maxWidth: '3.5rem' }}>
											Врач
										</span>
										<span className='text-primary fw-bold'>
											{values?.userFullName && values?.userFullName}
										</span>
									</p>
								</CardBody>
							</Card>
							{/* <FormGroup
								id='date'
								label='Врач, дата и время визита'
								className='d-flex flex-column gap-1'></FormGroup> */}
						</div>
					</div>
					<div className='d-flex justify-content-between w-100'>
						<Button
							className='fw-bold fs-6'
							color='link'
							isLight
							style={{ color: '#0D6EFD' }}
							onClick={() => {
								setCreateVisit(false);
								if (isNewPatientChoosen) {
									setIsNewPatientChoosen(false);
									setIsPatientChoosen(false);
									setIsNewPatientChoosen(false);
									setChoosenPatientId(null);
									setPatientCreatedId('');
									setPatientCreated(false);
									setCreateVisit(false);
								}
							}}>
							Назад
						</Button>
						<Button className='fw-bold fs-6' color='info' type='submit'>
							Создать визит
						</Button>
					</div>
				</form>
			</ModalBody>

			<ServiceChooseModal
				setIsOpen={setPopperIsOpenService}
				isOpen={popperIsOpenService}
				stage={stageUuid}
				setChosenValues={setValues}
				serviceDropdownInfo={values.services}
				createVisit
			/>

			<ScheduleModal
				clinicUuid={values?.clinicUuid}
				changeValues={changeValues}
				visitData={visitData}
				setVisitData={setVisitData}
				values={values}
				isOpen={schedulModalOpen}
				setIsOpen={setSchedulModalOpen}
			/>
		</>
	);
};

export default CreateVisit;
