import axios from 'axios';
import React, { useState, useEffect } from 'react';

interface WeatherData {
	main: {
		temp: number;
	};
	weather: {
		description: string;
		icon: string;
	}[];
}

const Weather: React.FC = () => {
	const [weatherData, setWeatherData] = useState<WeatherData | null>(null);

	useEffect(() => {
		const fetchWeatherData = async () => {
			try {
				const apiKey = '8dad3db309e50de33c8cdefbe69cec74';
				const city = 'yerevan';
				const lang = 'ru';
				const response = await axios.get<WeatherData>(
					`https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=${apiKey}&lang=${lang}`,
				);

				setWeatherData(response.data);
			} catch (error) {
				console.error('Error fetching weather data:', error);
			}
		};

		fetchWeatherData();
	}, []);

	const kelvinToCelsius = (kelvin: number) => {
		return Math.round(kelvin - 273.15);
	};

	return (
		<div>
			{weatherData ? (
				<div className='d-flex flex-row align-items-center gap-2'>
					<img
						src={`http://openweathermap.org/img/w/${weatherData.weather[0].icon}.png`}
						alt='Weather Icon'
						height={40}
					/>
					<span className='fs-2'> {kelvinToCelsius(weatherData.main.temp)}°C, </span>
					<span> {weatherData.weather[0].description}</span>
				</div>
			) : (
				<div className='fs-2'>загрузка...</div>
			)}
		</div>
	);
};

export default Weather;
