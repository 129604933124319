import React, { FC, useEffect, useState } from 'react';
import { QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';
import * as qs from 'qs';
import { IStage } from '@textnpayme/custom/api/interface/stage.interface';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
} from '../../../../../layout/SubHeader/SubHeader';
import Card, {
	CardBody,
	CardFooter,
	CardLabel,
	CardTitle,
} from '../../../../../components/bootstrap/Card';
import Button from '../../../../../components/bootstrap/Button';
import Icon from '../../../../../components/icon/Icon';
import Badge from '../../../../../components/bootstrap/Badge';
import Progress from '../../../../../components/bootstrap/Progress';
import UserImage3 from '../../../../../assets/img/wanna/wanna3.png';
import UserImage3Webp from '../../../../../assets/img/wanna/wanna3.webp';
import Avatar from '../../../../../components/Avatar';
import { StageService } from '../../../../../custom/api/service/stage.service';
import { number } from 'prop-types';
import { IService } from '@textnpayme/custom/api/interface/service.interface';
import { ServiceService } from '../../../../../custom/api/service/visit-service.service';
import { medicalCardPages } from '../../../../../menu';
import useDarkMode from '../../../../../hooks/useDarkMode';
import FormGroup from '../../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../../components/bootstrap/forms/Input';

import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../../../components/bootstrap/Dropdown';
import PaginationButtons, {
	dataPagination,
	PER_COUNT,
	PER_COUNT_FILTER,
} from '../../../../../components/PaginationButtons';
import { useDebounce } from '../../../../../hooks/useDebounce';
import { IVisit } from '@textnpayme/custom/api/interface/visit.interface';
import { Divider } from 'antd';
import EVENT_STATUS from '../../../../../common/data/enumEventStatus';
import ServiceChooseModal from '../../../../../custom/pages/services/ServiceChooseModal';
import ServiceChooseComponent from '../../../../../custom/pages/services/ServiceChooseComponent';
import StageModal from '../../../medicalCard/StageModal';
interface IStageProps {
	setCreateVisit(...args: unknown[]): unknown;
	setServiceId(...args: unknown[]): unknown;
	medicalCaseId: string;
	setMedicalCaseUuid(...args: unknown[]): unknown;
	setStageUuid(...args: unknown[]): unknown;
	setCloseModal(...args: unknown[]): unknown;
	patientUuid: any;
	setIsBackHidden(...args: unknown[]): unknown;
	setValues(...args: unknown[]): unknown;
	values: any;
	setStageId(...args: unknown[]): unknown;
	setIsNoServices(...args: unknown[]): unknown;
	setChosenServicesCount(...args: unknown[]): unknown;
	stageId: any;
	setAndLogServices: any;
	formRef?: any;
}

const Stage: FC<IStageProps> = ({
	setCreateVisit,
	setServiceId,
	setStageUuid,
	setCloseModal,
	setMedicalCaseUuid,
	medicalCaseId,
	patientUuid,
	setIsBackHidden,
	setValues,
	values,
	setStageId,
	stageId,
	setAndLogServices,
	formRef,
	setIsNoServices,
	setChosenServicesCount,
}) => {
	const [activeIndex, setActiveIndex] = useState<number | null>(null);
	const [popperIsOpenService, setPopperIsOpenService] = useState(false);
	const [servicesCurrentPage, setServicesCurrentPage] = useState<number>(1);
	const [servicesPerPage, setServicesPerPage] = useState<number>(PER_COUNT_FILTER['5']);
	const [modalStage, setModalStage] = useState<boolean>(false);
	const [submitStage, setSubmitStage] = useState<any>(null);
	const { themeStatus, darkModeStatus } = useDarkMode();

	useEffect(() => {
		setActiveIndex(null);
		setStageId('');
	}, [medicalCaseId]);

	const changeValues = (key: string, value: string | number) => {
		if (key === 'services') {
			setValues((prev: any) => ({
				...prev,
				[key]: [...(values[key] || []), value], // Ensure values[key] is an array
			}));
		}
	};
	const handleCheckboxChange = (e: any) => {
		const name = e.target.name;
		if (e.target.type === 'checkbox') {
			const isChecked = e.target.checked;
			if (isChecked) {
				// Check if the value is not already present
				if (!values[name].includes(e.target.defaultValue)) {
					changeValues(name, e.target.defaultValue);
					// changeValues('serviceName', e.target.defaultValue)
				}
			} else {
				// Remove the value from checkboxValue
				setValues((prev: any) => ({
					...prev,
					[name]: prev[name].filter((value: string) => value !== e.target.defaultValue),
				}));
			}
		}
		if (e.target.type === 'text') {
			if (name === 'serviceName') {
				setValues((prev: any) => ({ ...prev, [name]: e.target.value }));
			}
		}

		if (e.target.type === 'search') {
			if (name === 'serviceNameChosen') {
				setValues((prev: any) => ({ ...prev, [name]: e.target.value }));
			}
		}
	};

	const getServices = (uuid: string | undefined, active: number | null) => {
		setActiveIndex(active);
		setStageId(uuid);

		if (active === activeIndex) {
			setActiveIndex(null);
			setStageId('');
		}
	};

	const { data: stages }: { data: IStage[] | any; isLoading: boolean } = useQuery(
		['stages'],
		() =>
			StageService.findAll(
				qs.stringify({
					queryMeta: {
						order: { createdAt: 'DESC' },
					},
					filterMeta: {
						medicalCaseUuid: medicalCaseId,
					},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	const DebounsedSearch = useDebounce(values);

	const { data: servicesCount }: { data: IService[] | any; isLoading: boolean } = useQuery(
		['servicesCount', stageId, DebounsedSearch],
		() =>
			ServiceService.findAll(
				qs.stringify({
					includeMeta: [
						{
							association: 'stages',
							where: {
								uuid: stageId,
							},
						},
					],
					filterMeta: {
						...(values.serviceName && {
							...(values.serviceName && /\d/.test(values.serviceName)
								? { serviceId: { iLike: `%${values.serviceName}%` } }
								: { title: { iLike: `%${values.serviceName}%` } }),
						}),
					},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	const { data: services }: { data: IService[] | any; isLoading: boolean } = useQuery(
		['services', stageId, DebounsedSearch, servicesCurrentPage, servicesPerPage],
		() =>
			ServiceService.findAll(
				qs.stringify({
					queryMeta: {
						paginate: true,
						limit: servicesPerPage,
						page: DebounsedSearch?.serviceName?.length ? 1 : servicesCurrentPage,
						order: { createdAt: 'DESC' },
					},
					includeMeta: [
						{
							association: 'stages',
							where: {
								uuid: stageId,
							},
						},
					],
					filterMeta: {
						...(values.serviceName && {
							...(values.serviceName && /\d/.test(values.serviceName)
								? { serviceId: { iLike: `%${values.serviceName}%` } }
								: { title: { iLike: `%${values.serviceName}%` } }),
						}),
					},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);
	const formatPrice = (
		amount: number | string,
		currency: string,
		locale: string = 'en-US',
	): string => {
		try {
			// Convert string to number if amount is of type string
			const parsedAmount = typeof amount === 'string' ? parseFloat(amount) : amount;

			// If parsing fails or results in NaN, return the original amount as a string
			if (isNaN(parsedAmount)) {
				throw new Error('Invalid amount');
			}

			return new Intl.NumberFormat(locale, {
				style: 'currency',
				currency: currency,
			}).format(parsedAmount);
		} catch (error) {
			console.error('Error formatting price:', error);
			return amount.toString();
		}
	};

	const VisitStatusStyles = (status: any) => {
		switch (status) {
			case EVENT_STATUS.PENDING_SERVICE.name:
				return {
					iconeStyles: {
						color: '#DC3545',
						iconeColor: {
							color: EVENT_STATUS.PENDING_SERVICE.color,
						},
					},
					disabled: false,
				};

			case EVENT_STATUS.COMPLETED_SERVICE.name:
				return {
					iconeStyles: {
						color: '#198754',
						iconeColor: {
							color: EVENT_STATUS.COMPLETED_SERVICE.color,
						},
					},
					disabled: true,
				};
			default:
				return undefined; // Handle unknown status
		}
	};

	return (
		<div className='d-flex flex-column justify-content-between m-0'>
			<div className='d-flex flex-column justify-content-between p-0 gap-2'>
				<SubHeaderLeft className='col-4 text-muted m-0'>
					<p className='fs-4 fw-semibold d-flex flex-column'>
						План лечения
						<span className='fs-6 text-nowrap'>
							Выберите этап и услуги. Можно выбрать только один этап.
						</span>
					</p>
				</SubHeaderLeft>
				<SubHeaderRight className='col-12 d-flex flex-nowrap stage_buttons'>
					{stages &&
						stages?.data.map((i: IStage, index: number) => (
							<Button
								onClick={() => {
									getServices(i.uuid, index);
									setPopperIsOpenService(true);
									setIsBackHidden(true);
								}}
								color='info'
								isLight
								isActive={index === activeIndex}
								className='text-nowrap me-2 fw-bold d-flex'
								style={{ padding: '0.6rem 1.8rem', maxWidth: '15rem' }}>
								<span className='text-truncate'>{i.title}</span>
							</Button>
						))}
					<Button
						onClick={() => setModalStage(true)}
						color='info'
						isLight
						className='text-nowrap me-2'
						style={{
							fontWeight: 'bold',
							padding: '0.6rem 1.8rem',
							maxWidth: '15rem',
						}}>
						Создать этап
					</Button>
				</SubHeaderRight>
			</div>
			<div className='d-flex flex-column gap-4'>
				<CardBody className='d-flex flex-wrap column-gap-4 p-0 px-0'>
					{stageId && services?.data && (
						// 	 <Card
						// 		className=' d-flex align-items-center flex-row justify-content-center gap-4'f
						// 	style={{ height: '100px', width: '48%' }}>
						// 	<Avatar
						// 		src={UserImage3}
						// 		size={32}
						// 		srcSet={UserImage3Webp}
						// 		color='primary'
						// 	/>
						// 	<CardTitle className='h5 d-flex flex-column gap-2'>
						// 		{i.title}
						// 		<div
						// 			className='d-flex flex-row align-items-center gap-2'
						// 			style={{ pointerEvents: 'none' }}>
						// 			{' '}
						// 			<span className='text-muted fs-sm'>{i.description}</span>
						// 			{/* <div className='d-flex flex-row align-items-center gap-2'>
						// </div> */}
						// 		</div>
						// 	</CardTitle>
						// 	<Button
						// 		color='info'
						// 		className='text-nowrap me-2'
						// 		style={{
						// 			fontWeight: 'bold',
						// 		}}
						// 		onClick={() => {
						// 			setCreateVisit(true);
						// 			setServiceId(i.uuid);
						// 			setStageUuid(stageId);
						// 		}}>
						// 		Выбрать{' '}
						// 	</Button>
						// </Card>
						<>
							<div className='col-12'>
								{/* <FormGroup id='parent' label='Услуги' className='col-12'>
									{services.data.length > 0 &&
									services.data.filter((i: any) =>
										values.services.includes(i.uuid),
									).length > 0 ? (
										services.data
											.filter((i: any) => values.services.includes(i.uuid))
											.map((i: any) => (
												<SubHeader className='mb-2 shadow-lg bg-white rounded'>
													<SubHeaderLeft className='d-flex flex-row align-items-center'>
														<h5> {i?.title}</h5>
													</SubHeaderLeft>
													<SubHeaderRight>
														<span></span>
														<div
															className='date-frame'
															style={
																{
																	background: '#33FF77',
																	backgroundColor: '#33FF77'
																}
															}>
															<span className='text-nowrap fw-bold fs-5'>
															{i?.price}
															</span>
														</div>
													</SubHeaderRight>
												</SubHeader>
											))
									) : (
										<> </>
									)}
								</FormGroup> */}
								{/* <FormGroup id='parent' label='Услуги'>
									<SubHeader>
										<SubHeaderLeft className='d-flex flex-row align-items-center'>
											<h4>
												{' '}
												{parent.name} {parent.surname} {parent.middleName}
											</h4>
										</SubHeaderLeft>
										<SubHeaderRight>
											<span></span>
										</SubHeaderRight>
									</SubHeader>
								</FormGroup> */}
							</div>
							{/* <FormGroup label='Услуги' className='col-12'>
								<div className='mb-1 col-12'>
									{services?.data?.length > 0 &&
										servicesCount?.data?.filter(
											(i: any) => values?.services?.includes(i.uuid),
										)?.length > 0 &&
										servicesCount.data
											.filter((i: any) => values.services.includes(i.uuid))
											.map((i: any) => (
												<>
													<div className='d-flex p-1 col-12' key={i.uuid}>
														<div className='col-9'>
															<p className='mb-1'>
																<Icon
																	icon='Circle'
																	className='me-2'
																	color={
																		VisitStatusStyles(
																			i.stages?.[0]
																				?.StageServiceRelation
																				?.status,
																		)?.iconeStyles.iconeColor
																			.color
																	}
																/>
																{i?.title}
															</p>
														</div>
														<div className='d-flex align-items-start justify-content-end col-3'>
															<span className='text-nowrap fw-bolder fs-6'>
																{formatPrice(
																	i?.price,
																	'AMD',
																	'ru-RU',
																)}
															</span>
														</div>
													</div>
													<hr className='hr-divder-costum' />
												</>
											))}
								</div>
							</FormGroup> */}
							{/* <FormGroup className='col-12'> */}
							{/* <Button
									style={{
										boxShadow: 'inset 0 1px 2px rgba(0, 0, 0, 0.075)',
									}}
									color={themeStatus}
									className='text-nowrap me-2 col-12'
									onClick={() => setPopperIsOpenService(true)}>
									Выбрать услугу
								</Button> */}
							{/* <Dropdown
									isOpen={popperIsOpenService}
									setIsOpen={setPopperIsOpenService}>
									<DropdownToggle>
									</DropdownToggle>
									<DropdownMenu style={{ width: '600px' }}>
										<div className='d-flex justify-content-around'>
											<h4>Фильтр по сервису</h4>
											<Button
												type='button'
												className='btn-close'
												data-bs-dismiss='modal'
												aria-label='Close'
												onClick={() => setPopperIsOpenService(false)}
											/>
										</div>
										<div
											style={{
												display: 'flex',
												justifyContent: 'center',
												marginTop: '15px',
												flexWrap: 'wrap',
											}}>
											<FormGroup
												style={{ margin: '15px 0 30px 0' }}
												id='surname'
												label='Сервис / Код'
												className='col-10'>
												<Input
													name='serviceName'
													onChange={(e: any) => handleCheckboxChange(e)}
													value={values?.serviceName}
												/>
											</FormGroup>

											{services?.data.length > 0 ? (
												services?.data.map((i: any, index: number) => (
													<div className='form-check col-10' key={index}>
														{!VisitStatusStyles(
															i.stages?.[0]?.StageServiceRelation
																?.status,
														)?.disabled && (
															<input
																className='form-check-input'
																onChange={(e: any) =>
																	handleCheckboxChange(e)
																}
																type='checkbox'
																value={i.uuid}
																name='services'
																disabled={
																	VisitStatusStyles(
																		i.stages?.[0]
																			?.StageServiceRelation
																			?.status,
																	)?.disabled
																}
																checked={values?.services?.includes(
																	i.uuid,
																)}
																id={`flexCheckDefault-${index}`}
															/>
														)}
														<label
															color={themeStatus}
															className='form-check-label'
															aria-disabled={VisitStatusStyles(
																i.stages?.[0]?.StageServiceRelation
																	?.status,
															)?.disabled}
															htmlFor={`flexCheckDefault-${index}`}>
															<div className='col-1'>
																<Icon
																	icon='Circle'
																	className='me-2'
																	color={
																		VisitStatusStyles(
																			i.stages?.[0]
																				?.StageServiceRelation
																				?.status,
																		)?.iconeStyles.iconeColor
																			.color
																	}
																/>
															</div>
															<div className='col-11'>{i.title}</div>
														</label>
													</div>
												))
											) : (
												<h4>Сервисов не найдено</h4>
											)}
										</div>
										<PaginationButtons
											data={services || []}
											label='customers'
											small
											setCurrentPage={setServicesCurrentPage}
											currentPage={servicesCurrentPage}
											perPage={servicesPerPage}
											setPerPage={setServicesPerPage}
											totalPage={
												Math.ceil(
													servicesCount?.data?.length / servicesPerPage,
												) || 1
											}
										/>
									</DropdownMenu>
								</Dropdown> */}
							{/* </FormGroup> */}

							{/* <FormGroup
								style={{ marginTop: 20 }}
								id='doctor'
								label=''
								className='mb-2 col-12'>
								<Button
									onClick={() => {
										// console.log('serviceId',values.services);
										setCreateVisit(true);
										setServiceId(values.services);
										setStageUuid(stageId);
									}}
									isLight
									color='primary'
									className='text-nowrap fw-normal me-2 col-12 d-flex align-items-center justify-content-center'>
									Выбрать врача, дату и время визита
								</Button>
							</FormGroup> */}
							<ServiceChooseComponent
								stage={stageId}
								isStage
								setChosenValues={setAndLogServices}
								setSubmitStage={setSubmitStage}
								formRef={formRef}
								setIsNoServices={setIsNoServices}
								setChosenServicesCount={setChosenServicesCount}
							/>
						</>
					)}

					{/* {stageId !== undefined && activeIndex !== null && (
						<Card
							className='d-flex align-items-center justify-content-center'
							style={{ height: '100px', width: '48%' }}>
							<Button
								tag='a'
								className='fw-bold fs-3 col-6'
								color='primary'
								isLight
								onClick={() => {
									setCloseModal(false);
								}}
								to={`../${medicalCardPages.customerID.path}/${patientUuid}`}
								style={{ height: '70%', width: '80%' }}>
								<Icon icon='Add' />
								Добавить еще
							</Button>
						</Card>
					)} */}
				</CardBody>
				{/* {stageId && (
					<div className='d-flex justify-content-between w-100 mt-3'>
						<Button
							className='fw-bold fs-6 col-3'
							color='info'
							isLight
							onClick={() => {
								setStageId('');
								setIsBackHidden(false);
							}}>
							Назад
						</Button>
						<Button
							className='fw-bold fs-6 col-3'
							color='info'
							onClick={handleForwardClick}>
							Вперед
						</Button>
					</div>
				)} */}
			</div>

			{/* <ServiceChooseModal
				setIsOpen={setPopperIsOpenService}
				isOpen={popperIsOpenService}
				stage={stageId}
				isStage
				setChosenValues={setValues}
			/> */}
			<StageModal setIsOpen={setModalStage} isOpen={modalStage} medicalCase={medicalCaseId} />
		</div>
	);
};

export default Stage;
