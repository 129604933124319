import React, { SVGProps } from 'react';

const SvgTheethDirection = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg width="10" height="15" viewBox="0 0 12 14" fill='red' {...props}>
		<path d="M6.35194 0.53935C6.15667 0.344088 5.84009 0.344088 5.64483 0.53935L2.46285 3.72133C2.26759 3.91659 2.26759 4.23318 2.46285 4.42844C2.65811 4.6237 2.97469 4.6237 3.16996 4.42844L5.99838 1.60001L8.82681 4.42844C9.02207 4.6237 9.33865 4.6237 9.53392 4.42844C9.72918 4.23318 9.72918 3.91659 9.53392 3.72133L6.35194 0.53935ZM6.49838 12.7158L6.49838 0.892903H5.49838L5.49838 12.7158H6.49838Z" fill="white"/>
		<path d="M9.04428 12.7158H2.95248C2.12406 12.7158 1.45248 12.0442 1.45248 11.2158L1.45248 8.30285C1.45248 7.47443 2.12406 6.80285 2.95248 6.80285L9.04428 6.80285C9.87271 6.80285 10.5443 7.47443 10.5443 8.30285V11.2158C10.5443 12.0442 9.87271 12.7158 9.04428 12.7158Z" fill="#0D6EFD" stroke="white" stroke-width="1.5"/>
		</svg>

	);
};

export default SvgTheethDirection;
