import React, { SVGProps } from "react";

export const THEETH_35 = (props: SVGProps<SVGSVGElement>) => {

    return (
		<>
           
           <svg width="28" height="77" viewBox="0 0 28 77" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M13.4616 75.8025C25.4616 67.8024 26.1097 28.3332 24.4431 9.99986C24.0103 2.99986 3.95782 9.49916 4.44336 18C5.24942 32.1127 10.9434 53.5 9.96099 74.303C10.7991 77.2091 11.7196 76.9639 13.4616 75.8025Z" fill="url(#paint0_linear_402_4981)"/>
<g filter="url(#filter0_f_402_4981)">
<path d="M15.5776 21.5244C19.5629 35.7699 20.0023 43.8673 17.0776 58.5244" stroke="#F3F3F3" stroke-width="2.5" stroke-linecap="round"/>
</g>
<path d="M11.2347 75.4657C13.9727 55.6264 12.1912 40.0097 4.64742 15.3039C4.6113 15.1856 4.4803 15.1348 4.39681 15.2261C4.13468 15.5127 3.71756 16.276 4.0775 18.0752C4.57764 20.5752 4.39592 20.2065 4.5775 21.5752C5.06811 25.2733 5.5775 31.0752 5.5775 31.0752L8.0775 47.0752L9.5775 61.0752L10.0755 74.0234C10.0768 74.0577 10.084 74.0915 10.0967 74.1233L10.6627 75.5381C10.7766 75.8229 11.1927 75.7695 11.2347 75.4657Z" fill="url(#paint1_linear_402_4981)"/>
<path d="M3.82836 10.6359C3.46513 19.6372 8.85455 28 15.8298 28C24.944 28 27.5583 16.6399 27.7931 10.1228C27.8163 9.48054 27.6985 8.84175 27.4074 8.26874C24.9141 3.36069 18.9153 0 15.8298 0C12.6499 0 5.3439 5.09943 3.95021 9.83948C3.87417 10.0981 3.83923 10.3666 3.82836 10.6359Z" fill="url(#paint2_linear_402_4981)"/>
<path d="M3.82836 10.6359C3.46513 19.6372 8.85455 28 15.8298 28C24.944 28 27.5583 16.6399 27.7931 10.1228C27.8163 9.48054 27.6985 8.84175 27.4074 8.26874C24.9141 3.36069 18.9153 0 15.8298 0C12.6499 0 5.3439 5.09943 3.95021 9.83948C3.87417 10.0981 3.83923 10.3666 3.82836 10.6359Z" fill="url(#paint3_radial_402_4981)" fill-opacity="0.3"/>
<g filter="url(#filter1_f_402_4981)">
<path d="M14.1738 14.5752C15.1738 19.5752 10.4354 18.0752 7.67396 18.0752C4.91254 18.0752 5.67379 15.7843 5.67379 13.5752C5.67379 11.3661 13.4124 9.0752 16.1739 9.0752C18.9353 9.0752 17.1738 13.0752 14.1738 14.5752Z" fill="#FDFDFD"/>
</g>
<defs>
<filter id="filter0_f_402_4981" x="8.32715" y="14.2742" width="17.8926" height="51.5005" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_402_4981"/>
</filter>
<filter id="filter1_f_402_4981" x="0.577637" y="4.0752" width="21.9746" height="19.2109" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2.5" result="effect1_foregroundBlur_402_4981"/>
</filter>
<linearGradient id="paint0_linear_402_4981" x1="14.7765" y1="7.03394" x2="14.7765" y2="76.6154" gradientUnits="userSpaceOnUse">
<stop stop-color="#D29439"/>
<stop offset="1" stop-color="#887250"/>
</linearGradient>
<linearGradient id="paint1_linear_402_4981" x1="12.6556" y1="22.5992" x2="-9.20869" y2="71.3388" gradientUnits="userSpaceOnUse">
<stop stop-color="#725935"/>
<stop offset="1" stop-color="#5B482A"/>
</linearGradient>
<linearGradient id="paint2_linear_402_4981" x1="15.811" y1="0" x2="15.811" y2="28" gradientUnits="userSpaceOnUse">
<stop offset="0.64" stop-color="white"/>
<stop offset="1" stop-color="#B48B8B"/>
</linearGradient>
<radialGradient id="paint3_radial_402_4981" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(23.0452 10.6687) rotate(74.5856) scale(3.85106 3.83463)">
<stop stop-color="white" stop-opacity="0"/>
<stop offset="1" stop-color="#9B9B9B"/>
</radialGradient>
</defs>
</svg>

        </>
	);
};