import COLORS from './enumColors';
import { TColor } from '../../type/color-type';

export interface IEventStatus {
	[key: string]: { name: string; color: TColor};
}
const EVENT_STATUS: IEventStatus = {
	APPROVED: { name: 'Завершен', color: COLORS.SUCCESS.name },
	ACTIVE: { name: 'Активный', color: COLORS.PRIMARY.name },
	PENDING: { name: 'Предстоящий', color: COLORS.WARNING.name },
	CANCELED: { name: 'Отменен', color: COLORS.DANGER.name },
	IN_EXPECTATION: { name: 'В ожидании', color: COLORS.ORANGE.name },
	IN_PROGRESS: { name: 'В процессе', color: COLORS.PRIMARY.name },
	NOT_PAID: { name: 'Не оплачен', color: COLORS.DANGER.name},
	PENDING_SERVICE: { name: 'pending', color: COLORS.DANGER.name},
	COMPLETED_SERVICE: { name: 'completed', color: COLORS.SUCCESS.name},
};
export default EVENT_STATUS;
