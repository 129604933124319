import React, { SVGProps } from "react";

export const THEETH_27 = (props: SVGProps<SVGSVGElement>) => {

    return (
		<>
           <svg width="32" height="72" viewBox="0 0 32 72" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M20.0553 0.719776C8.17031 8.52129 4.07073 44.3691 5.19909 62.1588C5.49211 68.9512 22.8522 49.7979 22.5235 41.5491C21.9778 27.8549 22.5235 26.9207 23.0195 6.57113C22.4521 3.75125 21.4227 -0.177807 20.0553 0.719776Z" fill="url(#paint0_linear_402_4952)"/>
<g filter="url(#filter0_i_402_4952)">
<path d="M21.0195 6.58936C32.5195 7.58936 26.0733 20.2366 29.5552 54.3418C23.151 59.2541 7.85367 68.7826 0.730774 60.0247C0.0326653 51.3945 -0.980541 11.0894 15.0888 5.08936C19.1896 3.55821 9.51946 21.0894 14.5195 35.5894C21.0195 20.0894 16.9852 6.23854 21.0195 6.58936Z" fill="url(#paint1_linear_402_4952)"/>
</g>
<path d="M31.0001 63.0894C29.6364 68.0893 19.4999 70.2012 17.5624 66.3271C16.0625 68.827 9.5623 71.3271 7.0623 71.3271C4.5623 71.3271 -1.93769 69.3271 0.562305 57.3271C2.56231 47.7271 7.0623 46.5894 15.5 48.0894C28.4374 44.0894 33.0457 55.5894 31.0001 63.0894Z" fill="url(#paint2_linear_402_4952)"/>
<path d="M31.0001 63.0894C29.6364 68.0893 19.4999 70.2012 17.5624 66.3271C16.0625 68.827 9.5623 71.3271 7.0623 71.3271C4.5623 71.3271 -1.93769 69.3271 0.562305 57.3271C2.56231 47.7271 7.0623 46.5894 15.5 48.0894C28.4374 44.0894 33.0457 55.5894 31.0001 63.0894Z" fill="url(#paint3_radial_402_4952)" fill-opacity="0.3"/>
<defs>
<filter id="filter0_i_402_4952" x="-2.69033" y="1.89561" width="32.2455" height="61.6937" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-8" dy="-8"/>
<feGaussianBlur stdDeviation="1.55"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_402_4952"/>
</filter>
<linearGradient id="paint0_linear_402_4952" x1="14.0195" y1="63.5894" x2="14.0195" y2="0.589355" gradientUnits="userSpaceOnUse">
<stop stop-color="#5C472A"/>
<stop offset="1" stop-color="#685233"/>
</linearGradient>
<linearGradient id="paint1_linear_402_4952" x1="14.9823" y1="63.5894" x2="14.9823" y2="4.99563" gradientUnits="userSpaceOnUse">
<stop stop-color="#D29439"/>
<stop offset="1" stop-color="#887250"/>
</linearGradient>
<linearGradient id="paint2_linear_402_4952" x1="15.7329" y1="71.3271" x2="15.7329" y2="47.2671" gradientUnits="userSpaceOnUse">
<stop offset="0.64" stop-color="white"/>
<stop offset="1" stop-color="#B48B8B"/>
</linearGradient>
<radialGradient id="paint3_radial_402_4952" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(9.46582 57.0894) rotate(150.945) scale(5.14782 7.15006)">
<stop stop-color="white" stop-opacity="0"/>
<stop offset="1" stop-color="#9B9B9B"/>
</radialGradient>
</defs>
</svg>


        </>
	);
};