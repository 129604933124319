import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../layout/SubHeader/SubHeader';
import Page from '../../../layout/Page/Page';
import { demoPagesMenu, medicalCardPages } from '../../../menu';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import PaginationButtons, {
	dataPagination,
	PER_COUNT,
} from '../../../components/PaginationButtons';
import Button from '../../../components/bootstrap/Button';
import Icon from '../../../components/icon/Icon';
import Input from '../../../components/bootstrap/forms/Input';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import PAYMENTS from '../../../common/data/enumPaymentMethod';
import useDarkMode from '../../../hooks/useDarkMode';
import Avatar from '../../../components/Avatar';
import AddPatientModal from './AddPatientModal';
import UserImage3 from '../../../assets/img/wanna/wanna3.png';
import UserImage3Webp from '../../../assets/img/wanna/wanna3.webp';
import { useQuery } from 'react-query';
import * as qs from 'qs';
import { PatientService } from './../../../custom/api/service/patient.service';
import { IPatient } from '@textnpayme/custom/api/interface/patient.interface';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { useDebounce } from '../../../hooks/useDebounce';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../../components/bootstrap/Modal';
import ChooseMedicalCase from '../visit/planVisit/steps/ChooseMedicalCase';
import CreateVisitMedicalCard from '../medicalCard/CreateVisitMedicalCard';
import { IVisit } from '@textnpayme/custom/api/interface/visit.interface';
import TableComponent from '../../../custom/components/tables/TableComponent';
import { NameWithAvatar } from '../../../custom/components/tables/columns/NameWithAvatar';

// import CreateVisitMedicalCard from '../../CreateVisitMedicalCard';

const PatientsPage = () => {
	const { darkModeStatus, themeStatus } = useDarkMode();
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [perPage, setPerPage] = useState<number>(PER_COUNT['10']);
	const [editModalStatus, setEditModalStatus] = useState<boolean>(false);
	const [isValid, setIsValid] = useState(false);
	const [parts, setParts] = useState<any>('');
	const [patientSearch, setPatientSearch] = useState('');
	const [patientVisitUuid, setParentVisitUuid] = useState<string>('');

	const [serviceUuid, setServiceUuid] = useState<any | string>([]);
	const [createVisit, setCreateVisit] = useState(false);
	const [medicalCaseUuid, setMedicalCaseUuid] = useState<null | string>(null);
	const [clinicUuid, setClinicUuid] = useState<string | null>(null);
	const [stageUuid, setStageUuid] = useState<null | string>(null);
	const [visitData, setVisitData] = useState([]);
	const [schedulModalOpen, setSchedulModalOpen] = useState(false);
	const [patientSort, setPatientSort]=useState<boolean>()
	const [upcomingEventsEditOffcanvas, setUpcomingEventsEditOffcanvas] = useState(false);

	const [values, setValues] = useState<IVisit>({});

	const validatePhoneNumber = (phoneNumber: string): void => {
		const phoneUtil = PhoneNumberUtil.getInstance();
		const number = phoneNumber.replace(/\+/g, '');
		try {
			const parsedNumber = phoneUtil.parse(number);
			setIsValid(phoneUtil.isValidNumber(parsedNumber));
		} catch (e) {
			const allRegions: string[] = phoneUtil.getSupportedRegions();
			let isValidForAnyRegion: boolean = false;
			for (const region of allRegions) {
				try {
					const parsedNumber = phoneUtil.parse(number, region);
					if (phoneUtil.isValidNumber(parsedNumber)) {
						isValidForAnyRegion = true;
						break;
					}
				} catch (e) {
					continue;
				}
			}
			setIsValid(isValidForAnyRegion);
		}
	};

	useEffect(() => {
		if (patientSearch.includes('+')) {
			validatePhoneNumber(patientSearch);
		}
		if (patientSearch) {
			setParts(patientSearch.split(' '));
		}
	}, [patientSearch]);

	const DebiuncedPatinetSearch = useDebounce(patientSearch);

	const [query, setQuery] = useState({
		queryMeta: {
			paginate: true,
			limit: perPage,
			page: currentPage,
		},
		filterMeta: {},
	});

	const changeValues = (key: string, value: string | number) => {
		setValues((prev: any) => ({ ...prev, [key]: value }));
	};

	const { isLoading, data: patients }: { data: IPatient[] | any; isLoading: boolean } = useQuery(
		['patients', currentPage, patientSort, perPage, DebiuncedPatinetSearch],
		() =>
			PatientService.findAll(
				qs.stringify({
					queryMeta: {
						order: patientSort ? { name: 'ASC' }: {createdAt: 'DESC'},
						paginate: true,
						limit: perPage,
						page: currentPage,
					},
					filterMeta: {
						...(patientSearch &&{websearchQuery: {searchVector: patientSearch}})
					},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	const visitDropDownProps = {
		// setEventAdding: setEventAdding,
		// setCanvasData: setCanvasData,
		// setUpdateVisitModal: setUpdateVisitModal,
		// setActModal: setActModal,
		// setActVisit: setActVisit,
		// setPatientId: setPatientId,
		setMedicalCaseUuid: setMedicalCaseUuid,
		setServiceUuid: setServiceUuid,
		setClinicUuid: setClinicUuid,
		setStageUuid: setStageUuid,
		// setVisitUpdate: setVisitUpdate,
		// handleOpenCancel: handleOpenCancel,
		// setVisitUuid: setVisitUuid,
		// changeStatus: changeStatus,
	};
	const tableHeaders = [
		{ header: 'ID Визита', key: 'id' },
		{ header: 'Номер Визита', key: 'visitPill' },
		{ header: 'Пациент', key: 'patientNameWithAvatar' },
		{ header: 'Номер телефона', key: 'string' },
		{ header: 'Кол-во визитов', key: 'string' },
		{ header: 'Долг', key: 'string' },
		{ header: 'Статус', key: 'status' },
		{ header: 'Действие', key: 'patient-action' },
	];

	return (
		<PageWrapper title={demoPagesMenu.crm.subMenu.customersList.text}>
			<SubHeader>
				<SubHeaderLeft>
					<label
						className='border-0 bg-transparent cursor-pointer me-0'
						htmlFor='searchInput'>
						<Icon icon='Search' size='2x' color='primary' />
					</label>
					<Input
						id='searchInput'
						type='search'
						className='border-0 shadow-none bg-transparent'
						placeholder='Поиск пациента по Имени Фамилии Отчеству или по коду'
						onChange={(e: any) => setPatientSearch(e.target.value)}
						value={patientSearch}
					/>
					<Dropdown className='fw-bold'>
						<DropdownToggle hasIcon={false}>
							<Button
								isOutline={!darkModeStatus}
								color='dark'
								isLight={darkModeStatus}
								aria-label='More actions'
								className={classNames(
									'text-nowrap',

									{
										'border-light': !darkModeStatus,
									},
									'fw-normal',
									'fs-6',
								)}>
								Сортировать по:
							</Button>
						</DropdownToggle>
						<DropdownMenu className='fw-bold'>
							<DropdownItem >
								<Button onClick={()=> setPatientSort(false)}>Умолчанию</Button>
							</DropdownItem>
							<DropdownItem>
								<Button onClick={()=> setPatientSort(true)}>Алфавиту</Button>
							</DropdownItem>
						</DropdownMenu>
					</Dropdown>
				</SubHeaderLeft>
				<SubHeaderRight>
					<Button
						icon='PersonAdd'
						color='primary'
						isLight
						onClick={() => setEditModalStatus(true)}>
						Новый пациент{' '}
					</Button>
				</SubHeaderRight>
			</SubHeader>
			<Page container='fluid'>
				<div className='row h-100'>
					<div className='col-12'>
						<Card stretch>
							<CardBody className='table-responsive'>
								{/* <TableComponent
									data={patients?.data}
									headers={tableHeaders}
									visitDropDownProps={visitDropDownProps}
								/> */}
								<table className='table table-modern table-hover'>
									<thead>
										<tr>
											<th>№ карты пациента </th>
											<th>Пациент</th>
											<th>Номер телефона</th>
											<th>Кол-во визитов</th>
											<th>Долг</th>
											<th>Статус</th>
											<th>Действие</th>
										</tr>
									</thead>
									<tbody>
										{patients?.data?.map((i: IPatient, index: number) => (
											<tr key={index}>
												<td className='fw-bold '>
													<Button
														className='fs-5 fw-bold'
														tag='a'
														isLink
														to={`../${medicalCardPages.customerID.path}/${i.uuid}`}>
														{i.patientId}
													</Button>
												</td>
												<td>
													<NameWithAvatar
														user={i}
														patientUuid={i?.uuid}
														dynamicFont={16}
														themeStatus={themeStatus}
													/>
												</td>
												<td className='fw-bold fs-5'>{i.phone}</td>
												<td className='fw-bold fs-5'>
													{i.visitCount ? i.visitCount : '0 '}
												</td>
												<td className='fw-bold fs-5'>
													{i.debt ? i.debt : '0 '}֏
												</td>
												<td>
													<div className='text-nowrap fw-bold'>
														{i.status === 'active' ? (
															<>
																<Icon
																	icon='Circle'
																	className='me-2'
																	color='info'
																/>
																<span
																	style={{
																		color: '#0d6efd',
																	}}>
																	Активный
																</span>
															</>
														) : (
															<>
																<Icon
																	icon='Circle'
																	className='me-2'
																	color='warning'
																/>
																<span
																	style={{
																		color: '#FFCF52',
																	}}>
																	Неактивный
																</span>
															</>
														)}
													</div>
												</td>
												<td>
													<div className='d-flex flex-row'>
														<Button
															color='info'
															isLight
															className='text-nowrap me-2'
															onClick={() => {
																if (i?.uuid) {
																	setParentVisitUuid(i?.uuid);
																	setUpcomingEventsEditOffcanvas(
																		(prev: boolean) => !prev,
																	);
																}
															}}
															style={{
																fontWeight: 'bold',
															}}>
															Запланировать визит
														</Button>
														<Dropdown className='fw-bold'>
															<DropdownToggle hasIcon={false}>
																<Button
																	isOutline={!darkModeStatus}
																	color='dark'
																	isLight={darkModeStatus}
																	aria-label='More actions'
																	className={classNames(
																		'text-nowrap',

																		{
																			'border-light':
																				!darkModeStatus,
																		},
																		'fw-normal',
																		'fs-6',
																	)}>
																	•••
																</Button>
															</DropdownToggle>
															<DropdownMenu className='fw-bold'>
																<DropdownItem>
																	<Button
																		tag='a'
																		to={`../${
																			medicalCardPages
																				.customerID.path
																		}/${
																			i.uuid
																				? i.uuid
																				: { index }
																		}`}>
																		Перейти в ЭМК
																	</Button>
																</DropdownItem>
															</DropdownMenu>
														</Dropdown>
													</div>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</CardBody>
							<PaginationButtons
								data={patients?.data || []}
								label='customers'
								setCurrentPage={setCurrentPage}
								currentPage={currentPage}
								perPage={perPage}
								setPerPage={setPerPage}
								totalPage={Math.ceil(patients?.meta?.count / perPage) || 1}
							/>
						</Card>
					</div>
				</div>
			</Page>
			<AddPatientModal setIsOpen={setEditModalStatus} isOpen={editModalStatus} id='0' />
			<Modal
				isStaticBackdrop
				isCentered
				isOpen={upcomingEventsEditOffcanvas}
				setIsOpen={setUpcomingEventsEditOffcanvas}
				closeModalCustom={() => {
					setCreateVisit(false);
				}}
				size='xl'>
				<ModalHeader
					style={{ padding: '1.5rem 2.25rem' }}
					setIsOpen={setUpcomingEventsEditOffcanvas}
					className='p-4'>
					<span className='fs-3 fw-bold'>
						<Icon icon='Person' className='me-2 fs-2' color='primary' />
						Запланировать визит
					</span>
				</ModalHeader>
				<ModalBody style={{ padding: '1.5rem 2.25rem' }}>
					{!createVisit && (
						<ChooseMedicalCase
							setCloseModal={setUpcomingEventsEditOffcanvas}
							setServiceId={setServiceUuid}
							setMedicalCaseUuid={setMedicalCaseUuid}
							setStageUuid={setStageUuid}
							patientId={patientVisitUuid}
							setCreateVisit={setCreateVisit}
							setClinicUuid={setClinicUuid}
						/>
					)}

					{createVisit && (
						<CreateVisitMedicalCard
							setCreateVisit={setCreateVisit}
							setValues={setValues}
							values={values}
							setIsOpen={setUpcomingEventsEditOffcanvas}
							patientId={patientVisitUuid}
							serviceId={serviceUuid}
							medicalCaseUuid={medicalCaseUuid}
							clinicUuid={clinicUuid}
							stageUuid={stageUuid}
							visitData={visitData}
							setVisitData={setVisitData}
							schedulModalOpen={schedulModalOpen}
							changeValues={changeValues}
							setSchedulModalOpen={setSchedulModalOpen}
						/>
					)}
				</ModalBody>
			</Modal>
		</PageWrapper>
	);
};

export default PatientsPage;
