import React, { SVGProps } from "react";

export const TC_C = (props: SVGProps<SVGSVGElement>) => {

    return (
		<>
            <svg width="1.9rem" height="1.9rem" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12.5" cy="12.23" r="12" fill="url(#paint0_radial_523_10067)"/>
                <circle cx="12.5" cy="12.23" r="12" fill={!!props.fill?props.fill:"url(#paint1_radial_523_10067)"} fill-opacity={!!props.fill?"0.8":"0.3"}/>
                <circle cx="12.5" cy="12.23" r="11.5" stroke="black" stroke-opacity="0.2"/>
                <defs>
                <radialGradient id="paint0_radial_523_10067" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.5 12.23) rotate(90) scale(12)">
                <stop offset="0.799" stop-color="white"/>
                <stop offset="1" stop-color="#D2B8B8"/>
                </radialGradient>
                <radialGradient id="paint1_radial_523_10067" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(19.7341 9.37458) rotate(72.1686) scale(3.34275 3.78663)">
                <stop stop-color="white" stop-opacity="0"/>
                <stop offset="1" stop-color="#9B9B9B"/>
                </radialGradient>
                </defs>
            </svg>
        </>
	);
};