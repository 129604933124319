import React, { SVGProps } from "react";

export const THEETH_25 = (props: SVGProps<SVGSVGElement>) => {

    return (
		<>
           
           <svg width="2.15rem" height="6rem" viewBox="0 0 28 78" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M13.3835 1.52466C25.3834 9.52477 26.0316 48.994 24.3649 67.3273C23.9321 74.3273 3.8797 67.828 4.36523 59.3271C5.1713 45.2144 10.8652 23.8271 9.88286 3.02414C10.721 0.11808 11.6415 0.363297 13.3835 1.52466Z" fill="url(#paint0_linear_402_4953)"/>
<g filter="url(#filter0_f_402_4953)">
<path d="M15.4995 55.8027C19.4847 41.5572 19.9242 33.4598 16.9995 18.8027" stroke="#F3F3F3" stroke-width="2.5" stroke-linecap="round"/>
</g>
<path d="M11.1566 1.86148C13.8946 21.7007 12.113 37.3175 4.5693 62.0232C4.53317 62.1415 4.40218 62.1923 4.31869 62.101C4.05655 61.8145 3.63943 61.0512 3.99937 59.252C4.49951 56.752 4.31779 57.1207 4.49937 55.752C4.98999 52.0539 5.49937 46.252 5.49937 46.252L7.99937 30.252L9.49937 16.252L9.99738 3.30373C9.9987 3.26947 10.0059 3.23568 10.0186 3.20384L10.5845 1.78907C10.6985 1.50426 11.1146 1.55761 11.1566 1.86148Z" fill="url(#paint1_linear_402_4953)"/>
<path d="M3.75024 66.6912C3.38701 57.69 8.77642 49.3271 15.7517 49.3271C24.8658 49.3271 27.4802 60.6872 27.715 67.2043C27.7381 67.8466 27.6204 68.4854 27.3293 69.0584C24.836 73.9665 18.8371 77.3271 15.7517 77.3271C12.5718 77.3271 5.26578 72.2277 3.87208 67.4877C3.79605 67.2291 3.76111 66.9606 3.75024 66.6912Z" fill="url(#paint2_linear_402_4953)"/>
<path d="M3.75024 66.6912C3.38701 57.69 8.77642 49.3271 15.7517 49.3271C24.8658 49.3271 27.4802 60.6872 27.715 67.2043C27.7381 67.8466 27.6204 68.4854 27.3293 69.0584C24.836 73.9665 18.8371 77.3271 15.7517 77.3271C12.5718 77.3271 5.26578 72.2277 3.87208 67.4877C3.79605 67.2291 3.76111 66.9606 3.75024 66.6912Z" fill="url(#paint3_radial_402_4953)" fill-opacity="0.3"/>
<g filter="url(#filter1_f_402_4953)">
<path d="M14.0957 62.752C15.0957 57.752 10.3573 59.252 7.59584 59.252C4.83441 59.252 5.59566 61.5428 5.59566 63.752C5.59566 65.9611 13.3343 68.252 16.0957 68.252C18.8572 68.252 17.0957 64.252 14.0957 62.752Z" fill="#FDFDFD"/>
</g>
<defs>
<filter id="filter0_f_402_4953" x="8.24902" y="11.5525" width="17.8926" height="51.5005" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_402_4953"/>
</filter>
<filter id="filter1_f_402_4953" x="0.499512" y="54.041" width="21.9746" height="19.2109" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2.5" result="effect1_foregroundBlur_402_4953"/>
</filter>
<linearGradient id="paint0_linear_402_4953" x1="14.6983" y1="70.2932" x2="14.6983" y2="0.711761" gradientUnits="userSpaceOnUse">
<stop stop-color="#D29439"/>
<stop offset="1" stop-color="#887250"/>
</linearGradient>
<linearGradient id="paint1_linear_402_4953" x1="12.5775" y1="54.7279" x2="-9.28682" y2="5.98839" gradientUnits="userSpaceOnUse">
<stop stop-color="#725935"/>
<stop offset="1" stop-color="#5B482A"/>
</linearGradient>
<linearGradient id="paint2_linear_402_4953" x1="15.7329" y1="77.3271" x2="15.7329" y2="49.3271" gradientUnits="userSpaceOnUse">
<stop offset="0.64" stop-color="white"/>
<stop offset="1" stop-color="#B48B8B"/>
</linearGradient>
<radialGradient id="paint3_radial_402_4953" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(22.967 66.6585) rotate(-74.5856) scale(3.85106 3.83463)">
<stop stop-color="white" stop-opacity="0"/>
<stop offset="1" stop-color="#9B9B9B"/>
</radialGradient>
</defs>
</svg>

        </>
	);
};