import React, { SVGProps } from 'react';

const SvgTheethDirection = (props: SVGProps<SVGSVGElement>) => {
	return (
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 25 25" fill="none" {...props}>
<g clip-path="url(#clip0_2294_2907)">
<mask id="mask0_2294_2907"  style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="25">
<path d="M24.5 0.25H0.5V24.25H24.5V0.25Z" fill="white"/>
</mask>
<g mask="url(#mask0_2294_2907)">
<path d="M24.5 0.25H0.5V24.25H24.5V0.25Z" fill="white"/>
</g>
<path d="M12.8516 3.36813C12.6563 3.17286 12.3398 3.17286 12.1445 3.36813L8.96251 6.55011C8.76725 6.74537 8.76725 7.06195 8.96251 7.25721C9.15778 7.45248 9.47436 7.45248 9.66962 7.25721L12.498 4.42879L15.3265 7.25721C15.5217 7.45248 15.8383 7.45248 16.0336 7.25721C16.2288 7.06195 16.2288 6.74537 16.0336 6.55011L12.8516 3.36813ZM12.998 17.9092V3.72168H11.998V17.9092H12.998Z" fill="#0D6EFD"/>
<path d="M16.4531 17.9092H8.54297C7.71454 17.9092 7.04297 17.2376 7.04297 16.4092V12.3136C7.04297 11.4852 7.71454 10.8136 8.54297 10.8136H16.4531C17.2816 10.8136 17.9531 11.4852 17.9531 12.3136V16.4092C17.9531 17.2376 17.2816 17.9092 16.4531 17.9092Z" fill="white" stroke="#0D6EFD" stroke-width="1.5"/>
</g>
<defs>
<clipPath id="clip0_2294_2907">
<rect x="0.5" y="0.25" width="24" height="24" rx="8" fill="white"/>
</clipPath>
</defs>
</svg>
	);
};

export default SvgTheethDirection;
