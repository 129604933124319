import React, { FC } from 'react';
import PropTypes from 'prop-types';

interface ICreatePassProps {
	width?: number;
	height?: number;
}
const CreatePass: FC<ICreatePassProps> = ({ width, height }) => {
	return (
		<svg
			width={height !== 854 && !!height ? height * (2155 / 854) : width}
			height={width !== 2155 && !!width ? width * (854 / 2155) : height}
			viewBox='0 0 176 176'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g clipPath='url(#clip0_588_599)'>
				<path
					d='M163.625 176H12.375C5.73031 176 0.34375 170.613 0.34375 163.969V115.844C0.34375 109.199 5.73031 103.812 12.375 103.812H163.625C170.27 103.812 175.656 109.199 175.656 115.844V163.969C175.656 170.613 170.27 176 163.625 176Z'
					fill='#AED0FF'
				/>
				<path
					d='M175.656 163.969V115.844C175.656 109.199 170.27 103.812 163.625 103.812H88V176H163.625C170.27 176 175.656 170.613 175.656 163.969Z'
					fill='#989DEC'
				/>
				<path
					d='M12.375 165.688C11.4273 165.688 10.6562 164.916 10.6562 163.969V115.844C10.6562 114.896 11.4273 114.125 12.375 114.125H163.625C164.573 114.125 165.344 114.896 165.344 115.844V163.969C165.344 164.916 164.573 165.688 163.625 165.688H12.375Z'
					fill='#BCEAF9'
				/>
				<path
					d='M163.625 114.125H88V165.688H163.625C164.573 165.688 165.344 164.916 165.344 163.969V115.844C165.344 114.896 164.573 114.125 163.625 114.125Z'
					fill='#AED0FF'
				/>
				<path
					d='M152.813 128.968C150.8 126.954 147.535 126.954 145.521 128.968L141.875 132.614L138.229 128.968C136.215 126.954 132.951 126.954 130.937 128.968C128.923 130.982 128.923 134.246 130.937 136.26L134.583 139.906L130.937 143.552C128.923 145.566 128.923 148.831 130.937 150.844C132.951 152.858 136.215 152.858 138.229 150.844L141.875 147.198L145.521 150.844C147.535 152.858 150.8 152.858 152.813 150.844C154.827 148.831 154.827 145.566 152.813 143.552L149.167 139.906L152.813 136.26C154.827 134.246 154.827 130.982 152.813 128.968ZM109.604 128.968L105.958 132.614L102.312 128.968C100.298 126.954 97.0337 126.954 95.02 128.968C93.0063 130.982 93.0063 134.246 95.02 136.26L98.6665 139.906L95.0204 143.552C93.0067 145.566 93.0067 148.831 95.0204 150.844C97.0341 152.858 100.299 152.858 102.312 150.844L105.959 147.198L109.605 150.844C111.618 152.858 114.883 152.858 116.897 150.844C118.91 148.831 118.91 145.566 116.897 143.552L113.25 139.906L116.897 136.26C118.91 134.246 118.91 130.982 116.897 128.968C114.883 126.954 111.618 126.954 109.604 128.968Z'
					fill='#08475E'
				/>
				<path
					d='M73.688 128.968L70.0419 132.614L66.3957 128.968C64.382 126.954 61.1174 126.954 59.1037 128.968C57.09 130.982 57.09 134.246 59.1037 136.26L62.7499 139.906L59.1037 143.552C57.09 145.566 57.09 148.831 59.1037 150.844C61.1174 152.858 64.382 152.858 66.3957 150.844L70.0419 147.198L73.688 150.844C75.7017 152.858 78.9663 152.858 80.98 150.844C82.9937 148.831 82.9937 145.566 80.98 143.552L77.3338 139.906L80.98 136.26C82.9937 134.246 82.9937 130.982 80.98 128.968C78.9663 126.954 75.7017 126.954 73.688 128.968ZM37.7713 128.968L34.1251 132.614L30.479 128.968C28.4653 126.954 25.2007 126.954 23.187 128.968C21.1733 130.982 21.1733 134.246 23.187 136.26L26.8332 139.906L23.187 143.552C21.1733 145.566 21.1733 148.831 23.187 150.844C25.2007 152.858 28.4653 152.858 30.479 150.844L34.1251 147.198L37.7713 150.844C39.785 152.858 43.0496 152.858 45.0633 150.844C47.077 148.831 47.077 145.566 45.0633 143.552L41.4171 139.906L45.0633 136.26C47.077 134.246 47.077 130.982 45.0633 128.968C43.0496 126.954 39.785 126.954 37.7713 128.968Z'
					fill='#0A789B'
				/>
				<path
					d='M108.625 44.6875C105.777 44.6875 103.469 42.3789 103.469 39.5312V25.7812C103.469 17.2518 96.5295 10.3125 88 10.3125C79.4705 10.3125 72.5312 17.2518 72.5312 25.7812V39.5312C72.5312 42.3789 70.2226 44.6875 67.375 44.6875C64.5274 44.6875 62.2188 42.3789 62.2188 39.5312V25.7812C62.2188 11.5655 73.7842 0 88 0C102.216 0 113.781 11.5655 113.781 25.7812V39.5312C113.781 42.3789 111.473 44.6875 108.625 44.6875Z'
					fill='#FFF5F5'
				/>
				<path
					d='M88 0V10.3125C96.5295 10.3125 103.469 17.2518 103.469 25.7812V39.5312C103.469 42.3789 105.777 44.6875 108.625 44.6875C111.473 44.6875 113.781 42.3789 113.781 39.5312V25.7812C113.781 11.5655 102.216 0 88 0Z'
					fill='#E2DFF4'
				/>
				<path
					d='M108.625 92.8125H67.375C60.741 92.8125 55.3438 87.4153 55.3438 80.7812V46.4062C55.3438 39.7722 60.741 34.375 67.375 34.375H108.625C115.259 34.375 120.656 39.7722 120.656 46.4062V80.7812C120.656 87.4153 115.259 92.8125 108.625 92.8125Z'
					fill='#A2E786'
				/>
				<path
					d='M108.625 34.375H88V92.8125H108.625C115.259 92.8125 120.656 87.4153 120.656 80.7812V46.4062C120.656 39.7722 115.259 34.375 108.625 34.375Z'
					fill='#00CB75'
				/>
				<path
					d='M80.7085 74.5318L73.4165 67.2398C71.4028 65.2261 71.4028 61.9615 73.4165 59.9478C75.4302 57.9342 78.6948 57.9342 80.7085 59.9478L84.3546 63.5937L95.2928 52.6555C97.3064 50.6418 100.571 50.6418 102.585 52.6555C104.598 54.6692 104.598 57.9338 102.585 59.9475L88.0005 74.5318C85.9868 76.5455 82.7222 76.5455 80.7085 74.5318Z'
					fill='#0A789B'
				/>
				<path
					d='M95.292 52.6555L88 59.9475V74.5318L102.584 59.9475C104.598 57.9338 104.598 54.6692 102.584 52.6555C100.571 50.6418 97.3057 50.6418 95.292 52.6555Z'
					fill='#08475E'
				/>
			</g>
			<defs>
				<clipPath id='clip0_588_599'>
					<rect width='176' height='176' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
CreatePass.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
CreatePass.defaultProps = {
	width: 2155,
	height: 854,
};

export default CreatePass;
