import React, { SVGProps } from "react";

export const THEETH_34 = (props: SVGProps<SVGSVGElement>) => {

    return (
		<>
           <svg width="26" height="79" viewBox="0 0 26 79" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M7.97116 78.0693C19.9711 70.0692 23.1278 33.8429 21.4612 15.5096C21.0284 8.50956 3.47553 10.5089 3.96106 19.0097C4.76713 33.1225 4.47051 62.0097 4.47051 76.5698C5.30862 79.4759 6.22915 79.2306 7.97116 78.0693Z" fill="url(#paint0_linear_402_4976)"/>
<path d="M7.33045 14.0861C7.8449 28.5016 10.9025 43.3028 5.35094 78.2485C5.33082 78.3752 5.19618 78.4505 5.10538 78.3599C2.95273 76.2131 5.28149 54.5702 4.31055 23.5C3.36325 18.2915 3.76187 15.325 6.77952 13.7513C7.02947 13.6209 7.32039 13.8043 7.33045 14.0861Z" fill="url(#paint1_linear_402_4976)"/>
<g filter="url(#filter0_f_402_4976)">
<path d="M17.724 33.5008C17.5797 43.6776 13.6215 56.1262 12.6017 56.1118C11.5819 56.0973 14.9947 43.641 15.139 33.4642C15.2833 23.2874 14.4074 13.1331 15.4272 13.1475C16.447 13.162 17.8684 23.324 17.724 33.5008Z" fill="white"/>
</g>
<path d="M1.80889 12.9007C1.48605 21.2497 6.43067 29 12.8284 29C21.1687 29 23.5806 18.5204 23.8098 12.4594C23.8338 11.8238 23.7189 11.1904 23.4303 10.6236C21.1248 6.09575 15.6499 3 12.8284 3C9.91426 3 3.2257 7.71795 1.92876 12.1126C1.85329 12.3683 1.81919 12.6343 1.80889 12.9007Z" fill="url(#paint2_linear_402_4976)"/>
<path d="M1.80889 12.9007C1.48605 21.2497 6.43067 29 12.8284 29C21.1687 29 23.5806 18.5204 23.8098 12.4594C23.8338 11.8238 23.7189 11.1904 23.4303 10.6236C21.1248 6.09575 15.6499 3 12.8284 3C9.91426 3 3.2257 7.71795 1.92876 12.1126C1.85329 12.3683 1.81919 12.6343 1.80889 12.9007Z" fill="url(#paint3_radial_402_4976)" fill-opacity="0.2"/>
<g filter="url(#filter1_f_402_4976)">
<path d="M12.7939 3.5L13.7939 6L10.7939 12" stroke="black" stroke-width="0.5" stroke-linecap="round"/>
</g>
<g filter="url(#filter2_f_402_4976)">
<path d="M13.1246 6.7452L15.6563 7.662L18.1396 13.8937" stroke="black" stroke-width="0.5" stroke-linecap="round"/>
</g>
<g filter="url(#filter3_f_402_4976)">
<circle cx="7.29395" cy="15.5" r="2.5" fill="white"/>
</g>
<defs>
<filter id="filter0_f_402_4976" x="4.41162" y="5.14746" width="21.3228" height="58.9644" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="4" result="effect1_foregroundBlur_402_4976"/>
</filter>
<filter id="filter1_f_402_4976" x="7.54395" y="0.25" width="9.52393" height="15" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur_402_4976"/>
</filter>
<filter id="filter2_f_402_4976" x="9.87451" y="3.49512" width="11.5151" height="13.6487" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur_402_4976"/>
</filter>
<filter id="filter3_f_402_4976" x="0.793945" y="9" width="13" height="13" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_402_4976"/>
</filter>
<linearGradient id="paint0_linear_402_4976" x1="12.9128" y1="11.2656" x2="12.9128" y2="78.8822" gradientUnits="userSpaceOnUse">
<stop stop-color="#B1833C"/>
<stop offset="1" stop-color="#8F6729"/>
</linearGradient>
<linearGradient id="paint1_linear_402_4976" x1="6.24401" y1="13.5" x2="6.24401" y2="78.5" gradientUnits="userSpaceOnUse">
<stop stop-color="#7D633C"/>
<stop offset="1" stop-color="#564428"/>
</linearGradient>
<linearGradient id="paint2_linear_402_4976" x1="12.8112" y1="3" x2="12.8112" y2="29" gradientUnits="userSpaceOnUse">
<stop offset="0.64" stop-color="white"/>
<stop offset="1" stop-color="#B48B8B"/>
</linearGradient>
<radialGradient id="paint3_radial_402_4976" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(19.7939 15) rotate(119.358) scale(9.17878 7.77883)">
<stop stop-opacity="0"/>
<stop offset="1" stop-color="#5E5E5E"/>
</radialGradient>
</defs>
</svg>


        </>
	);
};