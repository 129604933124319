import React from 'react';
import { Select } from 'antd';
import { useDebounce } from '../../../hooks/useDebounce';
import { CommonEntity } from '@textnpayme/custom/api/interface/common-entity';
import useDarkMode from '../../../hooks/useDarkMode';

interface SelectProps {
	changeValues: (key: string, value: string | number) => void;
	changeSearchValue: (key: string, value: string | number) => void;
	options: any[] | undefined;
	value: string | undefined;
	name: string;
	placeholder: string;
	disabled?: any;
}
export default function CustomSelect({
	changeValues,
	changeSearchValue,
	value,
	name,
	placeholder,
	disabled = false,
	options,
}: SelectProps) {
	const { themeStatus, darkModeStatus } = useDarkMode();

	return (
		<>
			<Select
				disabled={disabled}
				notFoundContent={'Нет данных'}
				className={themeStatus === 'dark' ? 'dark-select' : ''}
				rootClassName={themeStatus === 'dark' ? 'dark-select' : ''}
				dropdownStyle={{ position: 'absolute', zIndex: '10000000' }}
				showSearch
				style={{ width: '100%' }}
				placeholder={placeholder}
				optionFilterProp='children'
				value={value ? value : null}
				onChange={(e) => {
					changeValues(`${name}Uuid`, e);
				}}
				onSelect={(value, option) => {
					changeValues(`${name}Uuid`, value);
				}}
				onSearch={(e) => changeSearchValue(name, e)}
				filterOption={false}
				options={options}
			/>
		</>
	);
}
