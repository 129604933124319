import React, { SVGProps } from "react";

export const THEETH_41 = (props: SVGProps<SVGSVGElement>) => {

    return (
		<>
           
           <svg width="23" height="73" viewBox="0 0 23 73" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M12.9292 71.5C4.72639 63.5399 7.16237 26.7488 8.30165 8.50724C8.59751 1.54229 19.7612 2.04174 19.4293 10.5C18.8783 24.5421 16.4295 55.5005 15.8224 69.5086C15.2495 72.4001 14.12 72.6555 12.9292 71.5Z" fill="url(#paint0_linear_402_4972)"/>
<g filter="url(#filter0_f_402_4972)">
<path d="M12.5791 26.5513C11.2064 35.789 11.1167 41.6101 12.0791 53.0513" stroke="#F3F3F3" stroke-width="1.5" stroke-linecap="round"/>
</g>
<path d="M14.561 72C16.561 68.5 16.856 55.3825 19.061 18" stroke="url(#paint1_linear_402_4972)" stroke-width="0.9" stroke-linecap="round"/>
<path d="M20.561 4.5C20.561 8 20.561 27 12.561 27C7.06104 27 5.06104 16.5 5.06104 14V1.5C5.06104 0.5 5.56104 0 7.56104 0H18.061C19.061 0 20.561 1 20.561 4.5Z" fill="url(#paint2_linear_402_4972)"/>
<path d="M20.561 4.5C20.561 8 20.561 27 12.561 27C7.06104 27 5.06104 16.5 5.06104 14V1.5C5.06104 0.5 5.56104 0 7.56104 0H18.061C19.061 0 20.561 1 20.561 4.5Z" fill="url(#paint3_radial_402_4972)" fill-opacity="0.3"/>
<g filter="url(#filter1_f_402_4972)">
<path d="M8.96484 17.5C7.96484 22.5 12.7033 21 15.4647 21C18.2261 21 17.4649 18.7091 17.4649 16.5C17.4649 14.2909 9.72624 12 6.96482 12C4.20339 12 5.96484 16 8.96484 17.5Z" fill="#FDFDFD"/>
</g>
<defs>
<filter id="filter0_f_402_4972" x="4.68945" y="19.8013" width="14.6396" height="40" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_402_4972"/>
</filter>
<filter id="filter1_f_402_4972" x="0.586426" y="7" width="21.9746" height="19.2109" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2.5" result="effect1_foregroundBlur_402_4972"/>
</filter>
<linearGradient id="paint0_linear_402_4972" x1="13.2489" y1="3.68213" x2="13.2489" y2="72.1424" gradientUnits="userSpaceOnUse">
<stop stop-color="#D29439"/>
<stop offset="1" stop-color="#887250"/>
</linearGradient>
<linearGradient id="paint1_linear_402_4972" x1="15.3289" y1="24.64" x2="41.0844" y2="52.4902" gradientUnits="userSpaceOnUse">
<stop stop-color="#725935"/>
<stop offset="1" stop-color="#5B482A"/>
</linearGradient>
<linearGradient id="paint2_linear_402_4972" x1="12.811" y1="0" x2="12.811" y2="27" gradientUnits="userSpaceOnUse">
<stop offset="0.64" stop-color="white"/>
<stop offset="1" stop-color="#B48B8B"/>
</linearGradient>
<radialGradient id="paint3_radial_402_4972" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(15.561 8) rotate(113.199) scale(3.80789 2.6424)">
<stop stop-color="white" stop-opacity="0"/>
<stop offset="1" stop-color="#9B9B9B"/>
</radialGradient>
</defs>
</svg>

        </>
	);
};