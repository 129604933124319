import React, { FC, useEffect, useState, useRef } from 'react';
import { CardHeader, CardTitle } from 'react-bootstrap';
import Button from '../../../../../components/bootstrap/Button';
// import SubHeader { SubHeaderLeft, SubHeaderRight } from '../../../../../layout/SubHeader/SubHeader';
import { IVisit } from '@textnpayme/custom/api/interface/visit.interface';
import Card, { CardBody, CardFooter, CardLabel } from '../../../../../components/bootstrap/Card';
import Icon from '../../../../../components/icon/Icon';
import Badge from '../../../../../components/bootstrap/Badge';
import Progress from '../../../../../components/bootstrap/Progress';
import UserImage3 from '../../../../../assets/img/wanna/wanna3.png';
import UserImage3Webp from '../../../../../assets/img/wanna/wanna3.webp';
import Avatar from '../../../../../components/Avatar';
import { useQuery } from 'react-query';
import { IMedicalCase } from '@textnpayme/custom/api/interface/medicalCases.interface';
import * as qs from 'qs';
import { MedicalCasesService } from '../../../../../custom/api/service/medicalCase.service';
import Stage from './Stage';
import { log } from 'console';
import PaginationButtons, {
	PER_COUNT,
	PER_COUNT_MEDICAL_CASE,
} from '../../../../../components/PaginationButtons';
import { number } from 'prop-types';
import { medicalCardPages } from '../../../../../menu';
import { PatientService } from '../../../../../custom/api/service/patient.service';
import { IPatient } from '../../../../../custom/api/interface/patient.interface';
import FormGroup from '../../../../../components/bootstrap/forms/FormGroup';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
} from '../../../../../layout/SubHeader/SubHeader';
import MedicalCaseModal from '../../../medicalCard/MedicalCaseModal';
import Tooltips from '../../../../../components/bootstrap/Tooltips';

interface IChooseMedicalCaseProps {
	setCreateVisit(...args: unknown[]): unknown;
	patientId: string;
	setServiceId(...args: unknown[]): unknown;
	setMedicalCaseUuid(...args: unknown[]): unknown;
	setStageUuid(...args: unknown[]): unknown;
	setCloseModal(...args: unknown[]): unknown;
	setClinicUuid(...args: unknown[]): unknown;
	setIsPatientChoosen?(...args: unknown[]): unknown;
	isPatientChoosen?: boolean | null;
}

function formatDate(strDate: string): any {
	const date = new Date(strDate);
	const year: number = date.getFullYear();
	const month: number = date.getMonth() + 1; // Adding 1 because getMonth() returns zero-based month index
	const day: number = date.getDate();

	// Padding month and day with leading zero if needed
	const formattedMonth: string = month < 10 ? `0${month}` : `${month}`;
	const formattedDay: string = day < 10 ? `0${day}` : `${day}`;

	const formattedDateDot: string = `${formattedDay}.${formattedMonth}.${year}`;

	return formattedDateDot;
}

const ChooseMedicalCase: FC<IChooseMedicalCaseProps> = ({
	setCreateVisit,
	setServiceId,
	setStageUuid,
	setCloseModal,
	setMedicalCaseUuid,
	setClinicUuid,
	patientId,
	setIsPatientChoosen,
	isPatientChoosen,
}) => {
	const [isOpen, setIsOpen] = useState<number | null>(null);
	const [medicalCaseId, setMedicalCaseId] = useState<string>('');
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [perPage, setPerPage] = useState<number>(PER_COUNT_MEDICAL_CASE['3']);
	const [modalMedicalCase, setModalMedicalCase] = useState<boolean>(false);
	const [chosenMedicalCase, setChosenMedicalCase] = useState<any>(null);
	const [isHidePagination, setHidePagination] = useState<boolean>(false);

	const [dynamicFont, setDynamicFont] = useState('Нормальный');

	const getFontSizeFromLocalStorage = () => {
		const fontSize = JSON.parse(localStorage.getItem('fontSize') || '{}');
		try {
			const parsedFontSize = fontSize
				? JSON.parse(fontSize)
				: { name: 'Нормальный', value: 16 };
			return parsedFontSize.name;
		} catch (error) {
			console.error('Ошибка при парсинге fontSize из localStorage:', error);
			return 'Нормальный';
		}
	};

	const updateFontSize = () => {
		const fontSizeName = getFontSizeFromLocalStorage();
		console.log(fontSizeName);
		setDynamicFont(fontSizeName);
	};

	useEffect(() => {
		updateFontSize();

		const handleFontSizeChange = () => {
			updateFontSize();
		};

		window.addEventListener('fontSizeChange', handleFontSizeChange);

		return () => {
			window.removeEventListener('fontSizeChange', handleFontSizeChange);
		};
	}, []);

	useEffect(() => {
		setIsOpen(null);
	}, [currentPage, perPage]);

	const { isLoading: patientLoading, data: patient } = useQuery<IPatient, Error>(
		['patient', patientId],
		() =>
			PatientService.findOne(
				patientId,
				qs.stringify({
					queryMeta: {
						paginate: true,
					},
					filterMeta: {},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	const { isLoading, data: medicalCases }: { data: IMedicalCase[] | any; isLoading: boolean } =
		useQuery(
			['medicalCases', patientId, currentPage, perPage],
			() =>
				MedicalCasesService.findAll(
					qs.stringify({
						queryMeta: {
							paginate: true,
							limit: perPage,
							page: currentPage,
							order: { createdAt: 'DESC' },
						},
						includeMeta: [
							{
								association: 'visits',
							},
						],
						filterMeta: {
							patientUuid: patientId,
							status: 'Активный',
						},
					}),
				),
			{
				keepPreviousData: true,
				refetchOnWindowFocus: false,
			},
		);

	const [goToNewStage, setGoToNewStage] = useState(false);
	const [stageId, setStageId] = useState<string | null>('');

	const [values, setValues] = useState<IVisit>({
		services: [],
		serviceName: '',
		serviceNameChosen: '',
	});

	const formRef = useRef<HTMLFormElement>(null);

	const [isNoServices, setIsNoServices] = useState(false);
	const [chosenServicesCount, setChosenServicesCount] = useState(0);

	const isDisabledForwardBtn =
		(!isNoServices && chosenServicesCount < 1) || (stageId === '' && isNoServices);

	const setAndLogServices = (service: any) => {
		setValues((prev: any) => ({
			...prev,
			services: service.services,
		}));
		console.log('NEW SERVICES:', values);
	};

	const goToNextStage = async (values: any) => {
		setServiceId(values.services);
		setStageUuid(stageId);
		setCreateVisit(true);
	};

	const handleForwardClick = () => {
		console.log('called');
		if (formRef.current) {
			formRef.current.dispatchEvent(
				new Event('submit', { cancelable: false, bubbles: true }),
			);
		}
		setGoToNewStage(true);
	};

	useEffect(() => {
		console.log('NEW SERVICES EFFECT:', values);
		if (goToNewStage) {
			goToNextStage(values);
			setGoToNewStage(false);
		}
	}, [goToNewStage]);

	return (
		<div
			className='d-flex flex-column justify-content-center align-items-center'
			style={{ gap: '1.8rem' }}>
			<div className='d-flex flex-wrap flex-row w-100'>
				{' '}
				{patient && (
					<div className='col-12'>
						<FormGroup id='patient'>
							<SubHeader className='shadow-none m-0 light-border-name-string'>
								<SubHeaderLeft className='d-flex flex-row align-items-center p-3 m-0'>
									<div
										className='fw-bold me-3 code-frame fs-5'
										style={{
											borderRadius: '0.5rem',
										}}>
										{patient.patientId}
									</div>
									<Avatar
										src={
											patient?.image
												? `https://backend.amracode.am${patient?.image}`
												: UserImage3
										} // Show preview or default image
										size={48}
										color='primary'
										className='me-3 m-0'
									/>
									<h4 className='m-0 fs-5'>
										{patient.name} {patient.surname} {patient.middleName}
										<p className='text-muted m-0'>{patient.phone}</p>
									</h4>
								</SubHeaderLeft>
								<SubHeaderRight>
									{/* <Button
										tag='a'
										color='primary'
										className='text-nowrap'
										isLight
										to={`../${medicalCardPages.customerID.path}/${patient?.uuid}`}
										target='_blank'>
										Посмотреть профиль
									</Button> */}
									<div className='text-nowrap fw-bold d-flex align-items-center justify-content-center gap-2'>
										{patient.status === 'active' ? (
											<>
												<Icon icon='Circle' color='info' />
												<span
													className='fs-6'
													style={{
														color: '#0d6efd',
													}}>
													Активный
												</span>
											</>
										) : (
											<>
												<Icon icon='Circle' color='warning' />
												<span
													className='fs-6'
													style={{
														color: '#FFCF52',
													}}>
													Неактивный
												</span>
											</>
										)}
									</div>
								</SubHeaderRight>
							</SubHeader>
						</FormGroup>
					</div>
				)}
			</div>
			{!chosenMedicalCase && (
				<div className='d-flex align-items-center justify-content-between w-100 ps-2'>
					<SubHeaderLeft className='col-4 text-muted'>
						<p className='fs-4 fw-semibold'>
							{medicalCases?.data.length
								? `Активные мед случаи`
								: `Нет активных мед.случаев`}
						</p>{' '}
					</SubHeaderLeft>
					<SubHeaderRight className='col-8'>
						<Button
							tag='button'
							color='info'
							className='text-nowrap fw-semibold fs-5 new-med-case-btn'
							// to={`../${medicalCardPages.customerID.path}/${patientId}`}
							onClick={() => setModalMedicalCase(true)}>
							Новый мед случай{' '}
						</Button>
					</SubHeaderRight>
				</div>
			)}

			<div
				className='d-flex flex-column justify-content-center w-100'
				style={{ gap: '1.25rem' }}>
				{chosenMedicalCase ? (
					<>
						<SubHeader className='shadow-none m-0 light-border-name-string'>
							<SubHeaderLeft className='d-flex flex-row align-items-center p-3'>
								<div
									className='fw-bold me-3 code-frame fs-5'
									style={{
										background: '#0D6EFD',
										borderRadius: '0.5rem',
									}}>
									{chosenMedicalCase.medicalCaseId}
								</div>
								<CardTitle className='h5 d-flex flex-column'>
									{chosenMedicalCase.title}{' '}
									<span className='text-muted fs-6'>
										{formatDate(String(chosenMedicalCase.startDate))}
									</span>
								</CardTitle>
							</SubHeaderLeft>
						</SubHeader>
					</>
				) : (
					<>
						{medicalCases?.data.map((i: IMedicalCase, index: number) => (
							<Card className='col-12 m-0'>
								<CardHeader className='rounded-5'>
									{/* <CardLabel icon='DoneAll'>
									<CardTitle className='h5 d-flex flex-column'>
										{i.title}{' '}
										<span className='text-muted fs-sm'>Код {i.medicalCaseId} </span>
									</CardTitle>
								</CardLabel> */}
									<SubHeaderLeft className='col-10'>
										<div
											className={`fw-bold me-3 code-frame fs-5 ${
												dynamicFont === 'Маленький' ? 'col-1' : 'col-2'
											}`}
											style={{
												background: '#0D6EFD',
												borderRadius: '0.5rem',
											}}>
											{i.medicalCaseId}
										</div>
										<CardTitle
											className='d-flex flex-column fs-5 col-10 '
											style={{ width: '80%' }}>
											<span className='overflow-hidden w-100'>
												<Tooltips
													title={i.title}
													placement='top'
													flip={['top']}>
													<span className='text-truncate'>{i.title}</span>
												</Tooltips>
											</span>
											<span className='text-muted fs-6'>
												{formatDate(String(i.startDate))}
											</span>
										</CardTitle>
									</SubHeaderLeft>
									<SubHeaderRight>
										<Button
											className='fw-bold fs-6 col-3 w-100'
											color='primary'
											isLight
											onClick={() => {
												// setIsOpen(index);
												setChosenMedicalCase(i);
												if (i.uuid) {
													setMedicalCaseId(i.uuid);
													setMedicalCaseUuid(i.uuid);
													setClinicUuid(i.clinicUuid);
												}
												setHidePagination(true);

												// if (index === isOpen) {
												// 	setIsOpen(null);
												// 	setMedicalCaseId('');
												// 	setMedicalCaseUuid(null);
												// 	setClinicUuid(null);
												// }
											}}>
											<span className='text-nowrap m-0'>
												{/* {isOpen === index ? `Cкрыть` : `Посмотреть больше`} */}
												Выбрать
											</span>
										</Button>
									</SubHeaderRight>
								</CardHeader>
								{/* <CardBody className='d-flex flex-wrap gap-3'>
								<Badge
									isLight
									color='warning'
									className='fs-6 d-flex gap-1  '
									style={{ height: 'fit-content' }}>
									<Icon icon='DoneAll' />
									осталось визитов{' '}
									{i.visits.filter((i: any) => i.status === 'Предстоящий').length}
								</Badge>{' '}
								<Badge
									isLight
									color='success'
									className='fs-6 d-flex gap-1 '
									style={{ height: 'fit-content' }}>
									<Icon icon='DoneAll' />
									завершенных визитов{' '}
									{i.visits.filter((i: any) => i.status === 'Завершен').length}
								</Badge>{' '}
							</CardBody>
							<CardFooter className='d-flex align-items-center gap-2'>
								<div className='col-5'>
									{Math.round(
										i?.visits?.length
											? (i.visits.filter(
													(visit: any) => visit.status === 'Завершен',
												).length /
													i.visits.length) *
													100
											: 0,
									) + '%'}
									<Progress
										value={
											i?.visits?.length
												? (i.visits.filter((i: any) => i.status === 'Завершен')
														.length /
														i.visits.length) *
													100
												: 0
										}
										color='success'
									/>
								</div>
								<Button
									className='fw-bold fs-6 col-3'
									color='primary'
									isLight={isOpen === index && true}
									onClick={() => {
										setIsOpen(index);
										if (i.uuid) {
											setMedicalCaseId(i.uuid);
											setMedicalCaseUuid(i.uuid);
											setClinicUuid(i.clinicUuid);
										}
					
										if (index === isOpen) {
											setIsOpen(null);
											setMedicalCaseId('');
											setMedicalCaseUuid(null);
											setClinicUuid(null);
										}
									}}>
									<span className='me-2 text-nowrap'>
										{isOpen === index ? `Cкрыть` : `Посмотреть больше`}
									</span>
								</Button>
							</CardFooter> */}
								{/* {isOpen === index && (
									<Stage
										setCloseModal={setCloseModal}
										patientUuid={patientId}
										setMedicalCaseUuid={setMedicalCaseUuid}
										setStageUuid={setStageUuid}
										setServiceId={setServiceId}
										setCreateVisit={setCreateVisit}
										medicalCaseId={medicalCaseId}
									/>
								)} */}
							</Card>
						))}
					</>
				)}
			</div>
			{chosenMedicalCase && (
				<div className='d-flex flex-column justify-content-center w-100'>
					<Stage
						setCloseModal={setCloseModal}
						patientUuid={patientId}
						setMedicalCaseUuid={setMedicalCaseUuid}
						setStageUuid={setStageUuid}
						setServiceId={setServiceId}
						setCreateVisit={setCreateVisit}
						medicalCaseId={medicalCaseId}
						setIsBackHidden={setHidePagination}
						stageId={stageId}
						setStageId={setStageId}
						setAndLogServices={setAndLogServices}
						formRef={formRef}
						setValues={setValues}
						values={values}
						setIsNoServices={setIsNoServices}
						setChosenServicesCount={setChosenServicesCount}
					/>
				</div>
			)}

			<>
				{medicalCases?.meta?.count > perPage && !isHidePagination && (
					<div className='w-100 d-flex justify-content-end'>
						<PaginationButtons
							data={medicalCases || []}
							label='customers'
							medicalCase
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
							perPage={perPage}
							setPerPage={setPerPage}
							totalPage={Math.ceil(medicalCases?.meta?.count / perPage) || 3}
							className='mx-2 mt-2'
						/>
					</div>
				)}
				{(isPatientChoosen || chosenMedicalCase || stageId) && (
					<div className='d-flex justify-content-between w-100'>
						<Button
							className='fw-bold fs-6'
							color='link'
							isLight
							style={{ color: '#0D6EFD' }}
							onClick={() => {
								if (chosenMedicalCase) {
									setChosenMedicalCase(null);
									medicalCases?.meta?.count > perPage && setHidePagination(false);
								} else if (setIsPatientChoosen) {
									setIsPatientChoosen(false);
								} else if (stageId) {
									setStageId('');
									setHidePagination(false);
								}
							}}>
							Назад
						</Button>
						<Button
							className='fw-bold fs-6'
							color='info'
							isOutline={isDisabledForwardBtn}
							isDisable={isDisabledForwardBtn}
							onClick={() => (isDisabledForwardBtn ? null : handleForwardClick())}>
							Вперед
						</Button>
					</div>
				)}
			</>

			<MedicalCaseModal
				setIsOpen={setModalMedicalCase}
				isOpen={modalMedicalCase}
				patient={patient}
			/>
		</div>
	);
};

export default ChooseMedicalCase;
