import { TColor } from '../../../type/color-type';
import SERVICES, { IServiceProps } from '../serviceDummyData';
import BRANCHES, { IBranchProps } from './branchData';
import UserImage from '../../../assets/img/wanna/wanna1.png';
import UserImageWebp from '../../../assets/img/wanna/wanna1.webp';

export interface IUserProps {
	id: string;
	name: string;
	surname: string;
	username: string;
	email: string;
	password: string;
	branch: IBranchProps;
	src: string;
	srcSet: string;
	position: string;
	isOnline: boolean;
	isReply?: boolean;
	color: TColor;
	fullImage?: string;
	services?: IServiceProps[];
}
const john: IUserProps = {
	id: '1',
	name: 'Смбат',
	surname: 'Варданян',
	username: 'john',
	email: 'test@gmail.com',
	password: '123456',
	branch: BRANCHES.FIRST,
	position: 'string',
	src: UserImage,
	srcSet: UserImageWebp,
	isOnline: true,
	isReply: true,
	color: 'primary',
	services: [SERVICES.SURFING, SERVICES.HANDBALL],
};

const grace: IUserProps = {
	id: '2',
	name: 'Лусине',
	surname: 'Арутюнян',
	username: 'grace',
	email: 'test1@gmail.com',
	password: '1234567',
	branch: BRANCHES.SECOND,
	position: 'string',
	src: UserImage,
	srcSet: UserImageWebp,
	isOnline: true,
	isReply: true,
	color: 'primary',
	services: [SERVICES.SURFING, SERVICES.HANDBALL],
};
const USERS: { [key: string]: IUserProps } = {
	JOHN: john,
	GRACE: grace,
};

export function getUserDataWithUsername(username: string): IUserProps {
	// @ts-ignore
	return USERS[Object.keys(USERS).filter((f) => USERS[f].username.toString() === username)];
}
export function getUserDataWithEmail(email: string): IUserProps {
	// @ts-ignore
	return USERS[Object.keys(USERS).filter((f) => USERS[f].email.toString() === email)];
}

export function getUserDataWithId(id?: string): IUserProps {
	// @ts-ignore
	return USERS[Object.keys(USERS).filter((f) => USERS[f].id.toString() === id.toString())];
}

export default USERS;
