/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-cycle */
import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import contents from '../../routes/contentRoutes';
import VisitsPage from '../../custom/pages/visit/VisitsPage';
import PatientsPage from '../../custom/pages/patients/PatientsPage';

// const PAGE_404 = lazy(() => import('../../pages/presentation/auth/Page404'));
const ContentRoutes = () => {
	return (
		<Routes>
			{contents.map((page) => (
				// eslint-disable-next-line react/jsx-props-no-spreading
				<Route key={page.path} {...page} />
			))}
			<Route path='/' element={<VisitsPage />} />
			<Route path='/patients' element={<PatientsPage />} />
		</Routes>
	);
};

export default ContentRoutes;
