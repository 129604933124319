import React, { SVGProps } from "react";

export const THEETH_26 = (props: SVGProps<SVGSVGElement>) => {

    return (
		<>
           
           <svg width="2.53rem" height="5.15rem" viewBox="0 0 33 67" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M22.4636 1.08383C11.6635 8.28355 7.93814 41.3662 8.96349 57.7835C9.22976 64.052 25.0052 46.3761 24.7065 38.7637C24.2106 26.1258 24.7065 25.2637 25.1572 6.48381C24.6416 3.88146 23.7061 0.255482 22.4636 1.08383Z" fill="url(#paint0_linear_402_4959)"/>
<g filter="url(#filter0_i_402_4959)">
<path d="M30.1068 5.91362C36.8567 14.0136 27.8567 22.1136 28.3381 51.1408C22.5744 55.5619 8.80676 64.1375 2.39615 56.2554C1.76786 48.4882 -1.84402 7.71362 13.906 6.36362C17.8311 6.02718 9.85598 20.3136 15.7684 32.4636C21.1067 26.1636 26.5067 2.76363 30.1068 5.91362Z" fill="url(#paint1_linear_402_4959)"/>
</g>
<path d="M31.8888 61.8268C29.7288 69.7468 18.3888 65.3135 16.6451 61.8268C15.2952 64.0768 9.445 66.3269 7.195 66.3269C4.945 66.3269 -0.904991 64.5269 1.34501 53.7269C3.14501 45.0869 10.2888 42.3635 16.6451 45.0635C28.2888 41.4635 34.5888 51.9268 31.8888 61.8268Z" fill="url(#paint2_linear_402_4959)"/>
<path d="M31.8888 61.8268C29.7288 69.7468 18.3888 65.3135 16.6451 61.8268C15.2952 64.0768 9.445 66.3269 7.195 66.3269C4.945 66.3269 -0.904991 64.5269 1.34501 53.7269C3.14501 45.0869 10.2888 42.3635 16.6451 45.0635C28.2888 41.4635 34.5888 51.9268 31.8888 61.8268Z" fill="url(#paint3_radial_402_4959)" fill-opacity="0.3"/>
<defs>
<filter id="filter0_i_402_4959" x="-1.19186" y="2.83354" width="33.8188" height="56.6301" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-5.4" dy="-3.6"/>
<feGaussianBlur stdDeviation="1.395"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_402_4959"/>
</filter>
<linearGradient id="paint0_linear_402_4959" x1="16.9788" y1="59.1038" x2="16.9788" y2="0.963467" gradientUnits="userSpaceOnUse">
<stop stop-color="#5C472A"/>
<stop offset="1" stop-color="#685233"/>
</linearGradient>
<linearGradient id="paint1_linear_402_4959" x1="17.1125" y1="59.4636" x2="17.1125" y2="5.62347" gradientUnits="userSpaceOnUse">
<stop stop-color="#D29439"/>
<stop offset="1" stop-color="#887250"/>
</linearGradient>
<linearGradient id="paint2_linear_402_4959" x1="16.6741" y1="66.3269" x2="16.6741" y2="44.0629" gradientUnits="userSpaceOnUse">
<stop offset="0.64" stop-color="white"/>
<stop offset="1" stop-color="#B48B8B"/>
</linearGradient>
<radialGradient id="paint3_radial_402_4959" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(11.4768 54.9633) rotate(138.814) scale(4.78357 6.33985)">
<stop stop-color="white" stop-opacity="0"/>
<stop offset="1" stop-color="#9B9B9B"/>
</radialGradient>
</defs>
</svg>

        </>
	);
};