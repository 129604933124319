/* eslint-disable array-callback-return */
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardFooterLeft,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import Input from '../../../../components/bootstrap/forms/Input';
import Textarea from '../../../../components/bootstrap/forms/Textarea';
import Button from '../../../../components/bootstrap/Button';
// import { MedicalDropdown } from '../../UI/Dropdown';
// import { InputWithTitle } from '../../UI/Inputs';
// import { Checkbox, CheckboxWithPrice } from '../../UI/Checkboxes';
import Icon from '../../../../components/icon/Icon';
// import HighlightedTitle from '../../UI/HighlightedTitle';
import DentalContext from '../../../../contexts/dentalContext';
import Dropdown, {
	DropdownToggle,
	DropdownItem,
	DropdownMenu,
} from '../../../../components/bootstrap/Dropdown';
import _, { cloneDeep } from 'lodash';
import { notification } from 'antd';
import type { NotificationArgsProps } from 'antd';
import DentalContextFunctions from '../../../../contexts/dentalContextFunctions';
import HighlightedTitle from '../../medicalCard/medicalCaseComponents/UI/HighlightedTitle';
type ItemType = 'bridge' | 'referral' | 'implant' | 'event' | 'deletion' | 'milk_theeth' | 'empty';

interface ITheethDropdownProps {
	index: number;
	data: any;
	type: ItemType;
	dentalData?: any;
	local_data: any;
	disable?: boolean;
	setLocalData: any;
	all_data: any;
	setData: any;
	onSelect?: any;
}

export const TheethDropdown: FC<ITheethDropdownProps> = ({
	index,
	data,
	setData,
	disable,
	all_data,
	local_data,
	setLocalData,
	type,
	dentalData,
	onSelect,
}) => {
	const {
		bridge_default,
		formData,
		selectMany,
		setSelectMany,
		setSelectedItems,
		setIntermediateItems,
		setFormData,
		dentalFormula,
		setDentalFormula,
	} = useContext(DentalContext);
	const {
		generateSequence,
		contextHolder,
		checkBridgeOverlap,
		localFormData,
		setLocalFormData,
		localDentalFormula,
		setLocalDentalFormula,
		checkDirection,
		isModified,
		setIsModified,
	} = useContext(DentalContextFunctions);
	const [disableDirection, setDisableDirection] = useState<string>('');
	// const [isModified, setIsModified] = useState(false);
	const [isOpen, setIsOpen] = useState<boolean>(false);

	
	const handleTeethUpdate = (
		value: number | string,
		index: number,
		direction: string,
		teethIndex: number,
		type: ItemType,
	) => {
		if (type === 'bridge') {
			addBridgeTeeth(value, index, direction);
		} else {
			updateTeethData(value, index, direction, teethIndex, type);
		}
	};

	const updateTeethData = (
		value: number | string,
		index: number,
		direction: string,
		teethIndex: number,
		type: ItemType,
	) => {
		// Validate formData and check for valid type and index

		// Assuming now formData is an array of objects, each having a "theeth" or "start" and "end" property
		const directionIndex =
			value.toString()[0] === '1' || value.toString() === '2' ? 'top' : 'bottom';

		// Reset the type property of the current item in the dental formula
		const currentTeethIndex = dentalFormula[directionIndex]?.findIndex(
			(item: any) => item.id === all_data[index]?.theeth,
		);

		if (currentTeethIndex !== -1) {
			dentalFormula[directionIndex][currentTeethIndex] = {
				...dentalFormula[directionIndex][currentTeethIndex],
				[type]: false,
			};
		}

		// Update the item in all_data
		const updatedFormData = [...all_data];
		updatedFormData[index] = {
			...updatedFormData[index],
			theeth: value,
		};

		// Update the dental formula
		const updatedDentalFormula = [...dentalFormula[direction]];
		updatedDentalFormula[teethIndex] = {
			...updatedDentalFormula[teethIndex],
			[type]: true,
		};

		// Update the state with the new values
		setDentalFormula((prev: any) => ({
			...prev,
			[direction]: updatedDentalFormula,
		}));

		setData([...updatedFormData]);

		setIsModified(true);
	};

	const addBridgeTeeth = (value: number | string, index: number, direction: string) => {
		setLocalData((prev: any) => {
			const updatedBridge = [...prev];
			const bridge = { ...updatedBridge[index] };

			if (!bridge.start && !bridge.end) {
				bridge.start = String(value);
			} else if (bridge.start && !bridge.end) {
				if (String(value) === bridge.start) {
					return prev; // No change if the selected tooth is the same as the start
				}
				if (!checkBridgeOverlap(bridge.start, String(value), direction, index)) {
					bridge.end = String(value);

					const startIdx = localDentalFormula[direction].findIndex(
						(item: any) => item.id === bridge.start,
					);
					const endIdx = localDentalFormula[direction].findIndex(
						(item: any) => item.id === bridge.end,
					);

					if (startIdx > -1 && endIdx > -1) {
						const sequence = generateSequence(bridge.start, bridge.end, direction);
						const updatedFormula = localDentalFormula[direction].map((tooth: any) => {
							if (tooth.id === bridge.start) {
								return { ...tooth, bridge: true, bridge_position: 'start' };
							} else if (tooth.id === bridge.end) {
								return { ...tooth, bridge: true, bridge_position: 'end' };
							} else if (sequence.includes(tooth.id)) {
								return { ...tooth, bridge: true, bridge_position: 'center' };
							}
							return tooth;
						});

						setLocalDentalFormula((prev: any) => ({
							...prev,
							[direction]: updatedFormula,
						}));
					} else {
						bridge.start = '';
						bridge.end = '';
					}
				} else {
					bridge.start = '';
					bridge.end = '';
				}
			}

			updatedBridge[index] = bridge;
			return [...updatedBridge];
		});

		setIsModified(true);
	};

	const cancel = (type: ItemType, index: number, direction: string) => {
		// Clone dentalFormula to avoid direct mutation
		if (type !== 'bridge') {
			const updatedDentalFormula = { ...dentalFormula };

			if (direction) {
				const toothIndex = updatedDentalFormula[direction].findIndex(
					(item: any) => item.id === formData.implant[index].theeth,
				);

				if (toothIndex !== -1) {
					updatedDentalFormula[direction][toothIndex] = {
						...updatedDentalFormula[direction][toothIndex],
						implant: false,
					};
				}

				setDentalFormula((prev: any) => ({
					...prev,
					[direction]: updatedDentalFormula[direction],
				}));
			}

			const updatedFormData = [...all_data];

			updatedFormData[index] = {
				...updatedFormData[index],
				theeth: '',
			};

			setData((prev: any) => [...prev, ...updatedFormData]);
		} else {
			setLocalData(all_data);
			setLocalDentalFormula(dentalFormula);
			setIsModified(false);
		}
		setIsModified(false);
	};

	const save = (type: ItemType, index: number, direction: string) => {
		if (type !== 'bridge') {
			if (direction) {
				dentalFormula[direction][
					dentalFormula[direction].findIndex(
						(item: any) => item.id === all_data[index].theeth,
					)
				] = {
					...dentalFormula[direction][
						dentalFormula[direction].findIndex(
							(item: any) => item.id === all_data[index].theeth,
						)
					],
					[type]: true,
				};
				setDentalFormula((prev: any) => ({
					...prev,
					[direction]: dentalFormula[direction],
				}));
				setLocalData([...all_data]);
			}
		} else {
			setData([...local_data]);
			setDentalFormula(localDentalFormula);
		}

		setIsModified(false);
	};

	const [openDropdown, setOpenDropdown] = useState<{ [key: number]: boolean }>({});
	const [dropIndex, setDropIndex] = useState<number | null>(null);

	// const [formComponentData, setFormComponentDtat] = useState();
	// const [dentalComponentData, setDentalComponentDtat] = useState();

	const classColor = (data_check: any, data_item_check: any, direction: string, type: string) => {
		// // console.log(data_check, '8974561651');
		if (
			type === 'bridge' &&
			data_check.start &&
			data_check.end &&
			generateSequence(data_check.start, data_check.end).includes(data_item_check.id)
		) {
			return 'bg-info';
		} else if (
			type !== 'bridge' &&
			(data?.theeth === data_item_check?.id || data_item_check?.[type])
		) {
			return 'bg-info';
		} else {
			return '';
		}
	};

	const disableButton = (data_item_check: any, type: string, direction: string) => {
		if (data_item_check[type] || disableDirection === direction) {
			return true;
		} else {
			return false;
		}
	};

	const styleButton = (data_check: any, data_item_check: any) => {
		if (
			type === 'bridge' &&
			(data_check?.start === data_item_check?.id || data_check?.end === data_item_check?.id)
		) {
			return {
				backgroundColor: '#19ABED',
				border: 'none',
			};
		} else {
			return {
				backgroundColor: '#FFFFF',
				border: '2px solid #0D6EFD',
			};
		}
	};

	const textButton = (data_check: any, data_item_check: any, type: string, direction: string) => {
		if (
			(type === 'bridge' &&
				(data_check.start === data_item_check.id ||
					data_check.end === data_item_check.id ||
					generateSequence(data_check.start, data_check.end, direction).includes(
						data_item_check.id,
					)) &&
				data_item_check?.[type]) ||
			data_check.start === data_item_check.id ||
			data_check.end === data_item_check.id
		) {
			return 'text-light';
		} else if (
			type !== 'bridge' &&
			(data_check?.theeth === data_item_check?.id || data_item_check?.implant)
		) {
			return 'text-light';
		} else {
			return 'text-dark';
		}
	};

	return (
		<div style={{ position: 'relative' }}>
			<HighlightedTitle title={'*Зуб'} />

			<Dropdown
				isOpen={disable ? false : isOpen}
				setIsOpen={setIsOpen}
				className='border rounded-1'>
				<DropdownToggle>
					<Button
						className='m-0 d-flex justify-content-center gap-2 align-items-center'
						isLight>
						{data?.start && data?.end ? (
							<>
								<div
									className='d-flex p-2 align-items-center justify-content-center'
									style={{
										width: 20,
										height: 20,
										borderRadius: 3,
										backgroundColor: '#19ABED',
									}}>
									<p className='m-0 fs-6 text-light'>{data.start}</p>
								</div>

								{generateSequence(data.start, data.end)?.map((item: any) => (
									<div
										key={item}
										className='d-flex p-2 align-items-center justify-content-center bg-info'
										style={{
											width: 20,
											height: 20,
											borderRadius: 3,
										}}>
										<p className='m-0 fs-6 text-light'>{item}</p>
									</div>
								))}

								<div
									className='d-flex p-2 align-items-center justify-content-center'
									style={{
										width: 20,
										height: 20,
										borderRadius: 3,
										backgroundColor: '#19ABED',
									}}>
									<p className='m-0 fs-6 text-light'>{data.end}</p>
								</div>
							</>
						) : data?.theeth && !data.start && !data.end ? (
							<div
								className='d-flex p-2 align-items-center justify-content-center bg-info'
								style={{
									width: 20,
									height: 20,
									borderRadius: 3,
								}}>
								<p className='m-0 fs-6 text-light'>{data.theeth}</p>
							</div>
						) : (
							'Зуб'
						)}
					</Button>
				</DropdownToggle>
				<DropdownMenu
					aria-disabled={disable}
					style={{ border: '1px solid #E7E7E7' }}
					isAlignmentEnd>
					<div className=' d-flex align-items-center justify-content-center flex-column gap-2 p-1'>
						<div className='col-12 d-flex gap-2'>
							{dentalFormula?.top?.map(
								(theeth_top: any, theeth_index_top: number) => (
									<Button
										isOutline={false}
										isDisable={disableButton(theeth_top, type, 'bottom')}
										onClick={(e) => {
											handleTeethUpdate(
												theeth_top?.id,
												index,
												'top',
												theeth_index_top,
												type,
											);
											if (onSelect) onSelect(theeth_top?.id);
											setDisableDirection('top');
										}}
										className='m-0 d-flex p-2 border-0 justify-content-center align-items-center'
										isLight>
										<div
											className={`d-flex p-2 align-items-center justify-content-center ${classColor(
												data,
												theeth_top,
												'top',
												type,
											)}`}
											style={{
												width: 25,
												height: 25,
												borderRadius: 3,
												backgroundColor: styleButton(data, theeth_top)
													.backgroundColor,
												border: styleButton(data, theeth_top).border,
											}}>
											<p
												className={`m-0 fs-6  ${textButton(
													data,
													theeth_top,
													type,
													'top',
												)}`}>
												{theeth_top?.id}
											</p>
										</div>
									</Button>
								),
							)}
						</div>
						<div className='col-12 d-flex gap-2'>
							{dentalFormula?.bottom?.map(
								(theeth_bottom: any, theeth_index_bottom: number) => (
									<Button
										isOutline={false}
										isDisable={disableButton(theeth_bottom, type, 'top')}
										onClick={(e) => {
											handleTeethUpdate(
												theeth_bottom?.id,
												index,
												'bottom',
												theeth_index_bottom,
												type,
											);
											if (onSelect) onSelect(theeth_bottom?.id);
											setDisableDirection('bottom');
										}}
										className='m-0 d-flex p-2 border-0 justify-content-center align-items-center'
										isLight>
										<div
											className={`d-flex p-2 align-items-center justify-content-center ${classColor(
												data,
												theeth_bottom,
												'bottom',
												type,
											)}`}
											style={{
												width: 25,
												height: 25,
												borderRadius: 3,
												backgroundColor: styleButton(data, theeth_bottom)
													.backgroundColor,
												border: styleButton(data, theeth_bottom).border,
											}}>
											<p
												className={`m-0 fs-6  ${textButton(
													data,
													theeth_bottom,
													type,
													'bottom',
												)}`}>
												{theeth_bottom?.id}
											</p>
										</div>
									</Button>
								),
							)}
						</div>
						{!onSelect && (
							<div className='col-12 d-flex justif gap-2'>
								<Button
									onClick={() => {
										save(type, index, disableDirection);
										setIsOpen(false);
										setDisableDirection('');
									}}
									color='success'
									isLight
									className='col'
									isDisable={!isModified}>
									Сохранить
								</Button>
								<Button
									onClick={() => {
										cancel(type, index, disableDirection);
										setIsOpen(false);
										setDisableDirection('');
									}}
									color='danger'
									isLight
									className='col'
									isDisable={!isModified}>
									Отменить
								</Button>
							</div>
						)}
					</div>
				</DropdownMenu>
			</Dropdown>
		</div>
	);
};
