import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { TC_TL } from '../assets/svg/TC_TL';
import { TC_TR } from '../assets/svg/TC_TR';
import { TC_BL } from '../assets/svg/TC_BL';
import { TC_BR } from '../assets/svg/TC_BR';
import { TC_C } from '../assets/svg/TC_C';
import { THEETH_18 } from '../assets/svg/THEETH_18';
import { THEETH_17 } from '../assets/svg/THEETH_17';
import { THEETH_16 } from '../assets/svg/THEETH_16';
import { THEETH_15 } from '../assets/svg/THEETH_15';
import { THEETH_14 } from '../assets/svg/THEETH_14';
import { THEETH_13 } from '../assets/svg/THEETH_13';
import { THEETH_12 } from '../assets/svg/THEETH_12';
import { THEETH_11 } from '../assets/svg/THEETH_11';
import { THEETH_21 } from '../assets/svg/THEETH_21';
import { THEETH_22 } from '../assets/svg/THEETH_22';
import { THEETH_23 } from '../assets/svg/THEETH_23';
import { THEETH_24 } from '../assets/svg/THEETH_24';
import { THEETH_25 } from '../assets/svg/THEETH_25';
import { THEETH_26 } from '../assets/svg/THEETH_26';
import { THEETH_27 } from '../assets/svg/THEETH_27';
import { THEETH_28 } from '../assets/svg/THEETH_28';
import { THEETH_48 } from '../assets/svg/THEETH_48';
import { THEETH_47 } from '../assets/svg/THEETH_47';
import { THEETH_46 } from '../assets/svg/THEETH_46';
import { THEETH_45 } from '../assets/svg/THEETH_45';
import { THEETH_44 } from '../assets/svg/THEETH_44';
import { THEETH_43 } from '../assets/svg/THEETH_43';
import { THEETH_42 } from '../assets/svg/THEETH_42';
import { THEETH_41 } from '../assets/svg/THEETH_41';
import { THEETH_31 } from '../assets/svg/THEETH_31';
import { THEETH_32 } from '../assets/svg/THEETH_32';
import { THEETH_33 } from '../assets/svg/THEETH_33';
import { THEETH_34 } from '../assets/svg/THEETH_34';
import { THEETH_35 } from '../assets/svg/THEETH_35';
import { THEETH_36 } from '../assets/svg/THEETH_36';
import { THEETH_37 } from '../assets/svg/THEETH_37';
import { THEETH_38 } from '../assets/svg/THEETH_38';
import { cloneDeep } from 'lodash';
import { title } from 'process';

export interface IDentalContextProps {
	formData: any;
	setFormData: any;
	manipulations: any;
	bridge_default: any;
	implant_default: any;
	dentalFormula: any;
	setDentalFormula: any;
	getObjectBasedOnId: any;
	event_default: any;
	referral_default: any;
	selectMany: any;
	setSelectMany: any;
	selectedItesm: any;
	setSelectedItems: any;
	intermediateItems: any;
	setIntermediateItems: any;
	default_dental_formula: any;
	default_form_data: any;
	empty_default: any;
	delation_default: any;
	milk_theeth_default: any;
}
const DentalContext = createContext<IDentalContextProps>({} as IDentalContextProps);

interface IDentalContextProviderProps {
	children: ReactNode;
} // Import necessary modules and types

// Define your DentalContext and related interfaces

const SurfaceSVG: FC<{ fill: string | undefined; surface: string }> = ({ fill, surface }) => {
	return (
		<>
			{surface === 'top_left' && <TC_TL fill={fill} />}
			{surface === 'top_right' && <TC_TR fill={fill} />}
			{surface === 'bottom_left' && <TC_BL fill={fill} />}
			{surface === 'bottom_right' && <TC_BR fill={fill} />}
			{surface === 'center' && <TC_C fill={fill} />}
		</>
	);
};

// Define your DentalContextProvider component
export const DentalContextProvider: FC<IDentalContextProviderProps> = ({ children }) => {
	const [selectMany, setSelectMany] = useState<boolean>(false);
	const [intermediateItems, setIntermediateItems] = useState<[]>([]);
	const [selectedItesm, setSelectedItems] = useState<[]>([]);
	const [color, setColor] = useState(undefined);

	// eslint-disable-next-line react-hooks/exhaustive-deps

	const [formData, setFormData] = useState({
		stage: {},
		complaint: '',
		isExamination: false,
		description: '',
		diagnosis_MKB_10: [],
		implant: [],
		bridge: [],
		isEvent: false,
		event: [],
		referral: [],
		empty: [],
		deletion: [],
		milk_theeth: [],
	});

	console.log(formData, 'asfassadfdsf');

	// eslint-disable-next-line react-hooks/exhaustive-deps

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const default_form_data = {
		stage: {},
		complaint: '',
		isExamination: false,
		description: '',
		diagnosis_MKB_10: [],
		implant: [],
		bridge: [],
		isEvent: false,
		event: [],
		referral: [],
		empty: [],
		deletion: [],
		milk_theeth: [],
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const default_dental_formula = {
		top: [
			{
				id: '18',
				transform: false,
				image: <THEETH_18 />,
				implant: false,
				event: false,
				referral: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				empty: false,
				milk_theeth: false,
				roots_count: '3',
				diagnostics: '',
				note: '',
				disease: {
					root: '',
					canal: '',
					gum: '',
					distal:[]
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' fill={color} />,
					top_right: <SurfaceSVG surface='top_right' fill={color} />,
					bottom_left: <SurfaceSVG surface='bottom_left' fill={color} />,
					bottom_right: <SurfaceSVG surface='bottom_right' fill={color} />,
					center: <SurfaceSVG surface='center' fill={color} />,
				},
			},
			{
				id: '17',
				image: <THEETH_17 />,
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				empty: false,
				milk_theeth: false,
				roots_count: '3',
				diagnostics: '',
				note: '',
				disease: {
					root: '',
					canal: '',
					gum: '',
					distal:[]
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' fill={color} />,
					top_right: <SurfaceSVG surface='top_right' fill={color} />,
					bottom_left: <SurfaceSVG surface='bottom_left' fill={color} />,
					bottom_right: <SurfaceSVG surface='bottom_right' fill={color} />,
					center: <SurfaceSVG surface='center' fill={color} />,
				},
			},
			{
				id: '16',
				image: <THEETH_16 />,
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				empty: false,
				milk_theeth: false,
				roots_count: '3',
				diagnostics: '',
				note: '',
				disease: {
					root: '',
					canal: '',
					gum: '',
					distal:[]
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' fill={color} />,
					top_right: <SurfaceSVG surface='top_right' fill={color} />,
					bottom_left: <SurfaceSVG surface='bottom_left' fill={color} />,
					bottom_right: <SurfaceSVG surface='bottom_right' fill={color} />,
					center: <SurfaceSVG surface='center' fill={color} />,
				},
			},
			{
				id: '15',
				image: <THEETH_15 />,
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				empty: false,
				milk_theeth: false,
				roots_count: '3',
				diagnostics: '',
				note: '',
				disease: {
					root: '',
					canal: '',
					gum: '',
					distal:[]
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' fill={color} />,
					top_right: <SurfaceSVG surface='top_right' fill={color} />,
					bottom_left: <SurfaceSVG surface='bottom_left' fill={color} />,
					bottom_right: <SurfaceSVG surface='bottom_right' fill={color} />,
					center: <SurfaceSVG surface='center' fill={color} />,
				},
			},
			{
				id: '14',
				image: <THEETH_14 />,
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				empty: false,
				milk_theeth: false,
				roots_count: '3',
				diagnostics: '',
				note: '',
				disease: {
					root: '',
					canal: '',
					gum: '',
					distal:[]
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' fill={color} />,
					top_right: <SurfaceSVG surface='top_right' fill={color} />,
					bottom_left: <SurfaceSVG surface='bottom_left' fill={color} />,
					bottom_right: <SurfaceSVG surface='bottom_right' fill={color} />,
					center: <SurfaceSVG surface='center' fill={color} />,
				},
			},
			{
				id: '13',
				image: <THEETH_13 />,
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				empty: false,
				milk_theeth: false,
				roots_count: '3',
				diagnostics: '',
				note: '',
				disease: {
					root: '',
					canal: '',
					gum: '',
					distal:[]
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' fill={color} />,
					top_right: <SurfaceSVG surface='top_right' fill={color} />,
					bottom_left: <SurfaceSVG surface='bottom_left' fill={color} />,
					bottom_right: <SurfaceSVG surface='bottom_right' fill={color} />,
					center: null,
				},
			},
			{
				id: '12',
				image: <THEETH_12 />,
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				empty: false,
				milk_theeth: false,
				roots_count: '3',
				diagnostics: '',
				note: '',
				disease: {
					root: '',
					canal: '',
					gum: '',
					distal:[]
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' fill={color} />,
					top_right: <SurfaceSVG surface='top_right' fill={color} />,
					bottom_left: <SurfaceSVG surface='bottom_left' fill={color} />,
					bottom_right: <SurfaceSVG surface='bottom_right' fill={color} />,
					center: null,
				},
			},
			{
				id: '11',
				image: <THEETH_11 />,
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				empty: false,
				milk_theeth: false,
				roots_count: '3',
				diagnostics: '',
				note: '',
				disease: {
					root: '',
					canal: '',
					gum: '',
					distal:[]
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' fill={color} />,
					top_right: <SurfaceSVG surface='top_right' fill={color} />,
					bottom_left: <SurfaceSVG surface='bottom_left' fill={color} />,
					bottom_right: <SurfaceSVG surface='bottom_right' fill={color} />,
					center: null,
				},
			},
			{
				id: '21',
				image: <THEETH_21 />,
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				empty: false,
				milk_theeth: false,
				roots_count: '3',
				diagnostics: '',
				note: '',
				disease: {
					root: '',
					canal: '',
					gum: '',
					distal:[]
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' fill={color} />,
					top_right: <SurfaceSVG surface='top_right' fill={color} />,
					bottom_left: <SurfaceSVG surface='bottom_left' fill={color} />,
					bottom_right: <SurfaceSVG surface='bottom_right' fill={color} />,
					center: null,
				},
			},
			{
				id: '22',
				image: <THEETH_22 />,
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				empty: false,
				milk_theeth: false,
				roots_count: '3',
				diagnostics: '',
				note: '',
				disease: {
					root: '',
					canal: '',
					gum: '',
					distal:[]
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' fill={color} />,
					top_right: <SurfaceSVG surface='top_right' fill={color} />,
					bottom_left: <SurfaceSVG surface='bottom_left' fill={color} />,
					bottom_right: <SurfaceSVG surface='bottom_right' fill={color} />,
					center: null,
				},
			},
			{
				id: '23',
				image: <THEETH_23 />,
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				empty: false,
				milk_theeth: false,
				roots_count: '3',
				diagnostics: '',
				note: '',
				disease: {
					root: '',
					canal: '',
					gum: '',
					distal:[]
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' fill={color} />,
					top_right: <SurfaceSVG surface='top_right' fill={color} />,
					bottom_left: <SurfaceSVG surface='bottom_left' fill={color} />,
					bottom_right: <SurfaceSVG surface='bottom_right' fill={color} />,
					center: null,
				},
			},
			{
				id: '24',
				image: <THEETH_24 />,
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				empty: false,
				milk_theeth: false,
				roots_count: '3',
				diagnostics: '',
				note: '',
				disease: {
					root: '',
					canal: '',
					gum: '',
					distal:[]
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' fill={color} />,
					top_right: <SurfaceSVG surface='top_right' fill={color} />,
					bottom_left: <SurfaceSVG surface='bottom_left' fill={color} />,
					bottom_right: <SurfaceSVG surface='bottom_right' fill={color} />,
					center: <SurfaceSVG surface='center' fill={color} />,
				},
			},
			{
				id: '25',
				image: <THEETH_25 />,
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				empty: false,
				milk_theeth: false,
				roots_count: '3',
				diagnostics: '',
				note: '',
				disease: {
					root: '',
					canal: '',
					gum: '',
					distal:[]
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' fill={color} />,
					top_right: <SurfaceSVG surface='top_right' fill={color} />,
					bottom_left: <SurfaceSVG surface='bottom_left' fill={color} />,
					bottom_right: <SurfaceSVG surface='bottom_right' fill={color} />,
					center: <SurfaceSVG surface='center' fill={color} />,
				},
			},
			{
				id: '26',
				image: <THEETH_26 />,
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				empty: false,
				milk_theeth: false,
				roots_count: '3',
				diagnostics: '',
				note: '',
				disease: {
					root: '',
					canal: '',
					gum: '',
					distal:[]
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' fill={color} />,
					top_right: <SurfaceSVG surface='top_right' fill={color} />,
					bottom_left: <SurfaceSVG surface='bottom_left' fill={color} />,
					bottom_right: <SurfaceSVG surface='bottom_right' fill={color} />,
					center: <SurfaceSVG surface='center' fill={color} />,
				},
			},
			{
				id: '27',
				image: <THEETH_27 />,
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				empty: false,
				milk_theeth: false,
				roots_count: '3',
				diagnostics: '',
				note: '',
				disease: {
					root: '',
					canal: '',
					gum: '',
					distal:[]
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' fill={color} />,
					top_right: <SurfaceSVG surface='top_right' fill={color} />,
					bottom_left: <SurfaceSVG surface='bottom_left' fill={color} />,
					bottom_right: <SurfaceSVG surface='bottom_right' fill={color} />,
					center: <SurfaceSVG surface='center' fill={color} />,
				},
			},
			{
				id: '28',
				image: <THEETH_28 />,
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				empty: false,
				milk_theeth: false,
				roots_count: '3',
				diagnostics: '',
				note: '',
				disease: {
					root: '',
					canal: '',
					gum: '',
					distal:[]
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' fill={color} />,
					top_right: <SurfaceSVG surface='top_right' fill={color} />,
					bottom_left: <SurfaceSVG surface='bottom_left' fill={color} />,
					bottom_right: <SurfaceSVG surface='bottom_right' fill={color} />,
					center: <SurfaceSVG surface='center' fill={color} />,
				},
			},
		],
		bottom: [
			{
				id: '38',
				image: <THEETH_38 />,
				implant: false,
				event: false,
				referral: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				empty: false,
				milk_theeth: false,
				roots_count: '3',
				diagnostics: '',
				note: '',
				disease: {
					root: '',
					canal: '',
					gum: '',
					distal:[]
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' fill={color} />,
					top_right: <SurfaceSVG surface='top_right' fill={color} />,
					bottom_left: <SurfaceSVG surface='bottom_left' fill={color} />,
					bottom_right: <SurfaceSVG surface='bottom_right' fill={color} />,
					center: <SurfaceSVG surface='center' fill={color} />,
				},
			},
			{
				id: '37',
				image: <THEETH_37 />,
				implant: false,
				event: false,
				referral: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				empty: false,
				milk_theeth: false,
				roots_count: '3',
				diagnostics: '',
				note: '',
				disease: {
					root: '',
					canal: '',
					gum: '',
					distal:[]
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' fill={color} />,
					top_right: <SurfaceSVG surface='top_right' fill={color} />,
					bottom_left: <SurfaceSVG surface='bottom_left' fill={color} />,
					bottom_right: <SurfaceSVG surface='bottom_right' fill={color} />,
					center: <SurfaceSVG surface='center' fill={color} />,
				},
			},
			{
				id: '36',
				image: <THEETH_36 />,
				implant: false,
				event: false,
				referral: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				empty: false,
				milk_theeth: false,
				roots_count: '3',
				diagnostics: '',
				note: '',
				disease: {
					root: '',
					canal: '',
					gum: '',
					distal:[]
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' fill={color} />,
					top_right: <SurfaceSVG surface='top_right' fill={color} />,
					bottom_left: <SurfaceSVG surface='bottom_left' fill={color} />,
					bottom_right: <SurfaceSVG surface='bottom_right' fill={color} />,
					center: <SurfaceSVG surface='center' fill={color} />,
				},
			},
			{
				id: '35',
				image: <THEETH_35 />,
				implant: false,
				event: false,
				referral: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				empty: false,
				milk_theeth: false,
				roots_count: '3',
				diagnostics: '',
				note: '',
				disease: {
					root: '',
					canal: '',
					gum: '',
					distal:[]
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' fill={color} />,
					top_right: <SurfaceSVG surface='top_right' fill={color} />,
					bottom_left: <SurfaceSVG surface='bottom_left' fill={color} />,
					bottom_right: <SurfaceSVG surface='bottom_right' fill={color} />,
					center: <SurfaceSVG surface='center' fill={color} />,
				},
			},
			{
				id: '34',
				image: <THEETH_34 />,
				implant: false,
				event: false,
				referral: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				empty: false,
				milk_theeth: false,
				roots_count: '3',
				diagnostics: '',
				note: '',
				disease: {
					root: '',
					canal: '',
					gum: '',
					distal:[]
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' fill={color} />,
					top_right: <SurfaceSVG surface='top_right' fill={color} />,
					bottom_left: <SurfaceSVG surface='bottom_left' fill={color} />,
					bottom_right: <SurfaceSVG surface='bottom_right' fill={color} />,
					center: <SurfaceSVG surface='center' fill={color} />,
				},
			},
			{
				id: '33',
				image: <THEETH_33 />,
				implant: false,
				event: false,
				referral: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				empty: false,
				milk_theeth: false,
				roots_count: '3',
				diagnostics: '',
				note: '',
				disease: {
					root: '',
					canal: '',
					gum: '',
					distal:[]
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' fill={color} />,
					top_right: <SurfaceSVG surface='top_right' fill={color} />,
					bottom_left: <SurfaceSVG surface='bottom_left' fill={color} />,
					bottom_right: <SurfaceSVG surface='bottom_right' fill={color} />,
					center: null,
				},
			},
			{
				id: '32',
				image: <THEETH_32 />,
				implant: false,
				event: false,
				referral: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				empty: false,
				milk_theeth: false,
				roots_count: '3',
				diagnostics: '',
				note: '',
				disease: {
					root: '',
					canal: '',
					gum: '',
					distal:[]
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' fill={color} />,
					top_right: <SurfaceSVG surface='top_right' fill={color} />,
					bottom_left: <SurfaceSVG surface='bottom_left' fill={color} />,
					bottom_right: <SurfaceSVG surface='bottom_right' fill={color} />,
					center: null,
				},
			},
			{
				id: '31',
				image: <THEETH_31 />,
				implant: false,
				event: false,
				referral: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				empty: false,
				milk_theeth: false,
				roots_count: '3',
				diagnostics: '',
				note: '',
				disease: {
					root: '',
					canal: '',
					gum: '',
					distal:[]
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' fill={color} />,
					top_right: <SurfaceSVG surface='top_right' fill={color} />,
					bottom_left: <SurfaceSVG surface='bottom_left' fill={color} />,
					bottom_right: <SurfaceSVG surface='bottom_right' fill={color} />,
					center: null,
				},
			},
			{
				id: '41',
				image: <THEETH_41 />,
				implant: false,
				event: false,
				referral: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				empty: false,
				milk_theeth: false,
				roots_count: '3',
				diagnostics: '',
				note: '',
				disease: {
					root: '',
					canal: '',
					gum: '',
					distal:[]
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' fill={color} />,
					top_right: <SurfaceSVG surface='top_right' fill={color} />,
					bottom_left: <SurfaceSVG surface='bottom_left' fill={color} />,
					bottom_right: <SurfaceSVG surface='bottom_right' fill={color} />,
					center: null,
				},
			},
			{
				id: '42',
				image: <THEETH_42 />,
				implant: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				empty: false,
				milk_theeth: false,
				roots_count: '3',
				diagnostics: '',
				note: '',
				disease: {
					root: '',
					canal: '',
					gum: '',
					distal:[]
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' fill={color} />,
					top_right: <SurfaceSVG surface='top_right' fill={color} />,
					bottom_left: <SurfaceSVG surface='bottom_left' fill={color} />,
					bottom_right: <SurfaceSVG surface='bottom_right' fill={color} />,
					center: null,
				},
			},
			{
				id: '43',
				image: <THEETH_43 />,
				implant: false,
				event: false,
				referral: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				empty: false,
				milk_theeth: false,
				roots_count: '3',
				diagnostics: '',
				note: '',
				disease: {
					root: '',
					canal: '',
					gum: '',
					distal:[]
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' fill={color} />,
					top_right: <SurfaceSVG surface='top_right' fill={color} />,
					bottom_left: <SurfaceSVG surface='bottom_left' fill={color} />,
					bottom_right: <SurfaceSVG surface='bottom_right' fill={color} />,
					center: null,
				},
			},
			{
				id: '44',
				image: <THEETH_44 />,
				implant: false,
				event: false,
				referral: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				empty: false,
				milk_theeth: false,
				roots_count: '3',
				diagnostics: '',
				note: '',
				disease: {
					root: '',
					canal: '',
					gum: '',
					distal:[]
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' fill={color} />,
					top_right: <SurfaceSVG surface='top_right' fill={color} />,
					bottom_left: <SurfaceSVG surface='bottom_left' fill={color} />,
					bottom_right: <SurfaceSVG surface='bottom_right' fill={color} />,
					center: <SurfaceSVG surface='center' fill={color} />,
				},
			},
			{
				id: '45',
				image: <THEETH_45 />,
				implant: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				empty: false,
				milk_theeth: false,
				roots_count: '3',
				diagnostics: '',
				note: '',
				disease: {
					root: '',
					canal: '',
					gum: '',
					distal:[]
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' fill={color} />,
					top_right: <SurfaceSVG surface='top_right' fill={color} />,
					bottom_left: <SurfaceSVG surface='bottom_left' fill={color} />,
					bottom_right: <SurfaceSVG surface='bottom_right' fill={color} />,
					center: <SurfaceSVG surface='center' fill={color} />,
				},
			},
			{
				id: '46',
				image: <THEETH_46 />,
				implant: false,
				event: false,
				referral: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				empty: false,
				milk_theeth: false,
				roots_count: '3',
				diagnostics: '',
				note: '',
				disease: {
					root: '',
					canal: '',
					gum: '',
					distal:[]
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' fill={color} />,
					top_right: <SurfaceSVG surface='top_right' fill={color} />,
					bottom_left: <SurfaceSVG surface='bottom_left' fill={color} />,
					bottom_right: <SurfaceSVG surface='bottom_right' fill={color} />,
					center: <SurfaceSVG surface='center' fill={color} />,
				},
			},
			{
				id: '47',
				image: <THEETH_47 />,
				implant: false,
				event: false,
				referral: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				empty: false,
				milk_theeth: false,
				roots_count: '3',
				diagnostics: '',
				note: '',
				disease: {
					root: '',
					canal: '',
					gum: '',
					distal:[]
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' fill={color} />,
					top_right: <SurfaceSVG surface='top_right' fill={color} />,
					bottom_left: <SurfaceSVG surface='bottom_left' fill={color} />,
					bottom_right: <SurfaceSVG surface='bottom_right' fill={color} />,
					center: <SurfaceSVG surface='center' fill={color} />,
				},
			},
			{
				id: '48',
				image: <THEETH_48 />,
				implant: false,
				event: false,
				referral: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				empty: false,
				milk_theeth: false,
				roots_count: '3',
				diagnostics: '',
				note: '',
				disease: {
					root: '',
					canal: '',
					gum: '',
					distal:[]
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' fill={color} />,
					top_right: <SurfaceSVG surface='top_right' fill={color} />,
					bottom_left: <SurfaceSVG surface='bottom_left' fill={color} />,
					bottom_right: <SurfaceSVG surface='bottom_right' fill={color} />,
					center: <SurfaceSVG surface='center' fill={color} />,
				},
			},
		],
	};

	const [dentalFormula, setDentalFormula] = useState(default_dental_formula);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const bridge_default = {
		start: '',
		end: '',
		restoration_type: 'Выберите тип реставрации',
		material: 'Выберите материал',
		fixation_type: 'Выберите тип фиксации',
		count: '1',
		price: '0',
		amount: '0',
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const empty_default = {
		theeth: '',
		description: '',
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const delation_default = {
		theeth: '',
		description: '',
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const milk_theeth_default = {
		theeth: '',
		description: '',
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const event_default = {
		title: '',
		serviceId: '',
		theeth: '',
		count: '1',
		price: '0',
		amount: '0',
		description: '',
		uuid: null,
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const referral_default = {
		theeth: '',
		description: '',
		direction: '',
		userUuid: undefined,
		doctorUuid: undefined,
		doctor_type: '',
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const implant_default = {
		theeth: '',
		amount: '',
		brand: '',
		implantName: '',
		size: '',

		prosthetics_restorations: {
			plug: {
				selected: false,
			},
			gum_former: {
				selected: false,
				price: '0',
			},
			temporary_teeth_with_abutment: {
				selected: false,
				price: '0',
			},
		},

		manipulation: false,
		manipulationData: [],
	};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const manipulations = {
		membrane: {
			name: 'Мембранная манипуляция',
			checked: false,
			data: [
				{
					title: null,
					checked: true,
					items: [
						{
							name: 'Коллаген',
							price: '0',
							checked: false,
						},
					],
				},
				{
					title: null,
					checked: true,
					items: [
						{
							name: 'Графт (Губка)',
							price: '0',
							checked: false,
						},
					],
				},
			],
		},
		bone: {
			name: 'Костная манипуляция',
			checked: false,
			column: true,
			data: [
				{
					title: 'Allo (Xeno)',
					checked: false,
					items: [
						{
							name: 'Боковая',
							price: '0',
							checked: false,
						},
						{
							name: 'Вертикальная',
							price: '0',
							checked: false,
						},
						{
							name: 'Синус',
							price: '0',
							checked: false,
						},
					],
				},
				{
					title: 'Auto',
					checked: false,
					items: [
						{
							name: 'Боковая',
							price: '0',
							checked: false,
						},
						{
							name: 'Вертикальная',
							price: '0',
							checked: false,
						},
						{
							name: 'Синус',
							price: '0',
							checked: false,
						},
					],
				},
			],
		},
		soft_tissue: {
			name: 'Мягкотканная манипуляция',
			checked: false,

			data: [
				{
					title: null,
					checked: true,
					items: [
						{
							name: 'Трансплантат',
							price: '0',
							checked: false,
						},
					],
				},
				{
					title: null,
					checked: true,
					items: [
						{
							name: 'Местными тканями',
							price: '0',
							checked: false,
						},
					],
				},
			],
		},
		default: {
			name: 'Выберите тип манипуляции',
		},
	};

	const getObjectBasedOnId = (arrayOfIds: any[], id: number | string) => {
		switch (true) {
			case arrayOfIds.includes(id):
				return {
					disabled: true,
					color: 'bg-info',
				};
			default:
				return {
					disabled: false,
					color: 'bg-default',
				};
		}
	};

	const value = useMemo(
		() => ({
			formData,
			manipulations,
			bridge_default,
			setFormData,
			implant_default,
			dentalFormula,
			setDentalFormula,
			getObjectBasedOnId,
			event_default,
			referral_default,
			selectMany,
			selectedItesm,
			setSelectedItems,
			setSelectMany,
			intermediateItems,
			setIntermediateItems,
			default_dental_formula,
			default_form_data,
			empty_default,
			delation_default,
			milk_theeth_default,
			setColor,
		}),
		[
			formData,
			manipulations,
			default_dental_formula,
			default_form_data,
			bridge_default,
			implant_default,
			dentalFormula,
			event_default,
			referral_default,
			selectMany,
			selectedItesm,
			intermediateItems,
			empty_default,
			delation_default,
			milk_theeth_default,
		],
	);

	return <DentalContext.Provider value={value}>{children}</DentalContext.Provider>;
};

// Define PropTypes for DentalContextProvider component

// Export DentalContextProvider component
DentalContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default DentalContext;
