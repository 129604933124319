import React, { SVGProps } from "react";

export const THEETH_18 = (props: SVGProps<SVGSVGElement>) => {

    return (
		<>
           <svg width="32" height="63" viewBox="0 0 32 63" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M8.0751 0.130337C19.9601 7.93185 22.7392 20.711 21.6108 38.5006C21.3178 45.2931 5.27816 49.2084 5.60688 40.9597C6.1526 27.2655 5.60688 26.3313 5.11084 5.98169C5.67826 3.16181 6.70771 -0.767246 8.0751 0.130337Z" fill="url(#paint0_linear_402_4971)"/>
<g filter="url(#filter0_i_402_4971)">
<path d="M2.14467 4.00073C0.110818 5.27186 5.62638 9.64802 2.14446 43.7532C8.54861 48.6655 23.846 58.1939 30.9689 49.436C31.667 40.8058 29.6108 11.0007 16.1108 5.00073C12.1108 3.22295 19.8911 24.5534 16.6108 27.5007C16.1894 15.0007 5.61084 -0.499268 2.14467 4.00073Z" fill="url(#paint1_linear_402_4971)"/>
</g>
<path d="M2.63118 57.0007C6.63115 61.5007 11.6307 58.501 13.6307 56.001C16.1307 56.001 20.5684 60.061 23.0684 60.061C25.5684 60.061 34.2771 57.3782 29.5684 46.061C26.6309 39.0007 17.6309 35.5007 14.6309 36.5007C-0.869415 38.0007 -2.53354 51.1904 2.63118 57.0007Z" fill="url(#paint2_linear_402_4971)"/>
<path d="M2.63118 57.0007C6.63115 61.5007 11.6307 58.501 13.6307 56.001C16.1307 56.001 20.5684 60.061 23.0684 60.061C25.5684 60.061 34.2771 57.3782 29.5684 46.061C26.6309 39.0007 17.6309 35.5007 14.6309 36.5007C-0.869415 38.0007 -2.53354 51.1904 2.63118 57.0007Z" fill="url(#paint3_radial_402_4971)" fill-opacity="0.3"/>
<g filter="url(#filter1_f_402_4971)">
<path d="M13.5 56L15.5 52.5L12.5 48L14.5 46" stroke="#140000"/>
</g>
<defs>
<filter id="filter0_i_402_4971" x="-1.41982" y="0.104834" width="32.5077" height="52.8959" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-8" dy="-8"/>
<feGaussianBlur stdDeviation="1.55"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_402_4971"/>
</filter>
<filter id="filter1_f_402_4971" x="5.85645" y="39.6465" width="16.231" height="22.6016" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_402_4971"/>
</filter>
<linearGradient id="paint0_linear_402_4971" x1="13.4762" y1="45.6138" x2="13.4762" y2="-8.7738e-05" gradientUnits="userSpaceOnUse">
<stop stop-color="#5C472A"/>
<stop offset="1" stop-color="#503E25"/>
</linearGradient>
<linearGradient id="paint1_linear_402_4971" x1="16.384" y1="53.0007" x2="16.384" y2="3.20494" gradientUnits="userSpaceOnUse">
<stop stop-color="#D29439"/>
<stop offset="1" stop-color="#887250"/>
</linearGradient>
<linearGradient id="paint2_linear_402_4971" x1="15.4709" y1="60.061" x2="15.4709" y2="36.3328" gradientUnits="userSpaceOnUse">
<stop offset="0.64" stop-color="white"/>
<stop offset="1" stop-color="#B48B8B"/>
</linearGradient>
<radialGradient id="paint3_radial_402_4971" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(24.7975 51.02) rotate(-67.244) scale(3.41171 4.72905)">
<stop stop-color="white" stop-opacity="0"/>
<stop offset="1" stop-color="#9B9B9B"/>
</radialGradient>
</defs>
</svg>


        </>
	);
};