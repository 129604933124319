import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import { FormikHelpers, useFormik } from 'formik';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Button from '../../../components/bootstrap/Button';
import Icon from '../../../components/icon/Icon';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from '../../../components/bootstrap/OffCanvas';

import Badge from '../../../components/bootstrap/Badge';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Textarea from '../../../components/bootstrap/forms/Textarea';
import Checks from '../../../components/bootstrap/forms/Checks';
import Popovers from '../../../components/bootstrap/Popovers';
import data from '../../../common/data/dummyEventsData';
import EVENT_STATUS from '../../../common/data/enumEventStatus';
import Avatar from '../../../components/Avatar';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../../components/bootstrap/Modal';
import PaginationButtons, {
	PER_COUNT,
	PER_COUNT_FILTER,
} from '../../../components/PaginationButtons';

import CancelVisitModal from './CancelVisitModal';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import * as qs from 'qs';
import UserImage3 from '../../../assets/img/wanna/wanna3.png';
import { VisitService } from '../../../custom/api/service/visit.service';
import { IVisit } from '@textnpayme/custom/api/interface/visit.interface';
import { IUser } from '@textnpayme/custom/api/interface/user.interface';
import { UserService } from '../../../custom/api/service/user.service';
import { IService } from '@textnpayme/custom/api/interface/service.interface';
import { ServiceService } from '../../../custom/api/service/visit-service.service';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { useDebounce } from '../../../hooks/useDebounce';
import CreateVisitMedicalCard from '../medicalCard/CreateVisitMedicalCard';
import VisitDropdownActions from './VisitDropdownActions';
import useDarkMode from '../../../hooks/useDarkMode';
import ActModal from './ActModal';
import { medicalCardPages, profilePage } from '../../../menu';
import TimePickerModal from '../doctors/schedule/TimePickerModal';
import Tooltips from '../../../components/bootstrap/Tooltips';
import { IAct } from '@textnpayme/custom/api/interface/act.interface';
import { ActService } from '../../../custom/api/service/act.service';
// import ServiceChooseModal from '../services/ServiceChooseModal';
import TableComponent from '../../components/tables/TableComponent';

interface ICommonUpcomingEventsProps {
	isFluid?: boolean;
	setDateDay: (value: React.SetStateAction<string>) => void;
	setAllDayButton(...args: unknown[]): unknown;
	allDayButton?: any;
	dateDay?: any;
	formatDate?(date: Date): string;
	dateInterval?: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null;
	setDateInterval?: Dispatch<[Dayjs | null, Dayjs | null] | null>;
}

const VisitTable: FC<ICommonUpcomingEventsProps> = ({
	isFluid,
	setDateDay,
	dateDay,
	formatDate,
	dateInterval,
	allDayButton,
	setAllDayButton,
	setDateInterval,
}) => {
	const { themeStatus, darkModeStatus } = useDarkMode();
	const [upcomingEventsInfoOffcanvas, setUpcomingEventsInfoOffcanvas] = useState(false);
	const [upcomingEventsEditOffcanvas, setUpcomingEventsEditOffcanvas] = useState(false);
	const [actVisit, setActVisit] = useState<IVisit | null>(null);
	const [actModal, setActModal] = useState<boolean>(false);
	const [openCancelVisits, setOpenCancelVisits] = useState<boolean>(false);
	const [openFilterCode, setOpenFilterCode] = useState<boolean>(false);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [perPage, setPerPage] = useState<number>(PER_COUNT['10']);
	const [usersCurrentPage, setUsersCurrentPage] = useState<number>(1);
	const [usersPerPage, setUsersPerPage] = useState<number>(PER_COUNT_FILTER['5']);
	const [servicesCurrentPage, setServicesCurrentPage] = useState<number>(1);
	const [servicesPerPage, setServicesPerPage] = useState<number>(PER_COUNT_FILTER['5']);
	const [popperIsOpenCode, setPopperIsOpenCode] = useState(false);
	const [popperIsOpenService, setPopperIsOpenService] = useState(false);
	const [popperIsOpenUser, setPopperIsOpenUser] = useState(false);
	const [popperIsOpenStatus, setPopperIsOpenStatus] = useState(false);
	const [visitUuid, setVisitUuid] = useState<undefined | string>(undefined);
	const [createVisit, setCreateVisit] = useState(false);
	const [serviceUuid, setServiceUuid] = useState<null | []>(null);
	const [medicalCaseUuid, setMedicalCaseUuid] = useState<null | string>(null);
	const [clinicUuid, setClinicUuid] = useState<string | null>(null);
	const [stageUuid, setStageUuid] = useState<null | string>(null);

	const [updateVisitModal, setUpdateVisitModal] = useState<boolean>(false);
	const [patientId, setPatientId] = useState('');

	const [visitData, setVisitData] = useState([]);
	const [schedulModalOpen, setSchedulModalOpen] = useState(false);
	const [visitUpdate, setVisitUpdate] = useState<IVisit>();

	const [isValid, setIsValid] = useState(false);
	const [patientSearch, setPatientSearch] = useState('');

	const [toggleInfoEventCanvas, setToggleInfoEventCanvas] = useState(false);
	const setInfoEvent = () => setToggleInfoEventCanvas(!toggleInfoEventCanvas);
	const [eventAdding, setEventAdding] = useState(false);

	const [canvasData, setCanvasData] = useState<any>([]);

	const [searchIconClicked, setSearchIconClicked] = useState(false);

	const isLargeDesktop = useMediaQuery({ query: '(min-width: 1620px)' });
	const isSmallDesktop = useMediaQuery({ query: '(min-width: 1200px)' });
	const isTablet = useMediaQuery({ query: '(max-width: 1200px)' });

	const [isUserDoctor, setIsUserDoctor] = useState<boolean>(false);

	const validatePhoneNumber = (phoneNumber: string): void => {
		const phoneUtil = PhoneNumberUtil.getInstance();
		const number = phoneNumber.replace(/\+/g, '');
		try {
			const parsedNumber = phoneUtil.parse(number);
			setIsValid(phoneUtil.isValidNumber(parsedNumber));
		} catch (e) {
			const allRegions: string[] = phoneUtil.getSupportedRegions();
			let isValidForAnyRegion: boolean = false;
			for (const region of allRegions) {
				try {
					const parsedNumber = phoneUtil.parse(number, region);
					if (phoneUtil.isValidNumber(parsedNumber)) {
						isValidForAnyRegion = true;
						break;
					}
				} catch (e) {
					continue;
				}
			}
			setIsValid(isValidForAnyRegion);
		}
	};

	const { data: user_role } = useQuery(['user_role'], () =>
		UserService.getProfile(qs.stringify({})),
	);
	

	const isMainDoctorOrDoctor = user_role?.roles?.some(
		(role: any) => role?.role === 'mainDoctor' || role?.role === 'Doctor',
	);

	useEffect(() => {
		setIsUserDoctor(isMainDoctorOrDoctor);
	}, [isMainDoctorOrDoctor, user_role]);

	console.log(6666666, isUserDoctor);

	const [checkboxValue, setCheckboxValue] = useState<any[]>([]);

	const openModal = () => {
		setOpen(true);
	};
	const [updatedValues, setUpdatedValues] = useState({});

	const changeUpdatedValues = (key: string, value: string | number) => {
		setUpdatedValues((prev: any) => ({ ...prev, [key]: value }));
	};

	const [open, setOpen] = useState(false);

	// const { items, requestSort, getClassNamesFor } = useSortableData(data);

	const changeStatus = async (data: IVisit) => {
		await mutate(data);
	};

	const handleOpenCancel = () => {
		setOpenCancelVisits(!openCancelVisits);
	};

	const splitDateTime = (datetimeStr: any) => {
		// Split the datetime string into date and time components
		var dateTimeParts = datetimeStr?.split('T');
		var dateStr = dateTimeParts[0];
		var timeStr = dateTimeParts[1].split('.')[0]; // Remove milliseconds and 'Z'

		// Parse the date component
		var dateParts = dateStr.split('-');
		var year = parseInt(dateParts[0]);
		var month = parseInt(dateParts[1]) - 1; // Months are zero-indexed
		var day = parseInt(dateParts[2]);

		// Parse the time component
		var timeParts = timeStr.split(':');
		var hour = parseInt(timeParts[0]);
		var minute = parseInt(timeParts[1]);
		var second = parseInt(timeParts[2]);

		// Create Date objects for date and time
		var dateObj = new Date(year, month, day);
		var timeObj = new Date(0, 0, 0, hour, minute, second); // Date object initialized with time only

		return { date: dateObj, time: timeObj };
	};

	const queryClient = useQueryClient();

	const queryOptions = {
		onSuccess: (response: any, formData: any) => {
			queryClient.invalidateQueries('visits');
		},
		onError: (error: any) => {},
	};

	const { mutate } = useMutation(
		(data: IVisit) => VisitService.update(data.uuid, data),
		queryOptions,
	);

	const [values, setValues] = useState<IVisit>({
		users: [],
		services: [],
		statuses: [],
		order: 'DESC',
		visitId: undefined,
		serviceName: '',
		userName: '',
	});

	const DebounsedSearch = useDebounce(values);

	const changeValues = (key: string, value: string | number) => {
		if (key === 'services') {
			setValues((prev) => ({
				...prev,
				[key]: [...(values[key] || []), value], // Ensure values[key] is an array
			}));
		}
		if (key === 'users')
			setValues((prev) => ({ ...prev, userName: undefined, [key]: [...values[key], value] }));
		setValues((prev) => ({ ...prev, [key]: [...values[key], value] }));
	};
	const handleCheckboxChange = (e: any) => {
		const name = e.target.name;
		if (e.target.type === 'checkbox') {
			const isChecked = e.target.checked;
			if (isChecked) {
				// Check if the value is not already present
				if (!values[name].includes(e.target.defaultValue)) {
					changeValues(name, e.target.defaultValue);
				}
			} else {
				// Remove the value from checkboxValue
				setValues((prev) => ({
					...prev,
					[name]: prev[name].filter((value: string) => value !== e.target.defaultValue),
				}));
			}
		}

		if (e.target.type === 'button') {
			setValues((prev) => ({ ...prev, [name]: e.target.value }));
		}

		if (e.target.type === 'search') {
			if (name === 'visitId') {
				if (/^\d*$/.test(e.target.value)) {
					setValues((prev) => ({ ...prev, [name]: e.target.value }));
				}
			}
			if (name === 'serviceName') {
				setValues((prev) => ({ ...prev, [name]: e.target.value }));
			}
			if (name === 'userName') {
				setValues((prev) => ({ ...prev, [name]: e.target.value }));
			}
		}
	};

	const [query, setQuery] = useState({
		queryMeta: {
			paginate: true,
			limit: perPage,
			page: currentPage,
			order: { createdAt: values.order },
		},
		includeMeta: [
			{
				association: 'patient',
			},
			{
				association: 'services',
			},
		],
		filterMeta: {},
	});

	const [userParts, setUserParts] = useState<any>('');

	useEffect(() => {
		if (values?.userName?.includes('+')) {
			validatePhoneNumber(values?.userName);
		}
		if (values.userName) {
			setUserParts(values?.userName.split(' '));
		}
	}, [values?.userName]);
	const [patientParts, setPatientParts] = useState<any>('');

	useEffect(() => {
		if (patientSearch.includes('+')) {
			validatePhoneNumber(patientSearch);
		}
		const newParts = patientSearch.split(' ');
		setPatientParts(newParts);
	}, [patientSearch]);

	const DebiuncedPatinetSearch = useDebounce(patientSearch);

	const filterQuery = [
		{
			association: 'patient',
		},
		{
			association: 'services',
			required: true,
			...(values.services.length && { where: { uuid: values.services } }),
		},
		{
			association: 'services',
		},
		{
			association: 'canceledVisit',
		},
		{
			association: 'user',
			required: true,
			...(isUserDoctor && !!user_role && { where: { uuid: user_role?.uuid } }),
			...(!isUserDoctor && values.users.length && { where: { uuid: values.users } }),
		},
		{
			association: 'canceledVisit',
		},

		{
			association: 'medicalCase',
			include: [
				{
					association: 'stage',
				},
			],
		},

		{
			association: 'clinic',
		},
		{
			association: 'act',
		},
	];

	const filterQueryMeta = {
		...(patientSearch &&{websearchQuery: {searchVector: patientSearch}}),
		...(values.visitId && {
			or: [
				{ visitId: { iLike: `%${values.visitId}%` } },
				{ visitNumber: { iLike: `%${values.visitId}%` } },
			],
		}),
		...(values.statuses.length && { status: values.statuses }),
		visitDate: {
			...(!allDayButton &&
				(dateInterval && dateDay
					? {
							between: [
								`${dateInterval[0]?.format('YYYY-MM-DD')}T00:00:00.000Z`, // Start of the given date
								`${dateInterval[1]?.format('YYYY-MM-DD')}T23:59:59.999Z`, // End of the given date
							],
					  }
					: {
							between: [
								`${dateDay.date1}T00:00:00.000Z`, // Start of the given date
								`${dateDay.date1}T23:59:59.999Z`, // End of the given date
							],
					  })),
		},
	};

	const { isLoading, data: visits }: { data: IVisit[] | any; isLoading: boolean } = useQuery(
		[
			'visits',
			currentPage,
			perPage,
			DebounsedSearch,
			DebiuncedPatinetSearch,
			dateDay,
			allDayButton,
			dateInterval,
			isUserDoctor,
		],
		() =>
			VisitService.findAll(
				qs.stringify({
					includeMeta: filterQuery,
					filterMeta: filterQueryMeta,
					queryMeta: {
						paginate: true,
						limit: perPage,
						page: currentPage,
						order: { createdAt: values.order },
					},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	const { data: actAmount }: { data: IAct | any; isLoading: boolean } = useQuery(
		[
			'actAmount',
			DebounsedSearch,
			DebiuncedPatinetSearch,
			dateDay,
			allDayButton,
			dateInterval,
			isUserDoctor,
		],
		() =>
			ActService.actFilterCount(
				qs.stringify({
					includeMeta: [
						{
							association: 'visit',
							include: filterQuery,
							where: filterQueryMeta,
						},
					],
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	console.log('actAmount', actAmount?.data);

	const { data: usersCount }: { data: IUser[] | any; isLoading: boolean } = useQuery(
		['usersCount', DebounsedSearch],
		() =>
			UserService.findAll(
				qs.stringify({
					queryMeta: {
						order: { createdAt: 'DESC' },
					},

					includeMeta: [
						{
							association: 'roles',
							include: [
								{
									association: 'clinics',
								},
							],
						},
						{
							...(values.userName &&
								/\d/.test(values?.userName) &&
								!isValid && {
									association: 'passport',
									required: true,
									where: {
										or: [
											{
												passportNumber: {
													iLike: `%${values.userName}%`,
												},
											},
											{
												ssCardId: {
													iLike: `%${values.userName}%`,
												},
											},
										],
									},
								}),
						},
					],
					filterMeta: {
						...(values.userName &&
							/\d/.test(values?.userName) &&
							isValid && { phone: { iLike: `%${values.userName}%` } }),
						...(values.userName &&
							!/\d/.test(values?.userName) &&
							!isValid &&
							((userParts.length === 1 && {
								name: { iLike: `%${userParts[0]}%` },
							}) ||
								(userParts.length === 2 && {
									and: [
										{ name: { iLike: `%${userParts[0]}%` } },
										{ surname: { iLike: `%${userParts[1]}%` } },
									],
								}) ||
								(userParts.length === 3 && {
									and: [
										{ name: { iLike: `%${userParts[0]}%` } },
										{ surname: { iLike: `%${userParts[1]}%` } },
										{ middleName: { iLike: `%${userParts[2]}%` } },
									],
								}))),
					},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	const { data: users }: { data: IUser[] | any; isLoading: boolean } = useQuery(
		['users', DebounsedSearch, usersCurrentPage, usersPerPage],
		() =>
			UserService.findAll(
				qs.stringify({
					queryMeta: {
						paginate: true,
						limit: usersPerPage,
						page: DebounsedSearch?.userName?.length ? 1 : usersCurrentPage,
						order: { createdAt: 'DESC' },
					},

					includeMeta: [
						{
							association: 'roles',
							required: true,

							where: {
								role: ['mainDoctor', 'doctor'],
							},
						},
						{
							...(values.userName &&
								/\d/.test(values?.userName) &&
								!isValid && {
									association: 'passport',
									required: true,
									where: {
										or: [
											{
												passportNumber: {
													iLike: `%${values.userName}%`,
												},
											},
											{
												ssCardId: {
													iLike: `%${values.userName}%`,
												},
											},
										],
									},
								}),
						},
					],
					filterMeta: {
						...(values.userName &&
							/\d/.test(values?.userName) &&
							isValid && { phone: { iLike: `%${values.userName}%` } }),
						...(values.userName &&
							!/\d/.test(values?.userName) &&
							!isValid &&
							((userParts.length === 1 && {
								name: { iLike: `%${userParts[0]}%` },
							}) ||
								(userParts.length === 2 && {
									and: [
										{ name: { iLike: `%${userParts[0]}%` } },
										{ surname: { iLike: `%${userParts[1]}%` } },
									],
								}) ||
								(userParts.length === 3 && {
									and: [
										{ name: { iLike: `%${userParts[0]}%` } },
										{ surname: { iLike: `%${userParts[1]}%` } },
										{ middleName: { iLike: `%${userParts[2]}%` } },
									],
								}))),
					},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	const { data: services }: { data: IService[] | any; isLoading: boolean } = useQuery(
		['services', DebounsedSearch, servicesCurrentPage, servicesPerPage],
		() =>
			ServiceService.findAll(
				qs.stringify({
					queryMeta: {
						paginate: true,
						limit: servicesPerPage,
						page: DebounsedSearch?.serviceName?.length ? 1 : servicesCurrentPage,
						order: { createdAt: 'DESC' },
					},

					filterMeta: {
						...(values.serviceName && {
							...(values.serviceName && /\d/.test(values.serviceName)
								? { serviceId: { iLike: `%${values.serviceName}%` } }
								: { title: { iLike: `%${values.serviceName}%` } }),
						}),
					},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	const { data: servicesCount }: { data: IService[] | any; isLoading: boolean } = useQuery(
		['servicesCount', DebounsedSearch],
		() =>
			ServiceService.findAll(
				qs.stringify({
					queryMeta: {
						order: { createdAt: 'DESC' },
					},

					filterMeta: {
						...(values.serviceName && {
							...(values.serviceName && /\d/.test(values.serviceName)
								? { serviceId: { iLike: `%${values.serviceName}%` } }
								: { title: { iLike: `%${values.serviceName}%` } }),
						}),
					},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	const VisitStatusStyles = (status: any) => {
		switch (status) {
			case EVENT_STATUS.PENDING.name:
				return {
					dateStyles: {
						backgroundColor: 'rgba(255, 193, 7, 0.3)',
						borderColor: 'rgba(255, 193, 7, 1)',
					},
					iconeStyles: {
						color: '#FFCF52',
						iconeColor: {
							color: EVENT_STATUS.PENDING.color,
						},
					},
				};
			case EVENT_STATUS.APPROVED.name:
				return {
					dateStyles: {
						backgroundColor: 'rgba(25, 135, 84, 0.3)',
						borderColor: 'rgba(25, 135, 84, 1)',
					},
					iconeStyles: {
						color: '#198754',
						iconeColor: {
							color: EVENT_STATUS.APPROVED.color,
						},
					},
				};
			case EVENT_STATUS.IN_PROGRESS.name:
				return {
					dateStyles: {
						backgroundColor: 'rgba(18, 102, 241, 0.3)',
						borderColor: 'rgba(18, 102, 241, 1)',
					},
					iconeStyles: {
						color: '#1266F1',
						iconeColor: {
							color: EVENT_STATUS.IN_PROGRESS.color,
						},
					},
				};

			case EVENT_STATUS.IN_EXPECTATION.name:
				return {
					dateStyles: {
						backgroundColor: 'rgb(249, 115, 0, 0.45)',
						borderColor: 'rgba(255, 165, 0, 1)',
					},
					iconeStyles: {
						color: 'rgb(249, 115, 0)',
						iconeColor: {
							color: EVENT_STATUS.IN_EXPECTATION.color,
						},
					},
				};
			case EVENT_STATUS.CANCELED.name:
				return {
					dateStyles: {
						backgroundColor: 'rgba(220, 53, 69, 0.30)',
						borderColor: '#DC3545',
					},
					iconeStyles: {
						color: '#DC3545',
						iconeColor: {
							color: EVENT_STATUS.CANCELED.color,
						},
					},
				};

			case EVENT_STATUS.NOT_PAID.name:
				return {
					dateStyles: {
						backgroundColor: 'rgba(220, 53, 69, 0.30)',
						borderColor: '#DC3545',
					},
					iconeStyles: {
						color: '#DC3545',
						iconeColor: {
							color: EVENT_STATUS.NOT_PAID.color,
						},
					},
				};
			default:
				return undefined; // Handle unknown status
		}
	};
	const getRootFontSize = () => parseFloat(getComputedStyle(document.documentElement).fontSize);
	const [dynamicFont, setDynamicFont] = useState(getRootFontSize());

	const getFontSizeFromLocalStorage = () => {
		try {
			const fontSize = localStorage.getItem('fontSize');
			if (fontSize) {
				console.log(fontSize);
				const parsedFontSize = JSON.parse(fontSize);
				return parsedFontSize.value || 16;
			}
		} catch (error) {
			console.error('Error parsing fontSize from localStorage:', error);
			const defaultFontSize = { name: 'Нормальный', value: 16 };
			localStorage.setItem('fontSize', JSON.stringify(defaultFontSize));
			return defaultFontSize.value;
		}
	};

	const updateFontSize = () => {
		const fontSizeValue = getFontSizeFromLocalStorage();
		console.log('Font size updated:', fontSizeValue);
		setDynamicFont(fontSizeValue);
	};

	useEffect(() => {
		updateFontSize();

		const handleFontSizeChange = () => {
			updateFontSize();
		};

		window.addEventListener('fontSizeChange', handleFontSizeChange);

		return () => {
			window.removeEventListener('fontSizeChange', handleFontSizeChange);
		};
	}, []);

	const parseDateTime = (dateString: string): { date: string; time: string } => {
		const date = new Date(dateString);

		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
		const hours = String(date.getHours()).padStart(2, '0');
		const minutes = String(date.getMinutes()).padStart(2, '0');

		const formattedDate = `${day}.${month}.${year}`;
		const formattedTime = `${hours}:${minutes}`;

		return {
			date: formattedDate,
			time: formattedTime,
		};
	};

	const formatPrice = (
		amount: number | string,
		currency: string,
		locale: string = 'en-US',
	): string => {
		try {
			// Convert string to number if amount is of type string
			const parsedAmount = typeof amount === 'string' ? parseFloat(amount) : amount;

			// If parsing fails or results in NaN, return the original amount as a string
			if (isNaN(parsedAmount)) {
				throw new Error('Invalid amount');
			}

			return new Intl.NumberFormat(locale, {
				style: 'currency',
				currency: currency,
			}).format(parsedAmount);
		} catch (error) {
			console.error('Error formatting price:', error);
			return amount.toString();
		}
	};

	const canvasColor = () => {
		if (canvasData?.status === 'Завершен') {
			return 'success';
		} else if (canvasData?.status === 'Отменен' || canvasData?.status === 'Не оплачен') {
			return 'danger';
		} else {
			return 'info';
		}
	};

	const visitDropDownProps = {
		setEventAdding: setEventAdding,
		setCanvasData: setCanvasData,
		setUpdateVisitModal: setUpdateVisitModal,
		setActModal: setActModal,
		setActVisit: setActVisit,
		setPatientId: setPatientId,
		setMedicalCaseUuid: setMedicalCaseUuid,
		setServiceUuid: setServiceUuid,
		setClinicUuid: setClinicUuid,
		setStageUuid: setStageUuid,
		setVisitUpdate: setVisitUpdate,
		handleOpenCancel: handleOpenCancel,
		setVisitUuid: setVisitUuid,
		changeStatus: changeStatus,
	};
	const tableHeaders = [
		{ header: 'ID Визита', key: 'id' },
		{ header: 'Номер Визита', key: 'visitPill' },
		{ header: 'Дата и Время', key: 'datePill' },
		{ header: 'Пациент', key: 'nameWithAvatar' },
		{ header: 'Врач', key: 'name' },
		{ header: 'Жалоба', key: 'string' },
		{ header: 'Статус', key: 'status' },
		{ header: 'Действие', key: 'action' },
	];

	console.log(visits);
	return (
		<>
			<Card stretch={isFluid}>
				<div className='table-row-header'>
					<div className='d-flex flex-row align-items-center w-full grow'>
						<div className='d-flex align-items-center flex-xl-row flex-lg-column me-3'>
							{!isTablet ? (
								<>
									<Icon
										icon='Search'
										size='2x'
										color='primary'
										className='me-2'
										style={{ cursor: 'pointer' }}
										onClick={() => setSearchIconClicked(!searchIconClicked)}
									/>
									<Input
										color={themeStatus}
										type='search'
										value={patientSearch}
										onChange={(e: any) => {
											setPatientSearch(e.target.value);
										}}
										// className='table-header-input'
										placeholder={
											'Поиск пациента по Имени Фамилии Отчеству или по номеру ЭМК'
										}
										style={{
											width: isLargeDesktop
												? 500
												: isSmallDesktop
												  ? 200
												  : 100,
										}}
									/>
								</>
							) : (
								<Dropdown
									isOpen={searchIconClicked}
									setIsOpen={setSearchIconClicked}>
									<DropdownToggle>
										<Icon
											icon='Search'
											size='2x'
											color='primary'
											className='me-2'
											style={{ cursor: 'pointer' }}
											onClick={() => setSearchIconClicked(!searchIconClicked)}
										/>
									</DropdownToggle>
									<DropdownMenu
										style={{ width: '400px', padding: '15px 0 15px 0' }}>
										<div
											style={{
												display: 'flex',
												justifyContent: 'center',
												// marginTop: '15px',
												flexWrap: 'wrap',
											}}>
											<FormGroup
												style={{ marginTop: '15px' }}
												id='surname'
												label='Поиск пациента по Имени Фамилии Отчеству или по номеру ЭМК'
												className='col-10'>
												<Input
													color={themeStatus}
													type='search'
													value={patientSearch}
													onChange={(e: any) => {
														setPatientSearch(e.target.value);
													}}
												/>
											</FormGroup>
										</div>
									</DropdownMenu>
								</Dropdown>
							)}
						</div>
						<div className='d-flex flex-row align-items-center'>
							{isLargeDesktop && (
								<span className='text-nowrap me-2'> Фильтровать по:</span>
							)}
							{/* code */}
							<Dropdown isOpen={popperIsOpenCode} setIsOpen={setPopperIsOpenCode}>
								<DropdownToggle>
									<Button
										onClick={() => {
											setOpenFilterCode(true);
										}}
										color={themeStatus}
										className='text-nowrap me-2'>
										Визиту
									</Button>
								</DropdownToggle>
								<DropdownMenu style={{ width: '400px', padding: '15px 0 15px 0' }}>
									{/* <div className='d-flex justify-content-around'>
										<h4>Фильтр по коду визита</h4>
										<Button
											type='button'
											className='btn-close'
											data-bs-dismiss='modal'
											aria-label='Close'
											onClick={() => setPopperIsOpenCode(false)}
										/>
									</div> */}

									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											// marginTop: '15px',
											flexWrap: 'wrap',
										}}>
										{/* <Button
											color={themeStatus}
											style={{ margin: '5px 0 5px 0' }}
											type={'button'}
											value={'DESC'}
											name='order'
											className={`col-10 code_Button code_Button_down ${
												values.order === 'DESC' ? 'code_active' : ''
											}`}
											onClick={(e: any) => handleCheckboxChange(e)}>
											Сортировать по убыванию
										</Button>

										<Button
											color={themeStatus}
											type={'button'}
											name='order'
											style={{ margin: '5px 0 5px 0' }}
											value={'ASC'}
											onClick={(e: any) => handleCheckboxChange(e)}
											className={`col-10 code_Button code_Button_up ${
												values.order === 'ASC' ? 'code_active' : ''
											}`}>
											Сортировать по возрастанию
										</Button> */}

										<FormGroup
											style={{ marginTop: '15px' }}
											id='surname'
											label='Введите id или номер визита'
											className='col-10'>
											<Input
												name='visitId'
												type={'search'}
												onChange={(e: any) => handleCheckboxChange(e)}
												value={values.visitId}
											/>
										</FormGroup>
									</div>
								</DropdownMenu>
							</Dropdown>
							{/* services */}
							<Dropdown
								isOpen={popperIsOpenService}
								setIsOpen={setPopperIsOpenService}>
								<DropdownToggle>
									<Button color={themeStatus} className='text-nowrap me-2'>
										Услуге
									</Button>
								</DropdownToggle>
								<DropdownMenu
									style={{ maxHeight: 600, width: '400px', overflow: 'scroll' }}>
									{/* <div className='d-flex justify-content-around'>
										<h4>Фильтр по сервису</h4>
										<Button
											type='button'
											className='btn-close'
											data-bs-dismiss='modal'
											aria-label='Close'
											onClick={() => setPopperIsOpenService(false)}
										/>
									</div> */}
									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											flexWrap: 'wrap',
										}}>
										<FormGroup
											style={{ margin: '15px 0 30px 0' }}
											id='surname'
											label='Введите название или код услуги'
											className='col-10'>
											<Input
												name='serviceName'
												type={'search'}
												onChange={(e: any) => handleCheckboxChange(e)}
												value={values?.serviceName}
											/>
											<div className='mb-1 col-12'>
												{services?.data?.length > 0 &&
													servicesCount?.data?.filter(
														(i: any) =>
															values?.services?.includes(i.uuid),
													)?.length > 0 &&
													servicesCount?.data
														.filter(
															(i: any) =>
																values?.services?.includes(i.uuid),
														)
														.reverse()
														.map((i: any) => (
															<>
																<div
																	className='d-flex p-1 col-12'
																	key={i.uuid}>
																	<div className='col-8'>
																		<p className='mb-1'>
																			{i?.title}
																		</p>
																	</div>
																	<div className='d-flex align-items-start justify-content-end col-4'>
																		<span className='text-nowrap fw-bolder fs-6'>
																			{formatPrice(
																				i?.price,
																				'AMD',
																				'ru-RU',
																			)}
																		</span>
																	</div>
																</div>
																<hr
																	className={`hr-divder-costum text-${canvasColor()} bg-${canvasColor()}`}
																/>
															</>
														))}
											</div>
										</FormGroup>
										{services?.data ? (
											services.data.map((i: IService, index: number) => (
												<div className='form-check col-10' key={index}>
													<input
														// color={themeStatus}
														className='form-check-input'
														onChange={(e: any) =>
															handleCheckboxChange(e)
														}
														type='checkbox'
														value={i.uuid}
														name='services'
														checked={values?.services?.includes(i.uuid)}
														id={`flexCheckDefault-${index}`}
													/>
													<label
														className='form-check-label col-12'
														htmlFor={`flexCheckDefault-${index}`}>
														<div className='col-3'>
															<span
																className='p-2 d-flex justify-content-center col-9 fw-normal text-white'
																style={{
																	borderRadius: '0.5rem',
																	backgroundColor: '#2c3178',
																	width: '100%',
																}}>
																{i.serviceId}
															</span>
														</div>
														<div className='col-9'>
															<p
																className='d-flex m-2 text-truncate'
																style={{ width: '95%' }}>
																<Tooltips
																	title={i.title}
																	placement='top'
																	flip={['top']}>
																	<span className='text-truncate overflow-hidden w-100 d-inline-block'>
																		{i.title}
																	</span>
																</Tooltips>
															</p>
														</div>
													</label>
												</div>
											))
										) : (
											<h4>Услуги не найдены</h4>
										)}
									</div>
									<div className='col-12'>
										<PaginationButtons
											data={services || []}
											label='customers'
											small
											filterPagination
											setCurrentPage={setServicesCurrentPage}
											currentPage={servicesCurrentPage}
											perPage={servicesPerPage}
											setPerPage={setServicesPerPage}
											totalPage={
												Math.ceil(
													services?.meta?.count / servicesPerPage,
												) || 1
											}
										/>
									</div>
								</DropdownMenu>
							</Dropdown>
							{/* users */}
							{!isUserDoctor && (
								<Dropdown isOpen={popperIsOpenUser} setIsOpen={setPopperIsOpenUser}>
									<DropdownToggle>
										<Button color={themeStatus} className='text-nowrap me-2'>
											Врачу
										</Button>
									</DropdownToggle>
									<DropdownMenu
										style={{
											maxHeight: 600,
											width: '400px',
											overflow: 'scroll',
										}}>
										{/* <div className='d-flex justify-content-around'>
										<h4>Фильтр по Врачу</h4>
										<Button
											type='button'
											className='btn-close'
											data-bs-dismiss='modal'
											aria-label='Close'
											onClick={() => setPopperIsOpenUser(false)}
										/>
									</div> */}
										<div
											style={{
												display: 'flex',
												justifyContent: 'center',
												flexWrap: 'wrap',
												// overFlow: 'auto',
											}}>
											<FormGroup
												style={{ margin: '15px 0 15px 0' }}
												id='surname'
												label='Введите ФИО врача'
												className='col-10'>
												<Input
													type={'search'}
													name='userName'
													onChange={(e: any) => handleCheckboxChange(e)}
													value={values.userName}
												/>
												<div className='mb-1 col-12'>
													{users?.data?.length > 0 &&
														usersCount?.data?.filter(
															(i: any) =>
																values?.users?.includes(i.uuid),
														)?.length > 0 &&
														usersCount.data
															.filter((i: any) =>
																values.users.includes(i.uuid),
															)
															.map((i: any) => (
																<>
																	<div
																		className='d-flex p-1 col-12'
																		key={i.uuid}>
																		<div className='col-10'>
																			<p className='mb-1'>
																				{i?.surname}{' '}
																				{i?.name}{' '}
																				{i?.middleName}
																			</p>
																		</div>
																		{/* <div style={{overflow: 'scroll'}} className='d-flex align-items-start justify-content-end col-7'>
																	{i.roles
																	? i.roles.map((i: any) => {
																			return (
																				<div className='col-auto'>
																					<Badge
																						isLight
																						color={
																							i.color
																						}
																						className='px-3 py-2'>
																						{
																							i
																								?.clinics
																								?.name
																						}{' '}
																						- {i.role}
																					</Badge>
																				</div>
																			);
																	  })
																	: null}
																		<span className='text-nowrap fw-bolder fs-6'>
														
																		</span>
																	</div> */}
																	</div>
																	<hr
																		className={`hr-divder-costum text-${canvasColor()} bg-${canvasColor()}`}
																	/>
																</>
															))}
												</div>
											</FormGroup>
											{users?.data.length > 0 ? (
												users?.data.map((i: IUser, index: number) => (
													<div className='form-check col-10' key={index}>
														<input
															className='form-check-input'
															onChange={(e: any) =>
																handleCheckboxChange(e)
															}
															type='checkbox'
															value={i.uuid}
															checked={values.users.includes(i.uuid)}
															name='users'
															id={`flexCheckDefault-${index}`}
														/>
														<label
															color={themeStatus}
															className='form-check-label'
															htmlFor={`flexCheckDefault-${index}`}>
															<Avatar
																src={
																	i?.image
																		? `https://backend.amracode.am${i?.image}`
																		: UserImage3
																}
																srcSet={
																	i?.image
																		? `https://backend.amracode.am${i?.image}`
																		: UserImage3
																}
																size={2.25 * dynamicFont}
																style={{ margin: '0 10px 0 0' }}
															/>
															{i?.surname} {i?.name} {i?.middleName}
														</label>
													</div>
												))
											) : (
												<h4>Врачей не найдено</h4>
											)}
										</div>
										<DropdownItem>
											<PaginationButtons
												data={users || []}
												label='customers'
												small
												setCurrentPage={setUsersCurrentPage}
												currentPage={usersCurrentPage}
												filterPagination
												perPage={usersPerPage}
												setPerPage={setUsersPerPage}
												totalPage={
													Math.ceil(users?.meta?.count / usersPerPage) ||
													1
												}
											/>
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>
							)}

							{/* statuses */}
							<Dropdown isOpen={popperIsOpenStatus} setIsOpen={setPopperIsOpenStatus}>
								<DropdownToggle>
									<Button color={themeStatus} className='text-nowrap me-2'>
										Статусу
									</Button>
								</DropdownToggle>
								<DropdownMenu style={{ width: '400px' }}>
									{/* <div className='d-flex justify-content-around'>
										<h4>Фильтр по Статусу</h4>
										<Button
											type='button'
											className='btn-close'
											data-bs-dismiss='modal'
											aria-label='Close'
											onClick={() => setPopperIsOpenStatus(false)}
										/>
									</div> */}

									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											flexWrap: 'wrap',
										}}>
										<div className='form-check col-10'>
											<input
												className='form-check-input'
												type='checkbox'
												value='Предстоящий'
												id='flexCheckDefault-0'
												onChange={(e: any) => handleCheckboxChange(e)}
												checked={values.statuses.includes('Предстоящий')}
												name='statuses'
											/>
											<label
												color={themeStatus}
												className='form-check-label'
												htmlFor='flexCheckDefault-0'>
												<Icon
													icon='Circle'
													className='me-2'
													color={
														VisitStatusStyles('Предстоящий')
															?.iconeStyles.iconeColor.color
													}
												/>
												<span
													style={{
														color: VisitStatusStyles('Предстоящий')
															?.iconeStyles.color,
													}}>
													{'Предстоящий'}
												</span>
											</label>
										</div>
										<div className='form-check col-10'>
											<input
												className='form-check-input'
												type='checkbox'
												value='В ожидании'
												id='flexCheckDefault-1'
												onChange={(e: any) => handleCheckboxChange(e)}
												checked={values.statuses.includes('В ожидании')}
												name='statuses'
											/>
											<label
												color={themeStatus}
												className='form-check-label'
												htmlFor='flexCheckDefault-1'>
												<Icon
													icon='Circle'
													className='me-2'
													style={{
														fill: VisitStatusStyles('В ожидании')
															?.iconeStyles.color,
													}}
													color={
														VisitStatusStyles('В ожидании')?.iconeStyles
															.iconeColor.color
													}
												/>
												<span
													style={{
														color: VisitStatusStyles('В ожидании')
															?.iconeStyles.color,
													}}>
													{'В ожидании'}
												</span>
											</label>
										</div>
										<div className='form-check col-10'>
											<input
												color={themeStatus}
												className='form-check-input'
												type='checkbox'
												value='В процессе'
												id='flexCheckDefault-2'
												onChange={(e: any) => handleCheckboxChange(e)}
												checked={values.statuses.includes('В процессе')}
												name='statuses'
											/>
											<label
												color={themeStatus}
												className='form-check-label'
												htmlFor='flexCheckDefault-2'>
												<Icon
													icon='Circle'
													className='me-2'
													color={
														VisitStatusStyles('В процессе')?.iconeStyles
															.iconeColor.color
													}
												/>
												<span
													style={{
														color: VisitStatusStyles('В процессе')
															?.iconeStyles.color,
													}}>
													{'В процессе'}
												</span>
											</label>
										</div>

										<div className='form-check col-10'>
											<input
												className='form-check-input'
												type='checkbox'
												value='Не оплачен'
												id='flexCheckDefault-3'
												onChange={(e: any) => handleCheckboxChange(e)}
												checked={values.statuses.includes('Не оплачен')}
												name='statuses'
											/>
											<label
												color={themeStatus}
												className='form-check-label'
												htmlFor='flexCheckDefault-3'>
												<Icon
													icon='Circle'
													className='me-2'
													color={
														VisitStatusStyles('Не оплачен')?.iconeStyles
															.iconeColor.color
													}
												/>
												<span
													style={{
														color: VisitStatusStyles('Не оплачен')
															?.iconeStyles.color,
													}}>
													{'Не оплачен'}
												</span>
											</label>
										</div>
										<div className='form-check col-10'>
											<input
												className='form-check-input'
												type='checkbox'
												value='Завершен'
												id='flexCheckChecked-4'
												onChange={(e: any) => handleCheckboxChange(e)}
												checked={values.statuses.includes('Завершен')}
												name='statuses'
											/>
											<label
												color={themeStatus}
												className='form-check-label'
												htmlFor='flexCheckChecked-4'>
												<Icon
													icon='Circle'
													className='me-2'
													color={
														VisitStatusStyles('Завершен')?.iconeStyles
															.iconeColor.color
													}
												/>
												<span
													style={{
														color: VisitStatusStyles('Завершен')
															?.iconeStyles.color,
													}}>
													{'Завершен'}
												</span>
											</label>
										</div>

										<div className='form-check col-10'>
											<input
												className='form-check-input'
												type='checkbox'
												value='Отменен'
												id='flexCheckDefault-5'
												onChange={(e: any) => handleCheckboxChange(e)}
												checked={values.statuses.includes('Отменен')}
												name='statuses'
											/>
											<label
												color={themeStatus}
												className='form-check-label'
												htmlFor='flexCheckDefault-5'>
												<Icon
													icon='Circle'
													className='me-2'
													color={
														VisitStatusStyles('Отменен')?.iconeStyles
															.iconeColor.color
													}
												/>
												<span
													style={{
														color: VisitStatusStyles('Отменен')
															?.iconeStyles.color,
													}}>
													{'Отменен'}
												</span>
											</label>
										</div>
									</div>
								</DropdownMenu>
							</Dropdown>
						</div>
					</div>
					<div>
						<Button
							color='info'
							onClick={() => {
								setAllDayButton(false);
								setValues({
									users: [],
									services: [],
									statuses: [],
									order: 'DESC',
									visitId: undefined,
									serviceId: undefined,
									userName: '',
								});
								setPatientSearch('');
								setDateDay(formatDate!(new Date()));
								if (setDateInterval) setDateInterval(null);
								setAllDayButton(false);
								localStorage.setItem('datePicker', 'null');
								localStorage.setItem('dateOfVisit', JSON.stringify(new Date()));
							}}
							isLight>
							Сбросить все {isSmallDesktop ? 'выбранные' : ''} даты и фильтры
						</Button>
					</div>
				</div>
				<CardBody className='table-responsive' isScrollable={isFluid}>
					<TableComponent
						data={visits?.data}
						headers={tableHeaders}
						visitDropDownProps={visitDropDownProps}
					/>
				</CardBody>
				<PaginationButtons
					data={visits || []}
					label='customers'
					setCurrentPage={setCurrentPage}
					currentPage={currentPage}
					perPage={perPage}
					setPerPage={setPerPage}
					totalPage={Math.ceil(visits?.meta?.count / perPage) || 1}
				/>
			</Card>
			{/* <FilterVisitByCode setIsOpen={setOpenFilterCode} isOpen={openFilterCode} id='0' /> */}
			<CancelVisitModal
				visitUuid={visitUuid}
				setIsOpen={setOpenCancelVisits}
				isOpen={openCancelVisits}
				id='0'
			/>

			<OffCanvas
				setOpen={(status: boolean) => {
					setToggleInfoEventCanvas(status);
					setEventAdding(status);
				}}
				isOpen={eventAdding}
				titleId='canvas-title'
				style={{ width: '25dvw' }}>
				<OffCanvasHeader
					setOpen={(status: boolean) => {
						setToggleInfoEventCanvas(status);
						setEventAdding(status);
					}}
					className='ms-4'
					style={{ marginRight: '2rem' }}>
					<OffCanvasTitle id='canvas-title'>Информация о визите</OffCanvasTitle>
				</OffCanvasHeader>
				<OffCanvasBody tag='form' className='px-4'>
					<div className='row g-4'>
						<div className='col-12'>
							{canvasData.act && (
								<Card
									className={`mb-10 ${
										themeStatus === 'light'
											? `bg-l10-${canvasColor()}`
											: 'bg-dark'
									}`}
									shadow='sm'>
									<CardHeader
										className={`${
											themeStatus === 'light'
												? `bg-l25-${canvasColor()}`
												: `bg-${canvasColor()}`
										}`}>
										<CardLabel
											iconColor={
												themeStatus === 'light'
													? `${canvasColor()}`
													: 'light'
											}
											icon='ReceiptLong'>
											<CardTitle
												className={`${
													themeStatus === 'light'
														? `text-${canvasColor()}`
														: 'text-white'
												}`}>
												Смета
											</CardTitle>
										</CardLabel>
									</CardHeader>
									<CardBody>
										<FormGroup
											id='date'
											label='Сумма и описание'
											className='mb-2'>
											<p
												style={{
													color:
														themeStatus === 'light' ? 'black' : 'white',
												}}>
												Сумма -{' '}
												{formatPrice(
													canvasData?.act?.amount,
													'AMD',
													'ru-RU',
												)}{' '}
											</p>
											<p
												style={{
													color:
														themeStatus === 'light' ? 'black' : 'white',
												}}>
												Описание - {canvasData?.act?.description}
												<span className={`fw-bold text-${canvasColor()}`}>
													{' '}
													{canvasData?.status === 'Не оплачен' &&
														'Не оплачено'}
													{canvasData?.status === 'Завершен' &&
														'Оплачено'}
												</span>
											</p>
										</FormGroup>
									</CardBody>
								</Card>
							)}
							<Card
								color={themeStatus}
								className={`mb-10 ${
									themeStatus === 'light' ? `bg-l10-${canvasColor()}` : 'bg-dark'
								}`}
								shadow='sm'>
								<CardHeader
									className={`${
										themeStatus === 'light'
											? `bg-l25-${canvasColor()}`
											: `bg-${canvasColor()}`
									}`}>
									<CardLabel
										iconColor={
											themeStatus === 'light' ? `${canvasColor()}` : 'light'
										}
										icon='Alarm'>
										<CardTitle
											className={`${
												themeStatus === 'light'
													? `text-${canvasColor()}`
													: 'text-white'
											}`}>
											Визит
										</CardTitle>
									</CardLabel>
								</CardHeader>
								<CardBody>
									<FormGroup
										id='date'
										label='Дата и время визита'
										className='mb-2'>
										<p
											style={{
												color: themeStatus === 'light' ? 'black' : 'white',
											}}>
											Дата визита -{' '}
											{parseDateTime(canvasData?.visitDate).date}
										</p>
										<p
											style={{
												color: themeStatus === 'light' ? 'black' : 'white',
											}}>
											Время визита -{' '}
											{parseDateTime(canvasData?.visitDate).time}
											{canvasData?.endDate &&
												` - ${parseDateTime(canvasData?.endDate).time}`}
										</p>
									</FormGroup>

									<FormGroup id='date' label='Статус' className='mb-2'>
										<p
											style={{
												color: themeStatus === 'light' ? 'black' : 'white',
											}}>
											{canvasData?.status}{' '}
											{canvasData?.status === 'Отменен' && (
												<span className={`fw-bold text-${canvasColor()}`}>
													{canvasData?.status === 'Отменен' &&
														canvasData?.canceledVisit[0]?.description}
													{', '}
													{canvasData?.status === 'Отменен' &&
													canvasData?.canceledVisit[0]?.type === 'patient'
														? 'Отказ от получения услуг'
														: 'Отказ от предоставления услуг'}
												</span>
											)}
										</p>
									</FormGroup>
								</CardBody>
							</Card>

							<Card
								className={`mb-10 ${
									themeStatus === 'light' ? `bg-l10-${canvasColor()}` : 'bg-dark'
								}`}
								shadow='sm'>
								<CardHeader
									className={`${
										themeStatus === 'light'
											? `bg-l25-${canvasColor()}`
											: `bg-${canvasColor()}`
									}`}>
									<CardLabel
										iconColor={
											themeStatus === 'light' ? `${canvasColor()}` : 'light'
										}
										icon='MedicalServices'>
										<CardTitle
											className={`${
												themeStatus === 'light'
													? `text-${canvasColor()}`
													: 'text-white'
											}`}>
											Услуги
										</CardTitle>
									</CardLabel>
								</CardHeader>
								<CardBody>
									<FormGroup id='date' label='Услуга - Цена' className='mb-2'>
										{canvasData?.services?.length > 0 ? (
											canvasData?.services?.map((i: any) => (
												<>
													<p
														style={{
															color:
																themeStatus === 'light'
																	? 'black'
																	: 'white',
														}}>
														{i?.title} -{' '}
														{formatPrice(i?.price, 'AMD', 'ru-RU')}
													</p>
													<hr
														className={`hr-divder-costum text-${canvasColor()} bg-${canvasColor()}`}
													/>
												</>
											))
										) : (
											<p
												style={{
													color:
														themeStatus === 'light' ? 'black' : 'white',
												}}>
												Нет Услуг
											</p>
										)}
									</FormGroup>
								</CardBody>
							</Card>

							<Card
								className={`mb-10 ${
									themeStatus === 'light' ? `bg-l10-${canvasColor()}` : 'bg-dark'
								}`}
								shadow='sm'>
								<CardHeader
									className={`${
										themeStatus === 'light'
											? `bg-l25-${canvasColor()}`
											: `bg-${canvasColor()}`
									}`}>
									<CardLabel
										iconColor={
											themeStatus === 'light' ? `${canvasColor()}` : 'light'
										}
										icon='DoneAll'>
										<CardTitle
											className={`${
												themeStatus === 'light'
													? `text-${canvasColor()}`
													: 'text-white'
											}`}>
											Медицинский случай
										</CardTitle>
									</CardLabel>
								</CardHeader>
								<CardBody>
									<FormGroup
										id='date'
										label='Название  Медицинского  случая'
										className='mb-2'>
										<p
											style={{
												color: themeStatus === 'light' ? 'black' : 'white',
											}}>
											{canvasData?.medicalCase?.title}
										</p>
									</FormGroup>
									<FormGroup
										id='date'
										label='Описание  медицинского случая'
										className='mb-2'>
										<p
											style={{
												color: themeStatus === 'light' ? 'black' : 'white',
											}}>
											{canvasData?.medicalCase?.description
												? canvasData?.medicalCase?.description
												: 'нет описания'}
										</p>
									</FormGroup>
								</CardBody>
							</Card>

							<Card
								className={`mb-10 ${
									themeStatus === 'light' ? `bg-l10-${canvasColor()}` : 'bg-dark'
								}`}
								shadow='sm'>
								<CardHeader
									className={`${
										themeStatus === 'light'
											? `bg-l25-${canvasColor()}`
											: `bg-${canvasColor()}`
									}`}>
									<CardLabel
										iconColor={
											themeStatus === 'light' ? `${canvasColor()}` : 'light'
										}
										icon='Person'>
										<CardTitle
											className={`${
												themeStatus === 'light'
													? `text-${canvasColor()}`
													: 'text-white'
											}`}>
											Врач
										</CardTitle>
									</CardLabel>
								</CardHeader>
								<CardBody>
									<FormGroup
										id='date'
										label='Имя Фамилия и Отчество врача'
										className='mb-2'>
										<p
											style={{
												color: themeStatus === 'light' ? 'black' : 'white',
											}}>
											{`${canvasData?.user?.name} ${canvasData?.user?.surname} ${canvasData?.user?.middleName}`}
										</p>
									</FormGroup>
								</CardBody>
							</Card>

							<Card
								className={`mb-10 ${
									themeStatus === 'light' ? `bg-l10-${canvasColor()}` : 'bg-dark'
								}`}
								shadow='sm'>
								<CardHeader
									className={`${
										themeStatus === 'light'
											? `bg-l25-${canvasColor()}`
											: `bg-${canvasColor()}`
									}`}>
									<CardLabel
										iconColor={
											themeStatus === 'light' ? `${canvasColor()}` : 'light'
										}
										icon='PersonSearch'>
										<CardTitle
											className={`${
												themeStatus === 'light'
													? `text-${canvasColor()}`
													: 'text-white'
											}`}>
											Пациент
										</CardTitle>
									</CardLabel>
								</CardHeader>
								<CardBody>
									<FormGroup
										id='date'
										label='Имя Фамилия и Отчество пациента'
										className='mb-2'>
										<p
											style={{
												color: themeStatus === 'light' ? 'black' : 'white',
											}}>
											{`${canvasData?.patient?.name} ${canvasData?.patient?.surname} ${canvasData?.patient?.middleName}`}
										</p>
									</FormGroup>
								</CardBody>
							</Card>
						</div>
					</div>
				</OffCanvasBody>
			</OffCanvas>

			<ActModal setIsOpen={setActModal} isOpen={actModal} visit={actVisit} />

			<Modal
				isStaticBackdrop
				isCentered
				isOpen={updateVisitModal}
				setIsOpen={setUpdateVisitModal}
				closeModalCustom={() => {
					setCreateVisit(false);
				}}
				size='xl'>
				<ModalHeader
					closeModalCustom={() => {
						setCreateVisit(false);
						setPatientId('');
						setMedicalCaseUuid('');
						setServiceUuid([]);
						setClinicUuid('');
						setStageUuid('');
						setVisitUpdate({});
					}}
					style={{ padding: '1.5rem 2.25rem' }}
					setIsOpen={setUpdateVisitModal}
					className='p-4'>
					<span className='fs-3 fw-bold'>
						<Icon icon='Person' className='me-2 fs-2' color='primary' />
						Редактировать визит
					</span>
				</ModalHeader>
				<ModalBody style={{ padding: '1.5rem 2.25rem' }} className='px-4'>
					{!createVisit && (
						<CreateVisitMedicalCard
							setCreateVisit={setCreateVisit}
							setValues={setUpdatedValues}
							values={updatedValues}
							setIsOpen={setUpdateVisitModal}
							patientId={patientId}
							serviceId={serviceUuid}
							medicalCaseUuid={medicalCaseUuid}
							clinicUuid={clinicUuid}
							stageUuid={stageUuid}
							visitData={visitData}
							visit={visitUpdate}
							setVisitData={setVisitData}
							schedulModalOpen={schedulModalOpen}
							changeValues={changeUpdatedValues}
							setSchedulModalOpen={setSchedulModalOpen}
						/>
					)}
				</ModalBody>
			</Modal>
		</>
	);
};
VisitTable.defaultProps = {
	isFluid: false,
};

export default VisitTable;
