import React, { FC, useContext, useEffect, useState } from 'react';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../../components/bootstrap/Modal';
import Icon from '../../../components/icon/Icon';
import Button from '../../../components/bootstrap/Button';
import Input from '../../../components/bootstrap/forms/Input';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import useDarkMode from '../../../hooks/useDarkMode';
import Textarea from '../../../components/bootstrap/forms/Textarea';
import { ServiceService } from '../../../custom/api/service/visit-service.service';
import { IService } from '@textnpayme/custom/api/interface/service.interface';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import * as qs from 'qs';
import { useDebounce } from '../../../hooks/useDebounce';
import Tooltips from '../../../components/bootstrap/Tooltips';

import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import PaginationButtons, {
	dataPagination,
	PER_COUNT,
	PER_COUNT_FILTER,
} from '../../../components/PaginationButtons';
import { StageService } from '../../../custom/api/service/stage.service';
import { VisitService } from '../../../custom/api/service/visit.service';
import { IVisit } from '@textnpayme/custom/api/interface/visit.interface';
import { ServicesList } from './components/ServicesList';
import { ServicesListButton } from './components/ServicesListButton';
import { ConsultationListService } from '../../../custom/api/service/consulatation-list.service';
import DentalContext from '../../../contexts/dentalContext';
import { IConsultationList } from '@textnpayme/custom/api/interface/consultation-list.interface';
import DentalContextFunctions from '../../../contexts/dentalContextFunctions';
import { CategoryService } from '../../../custom/api/service/category.service';
import { ClinicService } from '../../../custom/api/service/clinic.service';
import { IClinic } from '../../../custom/api/interface/clinic.interface';
import { notification } from 'antd';
import type { NotificationArgsProps } from 'antd';

type NotificationPlacement = NotificationArgsProps['placement'];

interface IServiceChooseModalProps {
	isOpen: boolean;
	setIsOpen(...args: unknown[]): unknown;
	serviceDropdownInfo?: any;
	setServiceDropdownInfo?(...args: unknown[]): unknown;
	stage: any;
	medicalCard?: boolean;
	isStage?: boolean;
	createVisit?: boolean;
	createEvent?: boolean;
	setChosenValues?(...args: unknown[]): unknown;
	setServiceUuid?(...args: unknown[]): unknown;
}

interface CreateConsultationParams {
	inputDto: IConsultationList;
	uuid: string;
}

interface CreateServiceParams {
	inputDto: IService;
	uuid: string;
}

const ServiceChooseModal: FC<IServiceChooseModalProps> = ({
	isOpen,
	serviceDropdownInfo,
	setServiceUuid,
	setServiceDropdownInfo,
	setIsOpen,
	stage,
	medicalCard,
	setChosenValues,
	isStage,
	createVisit,
	createEvent,
}) => {
	const {
		formData,
		setFormData,
		event_default,
		dentalFormula,
		setSelectMany,
		setSelectedItems,

		implant_default,
	} = useContext(DentalContext);
	const {
		localFormData,
		updateDentalFormulaFromBackend,
		getServicesUuids,
		setLocalFormData,
		getInfoForCreateVisit,
	} = useContext(DentalContextFunctions);

	const [api, contextHolder] = notification.useNotification();

	const openNotificationPlace = (placement: NotificationPlacement, description: string) => {
		api.info({
			message: 'Важно!',
			description,
			placement,
			className: 'ant-notification-custom',
			props: { style: { zIndex: 150000 } },
		});
	};

	// Function to trigger the file input click
	const [values, setValues] = useState<IService>({
		services: [],
		serviceName: '',
		serviceNameChosen: '',
	});

	const [filter, setFilter] = useState<IService>({
		clinic: '',
		treatmen_type: '',
		category: '',
	});

	const changeFilterValues = (key: string, value: string | number) => {
		setFilter((prev) => ({
			...prev,
			[key]: value,
		}));
	};

	const filterDebounce = useDebounce(filter);

	useEffect(() => {
		if (
			serviceDropdownInfo &&
			serviceDropdownInfo?.length &&
			serviceDropdownInfo.some((i: any) => !values?.services?.includes(i))
		) {
			setValues((prev) => ({
				...prev,
				services: serviceDropdownInfo,
			}));
		}
	}, [serviceDropdownInfo]);

	const [consultationListUuid, setConslutationListUuid] = useState<any>('');
	const [consultationListData, setConslutationListData] = useState<any>({});

	const [choosenServices, setChoosenService] = useState<any[]>([]);
	const [choosenServicesData, setChoosenServiceData] = useState<any[]>([]);

	const changeChosenServicesValue = (
		key: string,
		value: string | number,
		index: number,
		uuid: string,
	) => {
		const changedItem = choosenServices.find(
			(item_l: any, index_l: number) => item_l.uuid === uuid,
		);
		console.log(changedItem, 'changedItemchangedItem');

		setChoosenService((prev: any) => {
			const updatedServices = [...prev];
			if (updatedServices[index]) {
				updatedServices[index] = {
					...updatedServices[index],
					[key]: value,
				};
			}
			setLocalChosenService([...updatedServices]);
			return updatedServices;
		});
	};

	useEffect(() => {
		if (values.services.length > 0) {
			setChoosenService([...values.services]);
			setLocalChosenService([...values.services]);
		}
	}, [values?.services]);

	console.log(serviceDropdownInfo, 'valuesvaluesvalues');

	const [popperIsOpenService, setPopperIsOpenService] = useState(false);
	const [servicesCurrentPage, setServicesCurrentPage] = useState<number>(1);
	const [servicesPerPage, setServicesPerPage] = useState<number>(PER_COUNT_FILTER['5']);

	const [servicesChosenCurrentPage, setServicesChosenCurrentPage] = useState<number>(1);
	const [servicesChosenPerPage, setServicesChosenPerPage] = useState<number>(
		PER_COUNT_FILTER['5'],
	);

	const { themeStatus } = useDarkMode();

	const changeValues = (key: string, value: string | number) => {
		setValues((prev) => ({
			...prev,
			[key]: [...(prev[key] || []), value],
		}));
	};

	const [localChoosenServices, setLocalChosenService] = useState<any[]>([...choosenServices]);
	const isUnique = (data: any, item: any) => {
		const bool = data.some((service: any) => {
			if (item.uuid === service.uuid) {
				if (service.start && item.start && service.end && item.end) {
					return service.start === item.start && service.end === item.end;
				}
				if (service.theeth && item.theeth) {
					return service.theeth === item.theeth;
				}
			}
			return false;
		});

		if (bool) {
			return bool;
		} else {
			return bool;
		}
	};

	const handleCheckboxChange = (e: any, name_btn?: string, value?: string) => {
		const name = e.target.name;
		if (isUnique(choosenServices, value) && e.target.type !== 'search' && name_btn) {
			return openNotificationPlace('bottomRight', 'услуга с выбранным зубом уже существует');
		}
		if (e.target.type !== 'search' && name_btn && value) {
			setChoosenService((prev) => {
				setLocalChosenService([...prev, value]);
				return [...prev, value];
			});
		} else if (e.target.type === 'search') {
			setValues((prev) => ({ ...prev, [name]: e.target.value }));
		}
	};

	const DebounsedSearch = useDebounce(values);
	const { data: services }: { data: IService[] | any; isLoading: boolean } = useQuery(
		[
			'services',
			DebounsedSearch,
			servicesCurrentPage,
			servicesPerPage,
			isStage,
			stage,
			medicalCard,
			filterDebounce,
		],
		() =>
			ServiceService.findAll(
				qs.stringify({
					queryMeta: {
						paginate: true,
						limit: servicesPerPage,
						page: DebounsedSearch?.serviceName.length ? 1 : servicesCurrentPage,
						order: { createdAt: 'DESC' },
					},

					includeMeta: [
						{
							association: 'user',
						},
						{
							association: 'clinic',
							...(!!filter.clinic && {
								where: {
									uuid: filter.clinic,
								},
							}),
						},
						{
							association: 'category',
							...(!!filter.category && {
								where: {
									uuid: filter.category,
								},
							}),
						},
					],
					filterMeta: {
						...(values.serviceName && {
							...(values.serviceName && /\d/.test(values.serviceName)
								? { serviceId: { iLike: `%${values.serviceName}%` } }
								: { title: { iLike: `%${values.serviceName}%` } }),
						}),
						...(!!filter.treatment_type && {
							treatment_type: filter.treatment_type,
						}),
					},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	console.log(choosenServices, localChoosenServices, 'loashfhyasgyug');

	const { data: services_count }: { data: IService[] | any; isLoading: boolean } = useQuery(
		['services_count', isStage, DebounsedSearch, stage, medicalCard],
		() =>
			ServiceService.findAll(
				qs.stringify({
					queryMeta: {
						order: { createdAt: 'DESC' },
					},

					...(!medicalCard &&
						isStage &&
						stage && {
							includeMeta: [
								// {
								// 	association: 'stages',
								// 	where: {
								// 		uuid: stage,
								// 	},
								// },
							],
						}),

					filterMeta: {
						...(values.serviceName && {
							...(values.serviceName && /\d/.test(values.serviceName)
								? { serviceId: { iLike: `%${values.serviceName}%` } }
								: { title: { iLike: `%${values.serviceName}%` } }),
						}),
					},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	const { data: visits_services }: { data: IVisit[] | any; isLoading: boolean } = useQuery(
		['visits_services', stage],
		() =>
			VisitService.findAll(
				qs.stringify({
					filterMeta: {
						stageUuid: stage,
					},
					includeMeta: [
						{
							association: 'services',
						},
					],

					queryMeta: {
						order: { createdAt: 'DESC' },
					},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	console.log(choosenServices, 'valavllva');

	const { data: consulatation_list }: { data: any[] | any; isLoading: boolean } = useQuery(
		['consulatation_list', stage],
		() =>
			ConsultationListService.findAll(
				qs.stringify({
					queryMeta: {
						order: { createdAt: 'DESC' },
					},
					includeMeta: [
						{
							association: 'stage',
							where: {
								uuid: stage,
							},
						},
					],
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	useEffect(() => {
		if (consulatation_list?.data?.length > 0) {
			setConslutationListUuid(consulatation_list?.data[0]?.uuid);
			setConslutationListData(consulatation_list?.data[0]?.dentalFormula);
		}
	}, [consulatation_list]);

	console.log('chooseModal88', 333333333333);

	const hendelDeleteService = (type: string, item: any) => {
		setLocalChosenService((prev: any[]) => {
			const data = [
				...prev.filter((service: any) => {
					if (service.uuid === item.uuid) {
						if (type === 'bridge') {
							return !(service.start === item.start && service.end === item.end);
						} else {
							return service.theeth !== item.theeth;
						}
					}
					return true;
				}),
			];
			setChoosenService([...data]);

			return [...data];
		});
	};

	const [serviceNameChosenArray, setServiceNameChosenArray] = useState([]);

	const queryClient = useQueryClient();
	const DebounsedSearchFilterLocal = useDebounce(values.serviceNameChosen);

	useEffect(() => {
		if (values.serviceNameChosen) {
			let filteredArray = [] as any;
			if (/\d/.test(values.serviceNameChosen)) {
				choosenServices?.filter((item) => {
					item?.serviceId?.includes(values.serviceNameChosen);
				});
			} else {
				filteredArray = choosenServices?.filter(
					(item) => item?.title?.includes(values.serviceNameChosen),
				);
			}

			// Sort the filteredArray to have the last added item on top

			setServiceNameChosenArray(filteredArray);
		}
	}, [DebounsedSearchFilterLocal, choosenServices, values.serviceNameChosen]);

	const { mutate: addService } = useMutation(
		({ uuid, inputDto }: CreateServiceParams) => StageService.update(uuid, inputDto),
		{
			onSuccess: () => {
				queryClient.invalidateQueries('services');
				queryClient.invalidateQueries('stages');
				setIsOpen(false);
				setValues({
					services: [],
					serviceName: '',
				});
				if (setServiceDropdownInfo) {
					setServiceDropdownInfo([]);
				}
			},
			onError: (error) => {
				console.error(error); // Using console.error for error logging
			},
		},
	);

	const { mutate: addFavorite } = useMutation(
		(inputDto: any) => ServiceService.createFavorite(inputDto),
		{
			onSuccess: () => {
				queryClient.invalidateQueries('services');
			},
			onError: (error) => {
				console.error(error); // Using console.error for error logging
			},
		},
	);

	const { mutate: updateConslutationList } = useMutation(
		({ uuid, inputDto }: CreateConsultationParams) =>
			ConsultationListService.update(uuid, inputDto),
		{
			onSuccess: () => {
				queryClient.invalidateQueries('consultation_list');
				queryClient.invalidateQueries('stages');
				setValues({
					services: [],
					serviceName: '',
				});
				setIsOpen(false);
				setSelectMany(false);
				setSelectedItems([]);
				if (setServiceDropdownInfo) {
					setServiceDropdownInfo([]);
				}
			},
			onError: (error) => {
				console.error(error); // Using console.error for error logging
			},
		},
	);

	const handleSaveList = async (e: React.FormEvent<HTMLFormElement>) => {
		if (consultationListUuid) {
			const data_uuids = localChoosenServices.map((item: any) => ({
				uuid: item.uuid,
				...(item.start && item.end
					? { start: item.start, end: item.end }
					: { theeth: item.theeth }),
			}));
			const data = getInfoForCreateVisit(
				localChoosenServices,
				consulatation_list.data[0].dentalFormula,
			);
			if (setChosenValues) {
				setChosenValues((prev: any) => ({
					...prev,
					services: localChoosenServices,
					form_data: data.data,
					consulatation_list_uuid: consultationListUuid,
				}));
			}

			if (setServiceUuid) {
				setServiceUuid(data_uuids.map((item: any) => item.uuid));
			}
		}
		setIsOpen(false);
	};

	const { isLoading: clinicsLoading, data: clinics } = useQuery<IClinic | null, Error>(
		['clinics', DebounsedSearch],
		() =>
			ClinicService.findAll(
				qs.stringify({
					queryMeta: {
						order: { createdAt: 'DESC' },
					},
					// filterMeta: {
					// 	...(search?.clinic && { name: { iLike: `%${search.clinic}%` } }),
					// },
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	const { isLoading: categoryLoading, data: categoriesWithSubCategories } = useQuery<
		any | null,
		Error
	>(
		['categories'],
		() =>
			CategoryService.findAll(
				qs.stringify({
					queryMeta: {
						paginate: true,
					},
					// includeMeta: [
					// 	{
					// 		association: 'subCategory',
					// 	},
					// ],
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	const hendleSave = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (medicalCard) {
			addService({ uuid: stage, inputDto: values });
		}
		if (!medicalCard && isStage && setChosenValues) {
			setChosenValues((prev: any) => ({
				...prev,
				services: values?.services,
			}));
			setIsOpen(false);
		}

		if (!medicalCard && !isStage && createVisit && setChosenValues) {
			setChosenValues((prev: any) => ({
				...prev,
				services: values?.services,
			}));
			if (setServiceUuid) {
				setServiceUuid(values?.services);
			}
			setIsOpen(false);
		}
		if (!medicalCard && !isStage && !createVisit && createEvent && setChosenValues) {
			setChosenValues((prev: any) => ({
				...prev,
				services: values?.services,
			}));
			// if (setServiceUuid) {
			// 	setServiceUuid(values?.services);
			// }
			setIsOpen(false);
		}
	};

	return (
		<>
			<Modal
				isCentered
				isStaticBackdrop
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				size='xl'
				modalLevel={1060}>
				<ModalHeader setIsOpen={setIsOpen} style={{ padding: '2.25rem 2.25rem 0' }}>
					<></>
				</ModalHeader>
				<form style={{ padding: '0rem 2.25rem 0' }}>
					<ModalBody className='col-12 d-flex w-100 justify-content-between'>
						<div className='col-5 d-flex flex-column gap-2' style={{ width: '48%' }}>
							<span className='fs-5 fw-bold'>Список Услуг</span>
							<div
								// style={{ minHeight: 420 }}
								className='col-12 d-flex flex-column h-100 justify-content-between pb-5'>
								<div className='d-flex flex-column flex-wrap col-12 gap-2'>
									<FormGroup
										id='surname'
										label='Введите название или код услуги'
										className='col-12'>
										<Input
											name='serviceName'
											type={'search'}
											onChange={(e: any) => handleCheckboxChange(e)}
											value={values?.serviceName}
										/>
									</FormGroup>
									<div className='col-12 d-flex gap-3'>
										<FormGroup
											id='clinic'
											label='Филиал'
											className='m-0 d-flex flex-column col-3'>
											<div style={{ position: 'relative' }}>
												<select
													required
													onChange={(e) => {
														changeFilterValues(
															'clinic',
															e.target.value,
														);
													}}
													value={filter?.clinic}
													className='form-select'
													aria-label='Выберите пол'>
													<option value={''}>Все</option>
													{clinics?.data.map(
														(item: IClinic, index: number) => (
															<option value={item.uuid}>
																{item.name}
															</option>
														),
													)}
												</select>
											</div>
										</FormGroup>
										<FormGroup
											id='healing_type'
											label='Тип Лечения'
											className='m-0 d-flex flex-column col-3'>
											<div style={{ position: 'relative' }}>
												<select
													required
													onChange={(e) => {
														changeFilterValues(
															'treatment_type',
															e.target.value,
														);
													}}
													value={values?.treatment_type}
													className='form-select'
													aria-label='Выберите пол'>
													<option value={''}>Все</option>{' '}
													<option value={'Амбулаторная'}>
														Амбулаторная
													</option>
													<option value={'Стационарная'}>
														Стационарная
													</option>
												</select>
											</div>
										</FormGroup>
										<FormGroup
											id='category'
											label='Категория'
											className='m-0 d-flex flex-column'>
											<div style={{ position: 'relative' }}>
												<select
													required
													onChange={(e) => {
														changeFilterValues(
															'category',
															e.target.value,
														);
													}}
													value={values?.category}
													className='form-select'
													aria-label='Выберите пол'>
													<option value={''}>Все</option>
													{categoriesWithSubCategories?.data.map(
														(item: any, index: number) => (
															<option value={item.uuid}>
																{item?.name?.rus}
															</option>
														),
													)}
												</select>
											</div>
										</FormGroup>
									</div>
									{services?.data.length ? (
										services?.data.map((i: IService, index: number) => (
											<ServicesListButton
												index={index}
												addFavorite={addFavorite}
												type={i.service_type}
												chosenServices={choosenServices}
												setChosenServices={setChoosenService}
												changeChosenServicesValue={
													changeChosenServicesValue
												}
												localChosenServices={localChoosenServices}
												setLocalChosenServices={setLocalChosenService}
												item={{
													...i,
													...(i.service_type === 'bridge'
														? { start: '', end: '' }
														: { theeth: '' }),
														count: '1',

												}}
												isDisabled={
													!createVisit &&
													!isStage &&
													visits_services?.data.some(
														(item: any) =>
															item?.services.some(
																(service: any) =>
																	service?.uuid === i?.uuid,
															),
													)
												}
												// isChecked={values?.services?.includes(i.uuid)}
												handleCheckboxChange={handleCheckboxChange}
												themeStatus={themeStatus}
											/>
										))
									) : (
										<h4>Услуги не найдены</h4>
									)}
								</div>
								<div className='col-12'>
									{/* {stage && isStage || createVisit ? (
										<PaginationButtons
											data={services || []}
											label='customers'
											small
											filterPagination
											setCurrentPage={setServicesCurrentPage}
											currentPage={servicesCurrentPage}
											perPage={servicesPerPage}
											setPerPage={setServicesPerPage}
											totalPage={
												Math.ceil(
													services_count?.meta?.count / servicesPerPage,
												) || 1
											}
										/>
									) : ( */}
									<PaginationButtons
										data={services || []}
										label='customers'
										className='card-footer'
										small
										filterPagination
										setCurrentPage={setServicesCurrentPage}
										currentPage={servicesCurrentPage}
										perPage={servicesPerPage}
										setPerPage={setServicesPerPage}
										totalPage={
											Math.ceil(
												services_count?.data?.length / servicesPerPage,
											) || 1
										}
									/>
									{/* )} */}
								</div>
							</div>
						</div>
						<div
							className='col-1 d-flex justify-content-center aligin-items-center'
							style={{ width: '4%' }}>
							<div
								style={{
									width: 3,
									height: '100%',
									backgroundColor: 'rgba(123, 123, 123, 0.4)',
								}}></div>
						</div>
						<div className='col-5 d-flex flex-column gap-2' style={{ width: '48%' }}>
							<span className='fs-5 fw-bold'>Выбранные Услуги</span>
							<div
								// style={{ minHeight: 420 }}
								className='col-12 d-flex flex-column h-100 justify-content-between pb-5'>
								<div className='d-flex flex-column flex-wrap col-12 gap-2'>
									<FormGroup
										id='surname'
										label='Введите название или код услуги'
										className='col-12'>
										<Input
											name='serviceNameChosen'
											type={'search'}
											onChange={(e: any) => handleCheckboxChange(e)}
											value={values?.serviceNameChosen}
										/>
									</FormGroup>
									{DebounsedSearchFilterLocal ? (
										serviceNameChosenArray.length ? (
											dataPagination(
												serviceNameChosenArray,
												servicesChosenCurrentPage,
												servicesPerPage,
											).map((i: IService, index: number) => (
												<ServicesListButton
													localChosenServices={localChoosenServices}
													setLocalChosenServices={setLocalChosenService}
													type={i.service_type}
													changeChosenServicesValue={
														changeChosenServicesValue
													}
													chosenServices={choosenServices}
													setChosenServices={setChoosenService}
													index={index}
													item={i}
													isDisabled={
														!createVisit &&
														!isStage &&
														visits_services?.data.some(
															(item: any) =>
																item?.services.some(
																	(service: any) =>
																		service?.uuid === i?.uuid,
																),
														)
													}
													handleDeleteService={hendelDeleteService}
												/>
											))
										) : (
											<h4>Услуги не найдены</h4>
										)
									) : localChoosenServices ? (
										dataPagination(
											localChoosenServices,
											servicesChosenCurrentPage,
											servicesPerPage,
										).map((i: IService, index: number) => (
											<ServicesListButton
												type={i.service_type}
												localChosenServices={localChoosenServices}
												setLocalChosenServices={setLocalChosenService}
												chosenServices={choosenServices}
												changeChosenServicesValue={
													changeChosenServicesValue
												}
												setChosenServices={setChoosenService}
												index={index}
												item={i}
												isDisabled={
													!createVisit &&
													!isStage &&
													visits_services?.data.some(
														(item: any) =>
															item?.services.some(
																(service: any) =>
																	service?.uuid === i?.uuid,
															),
													)
												}
												handleDeleteService={hendelDeleteService}
											/>
										))
									) : (
										<h4>Услуги не найдены</h4>
									)}
								</div>
								{localChoosenServices?.length > 5 && (
									<div className='col-12'>
										<PaginationButtons
											data={localChoosenServices || []}
											label='customers'
											small
											filterPagination
											serviceModal
											setCurrentPage={setServicesChosenCurrentPage}
											currentPage={servicesChosenCurrentPage}
											perPage={servicesPerPage}
											setPerPage={setServicesPerPage}
											totalPage={
												Math.ceil(
													localChoosenServices?.length / servicesPerPage,
												) || 1
											}
										/>
									</div>
								)}
							</div>
						</div>
					</ModalBody>
				</form>
				<ModalFooter className='pt-0 pb-4'>
					<div className='d-flex justify-content-end col-12 gap-3 px-3 py-0'>
						<Button
							className='fw-bold fs-6'
							color='info'
							type='submit'
							onClick={handleSaveList}>
							Сохранить
						</Button>
					</div>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default ServiceChooseModal;
