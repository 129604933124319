/* eslint-disable import/order */
import React, { FormEvent, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
// import { Calendar as DatePicker } from 'react-date-range';
import { FormikHelpers, useFormik } from 'formik';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../../layout/SubHeader/SubHeader';
import Input from '../../../components/bootstrap/forms/Input';
import Icon from '../../../components/icon/Icon';
import Button, { ButtonGroup } from '../../../components/bootstrap/Button';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Popovers from '../../../components/bootstrap/Popovers';
import { demoPagesMenu } from '../../../menu';
import useDarkMode from '../../../hooks/useDarkMode';
import VisitTable from './VisitTable';
import eventList, { IEvents } from '../../data/events';
import { CardLabel, CardTitle } from '../../../components/bootstrap/Card';
import CurrentDate from '../../components/header/components/Date';
import { DropdownToggle } from '../../../components/bootstrap/Dropdown';
import { PER_COUNT } from '../../../components/PaginationButtons';
import { getViews } from '../../../components/extras/calendarHelper';
import { Views, dayjsLocalizer, View as TView } from 'react-big-calendar';
import { TColor } from '../../../type/color-type';
import Tooltips from '../../../components/bootstrap/Tooltips';
import Avatar, { AvatarGroup } from '../../../components/Avatar';
import { IUserProps } from '../../data/userDummyData';
import PlanVisitModal from './planVisit/PlanVisitModal';
import { IPatient } from '@textnpayme/custom/api/interface/patient.interface';
import { PatientService } from '../../../custom/api/service/patient.service';
import { useQuery } from 'react-query';
import * as qs from 'qs';
import { VisitService } from '../../../custom/api/service/visit.service';
import { IVisit } from '@textnpayme/custom/api/interface/visit.interface';
import { DateTime } from 'luxon';
import { DatePicker, Space } from 'antd';

interface IEvent extends IEvents {
	user?: IUserProps;
	users?: IUserProps[];
	color?: TColor;
}

function formatDate(date: Date): any {
	const year: number = date.getFullYear();
	const month: number = date.getMonth() + 1; // Adding 1 because getMonth() returns zero-based month index
	const day: number = date.getDate();

	// Padding month and day with leading zero if needed
	const formattedMonth: string = month < 10 ? `0${month}` : `${month}`;
	const formattedDay: string = day < 10 ? `0${day}` : `${day}`;

	// Constructing the YYYY-MM-DD string
	const formattedDate: string = `${year}-${formattedMonth}-${formattedDay}`;
	const formattedDateDot: string = `${formattedDay}.${formattedMonth}.${year}`;

	return {
		date1: formattedDate,
		date2: formattedDateDot,
	};
}
const MyEventDay = (data: { event: IEvent }) => {
	const { event } = data;
	return (
		<Tooltips
			title={`${event?.name} / ${dayjs(event.start).format('LT')} - ${dayjs(event.end).format(
				'LT',
			)}`}>
			<div className='row g-2'>
				{event?.user?.src && (
					<div className='col-auto'>
						<Avatar src={event?.user?.src} srcSet={event?.user?.srcSet} size={16} />
					</div>
				)}
				{event?.users && (
					<div className='col'>
						<AvatarGroup size={16}>
							{event.users.map((user) => (
								// eslint-disable-next-line react/jsx-props-no-spreading
								<Avatar key={user.src} {...user} />
							))}
						</AvatarGroup>
					</div>
				)}
				<small className='col text-truncate'>
					{event?.icon && <Icon icon={event?.icon} size='lg' className='me-2' />}
					{event?.name}
				</small>
			</div>
		</Tooltips>
	);
};
const VisitsPage = () => {
	const { RangePicker } = DatePicker;
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [perPage, setPerPage] = useState<number>(PER_COUNT['10']);
	const [query, setQuery] = useState({
		queryMeta: {
			paginate: true,
			limit: perPage,
			page: currentPage,
		},
		filterMeta: {},
	});

	const [editModalStatus, setEditModalStatus] = useState<boolean>(false);

	const [viewMode, setViewMode] = useState<TView>(Views.MONTH);
	const [eventAdding, setEventAdding] = useState(false);

	const localizer = dayjsLocalizer(dayjs);
	const { themeStatus, darkModeStatus } = useDarkMode();
	const [date, setDate] = useState<Date>(new Date());
	const [events, setEvents] = useState(eventList);
	const views = getViews();
	const [toggleInfoEventCanvas, setToggleInfoEventCanvas] = useState(false);
	const setInfoEvent = () => setToggleInfoEventCanvas(!toggleInfoEventCanvas);
	// Change view mode
	const handleViewMode = (e: dayjs.ConfigType) => {
		setDate(dayjs(e).toDate());
		setViewMode(Views.DAY);
	};

	const initialEventItem: IEvent = {
		start: undefined,
		end: undefined,
		name: undefined,
		id: undefined,
		user: undefined,
	};
	// Selected Event
	const savedAllDay = localStorage.getItem('allDay') || 'false';
	const initialAllDay = JSON.parse(savedAllDay);
	const [eventItem, setEventItem] = useState<IEvent>(initialEventItem);
	// BEGIN :: Upcoming Events
	const [upcomingEventsInfoOffcanvas, setUpcomingEventsInfoOffcanvas] = useState(false);
	const [allDayButton, setAllDayButton] = useState<boolean>(initialAllDay);
	const handleUpcomingDetails = () => {
		setUpcomingEventsInfoOffcanvas(!upcomingEventsInfoOffcanvas);
	};
	// New Event
	const handleSelect = ({ start, end }: { start: any; end: any }) => {
		setEventAdding(true);
		setEventItem({ start, end });
	};

	const [upcomingEventsEditOffcanvas, setUpcomingEventsEditOffcanvas] = useState(false);
	const handleUpcomingEdit = () => {
		setUpcomingEventsEditOffcanvas(!upcomingEventsEditOffcanvas);
	};
	// END :: Upcoming Events
	const formik = useFormik({
		onSubmit<Values>(
			values: Values,
			formikHelpers: FormikHelpers<Values>,
		): void | Promise<any> {
			return undefined;
		},
		initialValues: {
			customerName: '',
			service: '',
			date: dayjs().add(1, 'days').format('YYYY-MM-DD'),
			time: '10:30',
			branch: '',
			doctor: '',
		},
	});
	const dateNow = new Date();
	dateNow.setHours(4, 0, 0, 0);
	const today = new Date(dateNow.getTime());
	const savedDate = localStorage.getItem('dateOfVisit');
	const initialDate = savedDate ? new Date(JSON.parse(savedDate)) : today;
	const [dateDay, setDateDay] = useState<any>(formatDate(initialDate));

	const setAndSaveDate = (date: Date | null) => {
		date ? setDateDay(formatDate(date)) : setDateDay(null);
		date !== today && setAndSaveDateTimePicker(null);
		localStorage.setItem('dateOfVisit', JSON.stringify(date));
	};
	console.log(dateDay.date1, formatDate(today).date1, dateDay.date1 === formatDate(today).date1);
	const { isLoading, data: visits }: { data: IVisit[] | any; isLoading: boolean } = useQuery(
		['visits'],
		() =>
			VisitService.findAll(
				qs.stringify({
					queryMeta: {
						paginate: true,
						limit: perPage,
						page: currentPage,
					},
					filterMeta: {
						// visitDate: {DATE:moment().format('YYYY-MM-DD HH:mm:ss')},
					},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	const handlePrevDay = () => {
		const prevDate = new Date(dateDay.date1);
		prevDate.setDate(prevDate.getDate() - 1);
		setAndSaveAllDayButton(false);
		setAndSaveDate(prevDate);
		if (dateTimePicker) {
			setAndSaveDateTimePicker(null);
		}
	};

	const handleNextDay = () => {
		const nextDate = new Date(dateDay.date1);
		nextDate.setDate(nextDate.getDate() + 1);
		setAndSaveAllDayButton(false);
		setAndSaveDate(nextDate);
		if (dateTimePicker) {
			setAndSaveDateTimePicker(null);
		}
	};
	const handleToday = () => {
		setAndSaveDate(today);
		setAndSaveAllDayButton(false);
		if (dateTimePicker) {
			setAndSaveDateTimePicker(null);
		}
	};

	const getDayOfWeekInRussian = (dateString: string) => {
		if (dateString) {
			const date = DateTime.fromISO(dateString);
			const russianDays: string[] = [
				'Понедельник',
				'Вторник',
				'Среда',
				'Четверг',
				'Пятница',
				'Суббота',
				'Воскресенье',
			];
			const dayIndex = date.weekday - 1; // Luxon uses 1-based index
			return russianDays[dayIndex];
		} else {
			return '';
		}
	};
	const savedDatePickerDays = localStorage.getItem('datePicker');
	const initialDatePickerDays: [Dayjs | null, Dayjs | null] | null = savedDatePickerDays
		? (JSON.parse(savedDatePickerDays)?.map((date: string | null) =>
				date ? dayjs(date) : null,
		  ) as [Dayjs | null, Dayjs | null])
		: null;

	const [dateTimePicker, setDateTimePicker] = useState<[Dayjs | null, Dayjs | null] | null>(
		initialDatePickerDays,
	);

	const setAndSaveDateTimePicker = (days: [Dayjs | null, Dayjs | null] | null) => {
		setDateTimePicker(days);
		days && setAndSaveDate(today);
		localStorage.setItem('datePicker', JSON.stringify(days));
	};
	const setAndSaveAllDayButton = (allDay: boolean) => {
		setAllDayButton(allDay);
		localStorage.setItem('allDay', JSON.stringify(allDay));
		if (dateDay) {
			setAndSaveDate(today);
		}
		if (dateTimePicker) {
			localStorage.setItem('allDay', 'false');
		}
	};

	const handleDateChange = (
		dates: [Dayjs | null, Dayjs | null] | null,
		dateStrings: [string, string],
	) => {
		if (dates && dates[0] && dates[1] && dates[0].isValid() && dates[1].isValid()) {
			setAndSaveDateTimePicker(dates);
			setAndSaveAllDayButton(false);
		} else {
			console.error('Invalid date range');
			setAndSaveDateTimePicker(null);
		}
	};

	const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);
	return (
		<PageWrapper title={demoPagesMenu.listPages.subMenu.listFluid.text}>
			{/* <SubHeader className='mb-2'>
				<SubHeaderLeft>
					<Icon icon='Info' className='me-2' size='2x' />
					<span className='text-muted'>
						You have <Icon icon='TaskAlt' color='success' className='mx-1' size='lg' />{' '}
						3 approved appointments and{' '}
						<Icon icon='Pending' color='warning' className='mx-1' size='lg' /> 4 pending
						appointments for today.
					</span>
				</SubHeaderLeft>
				<SubHeaderRight>
					<Button style={{ pointerEvents: 'none' }} color={themeStatus}>
						{`${dayjs(date).startOf('weeks').format('MMM YYYY')}`}
					</Button>
				</SubHeaderRight>
			</SubHeader> */}
			<div
				className='subheader mt-1 pt-2 pb-2 d-flex flex-nowrap overflow-x-auto overflow-y-hidden align-items-center gap-5'
				style={{ maxWidth: 'unset' }}>
				<SubHeaderLeft className='col-auto d-flex flex-nowrap align-items-center'>
					<CardLabel icon='Alarm' iconColor='info'>
						<CardTitle tag='div' className='fs-5'>
							Визиты
						</CardTitle>
					</CardLabel>
					<SubheaderSeparator />
					<div className='d-flex col-auto gap-4 flex-nowrap'>
						<div>
							<div className='fs-5 fw-bold'>
								<div className='d-flex align-items-center flex-row gap-2 '>
									<span className='fs-3'>{dateDay.date2},</span>{' '}
									<small>{getDayOfWeekInRussian(dateDay.date1)}</small>
								</div>
							</div>
						</div>
						<div>
							<ButtonGroup>
								<Button isLight color={'primary'} onClick={handlePrevDay}>
									{'<'}
								</Button>
								<Button
									isLight={
										!(
											dateDay.date1 === formatDate(today).date1 &&
											!dateTimePicker &&
											!allDayButton
										)
									}
									color={'primary'}
									onClick={handleToday}>
									Сегодня
								</Button>
								<Button isLight color={'primary'} onClick={handleNextDay}>
									{'>'}
								</Button>
							</ButtonGroup>
						</div>
						<div>
							<Button
								isLight={!allDayButton}
								color={'primary'}
								onClick={() => {
									setAndSaveAllDayButton(true);
									setAndSaveDate(today);
									setAndSaveDateTimePicker(null);
								}}>
								За всё время
							</Button>
						</div>
						<div style={{ position: 'relative' }}>
							<Button
								isLight={!dateTimePicker}
								color='primary'
								icon='Window'
								className='text-nowrap'
								isDisable={datePickerOpen}
								onClick={() => setDatePickerOpen((prev) => !prev)}
								style={{ cursor: 'pointer' }}>
								{dateTimePicker
									? `${dateTimePicker[0]?.format(
											'DD.MM.YYYY',
									  )} - ${dateTimePicker[1]?.format('DD.MM.YYYY')}`
									: 'Указать промежуток'}
							</Button>
							<div
								style={{
									position: 'absolute',
									zIndex: '-1',
									opacity: '0',
									maxHeight: 0,
									display: datePickerOpen ? 'block' : 'none',
								}}>
								<Space direction='vertical' size={12}>
									<RangePicker
										onOpenChange={(e: boolean) => setDatePickerOpen(e)}
										open={datePickerOpen}
										onChange={handleDateChange}
										value={dateTimePicker}
									/>
								</Space>
							</div>
						</div>
					</div>
				</SubHeaderLeft>
				<SubHeaderRight className='mw-auto'>
					<Button
						color='primary'
						className={classNames('text-nowrap', {
							'border-light': !darkModeStatus,
						})}
						icon='Add'
						onClick={handleUpcomingEdit}>
						Запланировать визит{' '}
					</Button>
				</SubHeaderRight>
			</div>
			<Page container='fluid'>
				<VisitTable
					setDateDay={setDateDay}
					dateDay={dateDay}
					dateInterval={dateTimePicker}
					setDateInterval={setAndSaveDateTimePicker}
					formatDate={formatDate}
					allDayButton={allDayButton}
					setAllDayButton={setAndSaveAllDayButton}
					isFluid
				/>
			</Page>
			<PlanVisitModal
				setIsOpen={setUpcomingEventsEditOffcanvas}
				isOpen={upcomingEventsEditOffcanvas}
			/>
		</PageWrapper>
	);
};

export default VisitsPage;
