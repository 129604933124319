import React, { SVGProps } from "react";

export const TC_TL = (props: SVGProps<SVGSVGElement>) => {
   return (
		<>
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="path-1-inside-1_523_10066" fill={!!props.fill?props.fill:"white"}>
            <path d="M0.5 20.23C0.5 17.6035 1.01731 15.0028 2.02241 12.5763C3.0275 10.1498 4.50069 7.94502 6.35786 6.08784C8.21504 4.23067 10.4198 2.75748 12.8463 1.75239C15.2728 0.747296 17.8736 0.22998 20.5 0.22998V20.23H0.5Z"/>
            </mask>
            <g filter="url(#filter0_i_523_10066)">
            <path d="M0.5 20.23C0.5 17.6035 1.01731 15.0028 2.02241 12.5763C3.0275 10.1498 4.50069 7.94502 6.35786 6.08784C8.21504 4.23067 10.4198 2.75748 12.8463 1.75239C15.2728 0.747296 17.8736 0.22998 20.5 0.22998V20.23H0.5Z" fill={!!props.fill?props.fill:"url(#paint0_linear_523_10066)"} fill-opacity={!!props.fill?"0.8":"0.3"}/>
            </g>
            <path d="M0.5 20.23C0.5 17.6035 1.01731 15.0028 2.02241 12.5763C3.0275 10.1498 4.50069 7.94502 6.35786 6.08784C8.21504 4.23067 10.4198 2.75748 12.8463 1.75239C15.2728 0.747296 17.8736 0.22998 20.5 0.22998V20.23H0.5Z" stroke="black" stroke-opacity="0.2" mask="url(#path-1-inside-1_523_10066)"/>
            <defs>
            <filter id="filter0_i_523_10066" x="0.5" y="0.22998" width="20" height="24" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="4"/>
            <feGaussianBlur stdDeviation="2"/>
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_523_10066"/>
            </filter>
            <linearGradient id="paint0_linear_523_10066" x1="20.5" y1="0.729981" x2="0.5" y2="20.23" gradientUnits="userSpaceOnUse">
            <stop stop-color="#F8F4ED"/>
            <stop offset="1" stop-color="#9B9B9B"/>
            </linearGradient>
            </defs>
        </svg>
        </>
	);
};