import React, { useCallback, useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import AuthContext from '../../../contexts/authContext';
import USERS, { getUserDataWithEmail } from '../../data/customData/receptionistData';
import ForgotPass from '../../../components/ForgotPass';
import { authPage } from '../../../menu';
import { useMutation } from 'react-query';
import { AuthService } from '../../../custom/api/service/auth.service';
import Icon from '../../../components/icon/Icon';

const ResetPassword = () => {
	const { setUser } = useContext(AuthContext);
	const navigate = useNavigate();
	const handleOnClick = useCallback(() => navigate(authPage.login.path), [navigate]);
	const handleOnReturn = useCallback(() => navigate('/login'), [navigate]);
	const [sent, setSent] = useState<boolean>(false);

	const [email, setEmail] = useState('');
	const [emailError, setEmailError] = useState('');

	const { mutate, isLoading } = useMutation(
		(inputDto: { email: string }) => AuthService.forgotPassword(inputDto),
		{
			onSuccess: (response, formData) => {
				setSent(true);
			},
			onError: (error: any) => {
				setSent(false);
				if (error?.response.data?.message === 'Email not verified') {
					setEmailError('нет пользователя с такой эл. почтой');
				}
				// setError(error?.response?.data?.message);
				// console.log(error?.response?.data?.message, 777);
			},
		},
	);

	const emailCheck = (email: string) => {
		return !!getUserDataWithEmail(email);
	};
	const passwordCheck = (email: string, password: string) => {
		return getUserDataWithEmail(email).password === password;
	};
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			loginEmail: USERS.JOHN.email,
			loginPassword: USERS.JOHN.password,
		},
		validate: (values) => {
			const errors: { loginEmail?: string; loginPassword?: string } = {};

			if (!values.loginEmail) {
				errors.loginEmail = 'Введите эл. почту';
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: (values) => {
			if (emailCheck(values.loginEmail)) {
				if (passwordCheck(values.loginEmail, values.loginPassword)) {
					if (setUser) {
						setUser(values.loginEmail);
					}

					handleOnClick();
				} else {
					formik.setFieldError('loginPassword', 'email and password do not match.');
				}
			}
		},
	});

	const handleReset = () => {
		mutate({ email: email });
	};

	return (
		<PageWrapper isProtected={false} title='Sign Up' className='custom-login-wrapper'>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								{!sent ? (<div className='text-center h4 mb-4'>Забыли пароль?</div>):(' ')}
								<div className='text-center my-4'>
									{sent ? (
										<Icon color={'success'} size={'10x'} icon={'DoneAll'} />
									) : (
										<ForgotPass width={300} />
									)}
								</div>
								{
									
								}
								<p className='text-center fs-5 mt-2 mb-5 fw-semibold'>
									{sent
										? `Ссылка  для восстановления пароля отправлено на указанную вами эл. почту`
										: `Для восстановления вашего пароля укажите вашу эл. почту. Мы
									отправим туда ссылку для восстановления пароля.`}
								</p>
								{!sent && (
									<form className='row g-4'>
										<div className='col-12'>
											<div className='d-flex justify-content-center align-items-center col-12 p-2'>
												<span
													className='fs-7 fw-bold'
													style={{ color: 'red' }}>
													{emailError}
												</span>
											</div>
											<FormGroup
												id='loginEmail'
												isFloating
												label='Эл. почта'
												className='mb-3'>
												<Input
													autoComplete='email'
													value={email}
													onChange={(e: any) => {
														setEmail(e.target.value);
													}}
												/>
											</FormGroup>
										</div>
										<div className='col-12'>
											<Button
												tag='a'
												to={''}
												color='warning'
												className='w-100 py-3 mb-2'
												onClick={handleReset}>
												Подтвердить
											</Button>
											<Button
												isLight
												className='w-100 py-3'
												onClick={handleOnReturn}>
												Назад
											</Button>
										</div>
									</form>
								)}
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default ResetPassword;
