import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useFormik } from 'formik';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import AuthContext from '../../../contexts/authContext';
import USERS, { getUserDataWithEmail } from '../../data/customData/receptionistData';
import CreatePass from '../../../components/CreatePass';
import { authPage } from '../../../menu';
import { useMutation } from 'react-query';
import { AuthService } from '../../../custom/api/service/auth.service';
import { id } from 'date-fns/locale';

interface IPassResest {
	code: string | null;
	password: string | null;
	email: string | null;
	passwordCheck?: string;
	type: string;
}

const CreatePassword = () => {
	const { setUser } = useContext(AuthContext);
	const { darkModeStatus } = useDarkMode();
	const navigate = useNavigate();
	const handleOnClick = useCallback(() => navigate(authPage.login.path), [navigate]);

	const [error, setError] = useState('');

	const location = useLocation();

	// Create an instance of URLSearchParams with the search string from the location
	const queryParams = new URLSearchParams(location.search);

	// Extract the 'email' and 'code' parameters
	const email = queryParams.get('email');
	const code = queryParams.get('code');
	const error_pass = queryParams.get('error');

	const handleOnReturn = useCallback(() => navigate(authPage.resetPassword.path), [navigate]);
	const emailCheck = (email: string) => {
		return !!getUserDataWithEmail(email);
	};
	const passwordCheck = (email: string, password: string) => {
		return getUserDataWithEmail(email).password === password;
	};
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			loginEmail: USERS.JOHN.email,
			loginPassword: USERS.JOHN.password,
		},
		validate: (values) => {
			const errors: { loginEmail?: string; loginPassword?: string } = {};

			if (!values.loginEmail) {
				errors.loginEmail = 'Введите эл. почту';
			}

			if (!values.loginPassword) {
				errors.loginPassword = 'Введите пароль';
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: (values) => {
			if (emailCheck(values.loginEmail)) {
				if (passwordCheck(values.loginEmail, values.loginPassword)) {
					if (setUser) {
						setUser(values.loginEmail);
					}

					handleOnClick();
				} else {
					formik.setFieldError('loginPassword', 'email and password do not match.');
				}
			}
		},
	});

	const [values, setValues] = useState<IPassResest>({
		email: null,
		code: null,
		password: '',
		passwordCheck: '',
		type: 'forgot-password',
	});

	const changeValues = (key: string, value: string | number) => {
		setValues((prev: any) => ({ ...prev, [key]: value }));
	};

	useEffect(() => {
		if (email !== null) {
			changeValues('email', email);
		}
		if (code !== null) {
			changeValues('code', code);
		}
	}, [email, code]);

	const { mutate, isLoading } = useMutation(
		(inputDto: IPassResest) => AuthService.changePassword(inputDto),
		{
			onSuccess: (response, formData) => {
				handleOnClick();
			},
			onError: (error: any) => {
				if (!!error?.response.data?.message) {
					setError('Ссылка для восстановления пароля не дествительна');
				}
			},
		},
	);

	const { mutate: newPass } = useMutation(
		(inputDto: IPassResest) => AuthService.createNewPass({ password: inputDto.password }),
		{
			onSuccess: (response, formData) => {
				handleOnClick();
			},
			onError: (response: any, error: any) => {
				console.log(
					response?.response.data?.message[0].instancePath === '/password',
					response?.response.data?.message[0].keyword === 'pattern',
					'response',
				);
				if (response?.response.data?.message[0].instancePath === '/password') {
					console.log(6548465451894898);

					setError(
						'Пароль должен быть длиной не менее 8 символов, содержать заглавную букву, строчную букву, цифру и специальный символ.',
					);
				}
			},
		},
	);

	const handleSubmit = () => {
		if (values.password === values.passwordCheck && !!!error_pass) {
			setError('');
			mutate(values);
		} else if (
			values.password === values.passwordCheck &&
			error_pass === 'reset_your_password'
		) {
			newPass(values);
		} else {
			setError('пароли не совпадают');
		}
	};

	return (
		<PageWrapper isProtected={false} title='Sign Up' className='custom-login-wrapper'>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center h4  mb-3'>Придумайте новый пароль </div>{' '}
								<div className='text-center my-5'>
									<CreatePass width={300} />
								</div>
								<form className='row g-4'>
									<div className='col-12'>
										<div className='d-flex justify-content-center align-items-center col-12 p-2'>
											<span className='fs-7 fw-bold' style={{ color: 'red' }}>
												{error}
												{/* {!isPasswordValid &&
													`		Password must be between 8 and 30
														characters, and include uppercase,
														lowercase, numbers, and special characters.`} */}
											</span>
										</div>
										<FormGroup
											id='loginPassword'
											isFloating
											label='Пароль'
											className='mb-3'>
											<Input
												type='password'
												autoComplete='current-password'
												value={values.password}
												onChange={(e: any) =>
													changeValues('password', e.target.value)
												}
											/>
										</FormGroup>

										<FormGroup
											id='loginPassword1'
											isFloating
											label='Подтвердите пароль'
											className='mb-2'>
											<Input
												type='password'
												autoComplete='current-password'
												value={values?.passwordCheck}
												onChange={(e: any) =>
													changeValues('passwordCheck', e.target.value)
												}
											/>
										</FormGroup>
									</div>
									<div className='col-12'>
										<Button
											color='warning'
											className='w-100 py-3 mb-2'
											onClick={handleSubmit}>
											Подтвердить
										</Button>
										<Button
											isLight
											className='w-100 py-3 mb-2'
											onClick={handleOnReturn}>
											Назад
										</Button>
									</div>
								</form>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default CreatePassword;
