import React, { FC, useState } from 'react';
import classNames from 'classnames';
import Button from '../../../components/bootstrap/Button';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import EVENT_STATUS from '../../../common/data/enumEventStatus';
import useDarkMode from '../../../hooks/useDarkMode';
import StatusSubmitModal from './StatusSubmitModal';

interface ICommonUpcomingEventsProps {
	visit?: any;
	setUpdateVisitModal(...args: unknown[]): unknown;
	setPatientId(...args: unknown[]): unknown;
	setMedicalCaseUuid(...args: unknown[]): unknown;
	setServiceUuid(...args: unknown[]): unknown;
	setClinicUuid(...args: unknown[]): unknown;
	setStageUuid(...args: unknown[]): unknown;
	setVisitUpdate(...args: unknown[]): unknown;
	handleOpenCancel(...args: unknown[]): unknown;
	setVisitUuid(...args: unknown[]): unknown;
	changeStatus(...args: unknown[]): unknown;
	setActModal(...args: unknown[]): unknown;
	setActVisit(...args: unknown[]): unknown;
	setCanvasData?(...args: unknown[]): unknown;
	setEventAdding?(...args: unknown[]): unknown;
}

const VisitDropdownActions: FC<ICommonUpcomingEventsProps> = ({
	visit,
	setUpdateVisitModal,
	setPatientId,
	setMedicalCaseUuid,
	setServiceUuid,
	setClinicUuid,
	setStageUuid,
	setVisitUpdate,
	handleOpenCancel,
	setVisitUuid,
	setCanvasData,
	changeStatus,
	setActModal,
	setEventAdding,
	setActVisit,
}) => {
	const [dropdownClose, setDropdownClose] = useState<boolean>(false);
	const [statusModal, setStatusModal] = useState<boolean>(false);
	const { darkModeStatus } = useDarkMode();

	const handleStatusChange = (status: string) => {
		changeStatus({
			uuid: visit?.uuid,
			patientUuid: visit?.patientUuid,
			status: status,
		});
		setDropdownClose(false);
	};

	const handleEdit = () => {
		setUpdateVisitModal(true);
		setPatientId(visit?.patient?.uuid);
		setMedicalCaseUuid(visit?.medicalCase?.uuid);
		// Safeguard the array spreading
		const services_uuids = visit?.services.map((item: any) => item.event.uuid);

		setServiceUuid((prev: any) => {
			if (!prev) prev = [];

			if (services_uuids) {
				return [...prev, services_uuids];
			}
			return prev;
		});

		setClinicUuid(visit?.clinic?.uuid);
		setStageUuid(visit?.medicalCase?.stage[0]?.uuid);
		setVisitUpdate(visit);
		setDropdownClose(false);
	};

	const handleCanvas = () => {
		if (setCanvasData && setEventAdding) {
			setCanvasData(visit);
			setEventAdding(true);
			setDropdownClose(false);
		}
	};

	const handleCancel = () => {
		handleOpenCancel();
		if (visit?.uuid) {
			setVisitUuid(visit?.uuid);
		}
		setDropdownClose(false);
	};

	return (
		<>
			{visit?.status === EVENT_STATUS.PENDING.name && (
				<Button
					color='info'
					isLight
					className='text-nowrap me-2 fs-6'
					onClick={() => handleStatusChange('В ожидании')}
					style={{ maxWidth: '9.2rem', width: '100%', fontWeight: 'bold' }}>
					Ожидание
				</Button>
			)}

			{visit?.status === EVENT_STATUS.IN_EXPECTATION.name && (
				<Button
					color='info'
					isLight
					className='text-nowrap me-2 fs-6'
					onClick={() => handleStatusChange('В процессе')}
					style={{ maxWidth: '9.2rem', width: '100%', fontWeight: 'bold' }}>
					Подтвердить
				</Button>
			)}
			{visit?.status === EVENT_STATUS.APPROVED.name && (
				<Button
					color='info'
					isLight
					className='d-flex align-items-center justify-content-center text-nowrap me-2 fs-6'
					style={{ maxWidth: '9.2rem', width: '100%', fontWeight: 'bold' }}
					onClick={handleCanvas}>
					Подробнее
				</Button>
			)}
			{visit?.status === EVENT_STATUS.CANCELED.name && (
				<Button
					isOutline={!darkModeStatus}
					color='dark'
					isLight
					className='d-flex align-items-center justify-content-center text-nowrap me-2 fs-6'
					onClick={handleCanvas}
					icon='Info'
					style={{ maxWidth: '9.2rem', width: '100%' }}>
					Подробнее
				</Button>
			)}
			{visit?.status === EVENT_STATUS.IN_PROGRESS.name && (
				<Button
					onClick={() => {
						setActModal(true);
						setActVisit(visit);
					}}
					color='info'
					isLight
					className='text-nowrap me-2 fs-6'
					style={{ maxWidth: '9.2rem', width: '100%', fontWeight: 'bold' }}>
					Создать смету
				</Button>
			)}
			{visit?.status === EVENT_STATUS.NOT_PAID.name && (
				<Button
					onClick={() => {
						setStatusModal(true);
					}}
					color='danger'
					isLight
					className='text-nowrap me-2 fs-6'
					style={{ maxWidth: '9.2rem', width: '100%', fontWeight: 'bold' }}>
					Оплатить
				</Button>
			)}
			{visit?.status !== EVENT_STATUS.APPROVED.name &&
				visit?.status !== EVENT_STATUS.CANCELED.name && (
					<Dropdown isOpen={dropdownClose} setIsOpen={setDropdownClose}>
						<DropdownToggle hasIcon={false}>
							<Button
								isOutline={!darkModeStatus}
								color='dark'
								isLight={darkModeStatus}
								className={classNames(
									'text-nowrap',

									{
										'border-light': !darkModeStatus,
									},
									'fw-normal',
									'fs-6',
								)}>
								•••
							</Button>
						</DropdownToggle>
						<DropdownMenu className='fw-bold'>
							{visit?.status === EVENT_STATUS.PENDING.name && (
								<>
									<DropdownItem>
										<Button onClick={handleEdit}>Редактировать</Button>
									</DropdownItem>
									<DropdownItem>
										<Button onClick={handleCancel}>Отменить визит</Button>
									</DropdownItem>
									<DropdownItem>
										<Button onClick={handleCanvas}>Подробнее</Button>
									</DropdownItem>
								</>
							)}
							{visit?.status === EVENT_STATUS.IN_EXPECTATION.name && (
								<>
									{/* <DropdownItem>
									<Button onClick={handleEdit}>Редактировать</Button>
								</DropdownItem> */}
									<DropdownItem>
										<Button onClick={handleCancel}>Отменить визит</Button>
									</DropdownItem>
									<DropdownItem>
										<Button onClick={handleCanvas}>Подробнее</Button>
									</DropdownItem>
								</>
							)}
							{visit?.status === EVENT_STATUS.IN_PROGRESS.name && (
								<>
									{/* <DropdownItem>
									<Button onClick={handleEdit}>Редактировать</Button>
								</DropdownItem> */}
									<DropdownItem>
										<Button onClick={handleCancel}>Отменить визит</Button>
									</DropdownItem>
									<DropdownItem>
										<Button onClick={handleCanvas}>Подробнее</Button>
									</DropdownItem>
								</>
							)}

							{visit?.status === EVENT_STATUS.NOT_PAID.name && (
								<>
									{/* <DropdownItem>
									<Button onClick={handleCancel}>Отменить визит</Button>
								</DropdownItem> */}
									<DropdownItem>
										<Button onClick={handleCanvas}>Подробнее</Button>
									</DropdownItem>
								</>
							)}
							<></>
						</DropdownMenu>
					</Dropdown>
				)}

			<StatusSubmitModal
				setIsOpen={setStatusModal}
				isOpen={statusModal}
				changeStatus={handleStatusChange}
			/>
		</>
	);
};

export default VisitDropdownActions;
