import React, { FC, useEffect, useState } from 'react';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../../components/bootstrap/Modal';
import Icon from '../../../components/icon/Icon';
import Button from '../../../components/bootstrap/Button';
import Input from '../../../components/bootstrap/forms/Input';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import { useMutation, useQueryClient } from 'react-query';
import useDarkMode from '../../../hooks/useDarkMode';
import { StageService } from '../../../custom/api/service/stage.service';
import Textarea from '../../../components/bootstrap/forms/Textarea';
import { IStage } from '@textnpayme/custom/api/interface/stage.interface';

interface IStageModalProps {
	isOpen: boolean;
	setIsOpen(...args: unknown[]): unknown;
	setStage?(...args: unknown[]): unknown;
	medicalCase: any;
	stage?: any;
}

interface CreateStageParams {
	inputDto: IStage;
}

interface UbdateStageParams {
	uuid: string;
	inputDto: IStage;
}

const StageModal: FC<IStageModalProps> = ({ isOpen, setIsOpen, stage, setStage, medicalCase }) => {
	// Function to trigger the file input click
	const [values, setValues] = useState<IStage>({
		title: '',
		description: '',
		medicalCaseUuid: '',
	});

	useEffect(() => {
		if (!!stage) {
			setValues({
				title: stage.title || '',
				description: stage.description || '',
				medicalCaseUuid: stage.medicalCaseUuid || '',
			});
		}
	}, [stage]);

	const { themeStatus } = useDarkMode();

	const changeValues = (key: string, value: string | number) => {
		setValues((prev) => ({ ...prev, [key]: value }));
	};

	const { mutate: stageUpdate } = useMutation(
		({ uuid, inputDto }: UbdateStageParams) => StageService.update(uuid, inputDto),
		{
			onSuccess: (response, { uuid, inputDto }) => {
				// Update the cache directly to prevent re-render
				queryClient.invalidateQueries('stages');
				queryClient.invalidateQueries('services');
				setIsOpen(false);
				if (setStage && stage) setStage(null);
				setValues({
					title: '',
					description: '',
					medicalCaseUuid: medicalCase || null,
				});
			},
			onError: (error) => {
				console.error(error); // Using console.error for error logging
			},
		},
	);

	useEffect(() => {
		setValues({
			title: '',
			description: '',
			medicalCaseUuid: medicalCase || null,
		});
	}, [medicalCase]);

	const queryClient = useQueryClient();

	const { mutate: createStage } = useMutation(
		({ inputDto }: CreateStageParams) => StageService.create(inputDto),
		{
			onSuccess: () => {
				queryClient.invalidateQueries('stages');
				queryClient.invalidateQueries('medicalCases');
				setIsOpen(false);
				setValues({
					title: '',
					description: '',
					medicalCaseUuid: medicalCase || null,
				});
			},
			onError: (error) => {
				console.error(error); // Using console.error for error logging
			},
		},
	);

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!!stage) {
			stageUpdate({ uuid: stage.uuid, inputDto: values });
		} else {
			createStage({ inputDto: values });
		}
	};

	return (
		<>
			<Modal isCentered isOpen={isOpen} setIsOpen={setIsOpen} size='lg' modalLevel={1060}>
				<ModalHeader setIsOpen={setIsOpen} className='p-4'>
					<span className='fs-3 fw-bold'>
						<Icon icon='Person' className='me-2 fs-2' color='primary' />
						{!!stage ? 'Редактировать этап' : 'Создать этап'}
					</span>
				</ModalHeader>
				<form onSubmit={handleSubmit}>
					<ModalBody className='px-4'>
						<div className='medicalCard'>
							<div className='row'>
								<FormGroup className='col-12' id='medicine' label=' Название'>
									<Input
										type='text'
										onChange={(e: any) => changeValues('title', e.target.value)}
										value={values.title}
									/>
								</FormGroup>
							</div>
							<div className='row'>
								<FormGroup className='col-12' id='medicine' label='Описание'>
									<Textarea
										style={{ maxHeight: 300 }}
										onChange={(e: any) =>
											changeValues('description', e.target.value)
										}
										value={values.description}
									/>
								</FormGroup>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<div className='d-flex flex-row col-12 gap-3 px-3'>
							<Button
								color='primary'
								className='col-6'
								onClick={() => {
									setIsOpen(false);
									if (setStage && stage) setStage(null);
									setValues({
										title: '',
										description: '',
										medicalCaseUuid: medicalCase || null,
									});
								}}>
								Отменить
							</Button>
							<Button
								color='primary'
								type='submit'
								className='col-6'
								onClick={() => {
									setIsOpen(false);
								}}>
								{!!stage ? 'Сохранить' : 'Создать'}
							</Button>
						</div>
					</ModalFooter>
				</form>
			</Modal>
		</>
	);
};

export default StageModal;
