import React, { SVGProps } from "react";

export const THEETH_32 = (props: SVGProps<SVGSVGElement>) => {

    return (
		<>
           <svg width="2rem" height="6.15rem" viewBox="0 0 25 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M15.8047 78C24.0075 70.0399 22.0718 33.7482 20.9325 15.5067C20.6367 8.54175 8.97974 0.0839558 9.31164 8.54222C9.86265 22.5843 12.8047 62.5 13.4117 76.508C13.9847 79.3995 14.6139 79.1555 15.8047 78Z" fill="url(#paint0_linear_402_4967)"/>
<g filter="url(#filter0_f_402_4967)">
<path d="M16.0435 27.6533L18.0435 44.1533" stroke="#F3F3F3" stroke-width="1.5" stroke-linecap="round"/>
</g>
<path d="M15.4 77.4359L8.9502 5C8.9502 5 11.9502 71 12.9502 76.5C13.7215 80.7424 14.4666 78.9971 15.0167 78.2359C15.1272 78.083 15.4167 77.6238 15.4 77.4359Z" fill="url(#paint1_linear_402_4967)"/>
<path d="M8.17242 14.5C8.17242 17 10.1724 29.5 16.1724 29.5C21.6725 29.5 23.6724 17.5 23.6724 14.5V4.5C23.6724 3.5 23.6724 0 21.6724 0H12.1724C9.67236 0 8.67242 1.5 8.17242 3.5C7.67242 5.5 8.17242 12 8.17242 14.5Z" fill="url(#paint2_linear_402_4967)"/>
<path d="M8.17242 14.5C8.17242 17 10.1724 29.5 16.1724 29.5C21.6725 29.5 23.6724 17.5 23.6724 14.5V4.5C23.6724 3.5 23.6724 0 21.6724 0H12.1724C9.67236 0 8.67242 1.5 8.17242 3.5C7.67242 5.5 8.17242 12 8.17242 14.5Z" fill="url(#paint3_radial_402_4967)" fill-opacity="0.3"/>
<g filter="url(#filter1_f_402_4967)">
<path d="M14.5464 12.5C15.5464 17.5 10.8079 16 8.04652 16C5.2851 16 6.04635 13.7091 6.04635 11.5C6.04635 9.29086 13.785 7 16.5464 7C19.3078 7 17.5464 11 14.5464 12.5Z" fill="#FDFDFD"/>
</g>
<defs>
<filter id="filter0_f_402_4967" x="9.29346" y="20.9033" width="15.5" height="30" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_402_4967"/>
</filter>
<filter id="filter1_f_402_4967" x="0.950195" y="2" width="21.9746" height="19.2109" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2.5" result="effect1_foregroundBlur_402_4967"/>
</filter>
<linearGradient id="paint0_linear_402_4967" x1="15.6435" y1="5" x2="15.6435" y2="78.8088" gradientUnits="userSpaceOnUse">
<stop stop-color="#D29439"/>
<stop offset="1" stop-color="#887250"/>
</linearGradient>
<linearGradient id="paint1_linear_402_4967" x1="14.341" y1="14.1293" x2="-20.8914" y2="54.1545" gradientUnits="userSpaceOnUse">
<stop stop-color="#725935"/>
<stop offset="1" stop-color="#5B482A"/>
</linearGradient>
<linearGradient id="paint2_linear_402_4967" x1="15.8113" y1="0" x2="15.8113" y2="29.5" gradientUnits="userSpaceOnUse">
<stop offset="0.64" stop-color="white"/>
<stop offset="1" stop-color="#B48B8B"/>
</linearGradient>
<radialGradient id="paint3_radial_402_4967" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(20.5503 11.2402) rotate(80.2721) scale(3.96848 2.56829)">
<stop stop-color="white" stop-opacity="0"/>
<stop offset="1" stop-color="#9B9B9B"/>
</radialGradient>
</defs>
</svg>


        </>
	);
};