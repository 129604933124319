import React, { SVGProps } from "react";

export const THEETH_13 = (props: SVGProps<SVGSVGElement>) => {

    return (
		<>
          <svg width="24" height="87" viewBox="0 0 24 87" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M16.6 0.813019C4.60003 8.81313 2.93329 45.4797 4.59995 63.813C5.03276 70.813 22.0857 79.3133 21.6002 70.8125C20.7941 56.6998 16.5999 17.3125 20.1006 2.3125C19.2625 -0.593559 18.342 -0.348343 16.6 0.813019Z" fill="url(#paint0_linear_402_4956)"/>
<g filter="url(#filter0_f_402_4956)">
<path d="M10.501 43.5911C9.13359 35.2323 9.20213 30.8806 10.501 23.5911" stroke="#F3F3F3"/>
</g>
<path d="M19.2329 0.327148C12.9857 26.2035 13.8343 42.7606 21.7329 74.3271C20.2329 43.8271 16.7329 23.3271 20.406 3.01942C20.5578 0.812126 19.3068 0.352394 19.236 0.328186L19.2329 0.327148Z" fill="url(#paint1_linear_402_4956)"/>
<path d="M23.0895 71.6434C21.9889 56.0325 15.5216 53.1861 12.2329 53.8125C0.73291 53.8125 0.23291 73.8125 0.23291 75.8125C0.23291 77.8125 4.23291 85.8125 11.2329 86.3125C15.6857 86.6306 19.5906 81.7009 21.8414 77.6569C22.858 75.8306 23.2365 73.7285 23.0895 71.6434Z" fill="url(#paint2_linear_402_4956)"/>
<path d="M23.0895 71.6434C21.9889 56.0325 15.5216 53.1861 12.2329 53.8125C0.73291 53.8125 0.23291 73.8125 0.23291 75.8125C0.23291 77.8125 4.23291 85.8125 11.2329 86.3125C15.6857 86.6306 19.5906 81.7009 21.8414 77.6569C22.858 75.8306 23.2365 73.7285 23.0895 71.6434Z" fill="url(#paint3_radial_402_4956)" fill-opacity="0.3"/>
<defs>
<filter id="filter0_f_402_4956" x="3.00098" y="17.5034" width="13.9937" height="32.1685" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_402_4956"/>
</filter>
<linearGradient id="paint0_linear_402_4956" x1="12.8329" y1="74.3726" x2="12.8329" y2="0.00012207" gradientUnits="userSpaceOnUse">
<stop stop-color="#D29439"/>
<stop offset="1" stop-color="#887250"/>
</linearGradient>
<linearGradient id="paint1_linear_402_4956" x1="15.2355" y1="65.2738" x2="48.7028" y2="19.0652" gradientUnits="userSpaceOnUse">
<stop stop-color="#725935"/>
<stop offset="1" stop-color="#5B482A"/>
</linearGradient>
<linearGradient id="paint2_linear_402_4956" x1="11.7329" y1="86.3271" x2="11.7329" y2="53.7336" gradientUnits="userSpaceOnUse">
<stop offset="0.64" stop-color="white"/>
<stop offset="1" stop-color="#B48B8B"/>
</linearGradient>
<radialGradient id="paint3_radial_402_4956" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(7.73291 71.3271) rotate(-150.255) scale(4.03113 8.06399)">
<stop stop-color="white" stop-opacity="0"/>
<stop offset="1" stop-color="#9B9B9B"/>
</radialGradient>
</defs>
</svg>
 

        </>
	);
};