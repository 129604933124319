import React, { SVGProps } from "react";

export const THEETH_44 = (props: SVGProps<SVGSVGElement>) => {

    return (
		<>
           <svg width="26" height="79" viewBox="0 0 26 79" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M18.6519 78.0693C6.65196 70.0692 3.4952 33.8429 5.16187 15.5096C5.59467 8.50956 23.1475 10.5089 22.662 19.0097C21.8559 33.1225 22.1525 62.0097 22.1525 76.5698C21.3144 79.4759 20.3939 79.2306 18.6519 78.0693Z" fill="url(#paint0_linear_402_4970)"/>
<path d="M19.2926 14.0861C18.7781 28.5016 15.7205 43.3028 21.2721 78.2485C21.2922 78.3752 21.4269 78.4505 21.5177 78.3599C23.6703 76.2131 21.3416 54.5702 22.3125 23.5C23.2598 18.2915 22.8612 15.325 19.8435 13.7513C19.5936 13.6209 19.3027 13.8043 19.2926 14.0861Z" fill="url(#paint1_linear_402_4970)"/>
<g filter="url(#filter0_f_402_4970)">
<path d="M8.899 33.5008C9.04334 43.6776 13.0016 56.1262 14.0214 56.1118C15.0411 56.0973 11.6284 43.641 11.4841 33.4642C11.3397 23.2874 12.2156 13.1331 11.1958 13.1475C10.1761 13.162 8.75467 23.324 8.899 33.5008Z" fill="white"/>
</g>
<path d="M24.8137 12.9007C25.1365 21.2497 20.1919 29 13.7941 29C5.45384 29 3.042 18.5204 2.81278 12.4594C2.78874 11.8238 2.90367 11.1904 3.19231 10.6236C5.4978 6.09575 10.9727 3 13.7941 3C16.7083 3 23.3969 7.71795 24.6938 12.1126C24.7693 12.3683 24.8034 12.6343 24.8137 12.9007Z" fill="url(#paint2_linear_402_4970)"/>
<path d="M24.8137 12.9007C25.1365 21.2497 20.1919 29 13.7941 29C5.45384 29 3.042 18.5204 2.81278 12.4594C2.78874 11.8238 2.90367 11.1904 3.19231 10.6236C5.4978 6.09575 10.9727 3 13.7941 3C16.7083 3 23.3969 7.71795 24.6938 12.1126C24.7693 12.3683 24.8034 12.6343 24.8137 12.9007Z" fill="url(#paint3_radial_402_4970)" fill-opacity="0.2"/>
<g filter="url(#filter1_f_402_4970)">
<path d="M13.8286 3.5L12.8286 6L15.8286 12" stroke="black" stroke-width="0.5" stroke-linecap="round"/>
</g>
<g filter="url(#filter2_f_402_4970)">
<path d="M13.4979 6.7452L10.9662 7.662L8.483 13.8937" stroke="black" stroke-width="0.5" stroke-linecap="round"/>
</g>
<g filter="url(#filter3_f_402_4970)">
<circle cx="2.5" cy="2.5" r="2.5" transform="matrix(-1 0 0 1 21.8286 13)" fill="white"/>
</g>
<defs>
<filter id="filter0_f_402_4970" x="0.888672" y="5.14746" width="21.3228" height="58.9644" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="4" result="effect1_foregroundBlur_402_4970"/>
</filter>
<filter id="filter1_f_402_4970" x="9.55469" y="0.25" width="9.52393" height="15" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur_402_4970"/>
</filter>
<filter id="filter2_f_402_4970" x="5.23291" y="3.49512" width="11.5151" height="13.6487" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur_402_4970"/>
</filter>
<filter id="filter3_f_402_4970" x="12.8286" y="9" width="13" height="13" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_402_4970"/>
</filter>
<linearGradient id="paint0_linear_402_4970" x1="13.7103" y1="11.2656" x2="13.7103" y2="78.8822" gradientUnits="userSpaceOnUse">
<stop stop-color="#B1833C"/>
<stop offset="1" stop-color="#8F6729"/>
</linearGradient>
<linearGradient id="paint1_linear_402_4970" x1="20.379" y1="13.5" x2="20.379" y2="78.5" gradientUnits="userSpaceOnUse">
<stop stop-color="#7D633C"/>
<stop offset="1" stop-color="#564428"/>
</linearGradient>
<linearGradient id="paint2_linear_402_4970" x1="13.8114" y1="3" x2="13.8114" y2="29" gradientUnits="userSpaceOnUse">
<stop offset="0.64" stop-color="white"/>
<stop offset="1" stop-color="#B48B8B"/>
</linearGradient>
<radialGradient id="paint3_radial_402_4970" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(6.82861 15) rotate(60.6422) scale(9.17878 7.77883)">
<stop stop-opacity="0"/>
<stop offset="1" stop-color="#5E5E5E"/>
</radialGradient>
</defs>
</svg>


        </>
	);
};