import React, { SVGProps } from "react";

export const THEETH_23 = (props: SVGProps<SVGSVGElement>) => {

    return (
		<>
           
           <svg width="1.85rem" height="6.7rem" viewBox="0 0 24 87" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M6.86587 0.813019C18.8658 8.81313 20.5325 45.4797 18.8659 63.813C18.4331 70.813 1.38011 79.3133 1.86564 70.8125C2.67171 56.6998 6.86589 17.3125 3.36521 2.3125C4.20333 -0.593559 5.12386 -0.348343 6.86587 0.813019Z" fill="url(#paint0_linear_402_4969)"/>
<g filter="url(#filter0_f_402_4969)">
<path d="M12.9648 43.5911C14.3322 35.2323 14.2637 30.8806 12.9648 23.5911" stroke="#F3F3F3"/>
</g>
<path d="M1.73291 74.3271C6.24313 49.2858 8.24313 32.2858 4.24313 2.28577C4.24313 2.28577 3.99246 1.28307 3.60353 1.66802C3.44318 1.82674 3.25932 2.22137 3.05984 3.01942C6.73291 23.3271 3.23291 43.8271 1.73291 74.3271Z" fill="url(#paint1_linear_402_4969)"/>
<path d="M0.376319 71.6434C1.47695 56.0325 7.94418 53.1861 11.2329 53.8125C22.7329 53.8125 23.2329 73.8125 23.2329 75.8125C23.2329 77.8125 19.2329 85.8125 12.2329 86.3125C7.78011 86.6306 3.87526 81.7009 1.62437 77.6569C0.607829 75.8306 0.229317 73.7285 0.376319 71.6434Z" fill="url(#paint2_linear_402_4969)"/>
<path d="M0.376319 71.6434C1.47695 56.0325 7.94418 53.1861 11.2329 53.8125C22.7329 53.8125 23.2329 73.8125 23.2329 75.8125C23.2329 77.8125 19.2329 85.8125 12.2329 86.3125C7.78011 86.6306 3.87526 81.7009 1.62437 77.6569C0.607829 75.8306 0.229317 73.7285 0.376319 71.6434Z" fill="url(#paint3_radial_402_4969)" fill-opacity="0.3"/>
<defs>
<filter id="filter0_f_402_4969" x="6.47119" y="17.5034" width="13.9937" height="32.1685" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_402_4969"/>
</filter>
<linearGradient id="paint0_linear_402_4969" x1="10.6329" y1="74.3726" x2="10.6329" y2="0.00012207" gradientUnits="userSpaceOnUse">
<stop stop-color="#D29439"/>
<stop offset="1" stop-color="#887250"/>
</linearGradient>
<linearGradient id="paint1_linear_402_4969" x1="8.23029" y1="65.2738" x2="-25.2369" y2="19.0652" gradientUnits="userSpaceOnUse">
<stop stop-color="#725935"/>
<stop offset="1" stop-color="#5B482A"/>
</linearGradient>
<linearGradient id="paint2_linear_402_4969" x1="11.7329" y1="86.3271" x2="11.7329" y2="53.7336" gradientUnits="userSpaceOnUse">
<stop offset="0.64" stop-color="white"/>
<stop offset="1" stop-color="#B48B8B"/>
</linearGradient>
<radialGradient id="paint3_radial_402_4969" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(15.7329 71.3271) rotate(-29.7449) scale(4.03113 8.06399)">
<stop stop-color="white" stop-opacity="0"/>
<stop offset="1" stop-color="#9B9B9B"/>
</radialGradient>
</defs>
</svg>

        </>
	);
};