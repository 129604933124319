import React, { SVGProps } from 'react';

const SvgTheethDeleted = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="9.5" cy="10.23" r="7.57812" stroke="#595959" stroke-width="2"/>
			<path d="M2.01562 18.8081L16.9805 1.16357" stroke="#595959" stroke-width="2"/>
		</svg>
		
	);
};

export default SvgTheethDeleted;
