import { authHost } from '../axios';
import { INotification } from '../interface/notification.interface';

export class NotificationService {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor() {}
	static async findAll(query: any): Promise<INotification[] | null> {
		const { data } = await authHost.get(`notification?${query}`);
		return data;
	}

	static async findOne(uuid: string, query?: any) {
		const { data } = await authHost.get(`patient/${uuid}?${query}`);
		return data;
	}

	static async readNot(uuid: string) {
		const notification = await authHost.patch(`notification/read_not/${uuid}`)
		return notification;
	}

	static async update(data: INotification,query?:any) {		
		const patient = await authHost.patch(`notification?${query}`,data)
		return patient;
	}
}
