import { createSlice } from '@reduxjs/toolkit';

const dentalFormulaSlice = createSlice({
  name: 'dentalFormula',
  
  initialState: {}, // or any initial state you want

  reducers: {
    setDentalFormulaTest(state, action) {
      if (typeof action.payload === 'function') {
        return action.payload(state);
      } else {
        return action.payload;
      }
    },
  },

  extraReducers: (builder) => {

},
});

export default dentalFormulaSlice;
export const { setDentalFormulaTest } = dentalFormulaSlice.actions;
