import React, { SVGProps } from 'react';

const SvgTheethChooseService = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path opacity="0.3" d="M2.83398 14.8848H14.1673V6.38477H2.83398V14.8848ZM5.66732 9.92643H7.79232V7.80143H9.20898V9.92643H11.334V11.3431H9.20898V13.4681H7.79232V11.3431H5.66732V9.92643Z" fill="#0D6EFD"/>
			<path d="M14.166 4.9681H11.3327V3.55143C11.3327 2.77227 10.6952 2.13477 9.91602 2.13477H7.08268C6.30352 2.13477 5.66602 2.77227 5.66602 3.55143V4.9681H2.83268C2.05352 4.9681 1.41602 5.6056 1.41602 6.38477V14.8848C1.41602 15.6639 2.05352 16.3014 2.83268 16.3014H14.166C14.9452 16.3014 15.5827 15.6639 15.5827 14.8848V6.38477C15.5827 5.6056 14.9452 4.9681 14.166 4.9681ZM7.08268 3.55143H9.91602V4.9681H7.08268V3.55143ZM14.166 14.8848H2.83268V6.38477H14.166V14.8848Z" fill="#0D6EFD"/>
			<path d="M7.79085 13.468H9.2074V11.3431H11.3322V9.92659H9.2074V7.80176H7.79085V9.92659H5.66602V11.3431H7.79085V13.468Z" fill="#0D6EFD"/>
		</svg>
	);
};

export default SvgTheethChooseService;
