import React, { FC, ReactNode, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button, { IButtonProps } from '../../../../components/bootstrap/Button';
import { HeaderRight } from '../../../../layout/Header/Header';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from '../../../../components/bootstrap/OffCanvas';
import Alert from '../../../../components/bootstrap/Alert';
import Icon from '../../../../components/icon/Icon';
import ThemeContext from '../../../../contexts/themeContext';
import useDarkMode from '../../../../hooks/useDarkMode';
import Popovers from '../../../../components/bootstrap/Popovers';
import { INotification } from '@textnpayme/custom/api/interface/notification.interface';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { NotificationService } from '../../../../custom/api/service/notification.service';
import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../../components/bootstrap/Dropdown';
import * as qs from 'qs';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import EVENT_STATUS from '../../../../common/data/enumEventStatus';
import { IVisit } from '@textnpayme/custom/api/interface/visit.interface';
import { VisitService } from '../../../../custom/api/service/visit.service';
import NotificationComponent from './NotificationComponent';
import PaginationButtons, {
	dataPagination,
	PER_COUNT,
	PER_COUNT_FILTER,
	PER_COUNT_MEDICAL_CASE,
} from '../../../../components/PaginationButtons';
import { includes } from 'lodash';
import AuthContext from '../../../../contexts/authContext';

const small = { name: 'Маленький', value: 13 };
const normal = { name: 'Нормальный', value: 16 };
const large = { name: 'Большой', value: 20 };
const extraLarge = { name: 'Очень большой', value: 24 };

interface ICustomHeaderRightProps {
	beforeChildren?: ReactNode;
	afterChildren?: ReactNode;
}
const CustomHeaderRight: FC<ICustomHeaderRightProps> = ({ beforeChildren, afterChildren }) => {
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const [fonSizeChange, setFontSizeChange] = useState(false);
	// const [fontSize, setFontSize] = useState(localStorage.getItem('fontSize') || 'Нормальный');
	// const [fontSize, setFontSize] = useState(parsedSavedFont.name);
	const [notBody, setNotBody] = useState<null | number>(null);
	const styledBtn: IButtonProps = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
		className: 'fs-6 d-flex align-items-center justify-content-center responsive-icon',
	};
	const [offcanvasStatus, setOffcanvasStatus] = useState(false);
	const [offcanvasActStatus, setOffcanvasActStatus] = useState(false);

	const [currentPage, setCurrentPage] = useState<number>(1);
	const [perPage, setPerPage] = useState<number>(PER_COUNT_FILTER['5']);

	const [currentPageAct, setCurrentPageAct] = useState<number>(1);
	const [perPageAct, setPerPageAct] = useState<number>(PER_COUNT_FILTER['5']);
	const { fontSize, setFontSize } = useContext(AuthContext);

	// const setAndSaveFonSize = (fontSize: string) => {
	// 	setFontSize(fontSize);
	// 	localStorage.setItem('fontSize', fontSize);
	const setAndSaveFontSize = (fontSizeName: string, fontSize: number) => {
		setFontSize(fontSizeName);
		const jsonItem = JSON.stringify({ name: fontSizeName, value: fontSize });
		localStorage.setItem('fontSize', jsonItem);
		const event = new CustomEvent('fontSizeChange', { detail: jsonItem });
		window.dispatchEvent(event);
	};
	useEffect(() => {
		// if (fonSizeChange) {
		// 	document.documentElement.style.fontSize = '0.900rem';
		// 	document.documentElement.style.transition = '0.3s';
		// } else {
		// 	document.documentElement.style.fontSize = 'var(--bs-root-font-size)';
		// 	document.documentElement.style.transition = '0.3s';
		// }

		if (fontSize === small.name) {
			document.documentElement.style.fontSize = `${small.value}px`;
			document.documentElement.style.transition = '0.3s';
		}

		if (fontSize === normal.name) {
			document.documentElement.style.fontSize = `${normal.value}px`;
			document.documentElement.style.transition = '0.3s';
		}

		if (fontSize === large.name) {
			document.documentElement.style.fontSize = `${large.value}px`;
			document.documentElement.style.transition = '0.3s';
		}

		if (fontSize === extraLarge.name) {
			document.documentElement.style.fontSize = `${extraLarge.value}px`;
			document.documentElement.style.transition = '0.3s';
		}
	}, [fonSizeChange, fontSize]);

	const { data: notifications }: { data: INotification[] | any; isLoading: boolean } = useQuery(
		['notifications', currentPage, perPage],
		() =>
			NotificationService.findAll(
				qs.stringify({
					queryMeta: {
						paginate: true,
						limit: perPage,
						page: currentPage,
						order: { createdAt: 'DESC' },
					},
					includeMeta: [
						{
							association: 'visit',
							where: {
								status: ['Предстоящий', 'В ожидании'],
							},
						},
					],
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			refetchInterval: 60 * 1000,
		},
	);

	const { data: notifications_count }: { data: INotification[] | any; isLoading: boolean } =
		useQuery(
			['notifications_count'],
			() =>
				NotificationService.findAll(
					qs.stringify({
						queryMeta: {
							order: { createdAt: 'DESC' },
						},
						includeMeta: [
							{
								association: 'visit',
								where: {
									status: ['Предстоящий', 'В ожидании'],
								},
							},
						],
						filterMeta: {
							isRead: false,
						},
					}),
				),
			{
				keepPreviousData: true,
				refetchOnWindowFocus: false,
				refetchInterval: 60 * 1000,
			},
		);

	const { data: notifications_act }: { data: INotification[] | any; isLoading: boolean } =
		useQuery(
			['notifications_act', currentPageAct, perPageAct],
			() =>
				NotificationService.findAll(
					qs.stringify({
						queryMeta: {
							paginate: true,
							limit: perPage,
							page: currentPage,
							order: { createdAt: 'DESC' },
						},
						includeMeta: [
							{
								association: 'visit',
							},
						],
						filterMeta: {
							type: 'Не оплачен',
						},
					}),
				),
			{
				keepPreviousData: true,
				refetchOnWindowFocus: false,
				refetchInterval: 60 * 1000,
			},
		);

	const { data: notifications_act_count }: { data: INotification[] | any; isLoading: boolean } =
		useQuery(
			['notifications_act_count'],
			() =>
				NotificationService.findAll(
					qs.stringify({
						queryMeta: {
							order: { createdAt: 'DESC' },
						},
						filterMeta: {
							isRead: false,
							type: 'Не оплачен',
						},
					}),
				),
			{
				keepPreviousData: true,
				refetchOnWindowFocus: false,
				refetchInterval: 60 * 1000,
			},
		);

	const queryClient = useQueryClient();

	const queryOptions = {
		onSuccess: (response: any, formData: any) => {
			queryClient.invalidateQueries('visits');
		},
		onError: (error: any) => {},
	};

	const { mutate } = useMutation(
		(uuid: string) => NotificationService.readNot(uuid),
		queryOptions,
	);

	const { mutate: chnageStatus } = useMutation(
		(data: IVisit) => VisitService.update(data.uuid, data),
		queryOptions,
	);

	const changeStatusNot = async (data: any) => {
		await mutate(data);
	};

	const handleStatusChange = (status: string, patientUuid: string, uuid: string) => {
		chnageStatus({
			uuid: uuid,
			patientUuid: patientUuid,
			status: status,
		});
		queryClient.invalidateQueries({ queryKey: ['notifications_act'] });
		queryClient.invalidateQueries({ queryKey: ['notifications_act_count'] });
	};

	return (
		<HeaderRight>
			<div className='row g-3'>
				{beforeChildren}
				{/*	Accessibility */}
				<div className='d-flex justify-content-start col-auto'>
					<Dropdown isOpen={fonSizeChange} setIsOpen={setFontSizeChange}>
						<DropdownToggle>
							<>
								<Popovers
									trigger='hover'
									desc={fonSizeChange ? 'Уменьшить шрифт' : 'Увеличить шрифт'}>
									<Button
										// eslint-disable-next-line react/jsx-props-no-spreading
										{...styledBtn}
										onClick={() => setFontSizeChange((prev: boolean) => !prev)}
										icon='TextFormat'
										aria-label='fontsize'
										size={'lg'}
									/>
								</Popovers>
							</>
						</DropdownToggle>
						<DropdownMenu
							className='d-flex col-12 flex-column justify-content-around align-items-center w-100'
							style={{ minWidth: '12rem', padding: '15px 10px' }}>
							<Button
								className='fw-bold text-nowrap  mt-2  fs-6 w-100'
								isOutline={true}
								color={fontSize === small.name ? 'primary' : 'info'}
								isLight={fontSize === small.name}
								onClick={() => {
									setAndSaveFontSize(small.name, small.value);
								}}>
								Маленький
							</Button>

							<Button
								className='fw-bold text-nowrap mt-2 fs-6 w-100'
								isOutline={true}
								color={fontSize === normal.name ? 'primary' : 'info'}
								isLight={fontSize === normal.name}
								onClick={() => {
									setAndSaveFontSize(normal.name, normal.value);
								}}>
								Нормальный
							</Button>

							<Button
								className='fw-bold text-nowrap  mt-2  fs-6 w-100'
								isOutline={true}
								color={fontSize === large.name ? 'primary' : 'info'}
								isLight={fontSize === large.name}
								onClick={() => {
									setAndSaveFontSize(large.name, large.value);
								}}>
								Большой
							</Button>

							<Button
								className='fw-bold text-nowrap  mt-2  fs-6 w-100'
								isOutline={true}
								color={fontSize === extraLarge.name ? 'primary' : 'info'}
								isLight={fontSize === extraLarge.name}
								onClick={() => {
									setAndSaveFontSize(extraLarge.name, extraLarge.value);
								}}>
								Очень большой
							</Button>
						</DropdownMenu>
					</Dropdown>
				</div>
				{/* Dark Mode */}
				<div className='col-auto'>
					<Popovers trigger='hover' desc='Темная / Светлая тема'>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							onClick={() => setDarkModeStatus(!darkModeStatus)}
							className='btn-only-icon fs-6 d-flex align-items-center justify-content-center'
							data-tour='dark-mode'
							aria-label='Toggle dark mode'>
							<Icon
								icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
								color={darkModeStatus ? 'info' : 'warning'}
								className='btn-icon'
							/>
						</Button>
					</Popovers>
				</div>
				{/*	Full Screen */}
				<div className='col-auto'>
					<Popovers trigger='hover' desc='Полноэкранный режим'>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							icon={fullScreenStatus ? 'FullscreenExit' : 'Fullscreen'}
							onClick={() => setFullScreenStatus(!fullScreenStatus)}
							aria-label='Toggle fullscreen'
						/>
					</Popovers>
				</div>
				{/*	Quote  */}
				<div className='col-auto fs-6 d-flex align-items-center justify-content-center'>
					<Popovers trigger='hover' desc='Смета'>
						<div className='not_circle'>
							<Button
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...styledBtn}
								icon='ReceiptLong'
								onClick={() => setOffcanvasActStatus(true)}
								className='not_circle fs-6 d-flex align-items-center justify-content-center'>
								{notifications_act?.data.length > 0 &&
									notifications_act_count?.meta?.count > 0 && (
										<span className='span_not_circle'>
											{notifications_act_count?.meta?.count}
										</span>
									)}
							</Button>
						</div>
					</Popovers>
				</div>
				{/*	Notifications */}
				<div className='col-auto fs-6 d-flex align-items-center justify-content-center'>
					<Popovers trigger='hover' desc='Уведомления'>
						<div className='not_circle'>
							<Button
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...styledBtn}
								icon='Notifications'
								onClick={() => setOffcanvasStatus(true)}
								aria-label='Notifications'
								className='not_circle fs-6 d-flex align-items-center justify-content-center'>
								{notifications?.data.length > 0 &&
									notifications_count?.meta?.count > 0 && (
										<span className='span_not_circle'>
											{notifications_count?.meta?.count}
										</span>
									)}
							</Button>
						</div>
					</Popovers>
				</div>

				{/* {afterChildren} */}
			</div>
			<OffCanvas
				isBackdrop={true}
				id='notificationCanvas'
				titleId='offcanvasExampleLabel'
				placement='end'
				isOpen={offcanvasStatus}
				setOpen={setOffcanvasStatus}
				style={{
					width: '30vw',
					transform: 'translateX(0%) translateY(0%) translateZ(0px)',
					padding: '1.5rem',
					display: 'flex',
					flexDirection: 'column',
					gap: '2rem',
				}}>
				<OffCanvasHeader setOpen={setOffcanvasStatus}>
					<div className='d-flex gap-3'>
						<Icon icon='Notifications' size='2x' color='info' />
						<OffCanvasTitle id='offcanvasExampleLabel'>Уведомления</OffCanvasTitle>
					</div>
				</OffCanvasHeader>
				<OffCanvasBody className='p-0 d-flex flex-column justify-content-between'>
					<div className='d-flex flex-column align-items-center'>
						{notifications?.data?.length > 0 ? (
							notifications?.data?.map((i: any, index: number) => (
								<NotificationComponent
									not={i}
									handleStatusChange={handleStatusChange}
									chnageStatus={changeStatusNot}
								/>
							))
						) : (
							<p>У вас нет новых уведомлений</p>
						)}
					</div>
					{notifications?.meta?.count > 5 && (
						<PaginationButtons
							data={notifications || []}
							label='customers'
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
							perPage={perPage}
							setPerPage={setPerPage}
							totalPage={Math.ceil(notifications?.meta?.count / perPage) || 1}
						/>
					)}
				</OffCanvasBody>
			</OffCanvas>

			<OffCanvas
				isBackdrop={true}
				id='notificationCanvas'
				titleId='offcanvasExampleLabel'
				placement='end'
				isOpen={offcanvasActStatus}
				setOpen={setOffcanvasActStatus}
				style={{
					width: '30vw',
					transform: 'translateX(0%) translateY(0%) translateZ(0px)',
					padding: '1.5rem',
					display: 'flex',
					flexDirection: 'column',
					gap: '2rem',
				}}>
				<OffCanvasHeader setOpen={setOffcanvasActStatus}>
					<div className='d-flex gap-3'>
						<Icon icon='ReceiptLong' size='2x' color='info' />
						<OffCanvasTitle id='offcanvasExampleLabel'>
							Уведомления Сметы
						</OffCanvasTitle>
					</div>
				</OffCanvasHeader>
				<OffCanvasBody className='p-0 d-flex flex-column justify-content-between'>
					<div className='d-flex flex-column align-items-center'>
						{notifications_act?.data?.length > 0 ? (
							notifications_act?.data?.map((i: any, index: number) => (
								<NotificationComponent
									not={i}
									handleStatusChange={handleStatusChange}
									chnageStatus={changeStatusNot}
								/>
							))
						) : (
							<p>У вас нет новых уведомлений</p>
						)}
					</div>
				</OffCanvasBody>
				{notifications_act?.meta?.count > 5 && (
					<PaginationButtons
						data={notifications_act || []}
						label='customers'
						setCurrentPage={setCurrentPageAct}
						currentPage={currentPageAct}
						perPage={perPageAct}
						setPerPage={setPerPageAct}
						totalPage={Math.ceil(notifications_act?.meta?.count / perPage) || 1}
					/>
				)}
			</OffCanvas>
		</HeaderRight>
	);
};
CustomHeaderRight.propTypes = {
	beforeChildren: PropTypes.node,
	afterChildren: PropTypes.node,
};
CustomHeaderRight.defaultProps = {
	beforeChildren: null,
	afterChildren: null,
};

export default CustomHeaderRight;
