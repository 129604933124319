import React, { FC, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal, { ModalBody, ModalHeader } from '../../../components/bootstrap/Modal';
import Button from '../../../components/bootstrap/Button';
import Input from '../../../components/bootstrap/forms/Input';
import { Checkbox } from '../../../stories/components/bootstrap/forms/Checks.stories';
import Textarea from '../../../components/bootstrap/forms/Textarea';
import { useState } from 'react';
import classNames from 'classnames';
import { useFormik } from 'formik';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../layout/SubHeader/SubHeader';
import Icon from '../../../components/icon/Icon';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import USERS from '../../../common/data/userDummyData';
import Badge from '../../../components/bootstrap/Badge';
import Dropdown, { DropdownMenu, DropdownToggle } from '../../../components/bootstrap/Dropdown';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Label from '../../../components/bootstrap/forms/Label';
import Checks, { ChecksGroup } from '../../../components/bootstrap/forms/Checks';
import SERVICES from '../../../common/data/serviceDummyData';
import { demoPagesMenu } from '../../../menu';
import useTourStep from '../../../hooks/useTourStep';
import { useMutation, useQueryClient } from 'react-query';
import { VisitService } from '../../../custom/api/service/visit.service';
import { IVisit } from '@textnpayme/custom/api/interface/visit.interface';
import { VisitCanceleService } from '../../../custom/api/service/visit-cancele.service';
import { ICancelVisit } from '@textnpayme/custom/api/interface/cancel-visit.interface';

interface ICancelVisitModalProps {
	id: string;
	isOpen: boolean;
	visitUuid: string | undefined;
	setIsOpen(...args: unknown[]): unknown;
}
const CancelVisitModal: FC<ICancelVisitModalProps> = ({ id, visitUuid, isOpen, setIsOpen }) => {

	const [values, setValues] = useState<IVisit>({});

	// const DebouncedSearchData = useDebounce(values);
	useEffect(() => {
		setValues({
			visitUuid: visitUuid ? visitUuid : null,
			type: values?.type || '',
			description: '',
		});
	}, [visitUuid]);

	const changeValues = (key: string, value: string | number) => {
		setValues((prev) => ({ ...prev, [key]: value }));
	};


	const queryClient = useQueryClient();

	const { mutate: cancelVisit } = useMutation(
		(data: ICancelVisit) => VisitCanceleService.create(data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries('visits');
				setIsOpen(false);
				setValues({
					visitUuid: visitUuid ? visitUuid : null,
					type: values?.type || '',
					description: '',
				});
			},
			onError: (error) => {
				console.error(error);
			},
		},
	);

	const submit = async (e:any)=> {
		e.preventDefault()
		await cancelVisit(values)
	}


	return (
		<Modal isOpen={isOpen} setIsOpen={setIsOpen} type='md' isCentered titleId={id.toString()}>
			<ModalHeader setIsOpen={setIsOpen} className='p-4'>
				<div className='fs-5 fw-bold d-flex align-items-center'>
					<Icon icon='CalendarToday' className='me-2 fs-2' color='primary' />
					Отмена визита
				</div>
			</ModalHeader>
			<form onSubmit={submit}>
				<ModalBody className=' d-flex flex-column p-4 gap-5'>
				<div className='d-flex flex-column gap-5 col-12'>
					<FormGroup id='clinic' label='Выберите тип' className='mb-2'>
							<div style={{ position: 'relative' }}>
								<select
									required
									onChange={(e) => {										
										changeValues('type', e.target.value);
									}}
									value={values?.type}
									className='form-select'
									aria-label='Выберите пол'>
									<option value={undefined}>Выбрать</option>
									<option value='user'>Отказ от предоставления услуг</option>
									<option value='patient'>Отказ от получения услуг</option>
								</select>
							</div>
						{/* <CustomSelect
									placeholder={'Выберите филиал'}
									name={'clinic'}
									value={values.clinicUuid}
									options={clinics?.data.map((item: IClinic, index: number) => {
										return {
											value: item.uuid,
											label: item.name,
										};
									})}
									changeSearchValue={changeSearchValue}
									changeValues={changeValues}
								/> */}
					</FormGroup>

					<FormGroup className='col-12' id='complaint' label='Причина'>
						<Textarea
							required
							value={values.complaint}
							onChange={(e: any) => changeValues('description', e.target.value)}
						/>
					</FormGroup>
				</div>
				<div className='d-flex gap-2'>
					<Button onClick={()=>setIsOpen(false)} className='col-6' color='info' isLight>
						Назад
					</Button>
					<Button
						className='col-6'
						color='primary'
						type={'submit'}
						>
						Сохранить
					</Button>
				</div>
			</ModalBody>
			</form>
			
		</Modal>
	);
};
CancelVisitModal.propTypes = {
	id: PropTypes.string.isRequired,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
};

export default CancelVisitModal;
