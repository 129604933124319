import { authHost } from '../axios';
// import { IMkb10 } from '../interface/mkb10.interface';

export class CategoryService {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor() {}
	static async findAll(query?: any): Promise<any[] | null> {
		const { data } = await authHost.get(`category?${query}`);
		return data;
	}

	async findOne(uuid: string) {
		const { data } = await authHost.get(`category/${uuid}`);
		return data;
	}

	static async create(data: any) {
		const category = await authHost.post('category', data);
		return category;
	}
}
