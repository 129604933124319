import React from 'react';
import Icon from '../../../../components/icon/Icon';

const Status: React.FC<{ status: string | undefined; VisitStatusStyles: (status: any) => any }> = ({
	status,
	VisitStatusStyles,
}) => {
	return (
		<div className='text-nowrap fw-bold fs-6'>
			<Icon
				style={{
					fill:
						status !== undefined
							? VisitStatusStyles(status)?.iconeStyles.color
							: 'black',
				}}
				icon='Circle'
				className='me-2'
				color={
					status !== undefined
						? VisitStatusStyles(status)?.iconeStyles.iconeColor.color
						: 'black'
				}
			/>
			<span
				style={{
					color:
						status !== undefined
							? VisitStatusStyles(status)?.iconeStyles.color
							: 'black',
				}}>
				{status}
			</span>
		</div>
	);
};

export default Status;
