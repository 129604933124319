import React, { SVGProps } from "react";

export const THEETH_11 = (props: SVGProps<SVGSVGElement>) => {

    return (
		<>
           <svg width="27" height="82" viewBox="0 0 27 82" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g filter="url(#filter0_i_402_4977)">
<path d="M9.73817 1.8269C20.7383 10.8269 26.0559 45.7953 24.4888 64.0679C24.0818 71.0447 1.33539 81.0553 1.79194 72.5826C2.54988 58.5166 6.73828 28.3269 5.23828 5.8269C6.02636 2.93047 8.10425 0.490074 9.73817 1.8269Z" fill="url(#paint0_linear_402_4977)"/>
<path d="M9.73817 1.8269C20.7383 10.8269 26.0559 45.7953 24.4888 64.0679C24.0818 71.0447 1.33539 81.0553 1.79194 72.5826C2.54988 58.5166 6.73828 28.3269 5.23828 5.8269C6.02636 2.93047 8.10425 0.490074 9.73817 1.8269Z" fill="url(#paint1_linear_402_4977)"/>
</g>
<path d="M9.31816 1.36151C21.4025 9.8866 27.1588 37.9189 24.208 65.9512C22.3993 39.3624 19.2763 21.8325 9.04532 1.61372C8.95315 1.43157 9.15135 1.24383 9.31816 1.36151Z" fill="url(#paint2_linear_402_4977)"/>
<path d="M6.25781 81.3269H24.2578C26.2578 81.3269 26.7578 79.3269 26.7578 78.3269C26.7578 77.3269 26.2578 47.3269 12.7578 47.3269C-0.742188 47.3269 0.757812 73.8269 0.757812 75.8269C0.757812 77.8269 4.25781 81.3269 6.25781 81.3269Z" fill="url(#paint3_linear_402_4977)"/>
<path d="M6.25781 81.3269H24.2578C26.2578 81.3269 26.7578 79.3269 26.7578 78.3269C26.7578 77.3269 26.2578 47.3269 12.7578 47.3269C-0.742188 47.3269 0.757812 73.8269 0.757812 75.8269C0.757812 77.8269 4.25781 81.3269 6.25781 81.3269Z" fill="url(#paint4_radial_402_4977)" fill-opacity="0.3"/>
<defs>
<filter id="filter0_i_402_4977" x="-1.21484" y="1.45117" width="25.9731" height="77.4757" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-3" dy="5"/>
<feGaussianBlur stdDeviation="1.55"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_402_4977"/>
</filter>
<linearGradient id="paint0_linear_402_4977" x1="13.2716" y1="75.8269" x2="13.2716" y2="1.4511" gradientUnits="userSpaceOnUse">
<stop stop-color="#E87171"/>
<stop offset="1" stop-color="#935252"/>
</linearGradient>
<linearGradient id="paint1_linear_402_4977" x1="13.2716" y1="75.8269" x2="13.2716" y2="1.4511" gradientUnits="userSpaceOnUse">
<stop stop-color="#D29439"/>
<stop offset="1" stop-color="#887250"/>
</linearGradient>
<linearGradient id="paint2_linear_402_4977" x1="25.4026" y1="8.9034" x2="11.9162" y2="67.6892" gradientUnits="userSpaceOnUse">
<stop stop-color="#725935"/>
<stop offset="1" stop-color="#5B482A"/>
</linearGradient>
<linearGradient id="paint3_linear_402_4977" x1="13.733" y1="47.3269" x2="13.733" y2="81.3269" gradientUnits="userSpaceOnUse">
<stop stop-color="#B48B8B"/>
<stop offset="0.3" stop-color="white"/>
</linearGradient>
<radialGradient id="paint4_radial_402_4977" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(10.2082 70.9511) rotate(-131.433) scale(8.21795 6.6278)">
<stop stop-color="white" stop-opacity="0"/>
<stop offset="1" stop-color="#9B9B9B"/>
</radialGradient>
</defs>
</svg>


        </>
	);
};