import React, { FC, useEffect, useState } from 'react';
import { ModalBody } from '../../../components/bootstrap/Modal';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
// import Select from '../../../components/bootstrap/forms/Select';
import Textarea from '../../../components/bootstrap/forms/Textarea';
import Button from '../../../components/bootstrap/Button';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IVisit } from '@textnpayme/custom/api/interface/visit.interface';
import { PatientService } from '../../../custom/api/service/patient.service';
import { IPatient } from '@textnpayme/custom/api/interface/patient.interface';
import { IClinic } from '@textnpayme/custom/api/interface/clinic.interface';
import * as qs from 'qs';
import { ClinicService } from '../../../custom/api/service/clinic.service';
import { ServiceService } from '../../../custom/api/service/visit-service.service';
import { UserService } from '../../../custom/api/service/user.service';
import { IUser } from '@textnpayme/custom/api/interface/user.interface';
import { Select } from 'antd';
import { useDebounce } from '../../../hooks/useDebounce';
import { VisitService } from '../../../custom/api/service/visit.service';
import { relative } from 'path';
import { IService } from '@textnpayme/custom/api/interface/service.interface';
import { title } from 'process';
import CustomSelect from '../../../custom/components/Select/select';
import { PhoneNumberUtil } from 'google-libphonenumber';
import ScheduleModal from '../../../custom/pages/doctors/schedule/ScheduleModal';
import useDarkMode from '../../../hooks/useDarkMode';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import PaginationButtons, {
	dataPagination,
	PER_COUNT,
	PER_COUNT_FILTER,
} from '../../../components/PaginationButtons';
import Icon from '../../../components/icon/Icon';
import EVENT_STATUS from '../../../common/data/enumEventStatus';
import { Divider, notification, Space } from 'antd';
import type { NotificationArgsProps } from 'antd';
import ServiceChooseModal from '../services/ServiceChooseModal';
import { GoogleService } from '../../../custom/api/service/google-callendar.service';

type NotificationPlacement = NotificationArgsProps['placement'];

interface CreateVisitProps {
	patientId: string;
	setIsOpen(...args: unknown[]): unknown;
	serviceId: null | [];
	medicalCaseUuid: null | string;
	stageUuid: null | string;
	clinicUuid: string | null;
	setValues(...args: unknown[]): unknown;
	values: any;
	visitData: any;
	schedulModalOpen: any;
	setSchedulModalOpen(...args: unknown[]): unknown;
	changeValues(...args: unknown[]): unknown;
	setVisitData(...args: unknown[]): unknown;
	visitDate?: any;
	setCreateVisit(...args: unknown[]): unknown;
	setServiceUuid?(...args: unknown[]): unknown;
	visit?: IVisit;
}
interface UpdateVisitParams {
	uuid: string;
	inputDto: IVisit;
}

const CreateVisitMedicalCard: FC<CreateVisitProps> = ({
	patientId,
	clinicUuid,
	setCreateVisit,
	setValues,
	setServiceUuid,
	values,
	setIsOpen,
	serviceId,
	medicalCaseUuid,
	stageUuid,
	visitData,
	schedulModalOpen,
	setSchedulModalOpen,
	changeValues,
	setVisitData,
	visit,
}) => {
	const [search, setSearch] = useState<any>({});

	const [popperIsOpenService, setPopperIsOpenService] = useState(false);
	const [servicesCurrentPage, setServicesCurrentPage] = useState<number>(1);
	const [servicesPerPage, setServicesPerPage] = useState<number>(PER_COUNT_FILTER['5']);
	const [isGoogleCalendar, setIsGoogleCallendar] = useState<boolean>(false);
	const [isGoogleCalendarLink, setIsGoogleCallendarLink] = useState<any>(null);
	const DebouncedSearch = useDebounce(search);
	const { themeStatus, darkModeStatus } = useDarkMode();
	const [api, contextHolder] = notification.useNotification();

	const openNotification = (
		placement: NotificationPlacement,
		message: string,
		description: string,
	) => {
		api.info({
			message,
			description,
			placement,
		});
	};

	const [isUserDoctor, setIsUserDoctor] = useState<boolean>(false);

	const { data: user_role } = useQuery(['user_role'], () =>
		UserService.getProfile(qs.stringify({ refetchOnWindowFocus: true })),
	);

	useEffect(() => {
		setIsUserDoctor(
			user_role?.roles?.some(
				(role: any) => role?.role === 'mainDoctor' || role?.role === 'Doctor',
			),
		);
	}, [user_role]);

	useEffect(() => {
		if (isUserDoctor) {
			changeValues('clinicUuid', user_role?.roles[0]?.clinicUuid);
		}
	}, [isUserDoctor]);

	const queryClient = useQueryClient();

	const { isLoading: patientLoading, data: patient } = useQuery<IPatient, Error>(
		['patient', patientId],
		() =>
			PatientService.findOne(
				patientId,
				qs.stringify({
					queryMeta: {
						paginate: true,
					},
					filterMeta: {},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);
	const [isValid, setIsValid] = useState(false);

	const validatePhoneNumber = (phoneNumber: string): void => {
		const phoneUtil = PhoneNumberUtil.getInstance();
		const number = phoneNumber.replace(/\+/g, '');
		try {
			const parsedNumber = phoneUtil.parse(number);
			setIsValid(phoneUtil.isValidNumber(parsedNumber));
		} catch (e) {
			const allRegions: string[] = phoneUtil.getSupportedRegions();
			let isValidForAnyRegion: boolean = false;
			for (const region of allRegions) {
				try {
					const parsedNumber = phoneUtil.parse(number, region);
					if (phoneUtil.isValidNumber(parsedNumber)) {
						isValidForAnyRegion = true;
						break;
					}
				} catch (e) {
					continue;
				}
			}
			setIsValid(isValidForAnyRegion);
		}
	};

	const DebouncedSearchData = useDebounce(values);
	useEffect(() => {
		console.log('useEffect triggered');
		const getServiceUuids = (services: any) => {
			return services ? services.map((relation: any) => relation.uuid) : undefined;
		};

		const getUserFullName = (user: any) => {
			return user ? `${user.name} ${user.surname} ${user.middleName}` : undefined;
		};

		const getValue = (visitValue: any, defaultValue: any) => {
			return visitValue !== undefined ? visitValue : defaultValue;
		};

		const newValues: any = {
			services: getValue(getServiceUuids(visit?.services), serviceId),
			userUuid: getValue(visit?.user?.uuid, values.userUuid),
			complaint: getValue(visit?.complaint, values.complaint),
			visitDate: getValue(visit?.visitDate, values.visitDate),
			visitNumber: getValue(visit?.visitNumber, values?.visitNumber),
			clinicUuid: getValue(visit?.clinicUuid, values.clinicUuid),
			userFullName: getValue(getUserFullName(visit?.user), values.userFullName),
			patientUuid: patientId,
		};

		if (medicalCaseUuid !== null && stageUuid !== null) {
			newValues.medicalCaseUuid =
				visit?.medicalCaseUuid !== undefined ? visit.medicalCaseUuid : medicalCaseUuid;
			newValues.stageUuid = visit?.stageUuid !== undefined ? visit.stageUuid : stageUuid;
		}

		console.log('New Values: ', newValues);
		setValues(newValues);
	}, [patientId, medicalCaseUuid, stageUuid, visit, serviceId, clinicUuid]);
	const changeSearchValue = (key: string, value: string | number) => {
		setSearch((prev: any) => ({ ...prev, [key]: value }));
	};

	const [parts, setParts] = useState<any>('');

	useEffect(() => {
		if (typeof search?.user === 'string') {
			if (search?.user.includes('+')) {
				validatePhoneNumber(search?.user);
			}

			if (search?.user) {
				setParts(search?.user.split(' '));
			}
		}
	}, [search?.user]);

	const { isLoading: clinicsLoading, data: clinics } = useQuery<IClinic | null, Error>(
		['clinics', DebouncedSearch],
		() =>
			ClinicService.findAll(
				qs.stringify({
					queryMeta: {
						order: { createdAt: 'DESC' },
					},
					filterMeta: {
						...(search?.clinic && { name: { iLike: `%${search.clinic}%` } }),
					},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	console.log('values:', values);

	const DebouncedSearchServices = useDebounce(values?.serviceName);

	const { data: servicesCount }: { data: IService[] | any; isLoading: boolean } = useQuery(
		['servicesCount', values?.stageUuid, DebouncedSearchServices],
		() =>
			ServiceService.findAll(
				qs.stringify({
					includeMeta: [
						{
							association: 'stages',
							where: {
								uuid: values?.stageUuid,
							},
						},
					],
					filterMeta: {
						...(values.serviceName && {
							...(values.serviceName && /\d/.test(values.serviceName)
								? { serviceId: { iLike: `%${values.serviceName}%` } }
								: { title: { iLike: `%${values.serviceName}%` } }),
						}),
					},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	const { data: services }: { data: IService[] | any; isLoading: boolean } = useQuery(
		[
			'services',
			values.stageUuid,
			DebouncedSearchServices,
			servicesCurrentPage,
			servicesPerPage,
		],
		() =>
			ServiceService.findAll(
				qs.stringify({
					queryMeta: {
						paginate: true,
						limit: servicesPerPage,
						page: DebouncedSearchServices?.length ? 1 : servicesCurrentPage,
						order: { createdAt: 'DESC' },
					},
					includeMeta: [
						{
							association: 'stages',
							where: {
								uuid: values.stageUuid,
							},
						},
					],

					filterMeta: {
						...(values.serviceName && {
							...(values.serviceName && /\d/.test(values.serviceName)
								? { serviceId: { iLike: `%${values.serviceName}%` } }
								: { title: { iLike: `%${values.serviceName}%` } }),
						}),
					},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	const { isLoading: usersLoading, data: users } = useQuery<IVisit | null, Error>(
		['users', DebouncedSearch, DebouncedSearchData],
		() =>
			UserService.findAll(
				qs.stringify({
					queryMeta: {
						order: { createdAt: 'DESC' },
					},
					includeMeta: [
						{
							association: 'roles',
							where: {
								role: ['mainDoctor', 'doctor'],
								...(values.clinicUuid && { clinicUuid: values.clinicUuid }),
							},
							// include: [
							// 	{
							// 	  association: 'clinics',
							// 	  where: {
							// 		uuid: '4178f72a-45e4-4541-b71e-798578e1575f'
							// 	  }
							// 	}
							//   ]
						},
						{
							...(search?.user &&
								/\d/.test(search?.user) &&
								!isValid && {
									association: 'passport',
									where: {
										or: [
											{
												passportNumber: {
													iLike: `%${search?.user}%`,
												},
											},
											{
												ssCardId: {
													iLike: `%${search?.user}%`,
												},
											},
										],
									},
								}),
						},
					],
					filterMeta: {
						...(search?.user &&
							/\d/.test(search?.user) &&
							isValid && { phone: { iLike: `%${search?.user}%` } }),
						...(search?.user &&
							!/\d/.test(search?.user) &&
							!isValid &&
							((parts.length === 1 && {
								name: { iLike: `%${parts[0]}%` },
							}) ||
								(parts.length === 2 && {
									and: [
										{ name: { iLike: `%${parts[0]}%` } },
										{ surname: { iLike: `%${parts[1]}%` } },
									],
								}) ||
								(parts.length === 3 && {
									and: [
										{ name: { iLike: `%${parts[0]}%` } },
										{ surname: { iLike: `%${parts[1]}%` } },
										{ middleName: { iLike: `%${parts[2]}%` } },
									],
								}))),
					},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	const connectClick = () => {
		const width_ = 500;
		const height_ = 400; // This is sufficient, no need to redeclare it
		const left = window.screenX + (window.outerWidth - width_) / 2;
		const top = window.screenY + (window.outerHeight - height_) / 2.5;
		const title = `GOOGLE CALLENDAR ACCESS`;
		const url = isGoogleCalendarLink;
		if (url) {
			window.open(url, title, `width=${width_},height=${height_},left=${left},top=${top}`);
		}
	};

	useEffect(() => {
		if (isGoogleCalendar && isGoogleCalendarLink && typeof isGoogleCalendarLink === 'string') {
			connectClick();
		}
	}, [isGoogleCalendar, isGoogleCalendarLink]);

	const queryOptions = {
		onSuccess: (response: any, formData: any) => {
			queryClient.invalidateQueries('visits');
			setIsOpen(false);
			setValues(
				medicalCaseUuid !== null && stageUuid !== null
					? {
							serviceUuid: serviceId ? serviceId : undefined,
							userUuid: undefined,
							complaint: undefined,
							visitDate: undefined,
							clinicUuid: clinicUuid !== null ? clinicUuid : undefined,
							userFullName: undefined,
							medicalCaseUuid: medicalCaseUuid,
							stageUuid: stageUuid,
							patientUuid: patientId,
					  }
					: {
							serviceUuid: serviceId ? serviceId : undefined,
							userUuid: undefined,
							complaint: undefined,
							visitDate: undefined,
							userFullName: undefined,
							clinicUuid: clinicUuid !== null ? clinicUuid : undefined,
							patientUuid: patientId,
					  },
			);
			setCreateVisit(false);
		},
		onError: (error: any) => {
			if (error.response.data.message === 'calendar_auth_failed') {
				setIsGoogleCallendar(true);
			}
			if (error.response.data.message === 'choose_user_date_time')
				openNotification(
					'bottomRight',
					'Важно !',
					'Выберите дату и врача чтобы создать визит',
				);
			if (error.response.data.message === 'end_date_empty')
				openNotification('bottomRight', 'Важно !', 'выберите время окончания визита');

			if (error.response.data.message === 'start_date_empty')
				openNotification('bottomRight', 'Важно !', 'выберите время начала визита');

			if (error.response.data.message === 'can_not_create_visit_whitout_services')
				openNotification('bottomRight', 'Важно !', 'выберите услуги чтобы создать визит');
		},
	};

	const { mutate } = useMutation(
		(inputDto: IVisit) => VisitService.create(inputDto),

		queryOptions,
	);

	const { mutate: updateVisit } = useMutation(
		({ uuid, data }) => VisitService.update(uuid, data),
		queryOptions,
	);

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		e.stopPropagation();

		if (visit && visit.uuid) {
			const uuid: string = visit?.uuid;
			await updateVisit({ uuid, data: values });
		} else {
			await mutate(values);
		}
	};

	const { data: calendarAccess, isLoading } = useQuery(
		['calendar_access', isGoogleCalendar],
		async () => {
			if (isGoogleCalendar) {
				return await GoogleService.generateGoogleCallendarAccses();
			}
			return null;
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	useEffect(() => {
		setIsGoogleCallendarLink(calendarAccess);
	}, [isGoogleCalendar, calendarAccess]);

	if (patientLoading || clinicsLoading || usersLoading) {
		return <div>Loading...</div>;
	}

	function formatDateToYYYYMMDD(date: any) {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
		const day = String(date.getDate()).padStart(2, '0');
		return `${day}.${month}.${year}`;
	}

	const handleCheckboxChange = (e: any) => {
		const { name, type, checked, value, defaultValue } = e.target;

		if (type === 'checkbox') {
			setValues((prev: any) => {
				const currentValues = Array.isArray(prev[name]) ? prev[name] : [];
				if (checked) {
					// Add the value if not already present
					if (!currentValues.includes(defaultValue)) {
						return {
							...prev,
							[name]: [...currentValues, defaultValue],
						};
					}
				} else {
					// Remove the value
					return {
						...prev,
						[name]: currentValues.filter((val: string) => val !== defaultValue),
					};
				}
				return prev;
			});
		}

		if (type === 'text' && name === 'serviceName') {
			setValues((prev: any) => ({
				...prev,
				[name]: value,
			}));
		}
	};

	const VisitStatusStyles = (status: any) => {
		switch (status) {
			case EVENT_STATUS.PENDING_SERVICE.name:
				return {
					iconeStyles: {
						color: '#DC3545',
						iconeColor: {
							color: EVENT_STATUS.PENDING_SERVICE.color,
						},
					},
					disabled: false,
				};

			case EVENT_STATUS.COMPLETED_SERVICE.name:
				return {
					iconeStyles: {
						color: '#198754',
						iconeColor: {
							color: EVENT_STATUS.COMPLETED_SERVICE.color,
						},
					},
					disabled: true,
				};
			default:
				return undefined; // Handle unknown status
		}
	};

	const formatPrice = (
		amount: number | string,
		currency: string,
		locale: string = 'en-US',
	): string => {
		try {
			// Convert string to number if amount is of type string
			const parsedAmount = typeof amount === 'string' ? parseFloat(amount) : amount;

			// If parsing fails or results in NaN, return the original amount as a string
			if (isNaN(parsedAmount)) {
				throw new Error('Invalid amount');
			}

			return new Intl.NumberFormat(locale, {
				style: 'currency',
				currency: currency,
			}).format(parsedAmount);
		} catch (error) {
			console.error('Error formatting price:', error);
			return amount.toString();
		}
	};

	const timeFormatter = (time: string) => {
		return new Date(time).toTimeString().slice(0, 5);
	};

	const dateOfVisit = (start: any, end: any) => {
		if (start && !end) {
			return timeFormatter(start);
		}
		if (start && end) {
			return `${timeFormatter(start)} -	${timeFormatter(end)}`;
		}
	};

	return (
		<>
			<form style={{ width: '100%' }} onSubmit={handleSubmit}>
				<div className='d-flex flex-row justify-content-around align-items-start mb-4 col-12'>
					<div className='d-flex flex-column gap-4 col-5'>
						<FormGroup className='col-12' id='visitNumber' label='Номер визита'>
							<Input
								required
								value={values.visitNumber}
								onChange={(e: any) => changeValues('visitNumber', e.target.value)}
							/>
						</FormGroup>
						<FormGroup className='col-12' id='patient' label='Пациент'>
							<Input
								disabled={true}
								value={`${patient?.surname} ${patient?.name} ${patient?.middleName}`}
								type='text'
							/>
						</FormGroup>
						<FormGroup id='clinic' label='Филиал' className='mb-2'>
							<CustomSelect
								disabled={!!visit || isUserDoctor}
								placeholder={'Выберите филиал'}
								name={'clinic'}
								value={values.clinicUuid}
								options={clinics?.data.map((item: IClinic, index: number) => {
									return {
										value: item.uuid,
										label: item.name,
									};
								})}
								changeSearchValue={changeSearchValue}
								changeValues={changeValues}
							/>
						</FormGroup>

						<FormGroup className='col-12' id='complaint' label='Жалоба'>
							<Textarea
								required
								value={values.complaint}
								onChange={(e: any) => changeValues('complaint', e.target.value)}
							/>
						</FormGroup>
					</div>
					<div className='d-flex flex-column gap-5 col-5'>
						{' '}
						<FormGroup label='Услуги' className='col-12'>
							<Button
								style={{
									boxShadow: 'inset 0 1px 2px rgba(0, 0, 0, 0.075)',
								}}
								onClick={() => setPopperIsOpenService(true)}
								color={themeStatus}
								className='text-nowrap me-2 col-12'>
								Управление услугами
							</Button>
							{/* <Dropdown
								isOpen={popperIsOpenService}
								setIsOpen={setPopperIsOpenService}>
								<DropdownToggle>
								</DropdownToggle>
								<DropdownMenu
									style={{ maxHeight: 500, width: '500px', overflow: 'auto' }}>
									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											flexWrap: 'wrap',
										}}>
										<FormGroup
											id='surname'
											label=' Введите название или код услуги'
											className='col-10'>
											<Input
												name='serviceName'
												onChange={(e: any) => handleCheckboxChange(e)}
												value={values?.serviceName}
											/>
										</FormGroup>

										{services?.data.length > 0 ? (
											services.data.map((i: IService, index: number) => (
												<div className='form-check col-10' key={index}>
													{!VisitStatusStyles(
														i.stages?.[0]?.StageServiceRelation?.status,
													)?.disabled && (
														<input
															className='form-check-input'
															onChange={(e: any) =>
																handleCheckboxChange(e)
															}
															type='checkbox'
															value={i.uuid}
															name='services'
															disabled={
																VisitStatusStyles(
																	i.stages?.[0]
																		?.StageServiceRelation
																		?.status,
																)?.disabled
															}
															checked={values?.services?.includes(
																i.uuid,
															)}
															id={`flexCheckDefault-${index}`}
														/>
													)}
													<label
														color={themeStatus}
														className='form-check-label'
														htmlFor={`flexCheckDefault-${index}`}
														aria-disabled={
															VisitStatusStyles(
																i.stages?.[0]?.StageServiceRelation
																	?.status,
															)?.disabled
														}>
														<div className='col-2'>{i.serviceId}</div>
														<div className='col-1'>
															<Icon
																icon='Circle'
																className='me-2'
																color={
																	VisitStatusStyles(
																		i.stages?.[0]
																			?.StageServiceRelation
																			?.status,
																	)?.iconeStyles.iconeColor.color
																}
															/>
														</div>
														<div className='col-9'>{i.title}</div>
													</label>
												</div>
											))
										) : (
											<h4>Сервисов не найдено</h4>
										)}
									</div>
									<PaginationButtons
										data={services || []}
										label='customers'
										small
										filterPagination
										setCurrentPage={setServicesCurrentPage}
										currentPage={servicesCurrentPage}
										perPage={servicesPerPage}
										setPerPage={setServicesPerPage}
										totalPage={
											Math.ceil(
												servicesCount?.data?.length / servicesPerPage,
											) || 1
										}
									/>
								</DropdownMenu>
							</Dropdown> */}
						</FormGroup>
						{/* <FormGroup color={themeStatus} id='service' label='Сервис' className='mb-2'>
							<CustomSelect
								disabled={!!visit}
								placeholder={'Выберите сервис'}
								name={'service'}
								value={values.serviceUuid}
								options={services?.data.map((item: IService, index: number) => {
									return {
										value: item.uuid,
										label: item.title,
									};
								})}
								changeSearchValue={changeSearchValue}
								changeValues={changeValues}
							/>
						</FormGroup> */}
						<FormGroup id='doctor' label=' ' className='mb-2'>
							<Button
								onClick={() => {
									setSchedulModalOpen(true);
								}}
								isLight
								color='primary'
								className='text-nowrap fw-normal me-2 col-12 d-flex align-items-center justify-content-between'>
								Выбрать врача, дату и время визита
							</Button>
						</FormGroup>
						<Card className={`bg-light-primary rounded-2 mb-0 shadow-none`}>
							<CardHeader className='bg-transparent pb-3'>
								<CardLabel icon='Article' iconColor='primary'>
									<span className='fs-4 fw-bold'>Детали визита</span>
								</CardLabel>
							</CardHeader>
							<CardBody className='pt-0 d-flex flex-column gap-2'>
								{/* <p 
							style={{ color: themeStatus, fontWeight: 600 }}>
								Дата визита -{' '}
								{values?.visitDate &&
									formatDateToYYYYMMDD(new Date(values?.visitDate))}
							</p>
							<p style={{ color: themeStatus, fontWeight: 600 }}>
								Время визита -{' '}
								{values?.visitDate &&
									new Date(values?.visitDate).toTimeString().slice(0, 5)}
							</p>
							<p style={{ color: themeStatus, fontWeight: 600 }}>
								Врач -{' '}
								{visit
									? `${visit?.user?.name} ${visit?.user?.surname} ${visit?.user?.middleName}`
									: values?.userFullName && values?.userFullName}
							</p> */}

								<p
									className='m-0 fs-6 d-flex'
									style={{ color: themeStatus, fontWeight: 400 }}>
									<span className='w-100' style={{ maxWidth: '3.5rem' }}>
										Дата
									</span>
									<span className='text-primary fw-bold'>
										{values?.visitDate &&
											formatDateToYYYYMMDD(new Date(values?.visitDate))}
									</span>
								</p>
								<p
									className='m-0 fs-6 d-flex'
									style={{ color: themeStatus, fontWeight: 400 }}>
									<span className='w-100' style={{ maxWidth: '3.5rem' }}>
										Время
									</span>
									<span className='text-primary fw-bold'>
										{dateOfVisit(values?.visitDate, values?.endDate)}
									</span>
								</p>
								<p
									className='m-0 fs-6 d-flex'
									style={{ color: themeStatus, fontWeight: 400 }}>
									<span className='w-100' style={{ maxWidth: '3.5rem' }}>
										Врач
									</span>
									<span className='text-primary fw-bold'>
										{visit
											? `${visit?.user?.name} ${visit?.user?.surname} ${visit?.user?.middleName}`
											: values?.userFullName && values?.userFullName}
									</span>
								</p>
							</CardBody>
						</Card>
					</div>
				</div>
				<div className='d-flex justify-content-between w-100'>
					<Button
						className='fw-bold fs-6'
						color='link'
						isLight
						style={{ color: '#0D6EFD' }}
						onClick={() => {
							setCreateVisit(false);
						}}>
						Назад
					</Button>
					<Button className='fw-bold fs-6' color='info' type='submit'>
						Создать визит
					</Button>
				</div>
			</form>
			{!!visit ? (
				<ScheduleModal
					prevVisit={visit}
					clinicUuid={values?.clinicUuid}
					changeValues={changeValues}
					visitData={visitData}
					setVisitData={setVisitData}
					isOpen={schedulModalOpen}
					setIsOpen={setSchedulModalOpen}
					values={values}
				/>
			) : (
				<ScheduleModal
					clinicUuid={values?.clinicUuid}
					changeValues={changeValues}
					visitData={visitData}
					setVisitData={setVisitData}
					isOpen={schedulModalOpen}
					values={values}
					setIsOpen={setSchedulModalOpen}
				/>
			)}
			<ServiceChooseModal
				setServiceUuid={setServiceUuid}
				setIsOpen={setPopperIsOpenService}
				isOpen={popperIsOpenService}
				stage={stageUuid}
				setChosenValues={setValues}
				serviceDropdownInfo={values?.services}
				createVisit
			/>
		</>
	);
};

export default CreateVisitMedicalCard;
