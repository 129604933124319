import React, { FC, useEffect, useState } from 'react';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../../components/bootstrap/Modal';
import Icon from '../../../components/icon/Icon';
import Button from '../../../components/bootstrap/Button';
import Input from '../../../components/bootstrap/forms/Input';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import useDarkMode from '../../../hooks/useDarkMode';
import Textarea from '../../../components/bootstrap/forms/Textarea';
import { ServiceService } from '../../../custom/api/service/visit-service.service';
import { IService } from '@textnpayme/custom/api/interface/service.interface';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import * as qs from 'qs';
import { useDebounce } from '../../../hooks/useDebounce';
import Tooltips from '../../../components/bootstrap/Tooltips';

import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import PaginationButtons, {
	dataPagination,
	PER_COUNT,
	PER_COUNT_FILTER,
} from '../../../components/PaginationButtons';
import { StageService } from '../../../custom/api/service/stage.service';
import { VisitService } from '../../../custom/api/service/visit.service';
import { IVisit } from '@textnpayme/custom/api/interface/visit.interface';
import { ServicesList } from './components/ServicesList';

interface IServiceChooseModalProps {
	isOpen: boolean;
	setIsOpen(...args: unknown[]): unknown;
	serviceDropdownInfo?: any;
	setServiceDropdownInfo?(...args: unknown[]): unknown;
	stage: any;
	medicalCard?: boolean;
	isStage?: boolean;
	createVisit?: boolean;
	createEvent?: boolean;
	setChosenValues?(...args: unknown[]): unknown;
	setServiceUuid?(...args: unknown[]): unknown;
}

interface CreateServiceParams {
	inputDto: IService;
	uuid: string;
}

const ServiceChooseModal: FC<IServiceChooseModalProps> = ({
	isOpen,
	serviceDropdownInfo,
	setServiceUuid,
	setServiceDropdownInfo,
	setIsOpen,
	stage,
	medicalCard,
	setChosenValues,
	isStage,
	createVisit,
	createEvent,
}) => {
	// Function to trigger the file input click
	const [values, setValues] = useState<IService>({
		services: serviceDropdownInfo ?? [],
		serviceName: '',
		serviceNameChosen: '',
	});

	const [choosenServices, setChoosenService] = useState<any[]>([]);

	useEffect(() => {
		if (
			serviceDropdownInfo &&
			serviceDropdownInfo?.length &&
			serviceDropdownInfo.some((i: any) => !values?.services?.includes(i))
		) {
			setValues((prev) => ({
				...prev,
				services: serviceDropdownInfo,
			}));
		}
	}, [serviceDropdownInfo]);

	const [popperIsOpenService, setPopperIsOpenService] = useState(false);
	const [servicesCurrentPage, setServicesCurrentPage] = useState<number>(1);
	const [servicesPerPage, setServicesPerPage] = useState<number>(PER_COUNT_FILTER['5']);

	const [servicesChosenCurrentPage, setServicesChosenCurrentPage] = useState<number>(1);
	const [servicesChosenPerPage, setServicesChosenPerPage] = useState<number>(
		PER_COUNT_FILTER['5'],
	);

	const { themeStatus } = useDarkMode();

	const changeValues = (key: string, value: string | number) => {
		if (key === 'services') {
			setValues((prev) => ({
				...prev,
				[key]: [...(values[key] || []), value], // Ensure values[key] is an array
			}));
		}
	};

	const handleCheckboxChange = (e: any) => {
		const name = e.target.name;
		if (e.target.type === 'checkbox') {
			const isChecked = e.target.checked;
			if (isChecked) {
				// Check if the value is not already present
				if (!values[name]?.includes(e.target.defaultValue)) {
					changeValues(name, e.target.defaultValue);
				}
			} else {
				// Remove the value from checkboxValue
				setValues((prev) => ({
					...prev,
					[name]: prev[name].filter((value: string) => value !== e.target.defaultValue),
				}));
			}
		}
		if (e.target.type === 'search') {
			if (name === 'serviceName') {
				setValues((prev) => ({ ...prev, [name]: e.target.value }));
			}
		}

		if (e.target.type === 'search') {
			if (name === 'serviceNameChosen') {
				setValues((prev) => ({ ...prev, [name]: e.target.value }));
			}
		}
	};
	// useEffect(() => {
	// 	setValues({
	// 		serviceName: '',
	// 	});
	// }, [stage]);

	const DebounsedSearch = useDebounce(values);
	const { data: services }: { data: IService[] | any; isLoading: boolean } = useQuery(
		[
			'services',
			DebounsedSearch,
			servicesCurrentPage,
			servicesPerPage,
			isStage,
			stage,
			medicalCard,
		],
		() =>
			ServiceService.findAll(
				qs.stringify({
					queryMeta: {
						paginate: true,
						limit: servicesPerPage,
						page: DebounsedSearch?.serviceName.length ? 1 : servicesCurrentPage,
						order: { createdAt: 'DESC' },
					},
					...(!medicalCard &&
						isStage &&
						stage && {
							includeMeta: [
								{
									association: 'stages',
									where: {
										uuid: stage,
									},
								},
							],
						}),
					filterMeta: {
						...(values.serviceName && {
							...(values.serviceName && /\d/.test(values.serviceName)
								? { serviceId: { iLike: `%${values.serviceName}%` } }
								: { title: { iLike: `%${values.serviceName}%` } }),
						}),
					},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	const { data: services_count }: { data: IService[] | any; isLoading: boolean } = useQuery(
		['services_count', isStage, stage, medicalCard],
		() =>
			ServiceService.findAll(
				qs.stringify({
					queryMeta: {
						order: { createdAt: 'DESC' },
					},

					...(!medicalCard &&
						isStage &&
						stage && {
							includeMeta: [
								{
									association: 'stages',
									where: {
										uuid: stage,
									},
								},
							],
						}),

					// filterMeta: {
					// 	...(values.serviceName && {
					// 		...(values.serviceName && /\d/.test(values.serviceName)
					// 			? { serviceId: { iLike: `%${values.serviceName}%` } }
					// 			: { title: { iLike: `%${values.serviceName}%` } }),
					// 	}),
					// },
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	const { data: visits_services }: { data: IVisit[] | any; isLoading: boolean } = useQuery(
		['visits_services', stage],
		() =>
			VisitService.findAll(
				qs.stringify({
					filterMeta: {
						stageUuid: stage,
					},
					includeMeta: [
						{
							association: 'services',
						},
					],

					queryMeta: {
						order: { createdAt: 'DESC' },
					},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	useEffect(() => {
		if (services_count?.data && values?.services && !isStage) {
			const filteredServices = services_count?.data.filter((i: any) =>
				values.services.includes(i.uuid),
			);
			setChoosenService(filteredServices.reverse());
		}
		if (isStage && values?.services && visits_services?.data) {
			const filteredServices = services_count?.data.filter((i: any) =>
				values.services.includes(i.uuid),
			);
			setChoosenService(filteredServices.reverse());
		}
	}, [services_count?.data, values?.services, isStage]);

	const hendelDeleteService = (uuid: string | undefined) => {
		if (uuid && values?.services?.includes(uuid)) {
			setValues((prev) => {
				if (!prev.services) {
					return prev;
				}

				return {
					...prev,
					services: prev.services.filter((value: string) => value !== uuid),
				};
			});
		}
	};
	const [serviceNameChosenArray, setServiceNameChosenArray] = useState([]);

	const queryClient = useQueryClient();
	const DebounsedSearchFilterLocal = useDebounce(values.serviceNameChosen);

	useEffect(() => {
		if (values.serviceNameChosen) {
			let filteredArray = [] as any;
			if (/\d/.test(values.serviceNameChosen)) {
				choosenServices?.filter((item) => {
					item?.serviceId?.includes(values.serviceNameChosen);
				});
			} else {
				filteredArray = choosenServices?.filter(
					(item) => item?.title?.includes(values.serviceNameChosen),
				);
			}

			// Sort the filteredArray to have the last added item on top

			setServiceNameChosenArray(filteredArray);
		}
	}, [DebounsedSearchFilterLocal, choosenServices, values.serviceNameChosen]);

	const { mutate: addService } = useMutation(
		({ uuid, inputDto }: CreateServiceParams) => StageService.update(uuid, inputDto),
		{
			onSuccess: () => {
				queryClient.invalidateQueries('services');
				queryClient.invalidateQueries('stages');
				setIsOpen(false);
				setValues({
					services: [],
					serviceName: '',
				});
				if (setServiceDropdownInfo) {
					setServiceDropdownInfo([]);
				}
			},
			onError: (error) => {
				console.error(error); // Using console.error for error logging
			},
		},
	);

	const hendleSave = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (medicalCard) {
			addService({ uuid: stage, inputDto: values });
		}
		if (!medicalCard && isStage && setChosenValues) {
			setChosenValues((prev: any) => ({
				...prev,
				services: values?.services,
			}));
			setIsOpen(false);
		}

		if (!medicalCard && !isStage && createVisit && setChosenValues) {
			setChosenValues((prev: any) => ({
				...prev,
				services: values?.services,
			}));
			if (setServiceUuid) {
				setServiceUuid(values?.services);
			}
			setIsOpen(false);
		}
		if (!medicalCard && !isStage && !createVisit && createEvent && setChosenValues) {
			setChosenValues((prev: any) => ({
				...prev,
				services: values?.services,
			}));
			// if (setServiceUuid) {
			// 	setServiceUuid(values?.services);
			// }
			setIsOpen(false);
		}
	};
	return (
		<>
			<Modal
				isCentered
				isStaticBackdrop
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				size='xl'
				modalLevel={1060}>
				<ModalHeader setIsOpen={setIsOpen} style={{ padding: '2.25rem 2.25rem 0' }}>
					<></>
				</ModalHeader>
				<form onSubmit={hendleSave} style={{ padding: '0rem 2.25rem 0' }}>
					<ModalBody className='col-12 d-flex w-100 justify-content-between'>
						<div className='col-5 d-flex flex-column gap-2' style={{ width: '48%' }}>
							<span className='fs-5 fw-bold'>Список Услуг</span>
							<div
								// style={{ minHeight: 420 }}
								className='col-12 d-flex flex-column h-100 justify-content-between pb-5'>
								<div className='d-flex flex-column flex-wrap col-12 gap-2'>
									<FormGroup
										id='surname'
										label='Введите название или код услуги'
										className='col-12'>
										<Input
											name='serviceName'
											type={'search'}
											onChange={(e: any) => handleCheckboxChange(e)}
											value={values?.serviceName}
										/>
									</FormGroup>
									{services?.data.length ? (
										services?.data.map((i: IService, index: number) => (
											<ServicesList
												index={index}
												item={i}
												isDisabled={
													!createVisit &&
													!isStage &&
													visits_services?.data.some(
														(item: any) =>
															item?.services.some(
																(service: any) =>
																	service?.uuid === i?.uuid,
															),
													)
												}
												isChecked={values?.services?.includes(i.uuid)}
												handleCheckboxChange={handleCheckboxChange}
												themeStatus={themeStatus}
											/>
										))
									) : (
										<h4>Услуги не найдены</h4>
									)}
								</div>
								<div className='col-12'>
									{/* {stage && isStage || createVisit ? (
										<PaginationButtons
											data={services || []}
											label='customers'
											small
											filterPagination
											setCurrentPage={setServicesCurrentPage}
											currentPage={servicesCurrentPage}
											perPage={servicesPerPage}
											setPerPage={setServicesPerPage}
											totalPage={
												Math.ceil(
													services_count?.meta?.count / servicesPerPage,
												) || 1
											}
										/>
									) : ( */}
									<PaginationButtons
										data={services || []}
										label='customers'
										className='card-footer'
										small
										filterPagination
										setCurrentPage={setServicesCurrentPage}
										currentPage={servicesCurrentPage}
										perPage={servicesPerPage}
										setPerPage={setServicesPerPage}
										totalPage={
											Math.ceil(
												services_count?.data?.length / servicesPerPage,
											) || 1
										}
									/>
									{/* )} */}
								</div>
							</div>
						</div>
						<div
							className='col-1 d-flex justify-content-center aligin-items-center'
							style={{ width: '4%' }}>
							<div
								style={{
									width: 3,
									height: '100%',
									backgroundColor: 'rgba(123, 123, 123, 0.4)',
								}}></div>
						</div>
						<div className='col-5 d-flex flex-column gap-2' style={{ width: '48%' }}>
							<span className='fs-5 fw-bold'>Выбранные Услуги</span>
							<div
								// style={{ minHeight: 420 }}
								className='col-12 d-flex flex-column h-100 justify-content-between pb-5'>
								<div className='d-flex flex-column flex-wrap col-12 gap-2'>
									<FormGroup
										id='surname'
										label='Введите название или код услуги'
										className='col-12'>
										<Input
											name='serviceNameChosen'
											type={'search'}
											onChange={(e: any) => handleCheckboxChange(e)}
											value={values?.serviceNameChosen}
										/>
									</FormGroup>
									{DebounsedSearchFilterLocal ? (
										serviceNameChosenArray.length ? (
											dataPagination(
												serviceNameChosenArray.reverse(),
												servicesChosenCurrentPage,
												servicesPerPage,
											).map((i: IService, index: number) => (
												<ServicesList
													index={index}
													item={i}
													isDisabled={
														!createVisit &&
														!isStage &&
														visits_services?.data.some(
															(item: any) =>
																item?.services.some(
																	(service: any) =>
																		service?.uuid === i?.uuid,
																),
														)
													}
													handleDeleteService={hendelDeleteService}
												/>
											))
										) : (
											<h4>Услуги не найдены</h4>
										)
									) : choosenServices ? (
										dataPagination(
											choosenServices?.reverse(),
											servicesChosenCurrentPage,
											servicesPerPage,
										).map((i: IService, index: number) => (
											<ServicesList
												index={index}
												item={i}
												isDisabled={
													!createVisit &&
													!isStage &&
													visits_services?.data.some(
														(item: any) =>
															item?.services.some(
																(service: any) =>
																	service?.uuid === i?.uuid,
															),
													)
												}
												handleDeleteService={hendelDeleteService}
											/>
										))
									) : (
										<h4>Услуги не найдены</h4>
									)}
								</div>
								{choosenServices?.length > 5 && (
									<div className='col-12'>
										<PaginationButtons
											data={choosenServices || []}
											label='customers'
											small
											filterPagination
											serviceModal
											setCurrentPage={setServicesChosenCurrentPage}
											currentPage={servicesChosenCurrentPage}
											perPage={servicesPerPage}
											setPerPage={setServicesPerPage}
											totalPage={
												Math.ceil(
													choosenServices?.length / servicesPerPage,
												) || 1
											}
										/>
									</div>
								)}
							</div>
						</div>
					</ModalBody>
				</form>
				<ModalFooter className='pt-0 pb-4'>
					<div className='d-flex justify-content-end col-12 gap-3 px-3 py-0'>
						<Button
							className='fw-bold fs-6'
							color='info'
							type='submit'
							onClick={hendleSave}>
							Сохранить
						</Button>
					</div>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default ServiceChooseModal;
