import React from 'react';

const Pill: React.FC<{
	visitDate?: string;
	text?: string;
	status: string;
	VisitStatusStyles: (status: any) => any;
	className?: string | {};
}> = ({ visitDate, text, status, VisitStatusStyles, className }) => {
	const dateStyles = VisitStatusStyles(status)?.dateStyles;
	return (
		<div className={`d-flex align-items-center ${className}`}>
			{dateStyles && (
				<div
					className={`date-frame  ${visitDate ? 'rounded-pill' : 'rounded-2'}`}
					style={{ ...dateStyles, maxWidth: text ? '8rem' : '' }}>
					<span className='text-nowrap fw-bold fs-4'>
						{visitDate ? `${new Date(visitDate).toTimeString().slice(0, 5)}` : text}
					</span>
					<span className='text-nowrap fs-4'>
						{visitDate
							? `${new Date(visitDate).toDateString().split(' ')[1]} ${
									new Date(visitDate).toDateString().split(' ')[2]
							  }`
							: null}
					</span>
				</div>
			)}
		</div>
	);
};
export default Pill;
