import React, { useState, useEffect } from 'react';
import Name from './columns/Name';
import { NameWithAvatar } from './columns/NameWithAvatar';
import Pill from './columns/Pill';
import Status from './columns/Status';
import { StringWithTooltips } from './columns/StringWithTooltips';
import Button from '../../../components/bootstrap/Button';
import EVENT_STATUS from '../../../common/data/enumEventStatus';
import { IVisit } from '../../../custom/api/interface/visit.interface';
import VisitDropdownActions from '../../pages/visit/VisitDropdownActions';
import useDarkMode from '../../../hooks/useDarkMode';
import { Sum } from './columns/Sum';

interface ITableProps {
	headers: any[];
	data: any[];
	visitDropDownProps: any;
}
const VisitStatusStyles = (status: any) => {
	switch (status) {
		case EVENT_STATUS.PENDING.name:
			return {
				dateStyles: {
					backgroundColor: 'rgba(255, 193, 7, 0.3)',
					borderColor: 'rgba(255, 193, 7, 1)',
				},
				iconeStyles: {
					color: '#FFCF52',
					iconeColor: {
						color: EVENT_STATUS.PENDING.color,
					},
				},
			};
		case EVENT_STATUS.APPROVED.name:
			return {
				dateStyles: {
					backgroundColor: 'rgba(25, 135, 84, 0.3)',
					borderColor: 'rgba(25, 135, 84, 1)',
				},
				iconeStyles: {
					color: '#198754',
					iconeColor: {
						color: EVENT_STATUS.APPROVED.color,
					},
				},
			};
		case EVENT_STATUS.IN_PROGRESS.name:
			return {
				dateStyles: {
					backgroundColor: 'rgba(18, 102, 241, 0.3)',
					borderColor: 'rgba(18, 102, 241, 1)',
				},
				iconeStyles: {
					color: '#1266F1',
					iconeColor: {
						color: EVENT_STATUS.IN_PROGRESS.color,
					},
				},
			};

		case EVENT_STATUS.IN_EXPECTATION.name:
			return {
				dateStyles: {
					backgroundColor: 'rgb(249, 115, 0, 0.45)',
					borderColor: 'rgba(255, 165, 0, 1)',
				},
				iconeStyles: {
					color: 'rgb(249, 115, 0)',
					iconeColor: {
						color: EVENT_STATUS.IN_EXPECTATION.color,
					},
				},
			};
		case EVENT_STATUS.CANCELED.name:
			return {
				dateStyles: {
					backgroundColor: 'rgba(220, 53, 69, 0.30)',
					borderColor: '#DC3545',
				},
				iconeStyles: {
					color: '#DC3545',
					iconeColor: {
						color: EVENT_STATUS.CANCELED.color,
					},
				},
			};

		case EVENT_STATUS.NOT_PAID.name:
			return {
				dateStyles: {
					backgroundColor: 'rgba(220, 53, 69, 0.30)',
					borderColor: '#DC3545',
				},
				iconeStyles: {
					color: '#DC3545',
					iconeColor: {
						color: EVENT_STATUS.NOT_PAID.color,
					},
				},
			};
		default:
			return null; // Handle unknown status
	}
};

const TableComponent: React.FC<ITableProps> = ({ headers, data, visitDropDownProps }) => {
	const { themeStatus, darkModeStatus } = useDarkMode();
	const getRootFontSize = () => parseFloat(getComputedStyle(document.documentElement).fontSize);
	const [dynamicFont, setDynamicFont] = useState(getRootFontSize());

	const getFontSizeFromLocalStorage = () => {
		try {
			const fontSize = localStorage.getItem('fontSize');
			if (fontSize) {
				console.log(fontSize);
				const parsedFontSize = JSON.parse(fontSize);
				return parsedFontSize.value || 16;
			}
		} catch (error) {
			console.error('Error parsing fontSize from localStorage:', error);
			const defaultFontSize = { name: 'Нормальный', value: 16 };
			localStorage.setItem('fontSize', JSON.stringify(defaultFontSize));
			return defaultFontSize.value;
		}
	};

	const updateFontSize = () => {
		const fontSizeValue = getFontSizeFromLocalStorage();
		console.log('Font size updated:', fontSizeValue);
		setDynamicFont(fontSizeValue);
	};

	useEffect(() => {
		updateFontSize();

		const handleFontSizeChange = () => {
			updateFontSize();
		};

		window.addEventListener('fontSizeChange', handleFontSizeChange);

		return () => {
			window.removeEventListener('fontSizeChange', handleFontSizeChange);
		};
	}, []);

	const renderCell = (key: string, item: any) => {
		switch (key) {
			case 'id':
				return (
					<Button
						onClick={() => {
							if (
								visitDropDownProps?.setCanvasData &&
								visitDropDownProps?.setEventAdding
							) {
								visitDropDownProps.setCanvasData(item);
								visitDropDownProps.setEventAdding(true);
							}
						}}
						className='fw-bold fs-5 border-0 p-0'>
						{item.visitId}
					</Button>
				);
			case 'datePill':
				return (
					<Pill
						visitDate={item.visitDate}
						status={item.status}
						VisitStatusStyles={VisitStatusStyles}
					/>
				);
			case 'nameWithAvatar':
				return (
					<NameWithAvatar
						user={item.patient}
						patientUuid={item.patientUuid}
						dynamicFont={dynamicFont}
						themeStatus={themeStatus}
					/>
				);
			case 'patientNameWithAvatar':
				return (
					<NameWithAvatar
						user={item}
						patientUuid={item}
						dynamicFont={dynamicFont}
						themeStatus={themeStatus}
					/>
				);
			case 'name':
				return <Name user={item.user} themeStatus={themeStatus} />;
			case 'string':
				return <StringWithTooltips string={item.complaint} />;
			case 'status':
				return <Status status={item.status} VisitStatusStyles={VisitStatusStyles} />;
			case 'action':
				return (
					visitDropDownProps && (
						<div className='d-flex'>
							<VisitDropdownActions
								visit={item}
								setEventAdding={visitDropDownProps.setEventAdding}
								setCanvasData={visitDropDownProps.setCanvasData}
								setUpdateVisitModal={visitDropDownProps.setUpdateVisitModal}
								setActModal={visitDropDownProps.setActModal}
								setActVisit={visitDropDownProps.setActVisit}
								setPatientId={visitDropDownProps.setPatientId}
								setMedicalCaseUuid={visitDropDownProps.setMedicalCaseUuid}
								setServiceUuid={visitDropDownProps.setServiceUuid}
								setClinicUuid={visitDropDownProps.setClinicUuid}
								setStageUuid={visitDropDownProps.setStageUuid}
								setVisitUpdate={visitDropDownProps.setVisitUpdate}
								handleOpenCancel={visitDropDownProps.handleOpenCancel}
								setVisitUuid={visitDropDownProps.setVisitUuid}
								changeStatus={visitDropDownProps.changeStatus}
							/>
						</div>
					)
				);
			case 'visitPill':
				return (
					<Pill
						text={item?.visitNumber || '0000'}
						status={item.status}
						VisitStatusStyles={VisitStatusStyles}
					/>
				);
			case 'sum':
				return (
					<Sum
						text={item?.act?.amount || '0'}
						status={item.status}
						VisitStatusStyles={VisitStatusStyles}
					/>
				);
			default:
				return null;
		}
	};
	const cols = (col: string) => {
		switch (col) {
			case 'id':
				return 1;
			case 'action':
				return 1;
			case 'status':
				return 2;
			case 'datePill':
				return 2;
			case 'visitPill':
				return 1;
			default:
				return 2;
		}
	};

	return (
		<table className='table table-modern table-hover'>
			<thead>
				<tr>
					{headers.map((name, index) => (
						<th key={index}>{name.header}</th>
					))}
				</tr>
			</thead>
			<tbody>
				{data?.map((i: IVisit, index: number) => (
					<>
						<tr key={index}>
							{headers.map((name, index) => (
								<td
									key={index}
									style={{ maxWidth: name.key === 'string' ? '25rem' : 'unset' }}
									className={`col-${cols(name.key)}`}>
									{renderCell(name?.key, i)}
								</td>
							))}
						</tr>
					</>
				))}
			</tbody>
		</table>
	);
};

export default TableComponent;
