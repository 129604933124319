import React, { useContext, useEffect, useState } from 'react';
import Brand from '../../../layout/Brand/Brand';
import Navigation from '../../../layout/Navigation/Navigation';
import User from '../../../layout/User/User';
import { mainPages } from '../../../menu';
import ThemeContext from '../../../contexts/themeContext';
import Aside, { AsideBody, AsideFoot, AsideHead } from '../../../layout/Aside/Aside';

const Sidebar = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	const getRootFontSize = () => parseFloat(getComputedStyle(document.documentElement).fontSize);

	const [leftOffset, setLeftOffset] = useState(16 * getRootFontSize());

	const getFontSizeFromLocalStorage = () => {
		const fontSize = localStorage.getItem('fontSize'); //НЕ ТРОГАТЬ!! Эта переменная позволяет реактивно просчитывать отсутп от левого края и динамически менять его в зависимости от размера шрифта
		try {
			const parsedFontSize = fontSize
				? JSON.parse(fontSize)
				: { name: 'Нормальный', value: 16 };
			return parsedFontSize.value;
		} catch (error) {
			console.error('Ошибка при парсинге fontSize из localStorage:', error);
			return 16;
		}
	};

	const updateLeftOffset = () => {
		const fontSizeValue = getFontSizeFromLocalStorage();
		console.log(fontSizeValue);
		setLeftOffset(16 * fontSizeValue);
	};

	useEffect(() => {
		updateLeftOffset();

		const handleFontSizeChange = () => {
			updateLeftOffset();
		};

		window.addEventListener('fontSizeChange', handleFontSizeChange);

		return () => {
			window.removeEventListener('fontSizeChange', handleFontSizeChange);
		};
	}, []);

	return (
		<Aside leftOffset={leftOffset}>
			<AsideHead>
				<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
			</AsideHead>
			<AsideBody>
				<Navigation menu={mainPages} id='main-pages' />
			</AsideBody>
			<AsideFoot>
				<User />
			</AsideFoot>
		</Aside>
	);
};
export default Sidebar;
