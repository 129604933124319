import React, { useState } from 'react';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';

interface ValidatedInputProps {
	id: string;
	label: string;
	value: string | null | undefined;
	validationMask: RegExp;
	changeValues: (field: string, value: string) => void;
}

export const ValidatedInput: React.FC<ValidatedInputProps> = ({
	id,
	label,
	value,
	validationMask,
	changeValues,
}) => {
	const [isValid, setIsValid] = useState(true);

	const validate = (value: string) => {
		setIsValid(validationMask.test(value));
	};

	return (
		<FormGroup id={id} label={label} className='col-12 fs-6'>
			<Input
				required
				onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
					changeValues(id, e.target.value);
					validate(e.target.value);
				}}
				value={value}
			/>
			{!isValid ? <p className='text-danger'>Неверный формат поля "{label}".</p> : <></>}
		</FormGroup>
	);
};
