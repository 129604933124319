import { authHost } from '../axios';
import { ICancelVisit } from '../interface/cancel-visit.interface';

export class VisitCanceleService {
		// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor() {}
	static async findAll(query: any): Promise<ICancelVisit[] | null> {
		const { data } = await authHost.get(`canceled_visit?${query}`);
		return data;
	}

	static async findOne(uuid: string) {
		const { data } = await authHost.get(`canceled_visit/${uuid}`);
		return data;
	}

	static async create(data: ICancelVisit) {		
		const canceled_visit = await authHost.post('canceled_visit',data)
		return canceled_visit;
	}

	static async update(uuid: string, data: ICancelVisit) {		
		const canceled_visit = await authHost.patch(`canceled_visit/${uuid}`, data)		
		return canceled_visit;
	}

	static async delete(uuid: string) {		
		const canceled_visit = await authHost.delete(`canceled_visit/${uuid}`)		
		return canceled_visit;
	}
}
