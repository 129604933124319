/* eslint-disable react/self-closing-comp */
import React, { FC, useEffect, useState } from 'react';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../../../components/bootstrap/Modal';
import Button from '../../../../components/bootstrap/Button';
import UserImage3 from '../../../../assets/img/wanna/wanna3.png';
import UserImage3Webp from '../../../../assets/img/wanna/wanna3.webp';
import Avatar, { AvatarGroup } from '../../../../components/Avatar';
import Input from '../../../../components/bootstrap/forms/Input';
import SubHeader, { SubHeaderLeft } from '../../../../layout/SubHeader/SubHeader';
import { useFormik } from 'formik';
import { IPatientUpdate } from '@textnpayme/custom/api/interface/patient.interface';
// import Select from '@textnpayme/components/bootstrap/forms/Select';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { PatientService } from '../../../../custom/api/service/patient.service';
import { PassportService } from '../../../../custom/api/service/passport.service';
import { Carousel } from 'antd';
import { PhoneNumberUtil } from 'google-libphonenumber';
// import { Select } from 'antd';
// import Option from '@textnpayme/components/bootstrap/Option';
import { pick } from 'lodash';
import useDarkMode from '../../../../hooks/useDarkMode';
import Popovers from '../../../../components/bootstrap/Popovers';
import Icon from '../../../../components/icon/Icon';
import { useDebounce } from '../../../../hooks/useDebounce';
import { IVisit } from '../../../../custom/api/interface/visit.interface';
import { VisitService } from '../../../../custom/api/service/visit.service';
import * as qs from 'qs';
import { IUser } from '../../../../custom/api/interface/user.interface';
import { UserService } from '../../../../custom/api/service/user.service';
import EVENT_STATUS from '../../../../common/data/enumEventStatus';
import dayjs from 'dayjs';
import { getServiceDataWithServiceName } from '../../../../custom/data/serviceDummyData';
import { IUserProps } from '../../../../common/data/userDummyData';
import { TColor } from '../../../../type/color-type';
import eventList, { IEvents } from '../../../../common/data/events';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import { Calendar, dayjsLocalizer, View as TView, Views } from 'react-big-calendar';
import {
	CalendarViewModeButtons,
	CalendarTodayButton,
	getLabel,
	getUnitType,
	getViews,
} from '../../../../components/extras/calendarHelper';
import classNames from 'classnames';
import Tooltips from '../../../../components/bootstrap/Tooltips';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../../components/bootstrap/Dropdown';

import { format } from 'date-fns';
import moment from 'moment';
import { Divider, notification, Space } from 'antd';
import type { NotificationArgsProps } from 'antd';
import TimePickerModal from './TimePickerModal';
import DeletationSubmit from '../../visit/DeletationSubmit';

type NotificationPlacement = NotificationArgsProps['placement'];

interface IScheduleModalProps {
	visitData: any;
	prevVisit?: any;
	isOpen: boolean;
	values: any;
	setIsOpen(...args: unknown[]): unknown;
	changeValues(...args: unknown[]): unknown;
	setVisitData(...args: unknown[]): unknown;
	clinicUuid: string | undefined;
}

interface UpdatePatientParams {
	uuid: string;
	inputDto: IPatientUpdate;
}

const localizer = dayjsLocalizer(dayjs);
const now = new Date();

interface IEvent extends IEvents {
	user?: IUserProps;
	users?: IUserProps[];
	color?: TColor;
}
interface MyEventProps {
	event: IEvent;
	setIsOpen: (isOpen: boolean) => void;
	setTimePickerIsOpen: (isOpen: boolean) => void;
}

const MyEvent: FC<MyEventProps> = ({ event, setIsOpen, setTimePickerIsOpen }) => {
	// const { darkModeStatus } = useDarkMode();
	// const [dropdownClose, setDropdownClose] = useState<boolean>(false);
	const title = `${dayjs(event.start).format('LT')} - ${dayjs(event.end).format('LT')}`;
	return (
		<>
			{event.id === 'visitDate' && (
				<div
					color={event.color}
					className='d-flex flex-column delete-data-time p-2 align-items-center justify-content-center gap-2'
					style={{
						width: '100%',
						height: '100%',
						position: 'absolute',
						left: 0,
						top: 0,
						backgroundColor: '#1266f1',
						color: '#fff',
						fontSize: '.75rem',
					}}>
					<span className='text-truncate text-center'>{title}</span>
					<div className='d-flex justify-content-center align-items-start gap-3'>
						<span onClick={() => setTimePickerIsOpen(true)}>
							<Icon icon='Edit' size='lg' />
						</span>
						<span
							onClick={() => {
								if (!!event?.prevVisit) {
									event?.changeValues(
										'visitDate',
										event.visitData.filter(
											(i: any) => i.uuid === event?.prevVisit.uuid,
										)[0].visitDate,
									);
								}
								setIsOpen(true);
							}}>
							<Icon icon='Delete' size='lg' />
						</span>
					</div>
					{/* <Dropdown isOpen={dropdownClose} setIsOpen={setDropdownClose}>
						<DropdownToggle hasIcon={false}>
							<Button
								isOutline={!darkModeStatus}
								color='dark'
								isLight={darkModeStatus}
								className={classNames(
									'text-nowrap',

									{
										'border-light': !darkModeStatus,
									},
									'fw-normal',
									'fs-6',
								)}
								style={{ zIndex: '1056' }}>
								•••
							</Button>
						</DropdownToggle>
						<DropdownMenu className='fw-bold'>
							<>
								<DropdownItem>
									<Button onClick={() => null}>Редактировать</Button>
								</DropdownItem>
								<DropdownItem>
									<Button onClick={() => setIsOpen(true)}>Удалить</Button>
								</DropdownItem>
							</>
						</DropdownMenu>
					</Dropdown> */}
				</div>
			)}

			<div className='col-auto'>
				{/* <div className='col text-truncate'>
				{event?.icon && <Icon icon={event?.icon} size='lg' className='me-2' />}
				{event?.name}
				{'  '}
			</div> */}
				{/* {event?.user?.src && (
				<div className='col-auto'>
					<div className='row g-1 align-items-baseline'>
						<div className='col-auto'>
							<Avatar src={event?.user?.src} srcSet={event?.user?.srcSet} size={18} />
						</div>
						<small
							className={classNames('col-auto text-truncate', {
								'text-dark': !darkModeStatus,
								'text-white': darkModeStatus,
							})}>
							{event?.user?.name}
						</small>
					</div>
				</div>
			)} */}
				{/* {event?.users && (
				<div className='col-auto'>
					<AvatarGroup size={18}>
						{event.users.map((user) => (
							// eslint-disable-next-line react/jsx-props-no-spreading
							<Avatar key={user.src} {...user} />
						))}
					</AvatarGroup>
				</div>
			)} */}
			</div>
		</>
	);
};

const MyWeekEvent = (data: { event: IEvent }) => {
	const { darkModeStatus } = useDarkMode();

	const { event } = data;
	return (
		<div className='row g-2'>
			<div className='col-12 text-truncate'>
				{event?.icon && <Icon icon={event?.icon} size='lg' className='me-2' />}
				{event?.name}
			</div>
			{event?.user && (
				<div className='col-12'>
					<div className='row g-1 align-items-baseline'>
						<div className='col-auto'>
							{/* eslint-disable-next-line react/jsx-props-no-spreading */}
							<Avatar {...event?.user} size={18} />
						</div>
						<small
							className={classNames('col-auto text-truncate', {
								'text-dark': !darkModeStatus,
								'text-white': darkModeStatus,
							})}>
							{event?.user?.name}
						</small>
					</div>
				</div>
			)}
			{event?.users && (
				<div className='col-12'>
					<AvatarGroup size={18}>
						{event.users.map((user) => (
							// eslint-disable-next-line react/jsx-props-no-spreading
							<Avatar key={user.src} {...user} />
						))}
					</AvatarGroup>
				</div>
			)}
		</div>
	);
};

const MyEventDay = (data: { event: IEvent }) => {
	const { event } = data;
	return (
		<Tooltips
			title={`${event?.name} / ${dayjs(event.start).format('LT')} - ${dayjs(event.end).format(
				'LT',
			)}`}>
			<div className='row g-2'>
				{event?.user?.src && (
					<div className='col-auto'>
						<Avatar src={event?.user?.src} srcSet={event?.user?.srcSet} size={16} />
					</div>
				)}
				{event?.users && (
					<div className='col'>
						<AvatarGroup size={16}>
							{event.users.map((user) => (
								// eslint-disable-next-line react/jsx-props-no-spreading
								<Avatar key={user.src} {...user} />
							))}
						</AvatarGroup>
					</div>
				)}
				<small className='col text-truncate'>
					{event?.icon && <Icon icon={event?.icon} size='lg' className='me-2' />}
					{event?.name}
				</small>
			</div>
		</Tooltips>
	);
};

const ScheduleModal: FC<IScheduleModalProps> = ({
	visitData,
	prevVisit,
	changeValues,
	setVisitData,
	values,
	isOpen,
	setIsOpen,
	clinicUuid,
}) => {
	const [isValid, setIsValid] = useState(false);
	const [parts, setParts] = useState<any>('');
	const [userSearch, setUserSearch] = useState('');
	const [isUserDoctor, setIsUserDoctor] = useState<boolean>(false);

	const [api, contextHolder] = notification.useNotification();
	const [isOpenSubmit, setIsOpenSubmit] = useState(false);
	const [title, setTitle] = useState<string>('');

	const openNotification = (placement: NotificationPlacement) => {
		api.info({
			message: `Важно !`,
			description: 'Нужно выбрать врача, а потом время и дату',
			placement,
		});
	};

	const openNotificationDate = (placement: NotificationPlacement) => {
		api.info({
			message: `Важно !`,
			description: 'Вы не можете выбрать прошедшую дату для визита',
			placement,
		});
	};

	const validatePhoneNumber = (phoneNumber: string): void => {
		const phoneUtil = PhoneNumberUtil.getInstance();
		const number = phoneNumber.replace(/\+/g, '');
		try {
			const parsedNumber = phoneUtil.parse(number);
			setIsValid(phoneUtil.isValidNumber(parsedNumber));
		} catch (e) {
			const allRegions: string[] = phoneUtil.getSupportedRegions();
			let isValidForAnyRegion: boolean = false;
			for (const region of allRegions) {
				try {
					const parsedNumber = phoneUtil.parse(number, region);
					if (phoneUtil.isValidNumber(parsedNumber)) {
						isValidForAnyRegion = true;
						break;
					}
				} catch (e) {
					continue;
				}
			}
			setIsValid(isValidForAnyRegion);
		}
	};

	useEffect(() => {
		if (userSearch.includes('+')) {
			validatePhoneNumber(userSearch);
		}
		if (userSearch) {
			setParts(userSearch.split(' '));
		}
	}, [userSearch]);

	const [usersList, setUsersList] = useState<string>('');
	const { data: user_role } = useQuery(['user_role'], () =>
		UserService.getProfile(qs.stringify({ refetchOnWindowFocus: true })),
	);

	useEffect(() => {
		setIsUserDoctor(
			user_role?.roles?.some(
				(role: any) => role?.role === 'mainDoctor' || role?.role === 'Doctor',
			),
		);
	}, [user_role]);

	useEffect(() => {
		if (isUserDoctor) {
			changeValues('userUuid', user_role?.uuid);
			changeValues(
				'userFullName',
				`${user_role?.name} ${user_role?.surname} ${user_role?.middleName}`,
			);
			setUsersList(user_role?.uuid);
		}
	}, [isUserDoctor]);

	console.log('777777', usersList);

	const DebiuncedPatinetSearch = useDebounce(userSearch);

	const selectUser = (user: any) => {
		if (user.uuid && user.name && user.surname) {
			if (usersList !== user.uuid) {
				setUsersList(user.uuid);
				changeValues('userFullName', `${user?.name} ${user?.surname} ${user?.middleName}`);
				changeValues('userUuid', user?.uuid);
			} else {
				setUsersList('');
				changeValues('userFullName', '');
				changeValues('userUuid', '');
			}
		}
	};
	useEffect(() => {
		if (!!prevVisit?.userUuid) {
			setUsersList(prevVisit?.userUuid);
			changeValues('userUuid', prevVisit?.userUuid);
		}
	}, [isOpen]);

	const { isLoading: userLoading, data: users }: { data: IUser[] | any; isLoading: boolean } =
		useQuery(
			['users', DebiuncedPatinetSearch, clinicUuid],
			() =>
				UserService.findAll(
					qs.stringify({
						queryMeta: {
							paginate: true,
						},
						includeMeta: [
							{
								association: 'roles',
								where: {
									role: ['mainDoctor', 'doctor'],
									clinicUuid: clinicUuid,
								},
							},
							{
								...(userSearch &&
									/\d/.test(userSearch) &&
									!isValid && {
										association: 'passport',
										where: {
											or: [
												{
													passportNumber: {
														iLike: `%${userSearch}%`,
													},
												},
												{
													ssCardId: {
														iLike: `%${userSearch}%`,
													},
												},
											],
										},
									}),
							},
						],
						filterMeta: {
							...(userSearch &&
								/\d/.test(userSearch) &&
								isValid && { phone: { iLike: `%${userSearch}%` } }),
							...(userSearch &&
								!/\d/.test(userSearch) &&
								!isValid &&
								((parts.length === 1 && {
									surname: { iLike: `%${parts[0]}%` },
								}) ||
									(parts.length === 2 && {
										and: [
											{ surname: { iLike: `%${parts[0]}%` } },
											{ name: { iLike: `%${parts[1]}%` } },
										],
									}) ||
									(parts.length === 3 && {
										and: [
											{ name: { iLike: `%${parts[1]}%` } },
											{ surname: { iLike: `%${parts[0]}%` } },
											{ middleName: { iLike: `%${parts[2]}%` } },
										],
									}))),
						},
					}),
				),
			{
				keepPreviousData: true,
				refetchOnWindowFocus: false,
			},
		);

	const { isLoading, data: visits }: { data: IVisit[] | any; isLoading: boolean } = useQuery(
		['visits', usersList, isUserDoctor],
		() =>
			VisitService.findAll(
				qs.stringify({
					queryMeta: {
						paginate: true,
					},
					includeMeta: [
						{
							association: 'patient',
						},
						{
							association: 'medicalCase',
						},
						{
							association: 'services',
						},
						{
							association: 'user',
							...(!!!usersList && { where: { uuid: usersList } }),
							...(!!!usersList && isUserDoctor && { where: { uuid: usersList } }),
						},
					],
					filterMeta: {
						status: [
							'Предстоящий',
							'В  Процессе',
							'Завершен',
							'В Ожидание',
							'Не Оплачено',
						],
					},
				}),
			),
		{
			// keepPreviousData: true,
			refetchOnWindowFocus: true,
		},
	);

	useEffect(() => {
		if (visits?.data && !prevVisit) {
			setVisitData([...visits.data]);
		}
		if (visits?.data && prevVisit) {
			setVisitData(
				visits.data.filter((i: any) => {
					if (i?.uuid === prevVisit?.uuid) {
						i.status = null;
					}
					return i;
				}),
			);
		}
		if (!visits?.data) {
			setVisitData([]);
		}
	}, [visits, prevVisit]);
	useEffect(() => {
		if (visitData) {
			console.log(
				'visits',
				visitData.filter((i: any) => {
					if (i?.uuid === prevVisit?.uuid) {
						i.status = null;
					}
					return i;
				}),
			);
			setTitle(visitData?.title);
		}
	}, [visitData]);
	const { darkModeStatus, themeStatus } = useDarkMode();
	const [events, setEvents] = useState(eventList);

	// FOR DEV
	useEffect(() => {
		setEvents(eventList);
		return () => {};
	}, []);

	const initialEventItem: IEvent = {
		start: undefined,
		end: undefined,
		name: undefined,
		uuid: undefined,
		user: undefined,
	};
	// Selected Event
	const [eventItem, setEventItem] = useState<IEvent>(initialEventItem);

	const [chooseTimeModal, setChooseTiemModal] = useState<Date>(new Date());
	const [timePickerIsOpen, setTimePickerIsOpen] = useState<boolean>(false);
	// Calendar View Mode
	const [viewMode, setViewMode] = useState<TView>(Views.WEEK);
	// Calendar Date
	const [date, setDate] = useState(new Date());
	// Item edit panel status
	const [toggleInfoEventCanvas, setToggleInfoEventCanvas] = useState(false);
	const setInfoEvent = () => setToggleInfoEventCanvas(!toggleInfoEventCanvas);
	const [eventAdding, setEventAdding] = useState(false);
	// Calendar Unit Type
	const unitType = getUnitType(viewMode);
	const calendarDateLabel = getLabel(date, viewMode);

	// Change view mode
	const handleViewMode = (e: dayjs.ConfigType) => {
		setDate(dayjs(e).toDate());
		setViewMode(Views.DAY);
	};

	const adjustTimeToHour = (timeString: string) => {
		const date = new Date(timeString);
		const timeZoneOffset = date.getTimezoneOffset();
		const adjustedDate = new Date(date.getTime() - timeZoneOffset * 60000);
		adjustedDate.setMinutes(0, 0, 0); // Set minutes, seconds, and milliseconds to 0
		return adjustedDate.toISOString();
	};

	const views = getViews();

	function isDateBeforeNow(date: Date | string): boolean {
		const givenDate = new Date(date);
		const currentDate = new Date();
		return givenDate > currentDate;
	}
	// New Event
	const handleSelect = (data: any) => {
		if (!isDateBeforeNow(data.slots[0])) {
			return openNotificationDate('bottomRight');
		}

		if (!visits?.data) {
			return openNotification('bottomRight');
		}
		if (visits?.data && isDateBeforeNow(data.slots[0])) {
			setTimePickerIsOpen(true);
			setChooseTiemModal(data.slots[0]);
		}
	};

	useEffect(() => {
		if (eventAdding) {
			setInfoEvent();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [eventAdding]);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const eventStyleGetter = (
		event: { color?: TColor },
		start: any,
		end: any,
		isSelected: boolean,
	) => {
		const isActiveEvent = start <= now && end >= now;
		const isPastEvent = end < now;
		const color = isActiveEvent ? 'success' : event.color;

		return {
			className: classNames({
				[`bg-l${darkModeStatus ? 'o25' : '10'}-${color} text-${color}`]: color,
				'border border-success': isActiveEvent,
				'opacity-50': isPastEvent,
			}),
		};
	};

	// const formik = useFormik({
	// 	initialValues: {
	// 		eventName: '',
	// 		eventStart: '',
	// 		eventEnd: '',
	// 		eventEmployee: '',
	// 		eventAllDay: false,
	// 	},
	// 	onSubmit: (values) => {
	// 		if (eventAdding) {
	// 			setEvents((prevEvents) => [
	// 				...prevEvents,
	// 				{
	// 					id: values.eventStart,
	// 					...getServiceDataWithServiceName(values.eventName),
	// 					end: values.eventEnd,
	// 					start: values.eventStart,
	// 					user: { ...getUserDataWithUsername(values.eventEmployee) },
	// 				},
	// 			]);
	// 		}
	// 		setToggleInfoEventCanvas(false);
	// 		setEventAdding(false);
	// 		setEventItem(initialEventItem);
	// 		formik.setValues({
	// 			eventName: '',
	// 			eventStart: '',
	// 			eventEnd: '',
	// 			eventEmployee: '',
	// 			eventAllDay: false,
	// 		});
	// 	},
	// });

	// useEffect(() => {
	// 	if (eventItem)
	// 		formik.setValues({
	// 			...formik.values,
	// 			// @ts-ignore
	// 			eventId: eventItem.id || null,
	// 			eventName: eventItem.name || '',
	// 			eventStart: dayjs(eventItem.start).format(),
	// 			eventEnd: dayjs(eventItem.end).format(),
	// 			eventEmployee: eventItem?.user?.username || '',
	// 		});
	// 	return () => {};
	// 	//	eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [eventItem]);
	// END:: Calendar

	function addOneHour(dateString: string): string {
		// Parse the provided date string while preserving the original timezone
		const date = new Date(dateString);

		// Add one hour to the original date
		date.setHours(date.getHours() + 1);

		// Format the new date while preserving the original timezone
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
		const day = String(date.getDate()).padStart(2, '0');
		const hours = String(date.getHours()).padStart(2, '0');
		const minutes = String(date.getMinutes()).padStart(2, '0');
		const seconds = String(date.getSeconds()).padStart(2, '0');
		const milliseconds = String(date.getMilliseconds()).padStart(3, '0');

		// Construct the formatted date string in the local timezone
		const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;

		return formattedDate;
	}

	function addFifteenMinutes(dateString: string): string {
		// Parse the provided date string while preserving the original timezone
		const date = new Date(dateString);

		// Add 15 minutes to the original date
		date.setMinutes(date.getMinutes() + 15);

		// Format the new date while preserving the original timezone
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
		const day = String(date.getDate()).padStart(2, '0');
		const hours = String(date.getHours()).padStart(2, '0');
		const minutes = String(date.getMinutes()).padStart(2, '0');
		const seconds = String(date.getSeconds()).padStart(2, '0');
		const milliseconds = String(date.getMilliseconds()).padStart(3, '0');

		// Construct the formatted date string in the local timezone
		const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;

		return formattedDate;
	}

	useEffect(() => {
		if (!users?.data.length) {
			setUsersList('');
			changeValues('userFullName', ``);
			changeValues('userUuid', '');
		}
	}, [users?.data]);
	const VisitStatusStyles = (status: any) => {
		switch (status) {
			case EVENT_STATUS.PENDING.name:
				return {
					dateStyles: {
						backgroundColor: 'rgba(255, 193, 7, 0.3)',
						borderColor: 'rgba(255, 193, 7, 1)',
					},
					iconeStyles: {
						color: '#FFCF52',
						iconeColor: {
							color: EVENT_STATUS.PENDING.color,
						},
					},
				};
			case EVENT_STATUS.APPROVED.name:
				return {
					dateStyles: {
						backgroundColor: 'rgba(25, 135, 84, 0.3)',
						borderColor: 'rgba(25, 135, 84, 1)',
					},
					iconeStyles: {
						color: '#198754',
						iconeColor: {
							color: EVENT_STATUS.APPROVED.color,
						},
					},
				};
			case EVENT_STATUS.IN_PROGRESS.name:
				return {
					dateStyles: {
						backgroundColor: 'rgba(18, 102, 241, 0.3)',
						borderColor: 'rgba(18, 102, 241, 1)',
					},
					iconeStyles: {
						color: '#1266F1',
						iconeColor: {
							color: EVENT_STATUS.IN_PROGRESS.color,
						},
					},
				};

			case EVENT_STATUS.IN_EXPECTATION.name:
				return {
					dateStyles: {
						backgroundColor: 'rgb(249, 115, 0, 0.45)',
						borderColor: 'rgba(255, 165, 0, 1)',
					},
					iconeStyles: {
						color: '#f97300',
						iconeColor: {
							color: EVENT_STATUS.IN_EXPECTATION.color,
						},
					},
				};
			case EVENT_STATUS.CANCELED.name:
				return {
					dateStyles: {
						backgroundColor: 'rgba(220, 53, 69, 0.30)',
						borderColor: '#DC3545',
					},
					iconeStyles: {
						color: '#DC3545',
						iconeColor: {
							color: EVENT_STATUS.CANCELED.color,
						},
					},
				};

			case EVENT_STATUS.NOT_PAID.name:
				return {
					dateStyles: {
						backgroundColor: 'rgba(220, 53, 69, 0.30)',
						borderColor: '#DC3545',
					},
					iconeStyles: {
						color: '#DC3545',
						iconeColor: {
							color: EVENT_STATUS.NOT_PAID.color,
						},
					},
				};
			default:
				return undefined; // Handle unknown status
		}
	};

	function parseDateTimeToLocal(dateString: string): Date {
		// Parse the date string and preserve the original timezone
		const date = new Date(dateString);

		// Get the individual components of the date
		const year = date.getFullYear();
		const month = date.getMonth(); // Note: months are 0-indexed in JavaScript Date
		const day = date.getDate();
		const hours = date.getHours();
		const minutes = date.getMinutes();
		const seconds = date.getSeconds();
		const milliseconds = date.getMilliseconds();

		// Create a new Date object using the extracted components
		const localDate = new Date(year, month, day, hours, minutes, seconds, milliseconds);

		return localDate;
	}

	function parseDateTime(dateString: string): { date: string; time: string } {
		const date = new Date(dateString);
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
		const hours = String(date.getHours()).padStart(2, '0');
		const minutes = String(date.getMinutes()).padStart(2, '0');

		const formattedDate = `${year}-${month}-${day}`;
		const formattedTime = `${hours}:${minutes}`;

		return {
			date: formattedDate,
			time: formattedTime,
		};
	}

	return (
		<>
			<Modal isStaticBackdrop isCentered isOpen={isOpen} setIsOpen={setIsOpen} size={'xl'}>
				<ModalHeader setIsOpen={setIsOpen} className='p-4'>
					<span className='fs-3 fw-bold'>
						{isUserDoctor ? 'График Врачa' : 'График Врачей'}
					</span>
				</ModalHeader>
				<ModalBody className='px-4'>
					<>
						{!isUserDoctor && (
							<SubHeader>
								<SubHeaderLeft>
									<label
										className='border-0 bg-transparent cursor-pointer me-0'
										htmlFor='searchInput'>
										<Icon icon='Search' size='2x' color='primary' />
									</label>
									<Input
										id='searchInput'
										type='search'
										className='border-0 shadow-none bg-transparent'
										placeholder='Поиск врача по ФИО или по коду'
										onChange={(e: any) => setUserSearch(e.target.value)}
										value={userSearch}
									/>
								</SubHeaderLeft>
							</SubHeader>
						)}
					</>
					{contextHolder}
					{!isUserDoctor && (
						<div style={{ marginTop: '25px' }} className='row mb-4 g-3'>
							{users?.data?.length ? (
								users.data.length >= 10 ? (
									<Carousel
										slidesToShow={10}
										dots={false}
										arrows
										infinite={false}>
										{users?.data?.map((user: IUser, index: number) => (
											<div key={index}>
												<Popovers
													trigger='hover'
													desc={
														<div className='h6'>{`${user.surname} ${user.name} ${user.middleName}`}</div>
													}>
													<div className='position-relative'>
														<Avatar
															srcSet={
																user?.image
																	? `https://backend.amracode.am${user?.image}`
																	: UserImage3
															}
															src={
																user?.image
																	? `https://backend.amracode.am${user?.image}`
																	: UserImage3
															}
															size={64}
															border={4}
															className='cursor-pointer'
															borderColor={
																usersList === user?.uuid
																	? 'info'
																	: themeStatus
															}
															onClick={() => selectUser(user)}
														/>
													</div>
												</Popovers>
											</div>
										))}
									</Carousel>
								) : (
									<div className='d-flex p-2 bd-highlight' style={{ gap: 10 }}>
										{users.data.map((user: IUser, index: number) => (
											<div className='ml-1 mr-1' key={index}>
												<Popovers
													trigger='hover'
													desc={
														<div className='h6'>{`${user.surname} ${user.name} ${user.middleName}`}</div>
													}>
													<div className='position-relative'>
														<Avatar
															srcSet={
																user?.image
																	? `https://backend.amracode.am${user?.image}`
																	: UserImage3
															}
															src={
																user?.image
																	? `https://backend.amracode.am${user?.image}`
																	: UserImage3
															}
															size={64}
															border={4}
															className='cursor-pointer'
															borderColor={
																usersList === user.uuid
																	? 'info'
																	: themeStatus
															}
															onClick={() => selectUser(user)}
														/>
													</div>
												</Popovers>
											</div>
										))}
									</div>
								)
							) : (
								<div className='d-flex justify-content-center'>
									<h3>Врачей не найдено</h3>
								</div>
							)}
						</div>
					)}

					<div className='col-xl-9'>
						<Card stretch style={{ width: '1100px', minHeight: 500 }}>
							<CardHeader>
								<CardActions className='w-100'>
									<div className=' d-flex flex-row gap-4 justify-content-between w-100'>
										<CardLabel icon='Today' iconColor='info'>
											<CardTitle tag='div' className='h5'>
												{dayjs(date).format('MMMM D YYYY')}
											</CardTitle>
											<CardSubTitle tag='div' className='h6'>
												{dayjs(date).fromNow()}
											</CardSubTitle>
										</CardLabel>
										<CardActions className='d-flex'>
											<CalendarViewModeButtons
												doctors
												setViewMode={setViewMode}
												viewMode={viewMode}
											/>
											<CalendarTodayButton
												unitType={unitType}
												date={date}
												setDate={setDate}
												viewMode={viewMode}
											/>
										</CardActions>
									</div>
								</CardActions>
							</CardHeader>
							<CardBody isScrollable>
								<Calendar
									selectable
									toolbar={false}
									localizer={localizer}
									timeslots={3}
									events={
										visitData?.map((visit: any, idx: number) => ({
											color: VisitStatusStyles(visit.status)?.iconeStyles
												.iconeColor.color,
											uuid: visit?.uuid,
											name: visit?.services?.map((i: any) => (
												<>
													{i?.title} <br />
												</>
											)),
											start: new Date(visit.startDate),
											end: new Date(visit.endDate),
											user: {
												...visit?.user,
												name: '',
												src: UserImage3,
												srcSet: UserImage3Webp,
											},
											updateVisitUuid: !!prevVisit ? prevVisit.uuid : null,
											...visit,
										})) || []
									}
									defaultView={Views.WEEK}
									views={views}
									view={viewMode}
									date={date}
									onNavigate={(_date) => setDate(_date)}
									scrollToTime={new Date(1970, 1, 1, 6)}
									defaultDate={new Date()}
									onSelectSlot={handleSelect}
									onView={handleViewMode}
									onDrillDown={handleViewMode}
									components={{
										event: (props) => (
											<MyEvent
												{...props}
												setIsOpen={setIsOpenSubmit}
												setTimePickerIsOpen={setTimePickerIsOpen}
											/>
										),
									}}
									eventPropGetter={eventStyleGetter}
								/>
							</CardBody>
						</Card>
					</div>
				</ModalBody>
				<ModalFooter>
					<div className='d-flex flex-row col-12 gap-3 px-3'>
						<Button
							color='primary'
							className='col-12'
							onClick={() => {
								setIsOpen(false);
								if (
									values.userUuid &&
									!values.visitDate &&
									!values.endDate &&
									!values.startDate
								) {
									changeValues('visitDate', '');
									changeValues('endDate', '');
									changeValues('startDate', '');
									setUsersList('');
									changeValues('userFullName', '');
									changeValues('userUuid', '');
								}
							}}>
							Сохранить
						</Button>
					</div>
				</ModalFooter>
			</Modal>

			<TimePickerModal
				chooseTimeDate={chooseTimeModal}
				visitData={visitData}
				setVisitData={setVisitData}
				changeValues={changeValues}
				isOpen={timePickerIsOpen}
				setIsOpen={setTimePickerIsOpen}
				setChooseTimeDate={setChooseTiemModal}
				visits={visits}
				prevVisit={prevVisit}
			/>

			<DeletationSubmit
				setIsOpen={setIsOpenSubmit}
				isOpen={isOpenSubmit}
				submit={() => {
					setVisitData((prev: any) => prev.filter((i: any) => i.id !== 'visitDate'));
					changeValues('visitDate', '');
					changeValues('endDate', '');
					changeValues('startDate', '');
				}}
			/>
		</>
	);
};

export default ScheduleModal;
