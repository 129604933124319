import React, { SVGProps } from "react";

export const TC_BR = (props: SVGProps<SVGSVGElement>) => {

    return (
		<>
            <svg width="1.6rem" height="1.6rem" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="path-1-inside-1_523_10064" fill={props.fill}>
                <path d="M20.5 0.22998C20.5 2.85642 19.9827 5.45714 18.9776 7.88365C17.9725 10.3102 16.4993 12.5149 14.6421 14.3721C12.785 16.2293 10.5802 17.7025 8.15367 18.7076C5.72716 19.7127 3.12644 20.23 0.5 20.23V0.22998H20.5Z"/>
                </mask>
                <g filter="url(#filter0_i_523_10064)">
                <path d="M20.5 0.22998C20.5 2.85642 19.9827 5.45714 18.9776 7.88365C17.9725 10.3102 16.4993 12.5149 14.6421 14.3721C12.785 16.2293 10.5802 17.7025 8.15367 18.7076C5.72716 19.7127 3.12644 20.23 0.5 20.23V0.22998H20.5Z" fill={!!props.fill?props.fill:"url(#paint0_linear_523_10064)"} fill-opacity={!!props.fill?"0.8":"0.3"}/>
                </g>
                <path d="M20.5 0.22998C20.5 2.85642 19.9827 5.45714 18.9776 7.88365C17.9725 10.3102 16.4993 12.5149 14.6421 14.3721C12.785 16.2293 10.5802 17.7025 8.15367 18.7076C5.72716 19.7127 3.12644 20.23 0.5 20.23V0.22998H20.5Z" stroke="black" stroke-opacity="0.2" mask="url(#path-1-inside-1_523_10064)"/>
                <defs>
                <filter id="filter0_i_523_10064" x="0.5" y="-3.77002" width="20" height="24" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="-4"/>
                <feGaussianBlur stdDeviation="2"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_523_10064"/>
                </filter>
                <linearGradient id="paint0_linear_523_10064" x1="0.500002" y1="19.73" x2="20.5" y2="0.229978" gradientUnits="userSpaceOnUse">
                <stop stop-color="#F8F4ED"/>
                <stop offset="1" stop-color="#9B9B9B"/>
                </linearGradient>
                </defs>
            </svg>    
        </>
	);
};