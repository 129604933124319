/* eslint-disable react-hooks/exhaustive-deps */
import React, {
	createContext,
	FC,
	ReactNode,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import PropTypes from 'prop-types';
import DentalContext from './dentalContext';
import { notification } from 'antd';
import type { NotificationArgsProps } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import _ from 'lodash';
import { useAppDispatch } from '@textnpayme/hooks/store';
import { TC_TL } from '../assets/svg/TC_TL';
import { TC_TR } from '../assets/svg/TC_TR';
import { TC_BL } from '../assets/svg/TC_BL';
import { TC_BR } from '../assets/svg/TC_BR';
import { TC_C } from '../assets/svg/TC_C';

const SurfaceSVG: FC<{ fill: string; surface: string }> = ({ fill, surface }) => (
	<>
		{surface === 'top_left' && <TC_TL fill={fill} />}
		{surface === 'top_right' && <TC_TR fill={fill} />}
		{surface === 'bottom_left' && <TC_BL fill={fill} />}
		{surface === 'bottom_right' && <TC_BR fill={fill} />}
		{surface === 'center' && <TC_C fill={fill} />}
	</>
);

type NotificationPlacement = NotificationArgsProps['placement'];
export interface IDentalContextFunctionsProps {
	contextHolder: any;
	generateSequence: any;
	handleTeethUpdate: any;
	localFormData: any;
	setLocalFormData: any;
	localDentalFormula: any;
	setLocalDentalFormula: any;
	updateTeethData: any;
	isModified: any;
	setIsModified: any;
	checkDirection: any;
	changeItemInfo: any;
	deleteItem: any;
	addItem: any;
	save: any;
	cancel: any;
	updateDentalFormulaFromBackend: any;
	addItemAndUpdate: any;
	setDisease: any;
}
const DentalContextFunctions = createContext<IDentalContextFunctionsProps>(
	{} as IDentalContextFunctionsProps,
);

interface IDentalContextFunctionsProviderProps {
	children: ReactNode;
}
export const DentalContextFunctionsProvider: FC<IDentalContextFunctionsProviderProps> = ({
	children,
}) => {
	const {
		formData,
		setFormData,
		dentalFormula,
		setDentalFormula,
		setSelectMany,
		setIntermediateItems,
		setSelectedItems,
		default_dental_formula,
	} = useContext(DentalContext);

	const [isModified, setIsModified] = useState(false);

	const [localDentalFormula, setLocalDentalFormula] = useState(cloneDeep(dentalFormula));

	const [localFormData, setLocalFormData] = useState(cloneDeep(formData));
	const [api, contextHolder] = notification.useNotification();

	const generateSequence = (
		start: string | number,
		end: string | number,
		direction?: string,
	): string[] => {
		let result: string[] = [];

		const startId = start.toString();
		const endId = end.toString();

		if (!direction) {
			direction = startId[0] === '1' || endId[0] === '2' ? 'top' : 'bottom';
		}

		const formula = localDentalFormula[direction];

		if (!Array.isArray(formula)) {
			console.error(`Invalid direction or data structure: ${direction}`);
			return result;
		}

		const startIndex = formula.findIndex((tooth: any) => tooth.id === startId);
		const endIndex = formula.findIndex((tooth: any) => tooth.id === endId);

		if (startIndex < 0 || endIndex < 0) {
			// console.log('Start or end index not found.');
			return result;
		}

		if (startIndex < endIndex) {
			for (let i = startIndex + 1; i < endIndex; i++) {
				result.push(formula[i].id);
			}
		} else {
			for (let i = startIndex - 1; i > endIndex; i--) {
				// console.log(222222222, i, 'startIndexstartIndex');

				result.push(formula[i].id);
			}
		}

		return result;
	};

	const openNotificationBridgePlace = (placement: NotificationPlacement, description: string) => {
		api.info({
			message: 'Важно!',
			description,
			placement,
			className: 'ant-notification-custom',
			props: { style: { zIndex: 150000 } },
		});
	};
	const updateTeethData = (
		value: number | string,
		index: number,
		direction: string,
		teethIndex: number,
		type: ItemType,
	) => {
		// Validate formData and check for valid type and index
		if (!formData[type] || !formData[type][index]) {
			console.error(`Invalid type or index: ${type}, ${index}`);
			return;
		}

		const directionIndex =
			formData[type][index]?.theeth[0] === '1' || formData[type][index]?.theeth[0] === '2'
				? 'top'
				: 'bottom';

		// Reset the type property of the current item in the dental formula
		const currentTeethIndex = dentalFormula[directionIndex]?.findIndex(
			(item: any) => item.id === formData[type][index]?.theeth,
		);

		if (currentTeethIndex !== -1) {
			dentalFormula[directionIndex][currentTeethIndex] = {
				...dentalFormula[directionIndex][currentTeethIndex],
				[type]: false,
			};
		}
		const updatedFormData = [...formData[type]];
		updatedFormData[index] = {
			...updatedFormData[index],
			theeth: value,
		};
		const updatedDentalFormula = [...dentalFormula[direction]];
		updatedDentalFormula[teethIndex] = {
			...updatedDentalFormula[teethIndex],
			[type]: true,
		};

		// Update the state with the new values
		setDentalFormula((prev: any) => ({
			...prev,
			[direction]: updatedDentalFormula,
		}));

		setFormData((prev: any) => ({
			...prev,
			[type]: updatedFormData,
		}));

		setIsModified(true);
	};

	const checkBridgeOverlap = (start: string, end: string, direction: string, index: number) => {
		const bridge = { ...localFormData.bridge[index] };
		const startIdx = localDentalFormula[direction]?.findIndex(
			(tooth: any) => tooth.id === start,
		);
		const endIdx = localDentalFormula[direction]?.findIndex((tooth: any) => tooth.id === end);

		const sorted_start_index = startIdx > endIdx ? endIdx : startIdx;
		const sorted_end_index = endIdx > startIdx ? endIdx : startIdx;

		if (sorted_start_index === -1 || sorted_end_index === -1) return false;

		const isOverlapping = formData.bridge.some((existingBridge: any) => {
			const existingStartIdx = localDentalFormula[direction]?.findIndex(
				(tooth: any) => tooth.id === existingBridge.start,
			);
			const existingEndIdx = localDentalFormula[direction]?.findIndex(
				(tooth: any) => tooth.id === existingBridge.end,
			);

			const overlapsStart =
				sorted_start_index >= existingStartIdx && sorted_start_index <= existingEndIdx;
			const overlapsEnd =
				sorted_end_index >= existingStartIdx && sorted_end_index <= existingEndIdx;
			const containsExisting =
				sorted_start_index <= existingStartIdx && sorted_end_index >= existingEndIdx;

			if (overlapsStart || overlapsEnd || containsExisting) {
				bridge.start = '';
				bridge.end = '';
				openNotificationBridgePlace('bottomRight', 'На эти зубах уже есть мост');
				return true;
			}
			setIsModified(false);
			return false;
		});

		return isOverlapping;
	};
	// Bridge-specific logic should remain separate

	const checkDirection = (
		direction: string,
		type: 'referral' | 'implant' | 'event' | 'bridge',
	) => {
		return formData[type].some(
			(item: any) => dentalFormula[direction]?.some((tooth: any) => tooth.id === item.theeth),
		);
	};

	type ItemType =
		| 'bridge'
		| 'referral'
		| 'implant'
		| 'event'
		| 'deletion'
		| 'milk_theeth'
		| 'empty';

	const addItemAndUpdate = (type: ItemType, defaultItem: any, value: [any, any]) => {
		if (value.length > 1 && type === 'bridge') {
			const index_bridge = [...localFormData.bridge, { ...defaultItem }].length - 1;
			setLocalFormData((prev: any) => {
				const updatedBridge = [...prev[type], { ...defaultItem }];
				const direction = value[0][0] === '1' || value[0][0] === '2' ? 'top' : 'bottom';
				const bridge = { ...updatedBridge[index_bridge] };

				if (!!!bridge.start && !!!bridge.end) {
					bridge.start = String(value[0]);
				}
				if (!!bridge.start && !!!bridge.end) {
					if (String(value[1]) === bridge.start) {
						return prev;
					}
					if (
						!checkBridgeOverlap(bridge.start, String(value[1]), direction, index_bridge)
					) {
						bridge.end = String(value[1]);

						const startIdx = localDentalFormula[direction].findIndex(
							(item: any) => item.id === value[0],
						);
						const endIdx = localDentalFormula[direction].findIndex(
							(item: any) => item.id === value[1],
						);
						if (startIdx > -1 && endIdx > -1) {
							const sequence = generateSequence(bridge.start, bridge.end, direction);
							const updatedFormula = localDentalFormula[direction].map(
								(tooth: any) => {
									if (tooth.id === bridge.start) {
										return { ...tooth, [type]: true, bridge_position: 'start' };
									} else if (tooth.id === bridge.end) {
										return { ...tooth, [type]: true, bridge_position: 'end' };
									} else if (sequence.includes(tooth.id)) {
										return {
											...tooth,
											[type]: true,
											bridge_position: 'center',
										};
									}
									return tooth;
								},
							);

							setLocalDentalFormula((prev: any) => ({
								...prev,
								[direction]: updatedFormula,
							}));
							setDentalFormula((prev: any) => ({
								...prev,
								[direction]: updatedFormula,
							}));
						} else {
							bridge.start = '';
							bridge.end = '';
						}
					} else {
						bridge.start = '';
						bridge.end = '';
					}
				}

				updatedBridge[index_bridge] = bridge;
				setFormData((prev: any) => ({
					...prev,
					[type]: updatedBridge,
				}));
				return { ...prev, [type]: updatedBridge };
			});
			setSelectMany(false);
			setIsModified(false);
			setIntermediateItems([]);
			setSelectedItems([]);
		}

		if (type !== 'bridge') {
			const index = [...formData[type], { ...defaultItem }].length - 1;
			const direction = value[0][0] === '1' || value[0][0] === '2' ? 'top' : 'bottom';
			const updatedFormData = [...formData[type], { ...defaultItem }];
			formData[type] = updatedFormData;
			const teethIndex = dentalFormula[direction]?.findIndex(
				(item: any) => item.id === value[0],
			);

			if (!formData[type] || !formData[type][index]) {
				console.error(`Invalid type or index: ${type}, ${index}`);
				return;
			}

			const directionIndex =
				formData[type][index]?.theeth[0] === '1' || formData[type][index]?.theeth[0] === '2'
					? 'top'
					: 'bottom';

			// Reset the type property of the current item in the dental formula
			const currentTeethIndex = dentalFormula[directionIndex]?.findIndex(
				(item: any) => item.id === formData[type][index]?.theeth,
			);

			if (currentTeethIndex !== -1) {
				dentalFormula[directionIndex][currentTeethIndex] = {
					...dentalFormula[directionIndex][currentTeethIndex],
					[type]: false,
				};
			}

			updatedFormData[index] = {
				...updatedFormData[index],
				theeth: value[0],
			};

			const updatedDentalFormula = [...dentalFormula[direction]];
			updatedDentalFormula[teethIndex] = {
				...updatedDentalFormula[teethIndex],
				[type]: true,
			};

			setDentalFormula((prev: any) => ({
				...prev,
				[direction]: updatedDentalFormula,
			}));

			setFormData((prev: any) => ({
				...prev,
				[type]: updatedFormData,
			}));

			setSelectMany(false);
			setIsModified(false);
			setIntermediateItems([]);
			setSelectedItems([]);
		}
	};

	const addItem = (type: ItemType, defaultItem: any) => {
		setFormData((prev: any) => ({
			...prev,
			[type]: [...prev[type], { ...defaultItem }],
		}));
	};

	const handleTeethUpdate = (
		value: number | string,
		index: number,
		direction: string,
		teethIndex: number,
		type: ItemType,
	) => {
		if (type === 'bridge') {
			addBridgeTeeth(value, index, direction);
		} else {
			updateTeethData(value, index, direction, teethIndex, type);
		}
	};

	const addBridgeTeeth = (value: number | string, index: number, direction: string) => {
		setLocalFormData((prev: any) => {
			const updatedBridge = [...prev.bridge];
			const bridge = { ...updatedBridge[index] };

			if (!bridge.start && !bridge.end) {
				bridge.start = String(value);
			} else if (bridge.start && !bridge.end) {
				if (String(value) === bridge.start) {
					return prev; // No change if the selected tooth is the same as the start
				}
				if (!checkBridgeOverlap(bridge.start, String(value), direction, index)) {
					bridge.end = String(value);

					const startIdx = localDentalFormula[direction].findIndex(
						(item: any) => item.id === bridge.start,
					);
					const endIdx = localDentalFormula[direction].findIndex(
						(item: any) => item.id === bridge.end,
					);

					if (startIdx > -1 && endIdx > -1) {
						const sequence = generateSequence(bridge.start, bridge.end, direction);
						const updatedFormula = localDentalFormula[direction].map((tooth: any) => {
							if (tooth.id === bridge.start) {
								return { ...tooth, bridge: true, bridge_position: 'start' };
							} else if (tooth.id === bridge.end) {
								return { ...tooth, bridge: true, bridge_position: 'end' };
							} else if (sequence.includes(tooth.id)) {
								return { ...tooth, bridge: true, bridge_position: 'center' };
							}
							return tooth;
						});

						setLocalDentalFormula((prev: any) => ({
							...prev,
							[direction]: updatedFormula,
						}));
					} else {
						bridge.start = '';
						bridge.end = '';
					}
				} else {
					bridge.start = '';
					bridge.end = '';
				}
			}

			updatedBridge[index] = bridge;
			return { ...prev, bridge: updatedBridge };
		});

		setIsModified(true);
	};

	// State initialization

	const deleteItem = (type: ItemType, dataIndex: number) => {
		if (dataIndex > -1 && dataIndex < formData[type].length) {
			const updatedItems = formData[type].filter((_: any, idx: number) => idx !== dataIndex);
			setFormData((prev: any) => ({ ...prev, [type]: updatedItems }));

			if (type !== 'bridge') {
				const itemToDelete = formData[type][dataIndex];
				if (itemToDelete.theeth) {
					const direction =
						itemToDelete.theeth[0] === '1' || itemToDelete.theeth[0] === '2'
							? 'top'
							: 'bottom';
					let formula = [...dentalFormula[direction]];
					const theethIdx = formula.findIndex((item) => item.id === itemToDelete.theeth);
					formula[theethIdx] = {
						...formula[theethIdx],
						[type]: false,
					};
					setDentalFormula((prev: any) => ({ ...prev, [direction]: formula }));
				}
			} else {
				const itemToDelete = formData[type][dataIndex];
				if (itemToDelete.start && itemToDelete.end) {
					const direction =
						itemToDelete.start[0] === '1' || itemToDelete.start[0] === '2'
							? 'top'
							: 'bottom';
					let formula = [...localDentalFormula[direction]];
					const startIdx = formula.findIndex((item) => item.id === itemToDelete.start);
					const endIdx = formula.findIndex((item) => item.id === itemToDelete.end);

					if (startIdx > -1 && endIdx > -1) {
						formula[startIdx] = {
							...formula[startIdx],
							bridge: false,
							bridge_position: null,
						};
						formula[endIdx] = {
							...formula[endIdx],
							bridge: false,
							bridge_position: null,
						};

						for (let i = startIdx + 1; i < endIdx; i++) {
							formula[i] = { ...formula[i], bridge: false, bridge_position: null };
						}

						setDentalFormula((prev: any) => ({ ...prev, [direction]: formula }));
					}
				}
			}
		}
	};

	const changeItemInfo = (type: ItemType, e: any, index: number) => {
		const { value, name } = e.target;
		setFormData((prev: any) => {
			const updatedItems = prev[type].map((item: any, idx: number) =>
				idx === index ? { ...item, [name]: value } : item,
			);
			return { ...prev, [type]: updatedItems };
		});
	};

	const save = (type: ItemType, index: number, direction: string) => {
		if (type !== 'bridge') {
			if (direction) {
				dentalFormula[direction][
					dentalFormula[direction].findIndex(
						(item: any) => item.id === formData.implant[index].theeth,
					)
				] = {
					...dentalFormula[direction][
						dentalFormula[direction].findIndex(
							(item: any) => item.id === formData.implant[index].theeth,
						)
					],
					implant: true,
				};
				setDentalFormula((prev: any) => ({
					...prev,
					[direction]: dentalFormula[direction],
				}));
			}
		} else {
			setFormData(localFormData);
			setDentalFormula(localDentalFormula);
		}

		setIsModified(false);
	};

	const cancel = (type: ItemType, index: number, direction: string) => {
		// Clone dentalFormula to avoid direct mutation
		if (type !== 'bridge') {
			const updatedDentalFormula = { ...dentalFormula };

			if (direction) {
				const toothIndex = updatedDentalFormula[direction].findIndex(
					(item: any) => item.id === formData.implant[index].theeth,
				);

				if (toothIndex !== -1) {
					updatedDentalFormula[direction][toothIndex] = {
						...updatedDentalFormula[direction][toothIndex],
						implant: false,
					};
				}

				setDentalFormula((prev: any) => ({
					...prev,
					[direction]: updatedDentalFormula[direction],
				}));
			}

			const updatedFormData = { ...formData };

			updatedFormData.implant[index] = {
				...updatedFormData.implant[index],
				theeth: '',
			};

			setFormData((prev: any) => ({
				...prev,
				implant: updatedFormData.implant,
			}));
		} else {
			setLocalFormData(formData);
			setLocalDentalFormula(dentalFormula);
			setIsModified(false);
		}
		setIsModified(false);
	};

	const updateDentalFormulaFromBackend = (formDataFromBackend: any) => {
		setFormData((prevFormData: any) => {
			console.log('Backend Data:', formDataFromBackend);
			return { ...formDataFromBackend };
		});

		const updatedDentalFormula = { ...default_dental_formula };

		const propertiesToUpdate = [
			'bridge',
			'implant',
			'event',
			'referral',
			'empty',
			'deletion',
			'milk_theeth',
		];

		propertiesToUpdate.forEach((property) => {
			formDataFromBackend[property]?.forEach((item: any) => {
				const toothId = item.start || item.theeth;

				const direction = toothId[0] === '1' || toothId[0] === '2' ? 'top' : 'bottom';

				const toothIndex = updatedDentalFormula[direction].findIndex(
					(tooth: any) => tooth.id === toothId,
				);

				if (toothIndex > -1) {
					updatedDentalFormula[direction][toothIndex] = {
						...updatedDentalFormula[direction][toothIndex],
						[property]: true,
					};

					if (property === 'bridge') {
						const bridgeStart = item.start;
						const bridgeEnd = item.end;

						const startIdx = updatedDentalFormula[direction].findIndex(
							(tooth: any) => tooth.id === bridgeStart,
						);
						const endIdx = updatedDentalFormula[direction].findIndex(
							(tooth: any) => tooth.id === bridgeEnd,
						);

						if (startIdx > -1 && endIdx > -1) {
							const sequence = generateSequence(bridgeStart, bridgeEnd, direction);
							updatedDentalFormula[direction] = updatedDentalFormula[direction].map(
								(tooth: any) => {
									if (tooth.id === bridgeStart) {
										return { ...tooth, bridge: true, bridge_position: 'start' };
									} else if (tooth.id === bridgeEnd) {
										return { ...tooth, bridge: true, bridge_position: 'end' };
									} else if (sequence.includes(tooth.id)) {
										return {
											...tooth,
											bridge: true,
											bridge_position: 'center',
										};
									}
									return tooth;
								},
							);
						}
					}
				}
			});
		});

		setDentalFormula(updatedDentalFormula);
	};

	const setDisease = ({ color, id, position }: any) => {
		const direction = id[0] === '1' || id[0] === '2' ? 'top' : 'bottom';
		const updatedDentalFormula = dentalFormula;
		const toothIndex = updatedDentalFormula[direction].findIndex(
			(tooth: any) => tooth.id === id,
		);
		if (toothIndex > -1) {
			updatedDentalFormula[direction][toothIndex].enamel = {
				...updatedDentalFormula[direction][toothIndex].enamel,
				[position]: <SurfaceSVG surface={position} fill={color} />,
			};

			if (updatedDentalFormula !== dentalFormula) {
				setDentalFormula(updatedDentalFormula);
				return updatedDentalFormula;
			} else {
				return dentalFormula;
			}
		}
	};

	const value = useMemo(
		() => ({
			contextHolder,
			localFormData,
			setLocalFormData,
			localDentalFormula,
			updateDentalFormulaFromBackend,
			setLocalDentalFormula,
			generateSequence,
			handleTeethUpdate,
			updateTeethData,
			isModified,
			setIsModified,
			checkDirection,
			deleteItem,
			addItem,
			changeItemInfo,
			save,
			cancel,
			addItemAndUpdate,
			setDisease,
		}),
		[contextHolder, isModified, localDentalFormula, localFormData],
	);

	return (
		<DentalContextFunctions.Provider value={value}>{children}</DentalContextFunctions.Provider>
	);
};

// Define PropTypes for DentalContextFunctionsProvider component

// Export DentalContextFunctionsProvider component
DentalContextFunctionsProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default DentalContextFunctions;
