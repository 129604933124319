import { createSlice } from '@reduxjs/toolkit';

const formDataSlice = createSlice({
	name: 'formData',

	initialState: {
		complaint: '',
		diagnosType: 'Предварительный',
		MKBTenDiagnose: '',
		diagnoseOverview: '',
		examination: '',
		implant: [],
		bridge: [],
		isEvent: false,
		event: [],
		referral: [],
		empty: [],
		deletion: [],
		milk_theeth: [],
	},

	reducers: {
		setFormDataTest(state, action) {
			if (typeof action.payload === 'function') {
				return action.payload(state);
			} else {
				return action.payload;
			}
		},
	},

	extraReducers: (builder) => {},
});

export default formDataSlice;
export const { setFormDataTest } = formDataSlice.actions;
