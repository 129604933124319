import React, { SVGProps } from "react";

export const THEETH_21 = (props: SVGProps<SVGSVGElement>) => {

    return (
		<>
           <svg width="27" height="82" viewBox="0 0 27 82" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g filter="url(#filter0_i_402_4954)">
<path d="M17.7277 1.8269C6.72754 10.8269 1.40988 45.7953 2.97704 64.0679C3.38401 71.0447 26.1304 81.0553 25.6739 72.5826C24.9159 58.5166 20.7275 28.3269 22.2275 5.8269C21.4395 2.93047 19.3616 0.490074 17.7277 1.8269Z" fill="url(#paint0_linear_402_4954)"/>
<path d="M17.7277 1.8269C6.72754 10.8269 1.40988 45.7953 2.97704 64.0679C3.38401 71.0447 26.1304 81.0553 25.6739 72.5826C24.9159 58.5166 20.7275 28.3269 22.2275 5.8269C21.4395 2.93047 19.3616 0.490074 17.7277 1.8269Z" fill="url(#paint1_linear_402_4954)"/>
</g>
<path d="M18.1477 1.36151C6.0633 9.8866 0.307045 37.9189 3.25781 65.9512C5.06657 39.3624 8.18953 21.8325 18.4205 1.61372C18.5127 1.43157 18.3145 1.24383 18.1477 1.36151Z" fill="url(#paint2_linear_402_4954)"/>
<path d="M21.208 81.3269H3.20801C1.20801 81.3269 0.708008 79.3269 0.708008 78.3269C0.708008 77.3269 1.20801 47.3269 14.708 47.3269C28.208 47.3269 26.708 73.8269 26.708 75.8269C26.708 77.8269 23.208 81.3269 21.208 81.3269Z" fill="url(#paint3_linear_402_4954)"/>
<path d="M21.208 81.3269H3.20801C1.20801 81.3269 0.708008 79.3269 0.708008 78.3269C0.708008 77.3269 1.20801 47.3269 14.708 47.3269C28.208 47.3269 26.708 73.8269 26.708 75.8269C26.708 77.8269 23.208 81.3269 21.208 81.3269Z" fill="url(#paint4_radial_402_4954)" fill-opacity="0.3"/>
<defs>
<filter id="filter0_i_402_4954" x="-0.29248" y="1.45117" width="25.9731" height="77.4757" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-3" dy="5"/>
<feGaussianBlur stdDeviation="1.55"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_402_4954"/>
</filter>
<linearGradient id="paint0_linear_402_4954" x1="14.1942" y1="75.8269" x2="14.1942" y2="1.4511" gradientUnits="userSpaceOnUse">
<stop stop-color="#E87171"/>
<stop offset="1" stop-color="#935252"/>
</linearGradient>
<linearGradient id="paint1_linear_402_4954" x1="14.1942" y1="75.8269" x2="14.1942" y2="1.4511" gradientUnits="userSpaceOnUse">
<stop stop-color="#D29439"/>
<stop offset="1" stop-color="#887250"/>
</linearGradient>
<linearGradient id="paint2_linear_402_4954" x1="2.06327" y1="8.9034" x2="15.5496" y2="67.6892" gradientUnits="userSpaceOnUse">
<stop stop-color="#725935"/>
<stop offset="1" stop-color="#5B482A"/>
</linearGradient>
<linearGradient id="paint3_linear_402_4954" x1="13.7328" y1="47.3269" x2="13.7328" y2="81.3269" gradientUnits="userSpaceOnUse">
<stop stop-color="#B48B8B"/>
<stop offset="0.3" stop-color="white"/>
</linearGradient>
<radialGradient id="paint4_radial_402_4954" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(17.2576 70.9511) rotate(-48.5672) scale(8.21795 6.6278)">
<stop stop-color="white" stop-opacity="0"/>
<stop offset="1" stop-color="#9B9B9B"/>
</radialGradient>
</defs>
</svg>


        </>
	);
};