import React, { SVGProps } from "react";

export const THEETH_37 = (props: SVGProps<SVGSVGElement>) => {

    return (
		<>
           
           <svg width="2.46rem" height="4.3rem" viewBox="0 0 32 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g filter="url(#filter0_i_402_4979)">
<path d="M4.58786 53.4074C-6.37827 57.4686 6.9915 47.1111 3.97387 14.2151C9.52407 9.47696 22.7816 0.286315 28.9547 8.73371C29.5597 17.058 25.4985 55.2337 14.127 55.6398C8.83515 55.8288 19.8127 37.3642 16.9699 34.5213C11.6896 34.9274 10.4136 51.2498 4.58786 53.4074Z" fill="url(#paint0_radial_402_4979)"/>
</g>
<g filter="url(#filter1_i_402_4979)">
<path d="M4.23595 13.9059C14.7951 4.97573 2.61099 52.0513 0.986751 54.0851C0.0306592 55.2823 -0.637539 53.2751 3.01752 39.4668C3.97545 35.8479 3.99042 31.3817 4.23596 26.4708C4.64209 18.3483 2.04262 15.7609 4.23595 13.9059Z" fill="url(#paint1_linear_402_4979)"/>
</g>
<path d="M3.36 6.498C5.3094 -0.6498 13.3778 1.89529 17.1683 4.06131C18.3866 2.03075 23.6663 0 25.697 0C27.7276 0 33.0072 1.6245 30.9766 11.3715C29.3521 19.1691 21.0942 18.1403 17.1683 16.6511C7.82738 21.5246 0.923254 15.4328 3.36 6.498Z" fill="url(#paint2_linear_402_4979)"/>
<path d="M3.36 6.498C5.3094 -0.6498 13.3778 1.89529 17.1683 4.06131C18.3866 2.03075 23.6663 0 25.697 0C27.7276 0 33.0072 1.6245 30.9766 11.3715C29.3521 19.1691 21.0942 18.1403 17.1683 16.6511C7.82738 21.5246 0.923254 15.4328 3.36 6.498Z" fill="url(#paint3_radial_402_4979)" fill-opacity="0.3"/>
<g filter="url(#filter2_f_402_4979)">
<path d="M17.7316 8.11161L20.9855 4.31534C21.2821 3.9693 20.8714 3.4723 20.4757 3.69843L19.6685 4.15966L17.2318 5.78416L13.3914 4.42874C12.9406 4.26964 12.6332 4.88447 13.031 5.14963L17.5181 8.14106C17.5865 8.18663 17.6781 8.17399 17.7316 8.11161Z" fill="#BBA8A8"/>
<path d="M17.7316 8.11161L20.9855 4.31534C21.2821 3.9693 20.8714 3.4723 20.4757 3.69843L19.6685 4.15966L17.2318 5.78416L13.3914 4.42874C12.9406 4.26964 12.6332 4.88447 13.031 5.14963L17.5181 8.14106C17.5865 8.18663 17.6781 8.17399 17.7316 8.11161Z" stroke="#BBA8A8" stroke-width="0.81225"/>
</g>
<g filter="url(#filter3_f_402_4979)">
<path d="M15.2796 10.6576C16.0919 14.7188 12.2431 13.5004 10.0001 13.5004C7.75714 13.5004 8.37546 11.6397 8.37546 9.84531C8.37546 8.05094 14.6612 6.19019 16.9041 6.19019C19.1471 6.19019 17.7164 9.43919 15.2796 10.6576Z" fill="#FDFDFD"/>
</g>
<defs>
<filter id="filter0_i_402_4979" x="-3.05955" y="2.04641" width="32.0722" height="53.5949" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-4.8735" dy="-4.8735"/>
<feGaussianBlur stdDeviation="1.6245"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_402_4979"/>
</filter>
<filter id="filter1_i_402_4979" x="0.233398" y="12.7803" width="9.88915" height="43.2231" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="1.6245" dy="1.6245"/>
<feGaussianBlur stdDeviation="1.6245"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_402_4979"/>
</filter>
<filter id="filter2_f_402_4979" x="10.0017" y="0.795184" width="13.9316" height="10.216" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="1.21837" result="effect1_foregroundBlur_402_4979"/>
</filter>
<filter id="filter3_f_402_4979" x="4.23611" y="2.12894" width="17.8486" height="15.6042" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2.03063" result="effect1_foregroundBlur_402_4979"/>
</filter>
<radialGradient id="paint0_radial_402_4979" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(18.8565 27.7148) rotate(95.3322) scale(30.5918 17.514)">
<stop stop-color="#D7BA8E"/>
<stop offset="0.4967" stop-color="#A47F4A"/>
<stop offset="1" stop-color="#886024"/>
</radialGradient>
<linearGradient id="paint1_linear_402_4979" x1="8.718" y1="17.8695" x2="-1.84125" y2="54.4208" gradientUnits="userSpaceOnUse">
<stop stop-color="#725935"/>
<stop offset="1" stop-color="#5B482A"/>
</linearGradient>
<linearGradient id="paint2_linear_402_4979" x1="17.15" y1="0" x2="17.15" y2="18.378" gradientUnits="userSpaceOnUse">
<stop offset="0.64" stop-color="white"/>
<stop offset="1" stop-color="#B48B8B"/>
</linearGradient>
<radialGradient id="paint3_radial_402_4979" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25.7606 7.00248) rotate(63.435) scale(2.72437 4.23473)">
<stop stop-color="white" stop-opacity="0"/>
<stop offset="1" stop-color="#9B9B9B"/>
</radialGradient>
</defs>
</svg>

        </>
	);
};