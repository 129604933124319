import React from 'react';

const Name: React.FC<{ user: any; themeStatus: string }> = ({ user, themeStatus }) => {
	return (
		<p
			className={`d-flex align-items-start flex-column text-nowrap fs-5 ps-0 fw-bold mb-0 text-${
				themeStatus === 'light' ? 'dark' : 'light'
			}`}>
			<span>{`${user.surname} ${user.name} ${user.middleName}`}</span>
			<span className='small text-muted'>{user.phone}</span>
		</p>
	);
};
export default Name;
