import React, { FC, useContext, useEffect, useState } from 'react';
import Card, { CardBody, CardFooter, CardHeader } from '../../../components/bootstrap/Card';
import Icon from '../../../components/icon/Icon';
import Button from '../../../components/bootstrap/Button';
import Input from '../../../components/bootstrap/forms/Input';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import useDarkMode from '../../../hooks/useDarkMode';
import Textarea from '../../../components/bootstrap/forms/Textarea';
import { ServiceService } from '../../api/service/visit-service.service';
import { IService } from '@textnpayme/custom/api/interface/service.interface';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import * as qs from 'qs';
import { useDebounce } from '../../../hooks/useDebounce';
import Tooltips from '../../../components/bootstrap/Tooltips';

import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import PaginationButtons, {
	dataPagination,
	PER_COUNT,
	PER_COUNT_FILTER,
} from '../../../components/PaginationButtons';
import { StageService } from '../../api/service/stage.service';
import { VisitService } from '../../api/service/visit.service';
import { IVisit } from '@textnpayme/custom/api/interface/visit.interface';
import { includes } from 'lodash';
import { ServicesList } from './components/ServicesList';
import { ServicesListButton } from './components/ServicesListButton';
import DentalContext from '../../../contexts/dentalContext';
import { ConsultationListService } from '../../../custom/api/service/consulatation-list.service';
import { IConsultationList } from '@textnpayme/custom/api/interface/consultation-list.interface';
import DentalContextFunctions from '../../../contexts/dentalContextFunctions';
import { StagaeServiceService } from '../../../custom/api/service/stage-visit-service.service';

interface IServiceChooseModalProps {
	serviceDropdownInfo?: any;
	setServiceDropdownInfo?(...args: unknown[]): unknown;
	stage: any;
	medicalCard?: boolean;
	isStage?: boolean;
	createVisit?: boolean;
	setChosenValues?(...args: unknown[]): unknown;
	setServiceUuid?(...args: unknown[]): unknown;
	setSubmitStage?(...args: unknown[]): unknown;
	setIsNoServices?: any;
	setChosenServicesCount?: any;
	formRef?: any;
}

interface CreateServiceParams {
	inputDto: IService;
	uuid: string;
}

interface CreateConsultationParams {
	inputDto: IConsultationList;
	uuid: string;
}

const ServiceChooseComponent: FC<IServiceChooseModalProps> = ({
	serviceDropdownInfo,
	setServiceUuid,
	setServiceDropdownInfo,
	stage,
	medicalCard,
	setChosenValues,
	isStage,
	createVisit,
	setSubmitStage,
	formRef,
	setIsNoServices,
	setChosenServicesCount,
}) => {
	const { formData, setFormData, event_default, dentalFormula } = useContext(DentalContext);
	const { localFormData, updateDentalFormulaFromBackend, getServicesUuids } =
		useContext(DentalContextFunctions);

	// Function to trigger the file input click
	const [values, setValues] = useState<IService>({
		services: serviceDropdownInfo ?? [],
		serviceName: '',
		serviceNameChosen: '',
	});

	const [choosenServices, setChosenService] = useState<any[]>([]);
	const [localChoosenServices, setLocalChosenService] = useState<any[]>([...choosenServices]);
	const [choosenServicesData, setChoosenServiceData] = useState<any[]>([]);

	// useEffect(() => {
	// 	if (
	// 		serviceDropdownInfo &&
	// 		serviceDropdownInfo?.length &&
	// 		serviceDropdownInfo.some((i: any) => !values?.services?.includes(i))
	// 	) {
	// setValues((prev) => ({
	// 	...prev,
	// 	services: serviceDropdownInfo,
	// }));
	// 	}
	// }, [serviceDropdownInfo]);

	const [popperIsOpenService, setPopperIsOpenService] = useState(false);
	const [servicesCurrentPage, setServicesCurrentPage] = useState<number>(1);
	const [servicesPerPage, setServicesPerPage] = useState<number>(PER_COUNT_FILTER['5']);

	const [servicesChosenCurrentPage, setServicesChosenCurrentPage] = useState<number>(1);
	const [servicesChosenPerPage, setServicesChosenPerPage] = useState<number>(
		PER_COUNT_FILTER['5'],
	);

	const { themeStatus } = useDarkMode();

	const changeValues = (key: string, value: string | number) => {
		if (key === 'services') {
			setValues((prev) => ({
				...prev,
				[key]: [...(values[key] || []), value], // Ensure values[key] is an array
			}));
		}
	};

	const changeChosenServicesValue = (
		key: string,
		value: string | number,
		index: number,
		uuid: string,
	) => {
		const changedItem = choosenServices.find(
			(item_l: any, index_l: number) => item_l.uuid === uuid,
		);
		console.log(changedItem, 'changedItemchangedItem');

		setChosenService((prev: any) => {
			const updatedServices = [...prev];
			if (updatedServices[index]) {
				updatedServices[index] = {
					...updatedServices[index],
					[key]: value,
				};
			}
			setLocalChosenService([...updatedServices]);
			return updatedServices;
		});
	};

	const handleCheckboxChange = (e: any, item?: any, type?: string) => {
		console.log(item,'itemitem');
		
		const name = e.target.name;
		if (e.target.type === 'checkbox') {
			// Find the item in choosenServicesData by matching uuid and either teeth or start/end.
			const itemExists = choosenServicesData.some((service: any) => {
				if (service.uuid === item.uuid) {
					if (type === 'bridge') {
						return (
							service.start.toString() === item.start.toString() &&
							service.end.toString() === item.end.toString()
						);
					} else {
						return service.theeth.toString() === item.theeth.toString();
					}
				}
				return false;
			});

			if (!itemExists) {
				// If checked and the item doesn't exist, add it to the array
				setChoosenServiceData((prev: any[]) => {
					if (setServiceUuid) {
						setServiceUuid([...prev, item]);
						// setValues((prev_value) => ({
						// 	...prev_value,
						// 	services: [...prev, item],
						// }));
					}

					if (setChosenValues) {
						setChosenValues({ services: [...prev, item] });
					}
					return [...prev, item];
				});
			} else if (itemExists) {
				// If unchecked and the item exists, remove it from the array
				setChoosenServiceData((prev: any[]) => {
					const data = [
						...prev.filter((service: any) => {
							if (service.uuid === item.uuid) {
								if (type === 'bridge') {
									return !(
										service.start === item.start && service.end === item.end
									);
								} else {
									return service.theeth !== item.theeth;
								}
							}
							return true;
						}),
					];

					if (setServiceUuid) {
						setServiceUuid([...data]);
					}

					if (setChosenValues) {
						setChosenValues({ services: [...data] });
					}

					return [...data];
				});
			}
		}

		// Handle search input changes
		if (e.target.type === 'search') {
			setValues((prev) => ({
				...prev,
				[name]: e.target.value,
			}));
		}
	};

	console.log(values, 'setAndLogServicessetAndLogServices');

	const { data: consulatation_list }: { data: any[] | any; isLoading: boolean } = useQuery(
		['consulatation_list', stage],
		() =>
			ConsultationListService.findAll(
				qs.stringify({
					queryMeta: {
						order: { createdAt: 'DESC' },
					},
					includeMeta: [
						{
							association: 'stage',
							where: {
								uuid: stage,
							},
						},
					],
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	const DebounsedSearch = useDebounce(values);
	const { data: services }: { data: IService[] | any; isLoading: boolean } = useQuery(
		[
			'services',
			DebounsedSearch,
			servicesCurrentPage,
			servicesPerPage,
			isStage,
			stage,
			medicalCard,
		],
		() =>
			StagaeServiceService.findAll(
				qs.stringify({
					// queryMeta: {
					// 	paginate: true,
					// 	limit: servicesPerPage,
					// 	page: DebounsedSearch?.serviceName.length ? 1 : servicesCurrentPage,
					// 	order: { createdAt: 'DESC' },
					// },
					...(!medicalCard &&
						isStage &&
						stage && {
							includeMeta: [
								{
									association: 'stage',
									where: {
										uuid: stage,
									},
								},
								{
									association: 'services',
								},
							],
						}),
					filterMeta: {
						...(values.serviceName && {
							...(values.serviceName && /\d/.test(values.serviceName)
								? { serviceId: { iLike: `%${values.serviceName}%` } }
								: { title: { iLike: `%${values.serviceName}%` } }),
						}),
					},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	const { data: services_count }: { data: IService[] | any; isLoading: boolean } = useQuery(
		['services_count', isStage, DebounsedSearch, stage, medicalCard],
		() =>
			StagaeServiceService.findAll(
				qs.stringify({
					queryMeta: {
						order: { createdAt: 'DESC' },
					},

					...(!medicalCard &&
						isStage &&
						stage && {
							includeMeta: [
								{
									association: 'stage',
									where: {
										uuid: stage,
									},
								},

								{
									association: 'services',
								},
							],
						}),

					filterMeta: {
						...(values.serviceName && {
							...(values.serviceName && /\d/.test(values.serviceName)
								? { serviceId: { iLike: `%${values.serviceName}%` } }
								: { title: { iLike: `%${values.serviceName}%` } }),
						}),
					},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	// const { data: visits_services }: { data: IVisit[] | any; isLoading: boolean } = useQuery(
	// 	['visits_services', stage],
	// 	() =>
	// 		VisitService.findAll(
	// 			qs.stringify({
	// 				filterMeta: {
	// 					stageUuid: stage,
	// 				},
	// 				includeMeta: [
	// 					{
	// 						association: 'services',
	// 					},
	// 				],

	// 				queryMeta: {
	// 					order: { createdAt: 'DESC' },
	// 				},
	// 			}),
	// 		),
	// 	{
	// 		keepPreviousData: true,
	// 		refetchOnWindowFocus: false,
	// 	},
	// );

	useEffect(() => {
		if (services?.data?.length > 0) {
			setLocalChosenService((prev: any) => {
				const data = services.data.map((item: any) => item.event); // Use map instead of forEach
				console.log(data, 'localChosenService data');
				return [...data]; // Spread the new array into the state
			});

			setChosenService((prev: any) => {
				const data = services.data.map((item: any) => item.event); // Use map instead of forEach
				console.log(data, 'chosenService data');
				return [...data]; // Spread the new array into the state
			});
		}
	}, [services]);

	console.log(choosenServices, 'ascascasc');

	const hendelDeleteService = (uuid: string | undefined, index: number) => {
		if (!uuid || !values?.services) return;

		setChoosenServiceData((prev: any) => {
			if (!prev) return prev;

			// Remove the service with the specific UUID and index
			const updatedServices = prev.filter((value: any, idx: number) => idx !== index);

			// Update the services in values after removing the selected service
			setValues((prevValues) => ({
				...prevValues,
				services: updatedServices.map((item: any) => item?.uuid),
			}));

			return updatedServices;
		});
	};
	const [serviceNameChosenArray, setServiceNameChosenArray] = useState([]);

	const DebounsedSearchFilterLocal = useDebounce(values.serviceNameChosen);

	useEffect(() => {
		if (values.serviceNameChosen) {
			let filteredArray = [] as any;
			if (/\d/.test(values.serviceNameChosen)) {
				choosenServicesData?.filter((item) => {
					item?.serviceId?.includes(values.serviceNameChosen);
				});
			} else {
				filteredArray = choosenServicesData?.filter(
					(item) => item?.title?.includes(values.serviceNameChosen),
				);
			}

			// Sort the filteredArray to have the last added item on top

			setServiceNameChosenArray(filteredArray);
		}
	}, [DebounsedSearchFilterLocal, choosenServicesData, values.serviceNameChosen]);

	useEffect(() => {
		setIsNoServices(services?.data.length > 0 ? false : true);
		setChosenServicesCount(choosenServicesData?.length);
	}, [services?.data?.length, choosenServicesData?.length]);

	const isItemInArray = (item: any) => {
		return choosenServicesData.some((service: any) => {
			if (service.uuid === item.uuid) {
				if (item.service_type === 'bridge') {
					return (
						service.start.toString() === item.start.toString() &&
						service.end.toString() === item.end.toString()
					);
				} else {
					return service.theeth.toString() === item.theeth.toString();
				}
			}
			return false;
		});
		
	};

	// useEffect(() => {
	// 	updateDentalFormulaFromBackend(choosenServices);
	// }, [choosenServices.length]);

	return (
		<>
			{services ? (
				<form ref={formRef} style={{ width: '100%', padding: 0 }}>
					<CardBody className='col-12 d-flex w-100 p-0 justify-content-between'>
						<div className='col-5 d-flex flex-column gap-2' style={{ width: '48%' }}>
							<span className='fs-5 fw-bold'>Список Услуг</span>
							<div
								// style={{ minHeight: 420 }}
								className='col-12 d-flex flex-column h-100 justify-content-between pb-5'>
								<div className='d-flex flex-column flex-wrap col-12 gap-2'>
									<FormGroup
										id='surname'
										label='Введите название или код услуги'
										className='col-12'>
										<Input
											name='serviceName'
											type={'search'}
											onChange={(e: any) => handleCheckboxChange(e)}
											value={values?.serviceName}
										/>
									</FormGroup>
									{services.data.length ? (
										services?.data?.map((i: IService, index: number) => (
											<>
												<ServicesList
													localChosenServices={localChoosenServices}
													setLocalChosenServices={setLocalChosenService}
													type={i.event.service_type}
													chosenServices={choosenServices}
													setChosenServices={setChosenService}
													index={index}
													disable
													item={i.event}
													isDisabled={
														!createVisit &&
														!isStage &&
														services.data
															.map((item: any) => item.service)
															?.some(
																(item: any) =>
																	item?.services.some(
																		(service: any) =>
																			service?.uuid ===
																			i?.uuid,
																	),
															)
													}
													isChecked={isItemInArray(i.event)}
													handleCheckboxChange={handleCheckboxChange}
													themeStatus={themeStatus}
												/>
											</>
										))
									) : (
										<h4>Услуги не найдены</h4>
									)}
								</div>
								<div className='col-12'>
									{/* {stage && isStage || createVisit ? (
										<PaginationButtons
											data={services || []}
											label='customers'
											small
											filterPagination
											setCurrentPage={setServicesCurrentPage}
											currentPage={servicesCurrentPage}
											perPage={servicesPerPage}
											setPerPage={setServicesPerPage}
											totalPage={
												Math.ceil(
													services_count?.meta?.count / servicesPerPage,
												) || 1
											}
										/>
									) : ( */}
									<PaginationButtons
										data={services || []}
										label='customers'
										className='card-footer'
										small
										filterPagination
										setCurrentPage={setServicesCurrentPage}
										currentPage={servicesCurrentPage}
										perPage={servicesPerPage}
										setPerPage={setServicesPerPage}
										totalPage={
											Math.ceil(
												services?.data?.length / servicesPerPage,
											) || 1
										}
									/>
									{/* )} */}
								</div>
							</div>
						</div>
						<div
							className='col-1 d-flex justify-content-center aligin-items-center'
							style={{ width: '4%' }}>
							<div
								style={{
									width: 3,
									height: '100%',
									backgroundColor: 'rgba(123, 123, 123, 0.4)',
								}}></div>
						</div>
						<div className='col-5 d-flex flex-column gap-2' style={{ width: '48%' }}>
							<span className='fs-5 fw-bold'>Выбранные Услуги</span>
							<div
								// style={{ minHeight: 420 }}
								className='col-12 d-flex flex-column h-100 justify-content-between pb-5'>
								<div className='d-flex flex-column flex-wrap col-12 gap-2'>
									<FormGroup
										id='surname'
										label='Введите название или код услуги'
										className='col-12'>
										<Input
											name='serviceNameChosen'
											type={'search'}
											onChange={(e: any) => handleCheckboxChange(e)}
											value={values?.serviceNameChosen}
										/>
									</FormGroup>
									{DebounsedSearchFilterLocal ? (
										serviceNameChosenArray.length ? (
											dataPagination(
												serviceNameChosenArray,
												servicesChosenCurrentPage,
												servicesPerPage,
											).map((i: IService, index: number) => (
												<ServicesList
													localChosenServices={localChoosenServices}
													setLocalChosenServices={setLocalChosenService}
													type={i.service_type}
													chosenServices={choosenServices}
													setChosenServices={setChosenService}
													index={index}
													disable
													item={i}
													isDisabled={
														!createVisit &&
														!isStage &&
														services?.data
															?.map((item: any) => item.service)
															?.some(
																(item: any) =>
																	item?.services.some(
																		(service: any) =>
																			service?.uuid ===
																			i?.uuid,
																	),
															)
													}
													handleDeleteService={hendelDeleteService}
												/>
											))
										) : (
											<h4>Услуги не найдены</h4>
										)
									) : choosenServicesData ? (
										dataPagination(
											choosenServicesData,
											servicesChosenCurrentPage,
											servicesPerPage,
										).map((i: IService, index: number) => (
											<ServicesList
												type={i.service_type}
												localChosenServices={localChoosenServices}
												setLocalChosenServices={setLocalChosenService}
												chosenServices={choosenServices}
												setChosenServices={setChosenService}
												index={index}
												item={i}
												disable
												isDisabled={
													!createVisit &&
													!isStage &&
													services
														.map((item: any) => item.service)
														?.some(
															(item: any) =>
																item?.services.some(
																	(service: any) =>
																		service?.uuid === i?.uuid,
																),
														)
												}
												handleDeleteService={hendelDeleteService}
											/>
										))
									) : (
										<h4>Услуги не найдены</h4>
									)}
								</div>
								{choosenServicesData?.length > 5 && (
									<div className='col-12'>
										<PaginationButtons
											data={choosenServicesData || []}
											label='customers'
											small
											filterPagination
											serviceModal
											setCurrentPage={setServicesChosenCurrentPage}
											currentPage={servicesChosenCurrentPage}
											perPage={servicesPerPage}
											setPerPage={setServicesPerPage}
											totalPage={
												Math.ceil(
													choosenServicesData?.length / servicesPerPage,
												) || 1
											}
										/>
									</div>
								)}
							</div>
						</div>
					</CardBody>
				</form>
			) : (
				<></>
			)}
		</>
	);
};

export default ServiceChooseComponent;
