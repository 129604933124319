import { useContext, useEffect, useState } from 'react';
import { useMotionValue, useTransform } from 'framer-motion';
import ThemeContext from '../contexts/themeContext';
import useDeviceScreen from './useDeviceScreen';

interface IAsideTouchProps {
	leftOffset: number;
}

const useAsideTouch = ({ leftOffset }: IAsideTouchProps) => {
	const { asideStatus } = useContext(ThemeContext);
	const deviceScreen = useDeviceScreen();

	const mobileDesign =
		typeof deviceScreen?.width !== 'undefined' &&
		deviceScreen?.width <= Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE);
	const hasTouchButton =
		typeof deviceScreen?.width !== 'undefined' &&
		deviceScreen?.width > Number(process.env.REACT_APP_ASIDE_MINIMIZE_BREAKPOINT_SIZE);

	const [asideWidthWithSpace, setAsideWidthWithSpace] = useState(-leftOffset);
	const x = useMotionValue(
		process.env.REACT_APP_ASIDE_TOUCH_STATUS === 'true' ? 0 : asideWidthWithSpace,
	);

	useEffect(() => {
		setAsideWidthWithSpace(-leftOffset);
	}, [leftOffset]);

	useEffect(() => {
		x.set(process.env.REACT_APP_ASIDE_TOUCH_STATUS === 'true' ? 0 : asideWidthWithSpace);
	}, [asideWidthWithSpace]);

	const left = useTransform(
		x,
		[-100, -90, -10, 0],
		[asideWidthWithSpace, asideWidthWithSpace, 0, 0],
	);

	const [touchStatus, setTouchStatus] = useState(!x.get());

	useEffect(() => {
		function updateX() {
			const X = x.get();
			setTouchStatus(!X);
		}

		const unsubscribeX = x.onChange(updateX);

		return () => {
			unsubscribeX();
		};
	}, [x]);

	useEffect(() => {
		if (!hasTouchButton) {
			x.set(0);
		}
	}, [hasTouchButton, deviceScreen.width, x]);

	useEffect(() => {
		if (!asideStatus) {
			x.set(0);
			setTouchStatus(false);
		}
	}, [asideStatus, x]);

	const asideStyle = hasTouchButton ? { left } : { left: mobileDesign ? undefined : '0rem' };

	return { asideStyle, touchStatus, hasTouchButton, asideWidthWithSpace, x };
};

export default useAsideTouch;
