/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable prettier/prettier */
import React from 'react';
import classNames from 'classnames';
import Header, { HeaderLeft } from '../../../layout/Header/Header';
import useDarkMode from '../../../hooks/useDarkMode';
import Clock from './components/Clock';
import CurrentDate from './components/Date';
import Weather from './components/Weather';
import CustomHeaderRight from './components/CustomHeaderRight';
import HeaderChat from './components/HeaderChat';
import Icon from '../../../components/icon/Icon';
import { CardLabel, CardTitle } from '../../../components/bootstrap/Card';

const WidgetHeader = () => {
	const { darkModeStatus } = useDarkMode();
	return (
		<Header>
			<HeaderLeft>
				<div className='fs-2 d-flex align-items-center gap-3 '>
					<div
						className={classNames('fs-2', 'fw-bold', {
							'text-dark': !darkModeStatus,
						})}>
						<CardLabel
							className='d-flex flex-row align-items-center gap-2'
							icon='AccessTime'
							iconColor='primary'>
							<Clock />
						</CardLabel>
					</div>
					<CurrentDate />
					<Weather />
				</div>
			</HeaderLeft>
			<CustomHeaderRight afterChildren={<HeaderChat />} />
		</Header>
	);
};

export default WidgetHeader;
